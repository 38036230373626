<div *ngIf="showGraph && permissionsAvailable && dataAvailable">
    <hm-sentiment-listings-graph *ngIf="dataAvailable" [selectedMeasure]="selectedReportMeasure" [data]="chartData" [chartName]="chartName">
    </hm-sentiment-listings-graph>
    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>

<loading></loading>
<hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

<hm-sentiment-listings-table
    *ngIf="showTable"
    [data]="tableData"
    [dataAvailable]="dataAvailable"
    [selectedMeasure]="selectedMeasure"
></hm-sentiment-listings-table>
