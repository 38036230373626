import * as am4charts from '@amcharts/amcharts4/charts';
import { Component } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'hm-stacked-column',
    templateUrl: `../chart/chart.component.html`,
    styleUrls: ['../chart/chart.component.scss'],
})
export class StackedColumnComponent extends ChartComponent {
    // Overrides ChartComponent.createSeries
    createSeries(field, name) {
        // Create series
        const series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = field;
        if (this.options.xAxis.type === 'category') {
            series.dataFields.categoryX = this.options.xFieldName;
        } else if (this.options.xAxis.type === 'date') {
            series.dataFields.dateX = this.options.xFieldName;
        }
        series.name = name;

        // Make it stacked
        series.stacked = true;

        series.columns.template.tooltipText =
            '[bold]{name}[/]\n[font-size:14px]{categoryX}: [bold]{valueY}[/]';
        series.numberFormatter.numberFormat = '#,###.##a';
        series.numberFormatter.bigNumberPrefixes = [
            { number: 1e3, suffix: 'k' },
            { number: 1e6, suffix: 'm' },
            { number: 1e9, suffix: 'b' },
            { number: 1e12, suffix: 't' },
            { number: 1e15, suffix: 'q' },
        ];

        return series;
    }
}
