import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectDeviceGPS } from 'app/core/session-info/store/selectors/session-info.selector';
import { isDateValidForDataset } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { Subscription } from 'rxjs';
import { Measure } from '../../../measures/shared/measure.model';
import { DemographicsService } from '../demographics.service';
import { DeviceGPSDG } from './shared/device-gps-demographics.model';

@Component({
    selector: 'hm-device-gps-demographics',
    templateUrl: './device-gps-demographics.component.html',
    styleUrls: ['./device-gps-demographics.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DeviceGpsDemographicComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];
    @Input() showTable: boolean = true;
    @Input() showGraph: boolean = true;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('exporter') exporter: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() reportDate: string;

    displayedColumns = [
        'age_band',
        'gender',
        'total_visits_local',
        'total_visits_visitor',
        'unique_visits_local',
        'unique_visits_visitor',
        'local_ratio',
        'visitor_ratio',
    ];

    reportMeasures: Measure[] = [
        { uniqueName: 'total_visits_local', title: 'Total Visits (Local)', format: '#,###' },
        { uniqueName: 'total_visits_visitor', title: 'Total Visits (Visitor)', format: '#,###' },
        { uniqueName: 'unique_visits_local', title: 'Unique Visits (Local)', format: '#,###' },
        { uniqueName: 'unique_visits_visitor', title: 'Unique Visits (Visitor)', format: '#,###' },
        { uniqueName: 'local_ratio', title: 'Local Ratio', format: '#,###%' },
        { uniqueName: 'visitor_ratio', title: 'Visitor Ratio', format: '#,###%' },
    ];

    /** LISTINGS GRAPH */
    selectedReportMeasure = this.reportMeasures[0];
    mobile_GPD_Data: DeviceGPSDG[] = [];
    dataSource: MatTableDataSource<DeviceGPSDG> = new MatTableDataSource();
    multipleBoundaries = false;
    dataAvailable = true;
    permissionsAvailable = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private demoService: DemographicsService,
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.checkPermissions();
        this.refreshData();
    }
    checkPermissions() {
        const rights = this.store.pipe(select(selectDeviceGPS));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = isDateValidForDataset(r[0], this.reportDate);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
            this.refreshData();
        } else if (changes.reportDate) {
            this.checkPermissions();
            if (this.permissionsAvailable == true) {
                this.dataAvailable = true;
                this.refreshData();
            }
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'device-gps' }); // File name: name of tab + timestamp
    }

    selectedMeasure(selectedMeasure: Measure) {
        this.selectedReportMeasure = selectedMeasure;
        this.ref.detectChanges();
        this.dataSource.sort = this.sort;
    }

    onSelectReportMeasure() {
        // change chart
    }

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            this.subscriptions.push(
                this.demoService
                    .getDeviceGPS(
                        this.boundaryType as Level,
                        this.reportDate,
                        this.boundaries.map((boundary) => boundary.code)
                    )
                    .subscribe((res: DeviceGPSDG[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.dataSource.data = res;
                            this.mobile_GPD_Data = res;
                            this.dataSource.sort = this.sort;
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
