import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { ChartOptions } from '../shared/charts.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { isPlatformBrowser } from '@angular/common';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'hm-chart',
    templateUrl: `./chart.component.html`,
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() options: ChartOptions;

    id = `chart-${Math.random().toString()})`;
    legendId = `legend-${Math.random().toString()})`;

    amColours: string[];

    public chart: am4charts.XYChart;
    public categoryAxis: am4charts.CategoryAxis | am4charts.DateAxis;
    public valueAxis: am4charts.ValueAxis;

    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnInit(): void {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colours) => {
                this.amColours = colours;
            });
    }

    ngAfterViewInit(): void {
        this.browserOnly(() => {
            // Create chart instance
            this.chart = am4core.create(this.id, am4charts.XYChart);
            am4core.addLicense(environment.amcharts.licenseKey);
            am4core.options.autoDispose = true;

            // Hiding AmCharts logo
            this.chart.logo.height = -20000;

            const legendContainer = am4core.create(this.legendId, am4core.Container);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);
            legendContainer.logo.height = -20000;
            this.chart.legend = new am4charts.Legend();
            this.chart.legend.parent = legendContainer;
            this.chart.legend.scrollable = true;

            this.chart.data = this.options.data;

            // Create axes
            this.setCategoryAxis();

            this.setValueAxis();

            this.setColours();

            if (this.options.data.length > 0) {
                if (this.chart.modal) {
                    this.chart.closeModal();
                }

                this.draw();
                this.setToggleAll();
            } else {
                this.chart.openModal('Please select a different measure.', 'Data unavailable');
            }

            this.chart.legend.itemContainers.template.events.on('hit', (e) => {
                const target = e.target;
                const currentSeries = target.dataItem.dataContext;

                if (e.event.ctrlKey) {
                    this.chart.legend.itemContainers.template.togglable = false;

                    target.isActive = false;

                    this.chart.series.each((s) => {
                        if (s !== currentSeries && s.name !== 'All') {
                            s.hide();
                        }
                    });
                } else {
                    this.chart.legend.itemContainers.template.togglable = true;
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.isFirstChange()) {
            // Reset data
            this.chart.data = this.options.data;
            // Reset colours
            this.setColours();

            this.valueAxis.title.text = this.options.yAxis.title;

            this.draw();
            this.setToggleAll();
            if (this.options.data.length > 0) {
                if (this.chart.modal) {
                    this.chart.closeModal();
                }
            } else {
                this.chart.openModal('Please select a different measure.', 'Data unavailable');
            }
        }
    }

    ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setCategoryAxis() {
        switch (this.options.xAxis.type) {
            case 'date':
                this.categoryAxis = this.chart.xAxes.push(new am4charts.DateAxis());
                this.categoryAxis.renderer.grid.template.location = 0.5;
                this.categoryAxis.dateFormatter.inputDateFormat = this.options.xAxis.inputFormat;
                this.categoryAxis.renderer.minGridDistance = 30;
                this.categoryAxis.renderer.labels.template.rotation = 90;
                this.categoryAxis.renderer.labels.template.valign = 'top';
                break;
            case 'category':
                this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                this.categoryAxis.dataFields.category = this.options.xFieldName;
                this.categoryAxis.renderer.grid.template.location = 0;
                this.categoryAxis.renderer.minGridDistance = 30;

                break;
        }
        if (this.options.xAxis.title) {
            this.categoryAxis.title.text = this.options.xAxis.title;
        }
    }

    setValueAxis() {
        this.valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        this.valueAxis.min = 0;
        if (this.options.yAxis.title) {
            this.valueAxis.title.text = this.options.yAxis.title;
        }
    }

    setColours() {
        const colourList = [];
        this.amColours.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        this.chart.colors.list = colourList;
    }

    draw() {
        this.chart.series.clear();
        this.options.yFieldNames.forEach(({ name, title }) => {
            this.createSeries(name, title);
        });
    }

    createSeries(field: string, name: string): am4charts.XYSeries {
        throw new Error("Method 'createSeries' must be implemented in subclass");
    }

    setToggleAll() {
        if (this.chart.series.length > 1) {
            const toggleAllRadius = 12;
            const marker = this.chart.legend.markers.template.children.getIndex(0);

            (marker as am4core.RoundedRectangle).adapter.add(
                'cornerRadiusBottomLeft',
                (radius, target) => {
                    if (target.dataItem && target.dataItem.dataContext['name'] === 'All') {
                        return toggleAllRadius;
                    }
                    return radius;
                }
            );
            (marker as am4core.RoundedRectangle).adapter.add(
                'cornerRadiusBottomRight',
                (radius, target) => {
                    if (target.dataItem && target.dataItem.dataContext['name'] === 'All') {
                        return toggleAllRadius;
                    }
                    return radius;
                }
            );
            (marker as am4core.RoundedRectangle).adapter.add(
                'cornerRadiusTopLeft',
                (radius, target) => {
                    if (target.dataItem && target.dataItem.dataContext['name'] === 'All') {
                        return toggleAllRadius;
                    }
                    return radius;
                }
            );
            (marker as am4core.RoundedRectangle).adapter.add(
                'cornerRadiusTopRight',
                (radius, target) => {
                    if (target.dataItem && target.dataItem.dataContext['name'] === 'All') {
                        return toggleAllRadius;
                    }
                    return radius;
                }
            );

            const toggleAllSeries = this.createSeries('void', 'All');
            toggleAllSeries.events.on('shown', () => {
                this.chart.series.each((s) => {
                    s.show();
                });
            });
            toggleAllSeries.events.on('hidden', () => {
                this.chart.series.each((s) => {
                    s.hide();
                });
            });
        }
    }
}
