<!-- EXPORT FUNCTION -->
<div class="row justify-content-end">
    <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
        <mat-icon>file_download</mat-icon>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="exportAs()">CSV</button>
    </mat-menu>
</div>
<div class="row">
    <!-- TABLE -->
    <mat-table matTableExporter [dataSource]="dataSource" class="table-container" #exporter="matTableExporter" matSort>
        <ng-container matColumnDef="{{ column.uniqueName }}" *ngFor="let column of measures">
            <mat-header-cell class="dc-label" *matHeaderCellDef mat-sort-header>
                {{ column.title }}
            </mat-header-cell>
            <mat-cell mat-cell class="dc-content" *matCellDef="let element">
                <div *ngIf="isNumber(element[column.uniqueName]); else notNumber">
                    {{ element[column.uniqueName] | number: '.0-2' }}
                </div>
                <ng-template #notNumber>
                    {{ element[column.uniqueName] }}
                </ng-template>
                <!-- Data is referenced with the column.uniqueName key formatted to none or two decimal places -->
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
