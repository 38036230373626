import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GeoJsonProperties } from 'geojson';
import { LngLatLike, Map, MapLayerMouseEvent } from 'mapbox-gl';

import _ from 'lodash';
import {
    AsyncSubject,
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    of,
} from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { AppState } from 'app/core/reducers';
import {
    selectSettingLngLat,
    selectSettingZoom,
    selectTileServerUrl,
} from 'app/core/session-info/store/selectors/session-info.selector';
import { selectUserDataPermissionsByType } from 'app/core/user-data-permissions/store/selectors/user-data-permissions.selectors';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { SelectedPoint } from 'app/views/pages/hotels/shared/selected-point.model';
import { Category } from 'app/views/partials/content/category/shared/category.model';
import { environment } from 'environments/environment';

import { MapboxStyles } from 'app/core/whitelabel/shared/whitelabel.model';
import { selectWhitelabelState } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-boundary-selection-map',
    templateUrl: './boundary-selection-map.component.html',
    styleUrls: ['./boundary-selection-map.component.scss'],
})
export class BoundarySelectionMapComponent implements OnInit, OnChanges, OnDestroy {
    /** Show layer with listings color-coded by category */
    @Input() showListings: boolean = true;
    /** Whether to hide/dim boundaries that the user doesn't have access to */
    @Input() ownBoundaries: boolean = true;
    @Input() mapZoom: number = null;
    @Input() mapCenter: [number, number] = null;
    @Input() singleSelection: boolean = false;
    @Input() categories: Category[] = [];
    @Input() boundaryType: DataPermissionType = DataPermissionType.STATE;
    @Input() selectedBoundaries: Boundary[] = [];
    @Output() selectedBoundariesChange = new EventEmitter<Boundary[]>();
    @Input() mapResize: Observable<void> = EMPTY;

    listingsUrl: string = `${environment.tileserver.baseUrl}/capabilities/listings.json`;

    // Map
    private map$ = new AsyncSubject<Map>();
    style: Observable<string>;

    cursorStyle = '';

    mapSelectedColor;
    mapNoRightsColor;
    mapLineColor;

    listingsPaintConfig: any = {
        'circle-color': '#fff',
        'circle-radius': 2.2,
        'circle-stroke-width': 0.6,
        'circle-stroke-color': '#fff',
    };
    filterBoundariesAllowed: any[] = ['in'];
    filterBoundariesDisallowed: any[] = ['!'];
    filterListingsCategories: any[];

    lnglat$: Observable<number[]>;
    zoom$: Observable<number>;
    boundaryMap$ = new BehaviorSubject<string>('');
    permissionMap$ = new BehaviorSubject<string>('');

    permissionsSub: Subscription;
    mapResizeSub: Subscription;

    // Map popup
    popupLngLat: LngLatLike = null;
    popupProps: GeoJsonProperties = null;
    private hoveredBoundaryCode: any = null;

    private componentDestroyed = new Subject<void>();

    constructor(private store: Store<AppState>) {}
    private destroy$ = new Subject<void>();
    tileserverUrl: string;

    ngOnInit() {
        combineLatest([
            this.store.select(selectWhitelabelState),
            this.store.select(selectTileServerUrl),
        ])
            .pipe(
                takeUntil(this.destroy$),
                map(([wl, ts]) => {
                    this.tileserverUrl = ts;
                    this.boundaryMap$.next(
                        `${this.tileserverUrl}${this.boundaryType.toLowerCase()}.json`
                    );
                    this.mapSelectedColor = wl.whitelabel.mapboxSelectedColor;
                    this.mapNoRightsColor = wl.whitelabel.mapboxNoRightsColor;
                    this.mapLineColor = wl.whitelabel.mapLineColor;
                })
            )
            .subscribe();

        this.style = of(MapboxStyles.DNSW);
        if (this.ownBoundaries) {
            this.initPermissionFilters();
        }

        if (this.mapCenter) {
            this.lnglat$ = of(this.mapCenter);
        } else {
            this.lnglat$ = this.store.select(selectSettingLngLat);
        }
        if (this.mapZoom) {
            this.zoom$ = of(this.mapZoom);
        } else {
            this.zoom$ = this.store.select(selectSettingZoom);
        }
    }

    ngOnDestroy() {
        if (this.permissionsSub) {
            this.permissionsSub.unsubscribe();
        }
        if (this.mapResizeSub) {
            this.mapResizeSub.unsubscribe();
        }
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.categories) {
            if (changes.categories.isFirstChange()) {
                this.initListingsPaintConfig();
            }
            this.setListingsCategoriesFilter();
        }

        // // removing boundaries must happen before type change
        if (changes.selectedBoundaries) {
            if (!changes.selectedBoundaries.isFirstChange()) {
                this.clearSelection(changes.selectedBoundaries.previousValue);
            }
        }

        if (changes.boundaryType) {
            if (!changes.selectedBoundaries) {
                this.clearSelection(this.selectedBoundaries);
                this.selectedBoundariesChange.emit([]);
            }
            this.boundaryMap$.next(`${this.tileserverUrl}${this.boundaryType.toLowerCase()}.json`);
            this.permissionMap$.next(`${this.boundaryType.toUpperCase()}`);
            this.refreshMap();
        }

        // // adding boundaries must happen after type change
        if (changes.selectedBoundaries) {
            this.selectedBoundaries.forEach((b: Boundary) => this.select(b));
        }

        if (changes.mapResize) {
            if (this.mapResizeSub) {
                this.mapResizeSub.unsubscribe();
            }
            this.mapResizeSub = this.mapResize.subscribe(() => this.resizeMap());
        }
    }

    onMapLoad(map: Map) {
        this.map$.next(map);
        this.map$.complete();
    }

    resizeMap() {
        this.map$.subscribe((map) => window.setTimeout(() => map.resize(), 20));
    }

    zoomToPoint(point: SelectedPoint) {
        this.map$.subscribe((map) => {
            if (this.boundaryType === DataPermissionType.STATE) {
                map.setZoom(5);
            } else {
                map.setZoom(7);
            }
            map.flyTo({
                center: [point.lng, point.lat],
                // this animation is considered essential with respect to prefers-reduced-motion
                essential: true,
            });
        });
    }

    private initPermissionFilters() {
        this.permissionsSub = this.permissionMap$
            .pipe(
                map((layer) => layer),
                switchMap((type) => this.store.select(selectUserDataPermissionsByType(), { type })),
                map((arr) => _.uniqBy(arr, 'keyText')) // sometimes permissions are duplicated??
            )
            .subscribe((permissions) => {
                this.filterBoundariesAllowed = ['in'];
                this.filterBoundariesDisallowed = ['!'];

                let permittedCodes: Array<string | number>;
                if (permissions.length == 0) {
                    permittedCodes = [];
                } else {
                    permittedCodes = permissions.map((p) => p.keyText);
                }
                this.filterBoundariesAllowed.push(['get', 'code'], ['literal', permittedCodes]);

                this.filterBoundariesDisallowed.push(this.filterBoundariesAllowed);
            });
    }

    private initListingsPaintConfig() {
        const circleColor: any[] = ['match', ['get', 'category_id']];
        this.categories.map(({ id, color }) => {
            circleColor.push(+id);
            circleColor.push(color);
        });
        circleColor.push('#fff');
        this.listingsPaintConfig['circle-color'] = circleColor;
    }

    private setListingsCategoriesFilter() {
        this.filterListingsCategories = [
            'in',
            ['get', 'category_id'],
            ['literal', this.categories.filter((c) => c.selected).map((c) => c.id)],
        ];
    }

    onHoverOver(evt: MapLayerMouseEvent) {
        this.cursorStyle = 'pointer';
        this.popupProps = evt.features[0].properties;
        this.popupLngLat = evt.lngLat;
        if (evt.features.length > 0) {
            if (this.hoveredBoundaryCode) {
                this.setFeatureState(this.hoveredBoundaryCode, { hover: false });
            }
            this.hoveredBoundaryCode = evt.features[0].properties.code;
            this.setFeatureState(this.hoveredBoundaryCode, { hover: true });
        }
    }

    onHoverOff() {
        this.cursorStyle = '';
        if (this.hoveredBoundaryCode) {
            this.setFeatureState(this.hoveredBoundaryCode, { hover: false });
        }
        this.hoveredBoundaryCode = null;
        this.popupLngLat = null;
    }

    onClick(evt: MapLayerMouseEvent) {
        if (evt.features.length == 0) {
            return;
        }
        this.popupLngLat = evt.lngLat;
        const selected = {
            id: evt.features[0].id,
            code: evt.features[0].properties.code,
            type: this.boundaryType,
            name: `${evt.features[0].properties.title}, ${evt.features[0].properties.state}`,
            lat: evt.lngLat.lat,
            lng: evt.lngLat.lng,
        };
        if (this.singleSelection) {
            this.clearSelection(this.selectedBoundaries);
            this.select(selected);
            this.selectedBoundariesChange.emit([selected]);
        } else {
            if (this.selectedBoundaries.some((b) => b.code === selected.code)) {
                this.unselect(selected);
                this.selectedBoundariesChange.emit(
                    this.selectedBoundaries.filter((b) => b.code !== selected.code)
                );
            } else {
                this.select(selected);
                this.selectedBoundariesChange.emit(this.selectedBoundaries.concat(selected));
            }
        }
    }

    private setFeatureState(code: string, state: Object) {
        this.map$.subscribe((map) => {
            map.setFeatureState(
                {
                    source: 'boundaries',
                    sourceLayer: 'boundary',
                    id: code,
                },
                state
            );
        });
    }

    private select(boundary: Boundary) {
        this.setFeatureState(boundary.code, { click: true });
    }

    private unselect(boundary: Boundary) {
        this.setFeatureState(boundary.code, { click: false });
    }

    private clearSelection(selection: Boundary[]) {
        this.map$.subscribe((map) => {
            selection.forEach((a) =>
                map.setFeatureState(
                    {
                        source: 'boundaries',
                        sourceLayer: 'boundary',
                        id: a.code,
                    },
                    { click: false }
                )
            );
        });
    }

    private refreshMap() {
        this.map$.subscribe((map) => {
            if (map.getLayer('boundaries-borders')) {
                map.removeLayer('boundaries-borders');
            }
            if (map.getLayer('boundaries-fills')) {
                map.removeLayer('boundaries-fills');
            }
            if (map.getLayer('boundaries-no-permissions')) {
                map.removeLayer('boundaries-no-permissions');
            }
            map.removeSource('boundaries');

            map.addSource('boundaries', {
                type: 'vector',
                url: `${this.boundaryMap$.getValue()}`,
                promoteId: 'code',
            });
            map.addLayer({
                id: 'boundaries-borders',
                type: 'line',
                source: 'boundaries',
                'source-layer': `boundary`,
                paint: {
                    'line-color': this.mapLineColor,
                    'line-width': 1.1,
                },
            });
            if (this.ownBoundaries) {
                map.addLayer({
                    id: 'boundaries-no-permissions',
                    source: 'boundaries',
                    'source-layer': `boundary`,
                    type: 'fill',
                    filter: this.filterBoundariesDisallowed,
                    paint: {
                        'fill-color': this.mapNoRightsColor,
                        'fill-opacity': 0.5,
                    },
                });
            }
            map.addLayer({
                id: 'boundaries-fills',
                source: 'boundaries',
                'source-layer': `boundary`,
                type: 'fill',
                filter: this.filterBoundariesAllowed,
                paint: {
                    'fill-color': this.mapSelectedColor,
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.88,
                        ['boolean', ['feature-state', 'click'], false],
                        0.7,
                        0,
                    ],
                },
            });
        });
    }
}
