<table class="numerical-table" mat-table [dataSource]="tableData" matTableExporter #exporter="matTableExporter">
    <ng-container *ngFor="let measure of config.measures" matColumnDef="{{ measure.uniqueName }}">
        <mat-header-cell class="header" *matHeaderCellDef>
            {{ measure.title }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            <!-- If it is a datacell within the table, apply custom styling -->
            <div *ngIf="row[measure.uniqueName]; then style"></div>
            <ng-template #style>
                <div
                    [ngClass]="{
                        bold: row[measure.uniqueName].includes('Total')
                    }"
                >
                    {{ row[measure.uniqueName] }}
                </div>
            </ng-template>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="config.displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: config.displayedColumns"></mat-row>
</table>
