import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import {
    AfterViewInit,
    Component,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Category } from '../category/shared/category.model';
import { Measure } from '../measures/shared/measure.model';
import { selectAllCategory } from '../category/store/selectors/category.selector';
import { AppState } from '../../../../core/reducers';

// am4core.useTheme(am4themes_hemisphere);
@Component({
    selector: 'kt-catchment-graph',
    templateUrl: './catchment-graph.component.html',
    styleUrls: ['./catchment-graph.component.scss'],
})
export class CatchmentGraphComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() data: any[] = [];

    @Input() selectedMeasure: Measure;

    private chart: am4charts.XYChart;

    categories$: Observable<Category[]>;

    // If the previous category had no data, the current category will be assigned the colour of the previous category.
    // categoriesWithData ensures that we only push categories that has data associated with it
    categoriesWithData = new Set();

    constructor(public store: Store<AppState>, private zone: NgZone) {}

    ngOnInit() {
        this.categories$ = this.store.select(selectAllCategory);
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            // Chart instance
            const chart = am4core.create('chartdiv', am4charts.XYChart);

            // Data
            chart.data = [];

            // Axes
            // Horizontal Axes
            const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'category';
            categoryAxis.title.text = 'Categories';
            categoryAxis.renderer.minGridDistance = 100;

            // Vertical Axes
            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = 'No metric selected';

            // Hiding AmCharts logo
            chart.logo.height = -20000;

            // Rounding to two decimal places
            chart.numberFormatter.numberFormat = '#.##';

            // Create series
            const series = chart.series.push(new am4charts.ColumnSeries());

            series.dataFields.valueY = 'value';
            series.dataFields.categoryX = 'category';
            series.name = 'Statistics';

            // On-hover to show exact value
            const columnTemplate = series.columns.template;
            columnTemplate.tooltipText = '{categoryX}: [bold]{valueY}[/]';
            columnTemplate.fillOpacity = 0.8;
            columnTemplate.strokeOpacity = 0;

            chart.exporting.menu = new am4core.ExportMenu();

            this.chart = chart;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // Update the data in shown in graph if the data in catchment table changes
        if (
            (changes.data && !changes.data.isFirstChange()) ||
            (changes.selectedMeasure && !changes.selectedMeasure.isFirstChange())
        ) {
            this.updateData();
        }
    }

    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.chart) {
                this.chart.dispose();
            }
        });
    }

    removeData() {
        this.chart.data = [];
        this.chart.validateData();
    }

    updateData() {
        const chart = this.chart;
        const newSelectedMeasure = this.selectedMeasure;
        const newData = this.data;

        const chartData = [];
        const valueName = newSelectedMeasure.uniqueName;

        newData.forEach((element) => {
            // To be used in updateColours()
            this.categoriesWithData.add(element['category']);

            const newItem = {};
            newItem['category'] = element['category'];
            (newItem[valueName] = element[valueName]), chartData.push(newItem);
        });

        chart.data = chartData;

        // New title for vertical axes
        chart.yAxes.getIndex(0).title.text = newSelectedMeasure.title;

        // Updating vertical axes identifier so that CSV download is properly named
        chart.series.getIndex(0).dataFields.valueY = this.selectedMeasure.uniqueName;

        this.chart = chart;

        this.updateColours();
    }

    // Match bar colours to category colours
    updateColours() {
        const chart = this.chart;

        const selectedSeries = chart.series.values[0] as am4charts.ColumnSeries;
        const template = selectedSeries.columns.template;
        // Bar chart colour according to category
        this.categories$.subscribe((categories) => {
            const categoryColours = [];
            categories.map(({ id, selected, color }) => {
                if (selected && this.categoriesWithData.has(id)) {
                    categoryColours.push(color);
                }
            });

            const am4Colours: am4core.Color[] = [];
            categoryColours.forEach((colour) => {
                am4Colours.push(am4core.color(colour));
            });

            chart.colors.list = am4Colours;

            template.adapter.add('fill', (fill, target) => {
                if (target.dataItem.hasValue) {
                    return chart.colors.getIndex(target.dataItem.index);
                }
            });
        });

        this.chart = chart;
    }
}
