import { Injectable } from '@angular/core';
import { CubesService, exclude, include, SelectBuilder, SelectResponse } from 'app/core/cubes';
import { includeYearQuarterMonth } from 'app/core/utilities/date';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { DeviceGPSDG } from './device-gps-demographics/shared/device-gps-demographics.model';
import { IfiDomesticDG } from './ifi-domestic-demographics/shared/ifi-domestic-demographics.model';
import { IfiIntDG } from './ifi-int-demographics/shared/ifi-int-demographics.model';
import { SentimentDG } from './sentiment-demographics/shared/sentiment-demographics.model';

@Injectable({ providedIn: 'root' })
export class DemographicsService {
    constructor(private cubes: CubesService) {}

    getDeviceGPS(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<DeviceGPSDG[]> {
        const request = new SelectBuilder()
            .query('demographic:device-gps')
            .aggregate()
            .value('Visitor Ratio', 'average')
            .value('Local Ratio', 'average')
            .value('Total Visits Local', 'sum')
            .value('Total Visits Visitor', 'sum')
            .value('Unique Visits Local', 'sum')
            .value('Unique Visits Visitor', 'sum')
            .byRows('Age Band', 'Gender')
            .totals(false)
            .filter(
                'and',
                exclude('Gender', 'Any'),
                exclude('Age Band', 'Any'),
                include('Geo Type', level.toUpperCase()),
                include('Region Geo Code', ...boundaryIDs),
                include('Customer State', 'Any'),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, DeviceGPSDG[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          age_band: agg.keys['Age Band'] as string,
                          gender: agg.keys['Gender'] as string,
                          local_ratio: agg.values['Local Ratio']['average'],
                          visitor_ratio: agg.values['Visitor Ratio']['average'],
                          total_visits_local: agg.values['Total Visits Local']['sum'],
                          total_visits_visitor: agg.values['Total Visits Visitor']['sum'],
                          unique_visits_local: agg.values['Unique Visits Local']['sum'],
                          unique_visits_visitor: agg.values['Unique Visits Visitor']['sum'],
                      }))
                    : []
            )
        );
    }

    getSentiment(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<SentimentDG[]> {
        const request = new SelectBuilder()
            .query('demographic:sentiment')
            .aggregate()
            .value('Average Sentiment', 'average')
            .value('Median Sentiment', 'median')
            .byRows('Category', 'Reviewer Gender')
            .totals(false)
            .filter(
                'and',
                exclude('Category', 'Any'),
                exclude('Reviewer Gender', 'Any'),
                include('Reviewer State', 'Any'),
                include('Geo Type', level.toUpperCase()),
                include('Region Geo Code', ...boundaryIDs),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, SentimentDG[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          category: agg.keys['Category'] as string,
                          reviewer_gender: agg.keys['Reviewer Gender'] as string,
                          avg_sentiment: agg.values['Average Sentiment']['average'],
                          median_sentiment: agg.values['Median Sentiment']['median'],
                      }))
                    : []
            )
        );
    }

    getIfiDomestic(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<IfiDomesticDG[]> {
        const request = new SelectBuilder()
            .query('demographic:ifi-domestic')
            .aggregate()
            .value('Visitor Ratio', 'average')
            .value('Local Ratio', 'average')
            .value('Unique Customers Local', 'sum')
            .value('Unique Customers Visitor', 'sum')
            .value('Volume of Transactions Local', 'sum')
            .value('Volume of Transactions Visitor', 'sum')
            .value('Value of Transactions Local', 'sum')
            .value('Value of Transactions Visitor', 'sum')
            .byRow('Customer Behavioural Segment')
            .totals(false)
            .filter(
                'and',
                exclude('Customer Behavioural Segment', 'Any'),
                include('Customer State', 'Any'),
                include('Geo Type', level.toUpperCase()),
                include('Merchant Geo Code', ...boundaryIDs),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, IfiDomesticDG[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          cust_behavioral_segment: agg.keys[
                              'Customer Behavioural Segment'
                          ] as string,
                          local_ratio: agg.values['Local Ratio']['average'],
                          visitor_ratio: agg.values['Visitor Ratio']['average'],
                          unique_count_local: agg.values['Unique Customers Local']['sum'],
                          unique_count_visitor: agg.values['Unique Customers Visitor']['sum'],
                          vol_tran_local: agg.values['Volume of Transactions Local']['sum'],
                          vol_tran_visitor: agg.values['Volume of Transactions Visitor']['sum'],
                          val_tran_local: agg.values['Value of Transactions Local']['sum'],
                          val_tran_visitor: agg.values['Value of Transactions Visitor']['sum'],
                      }))
                    : []
            )
        );
    }

    getIfiInternational(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<IfiIntDG[]> {
        const request = new SelectBuilder()
            .query('demographic:ifi-international')
            .aggregate()
            .value('Visitor Ratio', 'average')
            .value('Local Ratio', 'average')
            .value('Unique Customers Local', 'sum')
            .value('Unique Customers Visitor', 'sum')
            .value('Volume of Transactions Local', 'sum')
            .value('Volume of Transactions Visitor', 'sum')
            .value('Value of Transactions Local', 'sum')
            .value('Value of Transactions Visitor', 'sum')
            .byRow('Customer Behavioural Segment')
            .totals(false)
            .filter(
                'and',
                exclude('Customer Behavioural Segment', 'Any'),
                include('Geo Type', level.toUpperCase()),
                include('Merchant Geo Code', ...boundaryIDs),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, IfiIntDG[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          cust_behavioral_segment: agg.keys[
                              'Customer Behavioural Segment'
                          ] as string,
                          local_ratio: agg.values['Local Ratio']['average'],
                          visitor_ratio: agg.values['Visitor Ratio']['average'],
                          unique_count_local: agg.values['Unique Customers Local']['sum'],
                          unique_count_visitor: agg.values['Unique Customers Visitor']['sum'],
                          vol_tran_local: agg.values['Volume of Transactions Local']['sum'],
                          vol_tran_visitor: agg.values['Volume of Transactions Visitor']['sum'],
                          val_tran_local: agg.values['Value of Transactions Local']['sum'],
                          val_tran_visitor: agg.values['Value of Transactions Visitor']['sum'],
                      }))
                    : []
            )
        );
    }
}
