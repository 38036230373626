import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import {
    AfterViewInit,
    Component,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    PLATFORM_ID,
    Inject,
} from '@angular/core';
import { Sentiment } from '../../sentiment/sentiment.model';
import { isPlatformBrowser } from '@angular/common';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Colours } from 'app/views/partials/content/category/shared/colour-palette';
import { Measure } from 'app/views/partials/content/measures/shared/measure.model';
import { Store, select } from '@ngrx/store';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'app/core/reducers';

@Component({
    selector: 'hm-sentiment-graph',
    template: `<div class="sentiment-chart" id="{{ chartName }}"></div>`,
    styleUrls: ['./sentiment-graph.component.scss'],
})
export class SentimentGraphComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() data: Sentiment[];
    @Input() selectedMeasure: Measure;
    @Input() chartName: string = 'profiler-sentiment-chart';

    private chart: am4charts.XYChart;
    newchartdata: number[] = [];
    categoriesWithData = new Set();
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    /**
     * Profiler - Sentiment Graph Component
     *  generates either a standard amCharts bar charts
     *  or stacked bar chart depending on the measure selected
     */
    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            this.initChart();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.data && !changes.data.isFirstChange()) ||
            (changes.selectedMeasure && !changes.selectedMeasure.isFirstChange())
        ) {
            this.chart.series.clear();
            switch (this.selectedMeasure.uniqueName) {
                case 'sentiment_by_topic_pct':
                    this.chart.legend.show(0);
                    // Angular Amcharts doesn't have typecasting for legend -> marker -> cornerRadius
                    const pctLegendMarker = this.chart.legend.markers.template.children.getIndex(0);
                    pctLegendMarker.properties['cornerRadiusBottomLeft'] = '0';
                    pctLegendMarker.properties['cornerRadiusBottomRight'] = '0';
                    pctLegendMarker.properties['cornerRadiusTopLeft'] = '0';
                    pctLegendMarker.properties['cornerRadiusTopRight'] = '0';

                    this.drawStackedBarChartPct();
                    break;
                // case 'sentiment_by_topic_count':
                //     this.chart.legend.show(0);
                //     // Angular Amcharts doesn't have typecasting for legend -> marker -> cornerRadius
                //     const countLegendMarker = this.chart.legend.markers.template.children.getIndex(
                //         0
                //     );
                //     countLegendMarker.properties['cornerRadiusBottomLeft'] = '0';
                //     countLegendMarker.properties['cornerRadiusBottomRight'] = '0';
                //     countLegendMarker.properties['cornerRadiusTopLeft'] = '0';
                //     countLegendMarker.properties['cornerRadiusTopRight'] = '0';

                //     this.drawStackedBarChartCount();
                //     break;
                default:
                    if (this.chart.legend) {
                        this.chart.legend.hide(0);
                    }
                    this.drawBarChart();
                    break;
            }
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    initChart() {
        // Chart instance
        am4core.useTheme(am4themes_animated);
        const chart = am4core.create(this.chartName, am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;
        // Data
        chart.data = [];
        // Axes
        // Vertical Axes
        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.title.text = 'Sentiment Topics';
        categoryAxis.renderer.minGridDistance = 20;

        // Horizontal Axes
        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 5;
        valueAxis.strictMinMax = true;

        // Hiding AmCharts logo
        chart.logo.height = -20000;

        // Rounding to two decimal places
        chart.numberFormatter.numberFormat = this.selectedMeasure.format;

        const watermark = new am4core.Label();
        watermark.text = 'Partial Dataset';
        watermark.fontSize = 80;
        watermark.opacity = 0.1;

        chart.plotContainer.children.push(watermark);
        watermark.align = 'center';
        watermark.valign = 'middle';

        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        chart.colors.list = colourList;

        // Export function
        chart.exporting.menu = new am4core.ExportMenu();

        chart.legend = new am4charts.Legend();
        chart.legend.hidden = true;

        this.chart = chart;
    }

    // updateData() formats incoming data to fit a standard bar chart
    drawBarChart() {
        const chart = this.chart;
        chart.xAxes.clear();
        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 5;
        valueAxis.strictMinMax = true;
        const newSelectedMeasure = this.selectedMeasure;
        const newData = this.data;

        const chartData = [];
        const valueName = newSelectedMeasure.uniqueName;
        chart.numberFormatter.numberFormat = this.selectedMeasure.format;
        newData.forEach((element) => {
            const newItem = {};
            newItem['category'] = element['category'];
            (newItem[valueName] = element[valueName]), chartData.push(newItem);
        });

        chart.data = chartData;

        chart.xAxes.getIndex(0).title.text = newSelectedMeasure.title;

        this.createSeries();

        this.chart = chart;
    }

    // createSeries() creates a standard bar chart
    createSeries() {
        const chart = this.chart;
        // Create series
        const series = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.valueX = 'value';
        series.dataFields.categoryY = 'category';
        series.name = 'Statistics';
        series.columns.template.adapter.add('fill', (fill, target) => {
            return chart.colors.getIndex(target.dataItem.index);
        });
        // On-hover to show exact value
        const columnTemplate = series.columns.template;
        columnTemplate.tooltipText = '{categoryY}: [bold]{valueX.value}[/]';
        columnTemplate.fillOpacity = 0.8;
        columnTemplate.strokeOpacity = 0;

        chart.series.getIndex(0).dataFields.valueX = this.selectedMeasure.uniqueName;

        this.chart = chart;
    }

    drawStackedBarChartPct() {
        const chart = this.chart;
        chart.xAxes.clear();
        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        const data = [];

        this.data.forEach((d) => {
            const newData = {
                category: d.category,
                rating_1: d.rating_1_pct,
                rating_2: d.rating_2_pct,
                rating_3: d.rating_3_pct,
                rating_4: d.rating_4_pct,
                rating_5: d.rating_5_pct,
            };
            data.push(newData);
        });
        chart.data = data;

        this.createSeriesByPct('rating_1', 'Rating 1', 0);
        this.createSeriesByPct('rating_2', 'Rating 2', 1);
        this.createSeriesByPct('rating_3', 'Rating 3', 2);
        this.createSeriesByPct('rating_4', 'Rating 4', 3);
        this.createSeriesByPct('rating_5', 'Rating 5', 4);

        chart.xAxes.getIndex(0).title.text = this.selectedMeasure.title;

        this.chart = chart;
    }

    // createStackedSeries() creates a stacked bar chart
    createSeriesByPct(field, name, index) {
        const chart = this.chart;

        // Set up series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueX = field;
        series.dataFields.categoryY = 'category';
        series.sequencedInterpolation = true;

        // Make it stacked
        series.stacked = true;

        // Configure columns
        series.columns.template.tooltipText =
            '[bold]{name}[/]\n[font-size:14px]{categoryY}: {valueX}%';

        // Set color of each stacked series to fixed index so that colors are consistent
        series.columns.template.adapter.add('fill', () => {
            return chart.colors.getIndex(index);
        });

        series.strokeWidth = 0;

        return series;
    }
}
