import { Pipe, PipeTransform } from '@angular/core';
import { MetricValue } from 'app/views/pages/metrics/metrics.model';
@Pipe({
    name: 'filterMetricValue',
})
export class FilterMetricValuePipe implements PipeTransform {
    transform(metricValues: MetricValue[], metric_id: number): MetricValue[] {
        if (!metricValues || !metric_id) {
            return metricValues;
        }

        return metricValues.filter((mv) => mv.metric_id == metric_id);
    }
}
