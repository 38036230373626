<div class="overlay-container" *ngIf="overlayService.loading$ | async">
    <mat-card class="marketplace-card">
        <img mat-card-image src="assets/media/marketplace/Hemisphere_icon_Digital_data_integration_large.svg" height="200px" alt="" />
        <mat-card-content>
            <p class="w400">
                {{ message }}
            </p>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-button color="primary" (click)="close()">Close</button>
            <button mat-button color="primary" (click)="enquire()">Enquire</button>
        </mat-card-actions>
    </mat-card>
</div>
