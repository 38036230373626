<!-- begin::Sticky Toolbar -->
<ul class="kt-sticky-toolbar" style="margin-top: 30px">
    <li
        class="kt-sticky-toolbar__item kt-sticky-toolbar__item--success"
        id="kt_bookmarks_panel_toggle"
        matTooltip="Report bookmarks"
        matTooltipPosition="left"
    >
        <a href="javascript:;" class="" *ngxPermissionsExcept="['TRIAL']">
            <i class="flaticon2-open-text-book"></i>
        </a>

        <a href="javascript:;" (click)="showTrial($event)" class="" *ngxPermissionsOnly="['TRIAL']"
            ><i class="flaticon2-open-text-book"></i
        ></a>
    </li>
</ul>
<!-- end::Sticky Toolbar -->

<!-- begin::Demo Panel -->
<div *ngxPermissionsExcept="['TRIAL']">
    <div ktOffcanvas [options]="bookmarksPanelOptions" id="kt_bookmarks_panel" class="kt-bookmarks-panel">
        <div class="kt-bookmarks-panel__head">
            <h3 class="kt-bookmarks-panel__title">Bookmarks</h3>
            <a href="javascript:;" class="kt-bookmarks-panel__close" id="kt_bookmarks_panel_close"><i class="flaticon2-delete"></i></a>
        </div>
        <div>
            <h3 class="mat-subheading-1">Save current view as</h3>
            <form class="reportSave" #saveForm="ngForm" (ngSubmit)="saveBookmark(); saveForm.resetForm()">
                <mat-form-field>
                    <mat-label>Bookmark name</mat-label>
                    <input matInput required [(ngModel)]="bookmarkName" name="bookmarkName" placeholder="Please enter a report name" />
                </mat-form-field>
                <div class="flex" *ngIf="type == 'view'">
                    <mat-checkbox [(ngModel)]="defaultView" name="defaultView">Default View for this report</mat-checkbox>
                    <mat-icon color="primary" mat-list-icon>star</mat-icon>
                </div>

                <mat-checkbox *ngxPermissionsOnly="['SYS_ADMIN', 'CLIENT_ADMIN']" [(ngModel)]="shareWithAllUsers" name="shareWithAllUsers"
                    >Share with all users</mat-checkbox
                >
                <div *ngIf="defaultView && shareWithAllUsers" class="warning">
                    ⚠️ Warning: Sharing this view to all users and making it a default view will overwrite the current default view for all
                    users.
                </div>

                <button
                    type="submit"
                    mat-raised-button
                    color="primary"
                    message="Are you sure you want to share and make this the default view for all users. "
                    [disabled]="!saveForm.form.valid"
                >
                    Save
                </button>
            </form>
            <hr />
            <h3 class="mat-subheading-1">Bookmarked reports</h3>

            <mat-tab-group animationDuration="10ms" [selectedIndex]="0">
                <mat-tab label="For this report " class="bookmark">
                    <mat-nav-list dense>
                        <mat-list-item *ngFor="let bookmark of bookmarks$ | async" (click)="loadBookmark(bookmark)">
                            <div class="bookmark-list">
                                <ng-container *ngIf="bookmark.default_view; else noteIcon">
                                    <mat-icon color="primary" mat-list-icon>star</mat-icon>
                                </ng-container>
                                <ng-template #noteIcon>
                                    <mat-icon mat-list-icon>note</mat-icon>
                                </ng-template>
                            </div>
                            <div class="column">
                                <h4 mat-line>
                                    {{ bookmark.name }}
                                </h4>
                                <p mat-line>{{ summary(bookmark) }}</p>
                                <p mat-line>{{ bookmark.updated_at | date }}</p>
                            </div>
                            <div class="w-40">
                                <button
                                    *ngIf="ownerOfBookmark(bookmark, isSysAdmin$ | async, isClientAdmin$ | async)"
                                    mat-icon-button
                                    color="warn"
                                    type="warn"
                                    (click)="$event.stopPropagation()"
                                    (confirm)="deleteBookmark(bookmark)"
                                    message="Delete report?"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-tab>
                <mat-tab label="Other Reports" class="bookmark">
                    <mat-nav-list dense>
                        <mat-list-item *ngFor="let bookmark of bookmarksAll$ | async" (click)="loadBookmark(bookmark)">
                            <div class="bookmark-list">
                                <ng-container *ngIf="bookmark.default_view; else noteIcon">
                                    <mat-icon color="primary" mat-list-icon>star</mat-icon>
                                </ng-container>
                                <ng-template #noteIcon>
                                    <mat-icon mat-list-icon>note</mat-icon>
                                </ng-template>
                            </div>
                            <div class="column">
                                <h4 mat-line>
                                    {{ bookmark.name }}
                                </h4>
                                <p mat-line>{{ summary(bookmark) }}</p>
                                <p mat-line>{{ bookmark.updated_at | date }}</p>
                            </div>
                            <div class="w-40">
                                <button
                                    *ngIf="ownerOfBookmark(bookmark, isSysAdmin$ | async, isClientAdmin$ | async)"
                                    mat-icon-button
                                    color="warn"
                                    type="warn"
                                    (click)="$event.stopPropagation()"
                                    (confirm)="deleteBookmark(bookmark)"
                                    message="Delete report?"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<!-- end::Demo Panel -->
