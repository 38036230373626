import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'environments/environment';
import { MatTableExporterModule } from 'mat-table-exporter';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPermissionsModule } from 'ngx-permissions';

// Core module
import { CoreModule } from '../../core/core.module';
import { PipeModule } from '../../pipes/pipe.module';
import { CatchmentGraphComponent } from './content/catchment-graph/catchment-graph.component';
import { CatchmentTableComponent } from './content/catchment-table/catchment-table.component';
import { AlertComponent } from './content/crud';
// Errpr
import { ErrorComponent } from './content/general/error/error.component';

// Layout partials
import {
    AdminMenuComponent,
    ExportMenuComponent,
    NotificationComponent,
    ScrollTopComponent,
    SplashScreenComponent,
    Subheader1Component,
    UserProfileComponent,
} from './layout';
import { ContextMenu3Component } from './layout/context-menu3/context-menu3/context-menu3.component';
import { LoadingComponent } from './layout/loading/loading.component';
import { OverlayComponent } from './layout/overlay/overlay.component';
import { ResizerComponent } from './layout/resizer/resizer.component';

// General
import { BookmarksComponent } from './content/bookmarks/bookmarks.component';
import {
    BarChartComponent,
    ChartComponent,
    DataTableComponent,
    HeatTableComponent,
    LineSeriesComponent,
    StackedBarComponent,
    StackedColumnComponent,
} from './content/charts';
import { DataNoticeComponent } from './content/data-notice/data-notice.component';
import { DatepickerComponent } from './content/datepicker/datepicker.component';
import { DialogComponent } from './content/datepicker/dialog/dialog.component';
import { AlertDialogComponent } from './content/dialogs/alert-dialog/alert-dialog.component';
import {
    ConfirmDialogComponent,
    ConfirmDirective,
} from './content/dialogs/confirm/confirm.component';
import { ConfirmationDialogComponent } from './content/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DigitalIntegrationTableComponent } from './content/digital-integration/data-table/data-table.component';
import { FromwhereComponent } from './content/digital-integration/fromwhere/fromwhere.component';
import { HeatMapComponent } from './content/digital-integration/heat-map/heat-map.component';
import { MultipleAxesLineChartComponent } from './content/digital-integration/multiple-axes-line-chart/multiple-axes-line-chart.component';
import { ProfileDataComponent } from './content/digital-integration/profile-data/profile-data.component';
import { TrendDataComponent } from './content/digital-integration/trend-data/trend-data.component';
import { EnquireComponent } from './content/enquire/enquire.component';
import { FullscreenButtonComponent } from './content/fullscreen-button/fullscreen-button.component';
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
import { H3PickerComponent } from './content/h3-picker/h3-picker.component';
import { ImpersonateComponent } from './content/impersonate/impersonate.component';
import {
    ChoroplethComponent,
    LegendComponent,
    RegionControlsComponent,
    TransportModeComponent,
    TravelTimeComponent,
} from './content/map';
import { ChoroplethLegendComponent } from './content/map/choropleth/choropleth-legend/choropleth-legend.component';
import { CatchmentsComponent } from './content/map/controls/catchments/catchments.component';
import { CategoriesComponent } from './content/map/controls/categories/categories.component';
import { ControlsComponent } from './content/map/controls/controls.component';
import { SelectCheckAllComponent } from './content/map/controls/select-check-all/select-check-all.component';
import { IsochroneComponent } from './content/map/isochrone/isochrone.component';
import { MarketplaceCardComponent } from './content/marketplace-card/marketplace-card.component';
import {
    MaxRangeDirective,
    MultiDateSelectorComponent,
} from './content/multi-date-selector/multi-date-selector.component';
import { OrganisationsSwitchComponent } from './content/organisations-switch/organisations-switch.component';
import { PermissionCheckComponent } from './content/permission-check/permission-check.component';
import { BoundaryAutocompleteSelectorComponent } from './content/region-selector/boundary-autocomplete-selector/boundary-autocomplete-selector.component';
import { BoundarySelectionMapComponent } from './content/region-selector/boundary-selection-map/boundary-selection-map.component';
import { BoundaryTypeSelectComponent } from './content/region-selector/boundary-type-select/boundary-type-select.component';
import { BoundaryTypeSelectorComponent } from './content/region-selector/boundary-type-selector/boundary-type-selector.component';
import { RegionSelectorDialogComponent } from './content/region-selector/region-selector-dialog/region-selector-dialog';
import { RegionSelectorComponent } from './content/region-selector/region-selector/region-selector.component';
import { DeviceGPSDemographicGraphComponent } from './content/reports/demographics/device-gps-demographics/device-gps-demographics-graph/device-gps-demographics-graph.component';
import { DeviceGpsDemographicComponent } from './content/reports/demographics/device-gps-demographics/device-gps-demographics.component';
import { IfiDomesticDemographicsGraphComponent } from './content/reports/demographics/ifi-domestic-demographics/ifi-domestic-demographics-graph/ifi-domestic-demographics-graph.component';
import { IfiDomesticDemographicsComponent } from './content/reports/demographics/ifi-domestic-demographics/ifi-domestic-demographics.component';
import { IfiIntDemographicsGraphComponent } from './content/reports/demographics/ifi-int-demographics/ifi-int-demographics-graph/ifi-int-demographics-graph.component';
import { IfiIntDemographicsComponent } from './content/reports/demographics/ifi-int-demographics/ifi-int-demographics.component';
import { SentimentDemographicsGraphComponent } from './content/reports/demographics/sentiment-demographics/sentiment-demographics-graph/sentiment-demographics-graph.component';
import { SentimentDemographicsComponent } from './content/reports/demographics/sentiment-demographics/sentiment-demographics.component';
import { MobileGpsComponent } from './content/reports/profiler/mobile-gps/mobile-gps.component';
import { MobileGraphComponent } from './content/reports/profiler/mobile-gps/mobile-graph/mobile-graph.component';
import { SentimentGraphComponent } from './content/reports/profiler/sentiment/sentiment-graph/sentiment-graph.component';
import { SentimentTableComponent } from './content/reports/profiler/sentiment/sentiment-table/sentiment-table.component';
import { SentimentComponent } from './content/reports/profiler/sentiment/sentiment.component';
import { TransactionIfiDomesticGraphComponent } from './content/reports/profiler/transaction-ifi-domestic/transaction-ifi-domestic-graph/transaction-ifi-domestic-graph.component';
import { TransactionIfiDomesticComponent } from './content/reports/profiler/transaction-ifi-domestic/transaction-ifi-domestic.component';
import { TransactionIfiIntGraphComponent } from './content/reports/profiler/transaction-ifi-int/transaction-ifi-int-graph/transaction-ifi-int-graph.component';
import { TransactionIfiIntComponent } from './content/reports/profiler/transaction-ifi-int/transaction-ifi-int.component';
import { ReportControlsComponent } from './content/reports/report-controls/report-controls.component';
import { SentimentListingsGraphComponent } from './content/sentiment/sentiment-listings/sentiment-listings-graph/sentiment-listings-graph.component';
import { SentimentListingsTableComponent } from './content/sentiment/sentiment-listings/sentiment-listings-table/sentiment-listings-table.component';
import { SentimentListingsComponent } from './content/sentiment/sentiment-listings/sentiment-listings.component';
import { MapHeatmapComponent } from './content/survey/map-heatmap/map-heatmap.component';
import { MapPinComponent } from './content/survey/map-pin/map-pin.component';
import { PictorialChartComponent } from './content/survey/pictorial-chart/pictorial-chart.component';
import { SurveyBarChartComponent } from './content/survey/survey-bar-chart/survey-bar-chart.component';
import { SurveyCardComponent } from './content/survey/survey-card/survey-card.component';
import { SurveyDrilldownTreemapChartComponent } from './content/survey/survey-drilldown-treemap-chart/survey-drilldown-treemap-chart.component';
import { SurveyMirrorBarChartComponent } from './content/survey/survey-mirror-bar-chart/survey-mirror-bar-chart.component';
import { SurveyStackedBarChartComponent } from './content/survey/survey-stacked-bar-chart/survey-stacked-bar-chart.component';
import { TimeSeriesTableComponent } from './content/survey/time-series-table/time-series-table.component';
import { XyLineComponent } from './content/survey/xy-line/xy-line.component';
import { WeekpickerComponent } from './content/weekpicker/weekpicker.component';
import { DownloadsHeaderComponent } from './layout/downloads-header/downloads-header.component';
import { SearchHeaderComponent } from './layout/searchheader/searchheader.component';
import { SliderComponent } from './content/slider/slider.component';
import { SliderItemDirective } from './content/slider/slider-item-directive';
import { MemberSelectorComponent } from './content/member-selector/member-selector.component';
import { MapControlsComponent } from './content/map/map-controls/map-controls.component';

@NgModule({
    declarations: [
        ScrollTopComponent,
        NoticeComponent,
        AlertComponent,
        MaxRangeDirective,
        // topbar components
        ContextMenu3Component,
        ExportMenuComponent,
        ScrollTopComponent,
        SplashScreenComponent,
        Subheader1Component,
        NotificationComponent,
        UserProfileComponent,
        ErrorComponent,
        TransportModeComponent,
        TravelTimeComponent,
        LegendComponent,
        ContextMenu3Component,
        SelectCheckAllComponent,
        ControlsComponent,
        RegionControlsComponent,
        CatchmentsComponent,
        CatchmentTableComponent,
        CatchmentGraphComponent,
        LoadingComponent,
        OverlayComponent,
        MobileGpsComponent,
        SentimentComponent,
        MobileGraphComponent,
        SentimentGraphComponent,
        ReportControlsComponent,
        TransactionIfiDomesticComponent,
        TransactionIfiDomesticGraphComponent,
        TransactionIfiIntComponent,
        TransactionIfiIntGraphComponent,
        SentimentDemographicsComponent,
        SentimentDemographicsGraphComponent,
        DeviceGpsDemographicComponent,
        DeviceGPSDemographicGraphComponent,
        IfiDomesticDemographicsComponent,
        IfiDomesticDemographicsGraphComponent,
        IfiIntDemographicsComponent,
        IfiIntDemographicsGraphComponent,
        ResizerComponent,
        PermissionCheckComponent,
        BookmarksComponent,
        FullscreenButtonComponent,
        ConfirmDirective,
        ConfirmDialogComponent,
        SentimentTableComponent,
        SentimentListingsComponent,
        SentimentListingsGraphComponent,
        DatepickerComponent,
        DialogComponent,
        SentimentListingsTableComponent,
        RegionSelectorComponent,
        RegionSelectorDialogComponent,
        BoundarySelectionMapComponent,
        BoundaryTypeSelectorComponent,
        BoundaryAutocompleteSelectorComponent,
        CategoriesComponent,
        PictorialChartComponent,
        XyLineComponent,
        SurveyBarChartComponent,
        SurveyDrilldownTreemapChartComponent,
        MapPinComponent,
        SurveyStackedBarChartComponent,
        MapHeatmapComponent,
        SurveyMirrorBarChartComponent,
        SurveyCardComponent,
        TimeSeriesTableComponent,
        MultiDateSelectorComponent,
        FromwhereComponent,
        MultipleAxesLineChartComponent,
        DigitalIntegrationTableComponent,
        TrendDataComponent,
        HeatMapComponent,
        ProfileDataComponent,
        BoundaryTypeSelectComponent,
        SearchHeaderComponent,
        MarketplaceCardComponent,
        EnquireComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        DataNoticeComponent,
        WeekpickerComponent,
        ImpersonateComponent,
        H3PickerComponent,
        AdminMenuComponent,
        OrganisationsSwitchComponent,
        // Charts
        HeatTableComponent,
        StackedBarComponent,
        BarChartComponent,
        LineSeriesComponent,
        DataTableComponent,
        IsochroneComponent,
        ChoroplethComponent,
        ChoroplethLegendComponent,
        DownloadsHeaderComponent,
        SliderComponent,
        SliderItemDirective,
        StackedColumnComponent,
        ChartComponent,
        MemberSelectorComponent,
        MapControlsComponent,
    ],
    exports: [
        NgxPermissionsModule,
        PortletModule,
        ScrollTopComponent,
        NoticeComponent,
        AlertComponent,
        // topbar components
        ContextMenu3Component,
        ExportMenuComponent,
        BookmarksComponent,
        SplashScreenComponent,
        Subheader1Component,
        NotificationComponent,
        AdminMenuComponent,
        UserProfileComponent,
        ErrorComponent,

        ControlsComponent,

        CatchmentsComponent,
        CatchmentTableComponent,
        CatchmentGraphComponent,
        LoadingComponent,
        OverlayComponent,
        SentimentComponent,
        SentimentGraphComponent,
        MobileGraphComponent,
        MobileGpsComponent,
        ReportControlsComponent,
        TransactionIfiDomesticComponent,
        TransactionIfiDomesticGraphComponent,
        TransactionIfiIntComponent,
        TransactionIfiIntGraphComponent,
        SentimentDemographicsComponent,
        DeviceGpsDemographicComponent,
        DeviceGPSDemographicGraphComponent,
        IfiDomesticDemographicsComponent,
        IfiIntDemographicsComponent,
        ResizerComponent,
        PermissionCheckComponent,
        FullscreenButtonComponent,
        SentimentListingsComponent,
        DialogComponent,
        DatepickerComponent,
        RegionSelectorComponent,
        RegionSelectorDialogComponent,
        BoundarySelectionMapComponent,
        BoundaryTypeSelectorComponent,
        BoundaryAutocompleteSelectorComponent,
        CategoriesComponent,
        PictorialChartComponent,
        XyLineComponent,
        SurveyBarChartComponent,
        SurveyDrilldownTreemapChartComponent,
        MapPinComponent,
        SurveyStackedBarChartComponent,
        MapHeatmapComponent,
        SurveyMirrorBarChartComponent,
        SurveyCardComponent,
        TimeSeriesTableComponent,
        MultiDateSelectorComponent,
        FromwhereComponent,
        MultipleAxesLineChartComponent,
        DigitalIntegrationTableComponent,
        TrendDataComponent,
        ProfileDataComponent,
        BoundaryTypeSelectComponent,
        MemberSelectorComponent,

        SearchHeaderComponent,
        MarketplaceCardComponent,
        DataNoticeComponent,
        WeekpickerComponent,
        ImpersonateComponent,
        H3PickerComponent,
        MatIconModule,

        // Chart Module
        HeatTableComponent,
        StackedBarComponent,
        BarChartComponent,
        LineSeriesComponent,
        DataTableComponent,
        StackedColumnComponent,
        MatIconModule,

        // Map Module
        LegendComponent,
        IsochroneComponent,
        ChoroplethComponent,
        RegionControlsComponent,
        TransportModeComponent,
        TravelTimeComponent,
        SliderComponent,
        SliderItemDirective,
        MapControlsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        A11yModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        InlineSVGModule,
        CoreModule,
        PortletModule,
        PipeModule.forRoot(),
        // angular material modules
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatDividerModule,
        MatChipsModule,
        MatButtonModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatTreeModule,
        MatDialogModule,
        MatExpansionModule,
        MatButtonToggleModule,
        MatGridListModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatTableExporterModule,
        MatChipsModule,
        // ng-bootstrap modules
        NgbDropdownModule,
        NgbTooltipModule,
        NgxPermissionsModule,
        NgxMapboxGLModule.withConfig({
            accessToken: environment.mapbox.accessToken,
        }),
    ],
})
export class PartialsModule {}
