import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'licence',
})
export class LicencePipe implements PipeTransform {
    transform(value: any, ...args: any[]): string {
        switch (value) {
            case 'CC BY 4.0': {
                return `<p  xmlns:cc='http://creativecommons.org/ns#'>
                       <a href='http://creativecommons.org/licenses/by/4.0/?ref=chooser-v1' target='_blank' rel='license noopener noreferrer'
                       >CC BY 4.0
                       <img class='h32' src='https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1'>
                       <img class='h32' src='https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1'>
                       </a></p>`;
            }
            case 'CC BY-SA 4.0': {
                return `<p xmlns:cc="http://creativecommons.org/ns#"><a href="http://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-SA 4.0
                <img class='h32'  src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
                <img class='h32'  src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
                <img class='h32'  src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"></a></p>`;
            }
            case 'CC BY-ND 4.0': {
                return `<p xmlns:cc="http://creativecommons.org/ns#" ><a href="http://creativecommons.org/licenses/by-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-ND 4.0
                <img class='h32'   src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
                <img class='h32'   src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
                <img class='h32'   src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1"></a></p>`;

                //return `© Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International`;
            }
            case 'CC BY-NC 4.0': {
                return `<p xmlns:cc="http://creativecommons.org/ns#" ><a href="http://creativecommons.org/licenses/by-nc/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-NC 4.0
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"></a></p>`;
            }
            case 'CC BY-NC-SA 4.0': {
                return `<p xmlns:cc="http://creativecommons.org/ns#" ><a href="http://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-NC-SA 4.0
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"></a></p>`;
            }
            case 'CC BY-NC-ND 4.0': {
                return `<p xmlns:cc="http://creativecommons.org/ns#" ><a href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style="display:inline-block;">CC BY-NC-ND 4.0
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1">
                <img class='h32' src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1"></a></p>`;
            }
            default: {
                return '';
            }
        }
    }
}
