import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Dataset } from '../shared/dataset.model';
import { DatasetService } from '../shared/dataset.service';
import {
    DatasetActionType,
    LoadingAllAction,
    LoadingFailureAllAction,
    LoadSuccessAllAction,
} from './dataset.actions';

@Injectable()
export class DatasetEffects {
    // API
    constructor(private service: DatasetService, private actions$: Actions) {}

    public loadDataset$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadingAllAction>(DatasetActionType.DatasetLoadingAll),
            switchMap((action) =>
                this.service.getDataset().pipe(
                    map(
                        (response: Dataset[]) =>
                            new LoadSuccessAllAction({
                                dataset: response,
                            }),
                        catchError((error) => of(new LoadingFailureAllAction(error)))
                    )
                )
            )
        )
    );
}
