import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';

import { BookmarkedReport, BookmarkType } from './bookmarked-report.model';

@Injectable({ providedIn: 'root' })
export class BookmarkedReportsService {
    apiBase: string = environment.hemisphereApi;
    headers = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(private http: HttpClient) {}

    list(type?: BookmarkType, subtype?: string, search?: string) {
        let params = new HttpParams();
        if (type) {
            params = params.set('type', type);
        }
        if (subtype) {
            params = params.set('subtype', subtype);
        }
        if (search) {
            params = params.set('search', search);
        }
        return this.http.get<BookmarkedReport[]>(`${this.apiBase}/reports`, { params });
    }

    create(report: BookmarkedReport) {
        return this.http.post<{ id: number }>(`${this.apiBase}/reports`, report, {
            headers: this.headers,
        });
    }

    edit(report: BookmarkedReport) {
        if (report.type == 'simple') {
            // we never update this on the db, this is just current state
            return of(report);
        } else if (report.type == 'dashboard') {
            // update the dashboard => call api to update specific id
        }
        return of(report);
    }

    delete(id: number) {
        return this.http.delete(`${this.apiBase}/reports/${id}`);
    }
}
