import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';
import { TrendChartConfig } from 'app/views/pages/regions/results/lga-view/lga-view-yearly-trend/shared/lga-view-year-trend-chart.model';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'hm-heat-table',
    template: `<div id="{{ chartName }}" class="heat-table"></div>`,
    styleUrls: ['./heat-table.component.scss'],
})
export class HeatTableComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() config: TrendChartConfig;

    private chart: am4charts.XYChart;
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    chartName = 'heat-table' + Math.random().toString();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
        this.browserOnly(() => {
            am4core.addLicense(environment.amcharts.licenseKey);

            this.chart = am4core.create(this.chartName, am4charts.XYChart);
            this.chart.maskBullets = false;

            let xAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
            xAxis.dataFields.category = this.config.categoryAxis[0].name;
            xAxis.renderer.grid.template.disabled = true;
            xAxis.renderer.minGridDistance = 10;
            xAxis.renderer.opposite = true;
            xAxis.title.numberFormatter.numberFormat = '####';

            let yAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
            yAxis.dataFields.category = this.config.categoryAxis[1].name;
            yAxis.renderer.grid.template.disabled = true;
            yAxis.renderer.inversed = true;
            yAxis.renderer.minGridDistance = 10;

            this.chart.data = this.config.data;
            this.chart.logo.height = -30000;

            // this.setColours();

            if (this.config.data.length > 0) {
                if (this.chart.modal) {
                    this.chart.closeModal();
                }

                this.draw();
            } else {
                this.chart.openModal('Please select a different measure.', 'Data unavailable');
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.series.clear();
            this.chart.bottomAxesContainer.children.clear();

            if (this.config.data.length > 0) {
                if (this.chart.modal) {
                    this.chart.closeModal();
                }

                this.draw();
            } else {
                this.chart.openModal('Please select a different measure.', 'Data unavailable');
            }
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        this.chart.colors.list = colourList;
    }

    draw() {
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = this.config.categoryAxis[0].name;
        series.dataFields.categoryY = this.config.categoryAxis[1].name;
        series.dataFields.value = this.config.dataFieldName;
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 3000;

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        columnTemplate.stroke = am4core.color(this.amColors[0]);
        columnTemplate.tooltipText =
            '{categoryX}, {categoryY}:\n [bold font-size: 12px]{value.workingValue.formatNumber("#,###.##a")}';

        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);
        series.heatRules.push({
            target: columnTemplate,
            property: 'fill',
            min: am4core.color('#ffffff'),

            max: am4core.color(this.amColors[0]),
        });

        // heat legend
        let heatLegend = this.chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = series;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;
        heatLegend.markerContainer.paddingTop = 20;
    }
}
