import { QueryBuilder, QueryRequest } from './query';

export class DownloadRequest extends QueryRequest {
    type: 'download' = 'download';
}

export class DownloadBuilder extends QueryBuilder<DownloadRequest> {
    constructor(req?: DownloadRequest) {
        super(req || new DownloadRequest());
    }
}
