import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

import { SurveyStackedBarChartConfig } from 'app/views/partials/content/survey/survey-stacked-bar-chart/survey-stacked-bar-chart.model';
import { Subject, takeUntil } from 'rxjs';

// import { Colours } from '../../category/shared/colour-palette';
// import { SurveyStackedBarChartConfig } from './survey-stacked-bar-chart.model';

@Component({
    selector: 'hm-stacked-bar',
    templateUrl: './stacked-bar.component.html',
    styleUrls: ['./stacked-bar.component.scss'],
})
export class StackedBarComponent implements OnDestroy, AfterViewInit, OnInit, OnChanges {
    @Input() config: SurveyStackedBarChartConfig;
    @Input() dataAvailable: boolean;
    id = 'stacked-bar-chart';

    messageLabel: am4core.Label;
    private chart: am4charts.XYChart;
    calculatedScore: number;

    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}
    ngOnInit(): void {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });

        const calculatedScoreSum =
            this.config.data[0]['product_nps_sum_promoters'] +
            this.config.data[0]['product_nps_passives'] +
            this.config.data[0]['product_nps_detractors'];

        this.calculatedScore =
            Number(this.config.data[0]['product_nps_sum_promoters']) / calculatedScoreSum -
            Number(this.config.data[0]['product_nps_detractors']) / calculatedScoreSum;
    }

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            const chart = am4core.create(this.id, am4charts.XYChart);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
            chart.data = this.config.data;
            if (this.dataAvailable == false) {
                const noDataMessagecontainer = chart.chartContainer.createChild(am4core.Container);
                noDataMessagecontainer.align = 'center';
                noDataMessagecontainer.isMeasured = false;
                noDataMessagecontainer.x = am4core.percent(50);
                noDataMessagecontainer.horizontalCenter = 'middle';
                noDataMessagecontainer.y = am4core.percent(50);
                noDataMessagecontainer.verticalCenter = 'middle';
                noDataMessagecontainer.layout = 'vertical';

                this.messageLabel = noDataMessagecontainer.createChild(am4core.Label);
                this.messageLabel.text = 'No data returned for the currently selected filters';
                this.messageLabel.textAlign = 'middle';
                this.messageLabel.maxWidth = 300;
                this.messageLabel.wrap = true;
                this.messageLabel.disabled = false;
            }

            // Create axes
            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'year';
            categoryAxis.renderer.grid.template.opacity = 0;
            categoryAxis.renderer.labels.template.disabled = true;

            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.max = 100;

            valueAxis.strictMinMax = true;
            valueAxis.calculateTotals = true;

            valueAxis.renderer.grid.template.opacity = 0;
            valueAxis.renderer.baseGrid.disabled = false;
            valueAxis.renderer.minGridDistance = 40;
            valueAxis.renderer.labels.template.disabled = true;

            chart.logo.height = -15000;
            chart.legend = new am4charts.Legend();

            this.chart = chart;
            this.setColours();

            // Create series
            this.config.category.forEach((c) => {
                this.createSeries(c.name, c.title);
            });
        });
    }

    createSeries(field, name) {
        const chart = this.chart;
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = 'year';
        series.stacked = true;
        series.name = name;
        series.dataFields.valueXShow = 'totalPercent';
        var labelBullet = series.bullets.push(new am4charts.LabelBullet());

        if (this.dataAvailable == false) {
            series.opacity = 0.3;
            series.strokeDasharray = '4.4';
            labelBullet.label.text = '';
        } else {
            labelBullet.label.text = "{valueX.totalPercent.formatNumber('#.#')}%";
        }

        labelBullet.locationX = 0.5;
        labelBullet.label.fill = am4core.color('#fff');

        this.chart = chart;
        this.chart.logo;
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const chart = this.chart;
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        chart.colors.list = colourList;
        if (this.dataAvailable == true) {
            chart.colors.list = colourList;
        } else {
            chart.colors.list = [
                am4core.color('#dadada'),
                am4core.color('#dadada'),
                am4core.color('#dadada'),
            ];
        }
        this.chart = chart;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.series.clear();
            this.chart.bottomAxesContainer.children.clear();
            this.messageLabel.disabled = true;

            const calculatedScoreSum =
                this.config.data[0]['product_nps_sum_promoters'] +
                this.config.data[0]['product_nps_passives'] +
                this.config.data[0]['product_nps_detractors'];

            this.calculatedScore =
                Number(this.config.data[0]['product_nps_sum_promoters']) / calculatedScoreSum -
                Number(this.config.data[0]['product_nps_detractors']) / calculatedScoreSum;
            if (!this.dataAvailable) {
                this.messageLabel.disabled = false;
            }
            // Create series
            this.config.category.forEach((c) => {
                this.createSeries(c.name, c.title);
            });
        }
    }
}
