import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Measure } from '../measures/shared/measure.model';

/**
 * How to use:
 *
 *  Attach selector <kt-catchment-table measure=<measure> data=<data>> to component and assign the following input properties:
 *  1. measures: a list of Measure items that contain:-
 *      i. Title: Column name to show
 *      ii. UniqueName: Data identifier
 *  2. data: a list of data items whose keys correspond to Measure.UniqueName
 *
 *  Data assignment has to be initialised within ngOnInit.
 *  See: HotelsComponent.measures and HotelsComponent.AggregateData
 */
@Component({
    selector: 'kt-catchment-table',
    templateUrl: './catchment-table.component.html',
    styleUrls: ['./catchment-table.component.scss'],
})
export class CatchmentTableComponent implements OnInit, OnChanges {
    @ViewChild(MatSort) sort: MatSort;

    // List of available data measures obtained from Hemisphere API: GET: /listings/measures
    @Input() measures: Measure[] = [];

    @Input() data: any[] = [];

    @ViewChild('exporter') exporter: any;

    // TableDataSource wrapper for data
    dataSource: MatTableDataSource<any> = new MatTableDataSource();

    // Extracted column names from measures
    displayedColumns: string[] = [];

    constructor() {}

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges) {
        // Populating displayedColumns which tells the table which column to map the data to
        if (changes.measures) {
            this.measures.map(({ uniqueName }) => {
                this.displayedColumns.push(uniqueName);
            });
        }

        // Aggregate data to be fed into TableDataSource
        if (changes.data) {
            this.dataSource.data = this.data;
            this.dataSource.sort = this.sort;
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'hotel-profiler' }); // File name: name of tab + timestamp
    }

    isNumber(val): boolean {
        return typeof val === 'number';
    }
}
