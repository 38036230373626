import { Action } from '@ngrx/store';
import { BookmarkedReport } from '../shared/bookmarked-report.model';

export enum BookmarkAction {
    LoadBookmarks = '[Bookmarks] Load Bookmarks',
    LoadBookmarksSuccess = '[Bookmarks] Load Bookmarks Success',
    LoadBookmarksFailure = '[Bookmarks] Load Bookmarks Failure',
    AddBookmark = '[Bookmarks] Add Bookmark',
    AddBookmarkSuccess = '[Bookmarks] Add Bookmark Success',
    AddBookmarkFailure = '[Bookmarks] Add Bookmark Failure',
    DeleteBookmark = '[Bookmarks] Delete Bookmark',
    DeleteBookmarkSuccess = '[Bookmarks] Delete Bookmark Success',
    DeleteBookmarkFailure = '[Bookmarks] Delete Bookmark Failure',

    EditBookmark = '[Bookmarks] Edit Bookmark',
    EditBookmarkSuccess = '[Bookmarks] Edit Bookmark Success',
    EditBookmarkFailure = '[Bookmarks] Edit Bookmark Failure',
}

export class LoadBookmarksAction implements Action {
    public readonly type = BookmarkAction.LoadBookmarks;
}

export class LoadBookmarksSuccessAction implements Action {
    public readonly type = BookmarkAction.LoadBookmarksSuccess;
    constructor(public payload: { bookmarks: BookmarkedReport[] }) {}
}

export class LoadBookmarksFailureAction implements Action {
    public readonly type = BookmarkAction.LoadBookmarksFailure;
    constructor(public payload: { error: Object }) {}
}

export class AddBookmarkAction implements Action {
    public readonly type = BookmarkAction.AddBookmark;
    constructor(public payload: { bookmark: BookmarkedReport }) {}
}

export class AddBookmarkSuccessAction implements Action {
    public readonly type = BookmarkAction.AddBookmarkSuccess;
    constructor(public payload: { id: number }) {}
}

export class AddBookmarkFailureAction implements Action {
    public readonly type = BookmarkAction.AddBookmarkFailure;
    constructor(public payload: { error: Object }) {}
}

export class DeleteBookmarkAction implements Action {
    public readonly type = BookmarkAction.DeleteBookmark;
    constructor(public payload: { id: number }) {}
}

export class DeleteBookmarkSuccessAction implements Action {
    public readonly type = BookmarkAction.DeleteBookmarkSuccess;
    constructor(public payload: {}) {}
}

export class DeleteBookmarkFailureAction implements Action {
    public readonly type = BookmarkAction.DeleteBookmarkFailure;
    constructor(public payload: { error: Object }) {}
}

export class EditBookmarksAction implements Action {
    public readonly type = BookmarkAction.EditBookmark;
    constructor(public payload: { bookmark: BookmarkedReport }) {}
}
export class EditBookmarksSuccessAction implements Action {
    public readonly type = BookmarkAction.EditBookmarkSuccess;
    constructor(public payload: { bookmark: BookmarkedReport }) {}
}
export class EditBookmarksFailureAction implements Action {
    public readonly type = BookmarkAction.EditBookmarkFailure;
    constructor(public payload: { error: Object }) {}
}

export type BookmarkActions =
    | LoadBookmarksAction
    | LoadBookmarksSuccessAction
    | LoadBookmarksFailureAction
    | AddBookmarkAction
    | AddBookmarkSuccessAction
    | AddBookmarkFailureAction
    | DeleteBookmarkAction
    | DeleteBookmarkSuccessAction
    | DeleteBookmarkFailureAction
    | EditBookmarksAction
    | EditBookmarksSuccessAction
    | EditBookmarksFailureAction;
