<div *ngIf="showGraph">
    <hm-sentiment-demographics-graph *ngIf="dataAvailable" [selectedMeasure]="selectedReportMeasure" [data]="sentimentData">
    </hm-sentiment-demographics-graph>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>
<loading></loading>
<div class="report" *ngIf="showTable">
    <div class="row justify-content-end">
        <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
            <mat-icon>file_download</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportAs()">CSV</button>
        </mat-menu>
    </div>

    <hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

    <table
        class="numerical-table"
        *ngIf="dataAvailable"
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        matSort
        [matSortActive]="selectedReportMeasure.uniqueName"
        matSortDirection="desc"
        matSortDisableClear
        #exporter="matTableExporter"
    >
        <ng-container matColumnDef="category" sticky>
            <mat-header-cell *matHeaderCellDef mat-sort-header>Sentiment Topics</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.category }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="reviewer_gender">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Reviewer Gender</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.reviewer_gender }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="avg_sentiment">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Average Sentiment</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.avg_sentiment | shortNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="median_sentiment">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Median Sentiment</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.median_sentiment | shortNumber }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButton></mat-paginator>
</div>
