<div [hidden]="!permissionsAvailable || !dataAvailable">
    <hm-transaction-ifi-domestic-graph
        [hidden]="!dataAvailable"
        [data]="aggregateData"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-transaction-ifi-domestic-graph>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>
<loading></loading>
<hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>
