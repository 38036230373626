export interface Whitelabel {
    name: string;
    mapboxStyle: string;
    mapboxSelectedColor: string;
    mapboxNoRightsColor: string;
    mapLineColor: string;
    mapboxColors: string[];
    amColors: string[];
    top3Colors: string[];
    siteIconColors: string[];
}

export enum MapboxStyles {
    Hemisphere = 'mapbox://styles/mapbox/standard',
    DNSW = 'mapbox://styles/mapbox/standard',
    // QMS = 'mapbox://styles/hemisphere/cljqh4zyh005801rgel8y2rgo',
    // TTF = 'mapbox://styles/hemisphere/cljpms3h5004t01pyhfes4to9',
    // Visa = 'mapbox://styles/hemisphere/cljqaj1d4004v01r9bo4ke1he',
    // GFK = 'mapbox://styles/hemisphere/cljqgjuov004a01oc0anq3p1u',
    // Waitoc = 'mapbox://styles/hemisphere/cljqn2dj8004g01ocajar38fw',
    // Wejugo = 'mapbox://styles/hemisphere/cljqmuj2r005w01q06fwc3so8',
    // COP = 'mapbox://styles/hemisphere/clkjf6cmo004z01r2f79kft5h',
    // VMO = 'mapbox://styles/hemisphere/cljplcqjt004q01r72l7534yo',
    // KauriOra = 'mapbox://styles/hemisphere/cljqhegsq005301ra0x881sjd',
    // AMTA = 'mapbox://styles/hemisphere/cllueu1ip005n01rafx4j6jqd',
    GREY = 'mapbox://styles/hemisphere/cknzjpmtu057617nz7d0xaafa',
}
