import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Measure } from '../../measures/shared/measure.model';
import { DigitalIntegrationTrend } from '../shared/digital-integration.model';
import { DigitalIntegrationFromWhere, DIFWCalculated } from '../shared/from-where.model';
import { DataTableConfig } from './data-table.model';

@Component({
    selector: 'hm-digital-integration-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DigitalIntegrationTableComponent implements AfterViewInit, OnChanges {
    @ViewChild('exporter', { static: false }) exporter: any;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTable) table: MatTable<
        MatTableDataSource<DigitalIntegrationTrend | DIFWCalculated>
    >;

    @Input() config: DataTableConfig;

    dataSource: MatTableDataSource<DigitalIntegrationTrend | DIFWCalculated> =
        new MatTableDataSource();

    ignoreFormatter = [
        'year',
        'month_number',
        'day_in_month',
        'date',
        'boundary_name',
        'spend_per_cardholder_local',
        'spend_per_cardholder_visitor',
        'spend_per_cardholder_local_12months',
        'spend_per_cardholder_visitor_12months',
    ];

    constructor() {}

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config) {
            this.dataSource.data = this.config.data;
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'digital-integration-report' }); // File name: name of tab + timestamp
    }

    getTotalCost(m) {
        return this.config.data
            .map((d: DigitalIntegrationTrend | DIFWCalculated) => d[m])
            .reduce((acc, value) => acc + value, 0);
    }
}
