// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    isMockEnabled: true, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    hemisphereApi: 'https://api.dev.hemisphere.digital',
    defaultHome: '/users/profile',
    externalUris: {
        support: 'https://hemisphere.digital/support',
        terms: 'https://hemisphere.digital/page-terms.html',
        privacy: 'https://hemisphere.digital/page-privacy.html',
        eula: 'https://hemisphere.digital/page-license.html',
    },
    defaultDate: '',
    defaultGroup: 'SATC',
    auth0: {
        clientId: '3ogwDluNBtggHpZPpIXl1RPClIrg89gx',
        domain: 'hemisphere-dev.au.auth0.com',
        namespace: 'https://auth0.hemisphere-dev.wejugo',
        audience: 'https://hemisphere-dev-api.wejugo.com',
    },
    mapbox: {
        accessToken:
            'pk.eyJ1IjoiaGVtaXNwaGVyZSIsImEiOiJja2NjdXV0azcwOGYyMnBtOWJ6Nzl3OGljIn0.ZjNv2yPQ9T1eEOIpoSzo6w',
        geocodingUrl: 'https://api.mapbox.com/search/geocode/v6',
    },
    targomo: {
        serviceUrl: 'https://api.targomo.com/australia/',
        serviceKey: '3NXW31RCI5G33VGXFLLY300571434',
        catchments: 20,
    },
    tileserver: {
        baseUrl: 'https://api.dev.hemisphere.digital/tiles',
        dynamicUrl: 'https://api.dev.hemisphere.digital/dynamic-tiles',
    },
    flexmonster: {
        licenseKey: 'Z7BA-XHDI39-4B6G01-4P3H1L-1V3Q3T-1V4H39-4J4Q2O-4U3C27-1O1N30-6N1G1W-5C2D6P',
    },
    amcharts: {
        licenseKey: 'AM5C200314131638110',
    },
    googleAnalytics: {
        licenseKey: '',
    },
    localContextApi: 'https://localcontextshub.org',
};
