<div class="dashboard-container__wrapper" id="dashboard">
    <div class="dashboard-header">
        <div class="dashboard-subheader">
            <div class="row">
                <div class="col">
                    <h3 class="kt-subheader__title ng-star-inserted title">{{ title }}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row"></div>
</div>
