import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Whitelabel } from '../shared/whitelabel.model';
import { WhitelabelState } from './whitelabel.state';

export const selectWhitelabelState = createFeatureSelector<WhitelabelState>('whitelabel');

export const selectMapboxStyle = createSelector(
    selectWhitelabelState,
    (state) => state.whitelabel.mapboxStyle
);

export const selectMapboxColors = createSelector(
    selectWhitelabelState,
    (state) => state.whitelabel.mapboxColors
);

export const selectAMColors = createSelector(
    selectWhitelabelState,
    (state) => state.whitelabel.amColors
);

export const selectTop3Colors = createSelector(
    selectWhitelabelState,
    (state) => state.whitelabel.top3Colors
);
export const selectSiteIconColors = createSelector(
    selectWhitelabelState,
    (state) => state.whitelabel.siteIconColors
);
