<mat-list class="hem-boundary-controls">
    <div mat-subheader>Boundary type</div>
    <mat-list-item>
        <mat-icon mat-list-icon><i class="flaticon-placeholder-3"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12">
                <hm-boundary-type-selector
                    #typeSelector
                    [boundaryTypes]="boundaryTypes$ | async"
                    [boundaryType]="boundaryType"
                    (boundaryTypeChange)="boundaryTypeChange.emit($event)"
                >
                </hm-boundary-type-selector>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <div mat-subheader>Boundaries selection</div>
    <mat-list-item>
        <mat-icon mat-list-icon><i class="flaticon2-architecture-and-city"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12">
                <hm-boundary-autocomplete-selector
                    [typeSelector]="typeSelector"
                    [boundaries]="boundaries"
                    (boundariesChange)="boundariesChange.emit($event)"
                ></hm-boundary-autocomplete-selector>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>
</mat-list>
