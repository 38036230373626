import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'hm-marketplace-card',
    templateUrl: './marketplace-card.component.html',
    styleUrls: ['./marketplace-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class MarketplaceCardComponent implements OnInit {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() iconUrl: string;
    @Input() imageUrl: string;
    @Input() noSubscription: boolean;
    constructor() {}

    ngOnInit(): void {}
}
