import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { SessionInfo } from './session-info.model';

@Injectable()
export class SessionInfoService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };
    constructor(private http: HttpClient) {}
    getSessionInfo(): Observable<SessionInfo> {
        return this.http.get<SessionInfo>(`${this.apiBase}/sessioninfo`);
    }
}
