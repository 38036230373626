import { Filters, FiltersSelect, Tool } from '../shared/filters.model';
import {
    CurrentFilterActions,
    CurrentFilterActionTypes,
    SearchFilterActions,
    SearchFilterActionTypes,
} from './filters.actions';

export const initialState: Filters = {
    // Common
    mapExtended: false,
    searchSaved: false,
    categories: undefined,
    pivotFilters: undefined,
    // Traffic
    trafficSearchSaved: false,
    showFields: false,
    startDateUsingDay: undefined,
    endDateUsingDay: undefined,
    searchType: undefined,
    sites: undefined,
    packs: undefined,
    // Region
    boundaryType: undefined,
    boundaries: undefined,
    startDateUsingMonth: undefined,
    endDateUsingMonth: undefined,
};

export function filtersReducer(state = initialState, action: CurrentFilterActions): Filters {
    switch (action.type) {
        case CurrentFilterActionTypes.SetCurrentFilter:
            // merge new state
            return { ...state, ...action.payload.filter };
        default:
            return state;
    }
}

export const searchState: FiltersSelect = {
    boundaryType: undefined,
    boundaries: undefined,
    tool: Tool.Region,
};

export function searchReducer(state = searchState, action: SearchFilterActions): FiltersSelect {
    switch (action.type) {
        case SearchFilterActionTypes.SetCurrentFilterSearch:
            // merge new state
            return { ...state, ...action.payload.filter };
        default:
            return state;
    }
}
