<div *ngIf="user$ | async as user" class="kt-header__topbar-item kt-header__topbar-item--user">
    <div [matMenuTriggerFor]="userProfile" class="kt-header__topbar-wrapper">
        <div class="kt-header__topbar-user" [ngClass]="{ 'kt-header__topbar-icon': icon }">
            <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting">Hi,</span>
            <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting">{{ user.given_name }}</span>
            <i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
            <img *ngIf="avatar" alt="Pic" [attr.src]="user.picture" />
            <span *ngIf="badge" class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
                >{{ user.given_name | firstLetter }} {{ user.family_name | firstLetter }}</span
            >
        </div>
    </div>
    <mat-menu #userProfile="matMenu" xPosition="before" yPosition="below" class="user-profile-menu">
        <!-- class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl" -->
        <!--begin: Head -->
        <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
            <div class="kt-user-card__avatar">
                <!-- <img alt="Pic" [attr.src]="_user.picture"/> -->
                <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold">
                    <div *ngIf="user.given_name; else elseBlock">
                        {{ user.given_name | firstLetter }}{{ user.family_name | firstLetter }}
                    </div>
                    <ng-template #elseBlock>HM</ng-template>
                </span>
            </div>
            <div class="kt-user-card__name">
                <div class="user-card-container">
                    {{ user.given_name }} {{ user.family_name }}
                    <hm-organisations-switch *ngxPermissionsOnly="['SYS_ADMIN', 'CLIENT_ADMIN', 'CONTRIBUTOR']"></hm-organisations-switch>
                </div>
            </div>
        </div>
        <!--end: Head -->

        <!--begin: Navigation -->
        <div class="kt-notification">
            <a routerLink="/users/profile" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div routerLink="/users/profile" class="kt-notification__item-title kt-font-bold">My Profile</div>
                    <div class="kt-notification__item-time">Account settings and more</div>
                </div>
            </a>

            <div class="kt-notification__custom">
                <a href="javascript:;" (click)="logout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
            </div>
        </div>
        <!--end: Navigation -->
    </mat-menu>
</div>
