import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectDigitalIntegration } from 'app/core/session-info/store/selectors/session-info.selector';
import { dateToDateString } from 'app/core/utilities/date';
import { checkDates } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Category } from '../../category/shared/category.model';
import { DataTableConfig } from '../data-table/data-table.model';
import { MultipleAxesLineChartConfig } from '../multiple-axes-line-chart/multiple-axes-line-chart.model';
import { DigitalIntegrationTrend } from '../shared/digital-integration.model';
import { DigitalIntegrationService } from '../shared/digital-integration.service';
import { TrendDataService } from './shared/trend-data.service';

@Component({
    selector: 'hm-trend-data',
    templateUrl: './trend-data.component.html',
    styleUrls: ['./trend-data.component.scss'],
})
export class TrendDataComponent implements OnInit, OnChanges, OnDestroy {
    @Input() categories: Category[];
    @Input() boundaries: Boundary[];
    @Input() boundaryType: string;
    @Input() dateRange: Date[];

    dataAvailable = true;
    permissionsAvailable = false;

    multipleBoundaries = false;

    chartConfig: MultipleAxesLineChartConfig;
    private chartSubject = new BehaviorSubject<boolean>(false);
    chart$: Observable<boolean> = this.chartSubject.asObservable();

    trendTableConfig: DataTableConfig;
    private trendTableSubject = new BehaviorSubject<boolean>(false);
    trendTable$: Observable<boolean> = this.trendTableSubject.asObservable();

    private subscriptions: Subscription[] = [];

    constructor(
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private trendDataService: TrendDataService,
        private digitalIntegrationService: DigitalIntegrationService
    ) {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.checkPermissions();
        if (this.permissionsAvailable) {
            this.getData();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    checkPermissions() {
        const rights = this.store.pipe(select(selectDigitalIntegration));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = checkDates(r[0], this.dateRange[0], this.dateRange[1]);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    getData() {
        this.loadingService.loadingOn();

        const startDate = dateToDateString(this.dateRange[0], 'YYYY-MMM-DD');
        const endDate = dateToDateString(this.dateRange[1], 'YYYY-MMM-DD');

        let allCategories = true;
        for (const c of this.categories) {
            if (!c.selected) {
                allCategories = false;
                break;
            }
        }

        const selectedCategories = allCategories
            ? ['Any']
            : this.categories.filter((c) => c.selected).map((c) => c.name);

        const trend$ = this.digitalIntegrationService.getTrend(
            selectedCategories,
            this.boundaryType as Level,
            this.boundaries.map((boundary) => boundary.code),
            startDate,
            endDate
        );

        this.subscriptions.push(
            trend$.subscribe({
                next: (res: DigitalIntegrationTrend[]) => {
                    if (res.length > 0) {
                        res.forEach((r) => {
                            r.date = new Date(r.year, r.month_number - 1, r.day_in_month);
                        });

                        res = res.sort((a, b) => a.date.valueOf() - b.date.valueOf());

                        this.dataAvailable = true;
                        this.chartConfig = this.trendDataService.getChartConfig(res, '', true, '', [
                            'leads',
                            'unique_visits_visitor',
                        ]);

                        this.trendTableConfig = this.trendDataService.getDataTableConfig(0, res);

                        this.chartSubject.next(true);
                        this.trendTableSubject.next(true);
                    } else {
                        this.dataAvailable = false;
                    }
                },
                complete: () => {
                    this.loadingService.loadingOff();
                },
            })
        );
    }
}
