<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
    <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
        <div class="kt-footer__copyright">
            {{ today | date: 'yyyy' }}&nbsp;&copy;&nbsp;<a href="https://wejugo.com" target="_blank" class="kt-link">wejugo.com</a>
        </div>
        <div class="kt-footer__menu">
            <!-- <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wejugo.zendesk.com/hc/en-au/signin"
                class="kt-link"
                >Contact Us</a
            >
            &nbsp;&nbsp; -->
            <a target="_blank" rel="noopener noreferrer" href="https://hemisphere.digital/terms" class="kt-link">Terms of Service</a>
            &nbsp;&nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://hemisphere.digital/privacy" class="kt-link">Privacy Policy</a>
        </div>
    </div>
</div>
<!-- end:: Footer -->
