export const colorsMap = [
    '#E3B505', // c1
    '#F7E733', // c2
    '#ccd859', //c3
    '#1C7C54', // c4
    '#004F2D', // c5
    '#a5be19', // c6
    '#60695c', // c7
    '#ccc', //c8
];

export function calculateColorBand(
    value: number,
    scale: number[],
    colors: string[] = colorsMap
): string {
    if (value < -10 && value != 0) return 'pink';
    if (value == 0) return 'rgba(9, 30, 66, 0.01)';
    else if (value >= scale[0] && value < scale[1]) return colors[0];
    else if (value >= scale[1] && value < scale[2]) return colors[1];
    else if (value >= scale[2] && value < scale[3]) return colors[2];
    else if (value >= scale[3] && value < scale[4]) return colors[3];
    else if (value >= scale[4] && value < scale[5]) return colors[4];
    else if (value >= scale[5] && value < scale[6]) return colors[5];
    else if (value >= scale[6]) return colors[6];
    else return colors[7];
}
