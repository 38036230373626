import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'hm-weekpicker',
    templateUrl: './weekpicker.component.html',
    styleUrls: ['./weekpicker.component.scss'],
})
export class WeekpickerComponent implements OnInit {
    @Input() min;
    @Input() max;
    @Input() value;
    @Output() valueChange = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}

    onWeekChange(event) {
        this.value = event.target.value;
        this.valueChange.emit(event.target.value);
    }
}
