import { QueryBuilder, QueryRequest, QueryResponse } from './query';

export class SelectRequest extends QueryRequest {
    type: 'select' = 'select';
}

export interface SelectResponse extends QueryResponse {}

export class SelectBuilder extends QueryBuilder<SelectRequest> {
    constructor(req?: SelectRequest) {
        super(req || new SelectRequest());
    }
}
