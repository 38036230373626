import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'kt-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements AfterViewInit, OnChanges {
    @Input() travelTimes: number[];
    @Input() travelTimeColours: string[];

    constructor() {}

    ngAfterViewInit() {
        this.relabelCells();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.travelTimes && !changes.travelTimes.isFirstChange()) {
            this.relabelCells();
        }
    }

    relabelCells() {
        const cell = document.getElementsByClassName('legend-cell');

        for (let i = 0; i < this.travelTimes.length; i++) {
            let item = cell[i] as HTMLElement;
            item.innerText = ((this.travelTimes[i] / 60) | 0) + ' mins';
        }
    }
}
