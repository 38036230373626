// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Module components
import { AuthComponent } from './auth.component';

import { LoginComponent } from './login/login.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
// Auth
import { AuthEffects, Auth0Guard, authReducer, Auth0Service } from '../../../core/auth';
import { WelcomeComponent } from './welcome/welcome.component';
import { PipeModule } from '../../../pipes/pipe.module';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
                data: { returnUrl: window.location.pathname },
            },
            {
                path: 'welcome',
                component: WelcomeComponent,
                data: { returnUrl: window.location.pathname },
            },
        ],
    },
];

@NgModule({
    providers: [],
    exports: [AuthComponent],
    declarations: [AuthComponent, LoginComponent, AuthNoticeComponent, WelcomeComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        RouterModule.forChild(routes),
        MatInputModule,
        MatFormFieldModule,
        MatCheckboxModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('auth', authReducer),
        EffectsModule.forFeature([AuthEffects]),
        PipeModule,
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [Auth0Service, Auth0Guard],
        };
    }
}
