export function hideSmall(ev) {
    if (ev.target.dataItem.values.value.percent <= 1) {
        ev.target.hide();
    } else {
        ev.target.show();
    }
}

export function hideSmalP(ev) {
    if (ev.target.dataItem.values.valueX <= 5) {
        ev.target.hide();
    } else {
        ev.target.show();
    }
}


export function setScaleBuckets(min: number, max: number, values: any[]): number[] {
    let buckets: number[] = [];

    if (values.length == 0) {
        buckets = [0,0,0,0,0,0,0];
    } else if (values.length <= 6){
        const min = 0;
        const numBuckets = 6;
        const range = max - min;
        const step = range / numBuckets;
        for (let i = min; i < numBuckets; i++) {
            buckets.push(min + i * step);
        }
        buckets.push(max);
    } else {
        // Take the logarithm of the minimum and maximum values
        const logMin = min > 0 ? Math.log10(min) : 0;
        const logMax = max > 0 ? Math.log10(max) : 0;

        // Divide the log range into 7 segments
        const logRange = logMax - logMin;
        const logSegmentSize = logRange / 6;

        buckets.push(0);
        for (let i = 1; i <= 5; i++) {
            buckets.push(10 ** (logMin + i * logSegmentSize));
        }
        buckets.push(max);
    }

    return buckets;
}