import { WhitelabelActions, WhitelabelActionType } from './whitelabel.actions';
import { initialWhitelabelState, WhitelabelState } from './whitelabel.state';

export function whitelabelReducer(
    state = initialWhitelabelState,
    action: WhitelabelActions
): WhitelabelState {
    switch (action.type) {
        case WhitelabelActionType.WhitelabelGet:
            return { ...state };

        case WhitelabelActionType.WhitelabelLoaded: {
            return {
                whitelabel: action.payload.whitelabel,
                isLoaded: true,
            };
        }
        default:
            return state;
    }
}
