import { Component, OnInit } from '@angular/core';
import { MenuConfigService } from 'app/core/_base/layout';
import { MenuItem } from 'app/core/_base/layout/models/menu-config.model';

@Component({
    selector: 'hm-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {
    permission: string | string[] = '';
    menu: MenuItem[];

    constructor(private menuConfig: MenuConfigService) {}

    ngOnInit(): void {
        const conf = this.menuConfig.getMenus();
        this.permission = conf.admin?.permission;
        this.menu = conf.admin?.items;
    }
}
