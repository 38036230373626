export class User {
    id: number;
    given_name: string;
    family_name: string;
    nickname: string;
    picture: string;
    locale: string;
    email: string;
    email_verified: boolean;
    roles?: number[];
    organisation?: string;
    sub: string;

    clear(): void {
        this.given_name = '';
    }
}
