<div [hidden]="!permissionsAvailable">
    <hm-sentiment-graph [hidden]="!dataAvailable" [selectedMeasure]="selectedReportMeasure" [data]="chartData" [chartName]="chartName">
    </hm-sentiment-graph>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>
<loading></loading>

<hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>
<hm-sentiment-table
    *ngIf="showTable"
    [data]="tableData"
    [dataAvailable]="dataAvailable"
    [selectedMeasure]="selectedMeasure"
></hm-sentiment-table>
