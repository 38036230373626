<table
    class="numerical-table"
    [dataSource]="source"
    mat-table
    matTableExporter
    matSort
    matSortDirection="desc"
    #exporter="matTableExporter"
>
    <ng-container *ngFor="let measure of measures" matColumnDef="{{ measure.uniqueName }}">
        <mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
            {{ measure.title }}
        </mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            <div *ngIf="!isNumber(row[measure.uniqueName]); else format">
                {{ row[measure.uniqueName] }}
            </div>
            <ng-template #format>
                {{ row[measure.uniqueName] | shortNumber }}
            </ng-template>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</table>
<mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButtons></mat-paginator>
