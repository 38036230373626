import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Measure } from '../../../content/measures/shared/measure.model';

@Component({
    selector: 'kt-context-menu3',
    templateUrl: './context-menu3.component.html',
    styleUrls: ['./context-menu3.component.scss'],
})
export class ContextMenu3Component implements OnInit {
    accessToken: Observable<string>;

    @Input() measures$: Observable<Measure[]>;

    @Output() selectedMeasureEvent = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {
        //   this.accessToken = this.store.pipe(select(currentAuthToken));
    }

    updateMeasure(measure) {
        this.selectedMeasureEvent.emit(measure);
    }
}
