import { Injectable } from '@angular/core';
import { TargomoClient, LatLngId } from '@targomo/core';
import { PolygonGeoJsonOptions } from '@targomo/core/typings/api/payload/polygonRequestPayload';
import { FeatureCollection, MultiPolygon } from 'geojson';
import { Observable, from } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TargomoService {
    serviceKey = environment.targomo.serviceKey;
    serviceUrl = environment.targomo.serviceUrl;

    client: TargomoClient;
    travelTimes = [600, 1200, 1800];
    timeColors = ['#006837', '#F7931E', '#C1272D'];
    options: PolygonGeoJsonOptions = {
        travelType: 'car',
        travelEdgeWeights: this.travelTimes,
        maxEdgeWeight: 1800,
        edgeWeight: 'time',
        srid: 4326,
        //   simplify: 200,
        serializer: 'geojson',
        //    buffer: 0.002,
        useClientCache: true,
        // elevation: true
    };
    sourceId = 1;

    targomoLayer: mapboxgl.Layer = {
        id: 'polygons',
        type: 'fill-extrusion',
        source: 'polygonSource',
        layout: {},
        paint: {
            'fill-extrusion-base': 0,
            'fill-extrusion-height': {
                property: 'time',
                stops: this.getHeightStops(this.travelTimes, 2),
            },
            'fill-extrusion-color': {
                property: 'time',
                stops: this.getColorStops(this.travelTimes, this.timeColors),
            },
            'fill-extrusion-opacity': 0.5,
        },
    };

    constructor() {
        this.client = new TargomoClient(this.serviceUrl, this.serviceKey);
    }

    getTargomoLayer(): mapboxgl.Layer {
        return this.targomoLayer;
    }

    setTargomoLayer(newLayerConfig: mapboxgl.Layer) {
        this.targomoLayer = newLayerConfig;
    }

    // height stops function
    getHeightStops(travelTimes, heightFactor) {
        return [
            [travelTimes[0], travelTimes.length * (10 * heightFactor)],
            [travelTimes[travelTimes.length - 1], travelTimes.length * heightFactor],
        ];
    }

    // color stop functionQ
    getColorStops(times, colors) {
        const colorsConfig = times.map((time, idx) => {
            return [times[idx], colors[idx]];
        });
        return colorsConfig;
    }

    getIsochrones(coordinates: LatLngId[]): Observable<FeatureCollection<MultiPolygon>> {
        return from(this.client.polygons.fetch(coordinates, this.options));
    }
}
