<mgl-geojson-source id="isochrone-source" [data]="isochrones | async"></mgl-geojson-source>
<mgl-layer
    id="isochrones"
    type="fill"
    source="isochrone-source"
    [paint]="{
        'fill-color': {
            type: 'identity',
            property: 'fill'
        },
        'fill-opacity': 0.2,
        'fill-outline-color': {
            type: 'identity',
            property: 'fill'
        }
    }"
    [layout]="{
        visibility: showIsochrones
    }"
></mgl-layer>

<mgl-layer
    id="isochrones-lines"
    type="line"
    source="isochrone-source"
    [paint]="{
        'line-color': {
            type: 'identity',
            property: 'fill'
        },
        'line-opacity': 1.0,
        'line-width': 1.5
    }"
    [layout]="{
        visibility: showIsochrones
    }"
></mgl-layer>
