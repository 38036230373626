// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject, filter } from 'rxjs';
import { AppMenus } from '../models/menu-config.model';
import { Store } from '@ngrx/store';
import { selectCurrentOrganisation } from 'app/core/session-info/store/selectors/session-info.selector';

@Injectable()
export class MenuConfigService {
    onConfigUpdated$: Subject<AppMenus>;

    private menuConfig: AppMenus;

    constructor(private store: Store) {
        // register on config changed event and set default config
        this.onConfigUpdated$ = new Subject();
    }

    getMenus() {
        return this.menuConfig;
    }

    loadConfigs(config: AppMenus) {
        this.menuConfig = config;

        this.store
            .select(selectCurrentOrganisation)
            .pipe(filter((org) => org.name.trim().toLowerCase() === 'destination nsw'))
            .subscribe(() => {
                // Find and rename the Performance Indicators menu item to Tourism Indicators
                const performanceIndicators = this.menuConfig.aside.items.find(
                    (item) => item.title.trim().toLowerCase() === 'performance indicators'
                );
                if (performanceIndicators) {
                    performanceIndicators.title = 'Tourism Indicators';
                }
            });

        this.onConfigUpdated$.next(this.menuConfig);
    }
}
