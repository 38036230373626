<!-- BEGIN: Horizontal Menu -->
<button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn">
    <i class="la la-close"></i>
</button>
<div ktOffcanvas [options]="offcanvasOptions" class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
    <div ktMenu id="kt_header_menu" class="kt-header-menu kt-header-menu-mobile">
        <ng-container *ngFor="let item of menuHorService.menuList$ | async">
            <ng-template *ngxPermissionsOnly="item.permission" [ngxPermissionsExcept]="item.except">
                <ng-container *ngIf="item.title" [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ item: item }">
                </ng-container>
            </ng-template>
        </ng-container>
    </div>
</div>

<ng-template #menuTemplate let-item="item">
    <button mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <i *ngIf="item.icon" class="menu-icon" [ngClass]="item.icon"></i> {{ item.title }}<mat-icon>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu" yPosition="below">
        <div class="flex">
            <ng-container *ngFor="let column of item.submenu">
                <ng-container *ngIf="column" [ngTemplateOutlet]="columns" [ngTemplateOutletContext]="{ column: column }"></ng-container>
            </ng-container>
        </div>
    </mat-menu>
</ng-template>

<ng-template #columns let-column="column">
    <ng-template [ngxPermissionsOnly]="column.permission" [ngxPermissionsExcept]="column.except">
        <div class="menu-split33">
            <div class="flexhover">
                <div class="svgbox">
                    <i *ngIf="column.icon" class="{{ column.icon }}"></i>
                </div>
                <div class="links">
                    <h4>{{ column.title }}</h4>
                    <p *ngIf="column.description">{{ column.description }}</p>

                    <div class="links-back">
                        <ng-container *ngFor="let link of column.submenu">
                            <ng-container *ngIf="link" [ngTemplateOutlet]="links" [ngTemplateOutletContext]="{ link: link }"></ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #links let-link="link">
    <ng-template #title>
        <p class="header">{{ link.title }}</p>
    </ng-template>
    <ng-template #externalLink>
        <a class="nav-link" target="{{ link.target }}" title="{{ link.title }}" href="{{ link.page }}" routerLinkActive="nav-link-active">
            {{ link.title }}
        </a>
        <p *ngIf="link.description">{{ link.description }}</p>
    </ng-template>
    <ng-template [ngxPermissionsOnly]="link.permission" [ngxPermissionsExcept]="link.except">
        <div *ngIf="link.page; else title">
            <ng-container *ngIf="!link.target; else externalLink">
                <a class="nav-link" title="{{ link.title }}" routerLink="{{ link.page }}" routerLinkActive="nav-link-active">
                    {{ link.title }}
                </a>
                <p *ngIf="link.description">{{ link.description }}</p>
            </ng-container>
        </div>
    </ng-template>
</ng-template>
