import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectDomesticTransaction } from 'app/core/session-info/store/selectors/session-info.selector';
import { isDateValidForDataset } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { Subscription } from 'rxjs';
import { Measure } from '../../../measures/shared/measure.model';
import { DemographicsService } from '../demographics.service';
import { IfiDomesticDG } from './shared/ifi-domestic-demographics.model';

@Component({
    selector: 'hm-ifi-domestic-demographics',
    templateUrl: './ifi-domestic-demographics.component.html',
    styleUrls: ['./ifi-domestic-demographics.component.scss'],
})
export class IfiDomesticDemographicsComponent implements OnChanges, OnDestroy, AfterViewInit {
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];
    @Input() showTable: boolean = true;
    @Input() showGraph: boolean = true;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('exporter') exporter: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() reportDate: string;

    displayedColumns = [
        'cust_behavioral_segment',
        'vol_tran_local',
        'vol_tran_visitor',
        'val_tran_local',
        'val_tran_visitor',
        'unique_count_local',
        'unique_count_visitor',
        'local_ratio',
        'visitor_ratio',
    ];
    reportMeasures: Measure[] = [
        {
            uniqueName: 'vol_tran_local',
            title: 'Volume (Local)',
            format: '#,###.##',
        },
        {
            uniqueName: 'vol_tran_visitor',
            title: 'Volume (Visitor)',
            format: '#,###.##',
        },
        {
            uniqueName: 'val_tran_local',
            title: 'Value (Local)',
            format: '$#,###.##',
        },
        {
            uniqueName: 'val_tran_visitor',
            title: 'Value (Visitor)',
            format: '$#,###.##',
        },
        {
            uniqueName: 'unique_count_local',
            title: 'Unique Count (Local)',
            format: '#,###.##',
        },
        {
            uniqueName: 'unique_count_visitor',
            title: 'Unique Count (Visitor)',
            format: '#,###.##',
        },
        {
            uniqueName: 'local_ratio',
            title: 'Local Ratio',
            format: '#,###.##%',
        },
        {
            uniqueName: 'visitor_ratio',
            title: 'Visitor Ratio',
            format: '#,###.##%',
        },
    ];

    /** LISTINGS GRAPH */
    selectedReportMeasure = this.reportMeasures[0];
    sentimentData: IfiDomesticDG[] = [];
    dataSource: MatTableDataSource<IfiDomesticDG> = new MatTableDataSource();
    dataAvailable = true;
    multipleBoundaries = false;
    permissionsAvailable = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private demoService: DemographicsService,
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.checkPermissions();
        this.refreshData();
    }
    checkPermissions() {
        const rights = this.store.pipe(select(selectDomesticTransaction));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = isDateValidForDataset(r[0], this.reportDate);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
            this.refreshData();
        } else if (changes.reportDate) {
            this.checkPermissions();
            if (this.permissionsAvailable == true) {
                this.dataAvailable = true;
                this.refreshData();
            }
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'ifi_domestic' }); // File name: name of tab + timestamp
    }

    selectedMeasure(selectedMeasure: Measure) {
        this.selectedReportMeasure = selectedMeasure;
        this.ref.detectChanges();
        this.dataSource.sort = this.sort;
    }

    onSelectReportMeasure() {
        // change chart
    }

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            this.subscriptions.push(
                this.demoService
                    .getIfiDomestic(
                        this.boundaryType as Level,
                        this.reportDate,
                        this.boundaries.map((boundary) => boundary.code)
                    )
                    .subscribe((res: IfiDomesticDG[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.dataSource.data = res;
                            this.sentimentData = res;
                            this.dataSource.sort = this.sort;
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
