import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DataPermission } from 'app/core/data-permissions/shared/data-permission.model';

export interface UserDataPermissionState extends EntityState<DataPermission> {
    error: boolean;
    loading: boolean;
}

export const userDataPermissionAdapter: EntityAdapter<DataPermission> = createEntityAdapter<
    DataPermission
>({
    selectId: (userDataPermission: DataPermission) => userDataPermission.id,
});

export const initialUserDataPermissionState: UserDataPermissionState = userDataPermissionAdapter.getInitialState(
    {
        error: false,
        loading: true,
    }
);
