import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable()
export class PrintService {
    private printSubject = new BehaviorSubject<boolean>(false);

    print$: Observable<boolean> = this.printSubject.asObservable();

    showPrintUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.printOn()),
            concatMap(() => obs$),
            finalize(() => this.printOff())
        );
    }

    printOn() {
        this.printSubject.next(true);
    }

    printOff() {
        this.printSubject.next(false);
    }
}
