import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as h3 from 'h3-js';

import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { Store, select } from '@ngrx/store';
import { area as turfArea, polygon as turfPolygon } from '@turf/turf';
import { AppState } from 'app/core/reducers';
import { selectMapboxStyle } from 'app/core/whitelabel/store/whitelabel.selector';
import { Site } from 'app/views/pages/traffic/sites/shared/sites.model';
import { environment } from 'environments/environment';
import { Polygon } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'hm-h3-picker',
    templateUrl: './h3-picker.component.html',
    styleUrls: ['./h3-picker.component.scss'],
})
export class H3PickerComponent implements OnInit, OnDestroy {
    @Input() site: Site;
    @Output() siteChange = new EventEmitter<Site>();
    @Input() iconMarker: String;
    @Input() polygon: Polygon[];
    @Input() zoom = 16;
    @Input() editable = false;

    public map: mapboxgl.Map;

    style: string = 'mapbox://styles/mapbox/standard';
    hexLayer: MapboxLayer;
    draw: MapboxDraw;

    editPolygon: any = '';
    user_portColor = '#000';
    customMapLayers: string[] = ['hex_0', 'hex_1', 'hex_2'];
    isExpanded = false;
    @ViewChild('file', { static: false }) file;

    constructor(
        private permissionsService: NgxPermissionsService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.store.pipe(select(selectMapboxStyle)).subscribe((style) => {
            this.style = style;
        });

        mapboxgl.accessToken = environment.mapbox.accessToken;
        this.map = new mapboxgl.Map({
            container: 'map',
            style: this.style, //'mapbox://styles/hemisphere/cknzjpmtu057617nz7d0xaafa',
            center: [this.site.longitude, this.site.latitude],
            zoom: this.zoom,
        });

        this.map.addControl(new mapboxgl.NavigationControl());

        const customMarker = document.createElement('div');
        customMarker.style.backgroundSize = 'cover';
        customMarker.style.backgroundPosition = 'center';

        if (this.iconMarker != null) {
            if (this.iconMarker.startsWith('http')) {
                customMarker.style.backgroundImage = `url(${this.iconMarker})`;
            } else {
                customMarker.style.backgroundImage = `url(https://storage.googleapis.com/whitelabel-hd/icons/${this.iconMarker})`;
            }
            customMarker.style.width = '24px';
            customMarker.style.height = '24px';
            const marker = new mapboxgl.Marker({ element: customMarker, draggable: true })
                .setLngLat([this.site.longitude, this.site.latitude])
                .addTo(this.map);

            marker.on('dragend', () => {
                const lngLat = marker.getLngLat();
                this.site.longitude = lngLat.lng;
                this.site.latitude = lngLat.lat;
                this.siteChange.emit(this.site);
            });
        } else {
            const marker2 = new mapboxgl.Marker({ rotation: 0, draggable: true })
                .setLngLat([this.site.longitude, this.site.latitude])
                .addTo(this.map);

            marker2.on('dragend', () => {
                const lngLat = marker2.getLngLat();
                this.site.longitude = lngLat.lng;
                this.site.latitude = lngLat.lat;
                this.siteChange.emit(this.site);
            });
        }

        if (this.site.effective_area) {
            if (
                this.site.effective_area.coordinates &&
                this.site.effective_area.coordinates.length > 0
            ) {
                this.editPolygon = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {
                                id: 'a43686ff66a8f84ec4f352647f80a8dc',
                                meta: 'feature',
                                'meta:type': 'Polygon',
                                active: 'true',
                                mode: 'direct_select',
                            },
                            geometry: this.site.effective_area,
                        },
                    ],
                };
                var coordinates = this.site.effective_area.coordinates[0];
                var bounds = coordinates.reduce(function (bounds, coord) {
                    return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
                this.map.fitBounds(bounds, {
                    padding: 20,
                });
            }
        }

        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: this.editable,
                trash: this.editable,
            },
            defaultMode: 'draw_polygon',
            styles: [
                {
                    id: 'gl-draw-polygon-fill-inactive',
                    type: 'fill',
                    filter: [
                        'all',
                        ['==', 'active', 'false'],
                        ['==', '$type', 'Polygon'],
                        ['!=', 'mode', 'static'],
                    ],
                    paint: {
                        'fill-color': '#000',
                        'fill-outline-color': '#000',
                        'fill-opacity': 0.1,
                    },
                },
                {
                    id: 'gl-draw-polygon-fill-active',
                    type: 'fill',
                    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
                    paint: {
                        'fill-color': '#000',
                        'fill-outline-color': '#000',
                        'fill-opacity': 0.1,
                    },
                },
                {
                    id: 'gl-draw-polygon-midpoint',
                    type: 'circle',
                    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
                    paint: {
                        'circle-radius': 3,
                        'circle-color': '#000',
                    },
                },
                {
                    id: 'gl-draw-polygon-stroke-inactive',
                    type: 'line',
                    filter: [
                        'all',
                        ['==', 'active', 'false'],
                        ['==', '$type', 'Polygon'],
                        ['!=', 'mode', 'static'],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#000',
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-polygon-stroke-active',
                    type: 'line',
                    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#000',
                        'line-dasharray': [0.2, 2],
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-line-inactive',
                    type: 'line',
                    filter: [
                        'all',
                        ['==', 'active', 'false'],
                        ['==', '$type', 'LineString'],
                        ['!=', 'mode', 'static'],
                    ],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#000',
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-line-active',
                    type: 'line',
                    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#000',
                        'line-dasharray': [0.2, 2],
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', 'meta', 'vertex'],
                        ['==', '$type', 'Point'],
                        ['!=', 'mode', 'static'],
                    ],
                    paint: {
                        'circle-radius': 5,
                        'circle-color': '#fff',
                    },
                },
                {
                    id: 'gl-draw-polygon-and-line-vertex-inactive',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', 'meta', 'vertex'],
                        ['==', '$type', 'Point'],
                        ['!=', 'mode', 'static'],
                    ],
                    paint: {
                        'circle-radius': 3,
                        'circle-color': '#000',
                    },
                },
                {
                    id: 'gl-draw-point-point-stroke-inactive',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', 'active', 'false'],
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'feature'],
                        ['!=', 'mode', 'static'],
                    ],
                    paint: {
                        'circle-radius': 5,
                        'circle-opacity': 1,
                        'circle-color': '#fff',
                    },
                },
                {
                    id: 'gl-draw-point-inactive',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', 'active', 'false'],
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'feature'],
                        ['!=', 'mode', 'static'],
                    ],
                    paint: {
                        'circle-radius': 3,
                        'circle-color': '#000',
                    },
                },
                {
                    id: 'gl-draw-point-stroke-active',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['==', 'active', 'true'],
                        ['!=', 'meta', 'midpoint'],
                    ],
                    paint: {
                        'circle-radius': 7,
                        'circle-color': '#fff',
                    },
                },
                {
                    id: 'gl-draw-point-active',
                    type: 'circle',
                    filter: [
                        'all',
                        ['==', '$type', 'Point'],
                        ['!=', 'meta', 'midpoint'],
                        ['==', 'active', 'true'],
                    ],
                    paint: {
                        'circle-radius': 5,
                        'circle-color': '#000',
                    },
                },
                {
                    id: 'gl-draw-polygon-fill-static',
                    type: 'fill',
                    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
                    paint: {
                        'fill-color': '#404040',
                        'fill-outline-color': '#404040',
                        'fill-opacity': 0.1,
                    },
                },
                {
                    id: 'gl-draw-polygon-stroke-static',
                    type: 'line',
                    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#404040',
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-line-static',
                    type: 'line',
                    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': '#404040',
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-point-static',
                    type: 'circle',
                    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
                    paint: {
                        'circle-radius': 5,
                        'circle-color': '#404040',
                    },
                },
                {
                    id: 'gl-draw-polygon-color-picker',
                    type: 'fill',
                    filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_portColor']],
                    paint: {
                        'fill-color': ['get', 'user_portColor'],
                        'fill-outline-color': ['get', 'user_portColor'],
                        'fill-opacity': 0.5,
                    },
                },
                {
                    id: 'gl-draw-line-color-picker',
                    type: 'line',
                    filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_portColor']],
                    paint: {
                        'line-color': ['get', 'user_portColor'],
                        'line-width': 2,
                    },
                },
                {
                    id: 'gl-draw-point-color-picker',
                    type: 'circle',
                    filter: ['all', ['==', '$type', 'Point'], ['has', 'user_portColor']],
                    paint: {
                        'circle-radius': 3,
                        'circle-color': ['get', 'user_portColor'],
                    },
                },
            ],
        });

        this.map.on('draw.delete', (e) => {
            this.deleteArea(e);
        });
        this.map.addControl(this.draw);
        this.map.on('load', (e) => {
            if (this.editable) {
                this.map.on('draw.create', (e) => {
                    this.updateArea(e);
                });
                this.map.on('draw.update', (e) => {
                    this.updateArea(e);
                });
                this.draw.trash();
            }
            this.createArea();

            if (this.site.effective_area) {
                if (
                    this.site.effective_area.coordinates &&
                    this.site.effective_area.coordinates.length > 0
                ) {
                    this.draw.set(this.editPolygon);
                    this.updateArea(e);
                }
            }
        });
    }

    ngOnDestroy() {
        this.map.remove();
    }

    deleteArea(e) {
        if (this.editable == true) {
            this.site.effective_area = { type: 'polygon', coordinates: null };
            this.siteChange.emit(this.site);
        }
        this.customMapLayers.forEach((l) => this.map.removeLayer(l));
        this.customMapLayers = [];
    }

    createArea() {
        const hex = new MapboxLayer({
            id: 'hex_0',
            type: H3HexagonLayer,
            data: null,
        });
        this.map.addLayer(hex, 'gl-draw-polygon-fill-inactive.cold');

        const hex2 = new MapboxLayer({
            id: 'hex_1',
            type: H3HexagonLayer,
            data: null,
        });
        this.map.addLayer(hex2, 'gl-draw-polygon-fill-inactive.cold');

        const hex3 = new MapboxLayer({
            id: 'hex_2',
            type: H3HexagonLayer,
            data: null,
        });
        this.map.addLayer(hex3, 'gl-draw-polygon-fill-inactive.cold');
    }

    updateArea(e) {
        const data = this.draw.getAll();
        const polygon = data.features[0].geometry as any;
        console;
        if (this.editable == true) {
            this.site.effective_area = polygon as any;
            this.siteChange.emit(this.site);
        }

        let hexLayers = polygon.coordinates.length ? calculateHexLayers(polygon) : [];
        this.customMapLayers.forEach((l) => this.map.removeLayer(l));
        this.customMapLayers = [];

        hexLayers.forEach((l) => {
            this.map.addLayer(l, 'gl-draw-polygon-fill-inactive.cold');
            this.customMapLayers.push(l.id);
        });
    }

    help() {
        // this._bottomSheet.open(HelpComponent);
        this.isExpanded = !this.isExpanded;
    }

    uploadGeoJson() {
        this.editable = true;
        this.site.effective_area = this.editPolygon;
        this.draw.deleteAll();
        this.draw.set(this.editPolygon);
        this.updateArea(null);
    }
    addFiles() {
        this.file.nativeElement.click();
    }
    async onFilesAdded() {
        const files: FileList = this.file.nativeElement.files;
        for (var i = 0; i < files.length; i++) {
            const geojson = await files[i].text();
            const f1 = JSON.parse(geojson);
            const feature = {
                type: 'FeatureCollection',
                features: [f1],
            };
            this.editPolygon = feature;
            this.uploadGeoJson();
        }
    }
}

// area at each resolution level
// e.g index 0 of this array is level 0 hex area m^2
const hexLookup = [
    4357449416078.392, 609788441794.134, 86801780398.997, 12393434655.088, 1770347654.491,
    252903858.182, 36129062.164, 5161293.36, 737327.598, 115047.502, 105332.513, 2149.643, 307.092,
    43.87, 6.267, 0.895,
];

const layerColours = [
    [0, 255, 0],
    [242, 121, 53],
    [226, 215, 125],
];

function calculateHexLayers(polygon: any): mapboxgl.AnyLayer[] {
    let t = turfPolygon(polygon.coordinates);
    let aream2 = turfArea(t);
    const threshold = 15000;
    let hl = hexLookup.map((h) => h * threshold);
    let baseRes = 0;
    for (const [index, element] of hl.entries()) {
        if (aream2 > element) {
            break;
        }
        baseRes = index;
    }

    const convertedPolygon = polygon.coordinates[0].map(function (coord) {
        return [coord[1], coord[0]];
    });

    const hexagons = h3.polygonToCells(convertedPolygon, baseRes);

    let layers: mapboxgl.AnyLayer[] = [];
    for (let index = 0; index <= 2; index++) {
        const refIndex = Math.max(baseRes - 2 - index, 0);
        if ((refIndex > 8 && index > 1) || index <= 1) {
            const d = [...new Set(hexagons.map((h) => h3.cellToParent(h, refIndex)))];
            const layer = createH3MapLayer(index, d);
            layers.unshift(layer);
        }
    }

    return layers;
}

function createH3MapLayer(id: number, data: string[]): mapboxgl.AnyLayer {
    return new MapboxLayer({
        id: `hex_${id}`,
        type: H3HexagonLayer,
        data: data,
        pickable: false,
        wireframe: false,
        filled: true,
        extruded: false,
        autoHighlight: true,
        opacity: 0.1,
        coverage: 1,
        lineWidthMinPixels: 1,
        widthMaxPixels: 5,
        getHexagon: (d) => d,
        getFillColor: (d) => layerColours[id],
        getLineColor: (d) => [255, 255, 255],
        getElevation: (d) => 0,
    });
}
