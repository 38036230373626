import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'hm-permission-check',
    templateUrl: './permission-check.component.html',
    styleUrls: ['./permission-check.component.scss'],
})
export class PermissionCheckComponent implements OnInit {
    @Input() dataAvailable: boolean;
    @Input() permissionsAvailable: boolean;

    constructor() {}
    ngOnInit() {}
}
