import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { Component } from '@angular/core';
import { ChartComponent } from '../chart/chart.component';

@Component({
    selector: 'hm-line-series',
    templateUrl: `../chart/chart.component.html`,
    styleUrls: ['../chart/chart.component.scss'],
})
export class LineSeriesComponent extends ChartComponent {
    // Overrides ChartComponent.setCategoryAxis
    setCategoryAxis() {
        switch (this.options.xAxis.type) {
            case 'date':
                this.categoryAxis = this.chart.xAxes.push(new am4charts.DateAxis());
                this.categoryAxis.dateFormatter.inputDateFormat = this.options.xAxis.inputFormat;
                this.categoryAxis.renderer.minGridDistance = 20;
                this.categoryAxis.renderer.labels.template.dx = 20;
                this.categoryAxis.renderer.labels.template.rotation = 90;
                this.categoryAxis.renderer.labels.template.valign = 'top';
                this.categoryAxis.renderer.labels.template.horizontalCenter = 'left';
                break;
            case 'category':
                this.categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
                this.categoryAxis.dataFields.category = this.options.xFieldName;
                this.categoryAxis.renderer.grid.template.location = 0;
                this.categoryAxis.renderer.minGridDistance = 30;
                break;
        }
    }

    // Overrides ChartComponent.createSeries
    createSeries(field, name) {
        // Create series
        const series = this.chart.series.push(new am4charts.LineSeries());
        series.tooltipText = '{date}\n[bold font-size: 12px] {valueY}[/]';
        series.numberFormatter.numberFormat = '#,###.##a';
        series.numberFormatter.bigNumberPrefixes = [
            { number: 1e3, suffix: 'k' },
            { number: 1e6, suffix: 'm' },
            { number: 1e9, suffix: 'b' },
            { number: 1e12, suffix: 't' },
            { number: 1e15, suffix: 'q' },
        ];

        series.name = name;
        series.dataFields.valueY = field;
        if (this.options.xAxis.type === 'category') {
            series.dataFields.categoryX = this.options.xFieldName;
        } else if (this.options.xAxis.type === 'date') {
            series.dataFields.dateX = this.options.xFieldName;
        }

        series.strokeWidth = 2;
        series.tooltip.pointerOrientation = 'vertical';

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.stroke = am4core.color('#fff');
        circleBullet.circle.strokeWidth = 2;

        return series;
    }
}
