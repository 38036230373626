import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Observable } from 'rxjs';

import { AppState } from 'app/core/reducers';
import { OffcanvasOptions } from 'app/core/_base/layout';
import { OverlayService } from 'app/views/partials/layout/overlay/overlay.service';
import {
    BookmarkedReport,
    BookmarkType,
    DashboardReportContent,
    SimpleReportContent,
} from './shared/bookmarked-report.model';
import {
    AddBookmarkAction,
    DeleteBookmarkAction,
    LoadBookmarksAction,
} from './store/bookmark.actions';
import { selectBookmarksByType } from './store/bookmark.selector';

@Component({
    selector: 'hm-bookmarks',
    templateUrl: './bookmarks.component.html',
    styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements OnInit, OnChanges {
    @Input() type: BookmarkType;
    @Input() subtype: string;
    @Input() content: Object;
    bookmarkName: string;
    bookmarksSimple$: Observable<BookmarkedReport[]>;
    bookmarksAnalysis$: Observable<BookmarkedReport[]>;
    bookmarksDashboard$: Observable<BookmarkedReport[]>;

    bookmarksPanelOptions: OffcanvasOptions = {
        overlay: true,
        baseClass: 'kt-bookmarks-panel',
        closeBy: 'kt_bookmarks_panel_close',
        toggleBy: 'kt_bookmarks_panel_toggle',
    };

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private overlayService: OverlayService
    ) {}

    ngOnInit() {
        this.store.dispatch(new LoadBookmarksAction());
        this.bookmarksAnalysis$ = this.store.select(selectBookmarksByType(), {
            type: 'analysis',
        });
        this.bookmarksSimple$ = this.store.select(selectBookmarksByType(), {
            type: 'simple',
        });
        this.bookmarksDashboard$ = this.store.select(selectBookmarksByType(), {
            type: 'dashboard',
        });
    }

    ngOnChanges(changes: SimpleChanges) {}

    loadBookmark(bookmark: BookmarkedReport) {
        // route to component based on type/subtype
        let route: string[];
        if (bookmark.type == 'analysis') {
            route = ['/analysis', bookmark.subtype];
        } else if (bookmark.type == 'simple' || bookmark.type == 'dashboard') {
            route = [bookmark.subtype];
        } else {
            console.error('Invalid bookmark type', bookmark.type, bookmark.subtype);
            return;
        }
        this.router.navigate(route, { queryParams: { bookmark: bookmark.id } });
    }

    saveBookmark() {
        const now = new Date();
        this.store.dispatch(
            new AddBookmarkAction({
                bookmark: {
                    type: this.type,
                    subtype: this.subtype,
                    name: this.bookmarkName,
                    content: _.cloneDeep(this.content),
                },
            })
        );
    }

    showTrial(e) {
        e.preventDefault();
        this.overlayService.overlaySetMessage(
            'This feature is only not available with your current account'
        );
        this.overlayService.overlayOn();
    }

    deleteBookmark(bookmark: BookmarkedReport) {
        this.store.dispatch(new DeleteBookmarkAction({ id: bookmark.id }));
    }

    simpleSummary(bookmark: BookmarkedReport) {
        const content = bookmark.content as SimpleReportContent;
        const type = bookmark.subtype.split('/').slice(2).join(' ');
        const dates = content.date instanceof Array ? content.date.join(' to ') : content.date;
        const plural = content.boundaries.length > 1 ? '`s' : '';
        const boundaries = `${content.boundaries.length} ${content.boundaryType}${plural}`;
        return `${type}, ${dates}, ${boundaries}`;
    }

    compareSummary(bookmark: BookmarkedReport) {
        const content = bookmark.content as DashboardReportContent;
        if (bookmark.subtype == '/regions/region-compare') {
            return `comparison dashboard - ${content.comparisons.length} cols`;
        } else {
            const dashboard = content.comparisons[0]; // Dashboard uses the first object from the comparisons
            const date = dashboard.endDate;
            const type = bookmark.subtype.split('/').slice(2).join(' ');
            const plural = dashboard.boundaries.length > 1 ? '`s' : '';
            const boundaries = `${dashboard.boundaries.length} ${dashboard.boundaryType}${plural}`;
            return `${type}, ${date}, ${boundaries}`;
        }
    }

    analysisSummary(bookmark: BookmarkedReport) {
        return `${bookmark.subtype.split(':').join(' ')}`;
    }
}
