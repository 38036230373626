<div class="datepicker">
    <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="dateSelectorGroup" [rangePicker]="myPicker" [min]="min" [max]="max">
            <input matStartDate formControlName="startDate" placeholder="Start date" #start />
            <input matEndDate formControlName="endDate" placeholder="End date" #end (dateChange)="dateChange()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="myPicker"></mat-datepicker-toggle>
        <mat-date-range-picker maxRange="{{ maxDateRange }}" #myPicker></mat-date-range-picker>
    </mat-form-field>
</div>
