import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';

import { AppState } from 'app/core/reducers';
import {
    isImpersonating,
    selectCurrentOrganisationName,
    selectCurrentUser,
} from 'app/core/session-info/store/selectors/session-info.selector';
import { APIError } from 'app/core/utilities/api-error';
import { OrganisationsService } from 'app/views/pages/organisations/shared/organisations.service';

@Component({
    selector: 'hm-impersonate',
    templateUrl: './impersonate.component.html',
    styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateComponent implements OnInit {
    impersonating$: Observable<boolean> = this.store.select(isImpersonating);
    orgName$: Observable<string> = this.store.select(selectCurrentOrganisationName);
    hide = false;
    constructor(
        private store: Store<AppState>,
        private orgsService: OrganisationsService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {}

    exit(e: Event) {
        this.store
            .select(selectCurrentUser)
            .pipe(
                take(1),
                switchMap((user) => this.orgsService.deleteImpersonateOrganisation(user.id))
            )
            .subscribe({
                next: () => window.location.reload(),
                error: (error: HttpResponse<APIError>) =>
                    this.snackBar.open(`An error occurred: ${error.body?.message}`),
            });
    }
    hideBar(e: Event) {
        this.hide = !this.hide;
        // add a hide class to impersonation-bar
    }
}
