import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectSentiment } from 'app/core/session-info/store/selectors/session-info.selector';
import { isDateValidForDataset } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { Subscription } from 'rxjs';
import { Category } from '../../category/shared/category.model';
import { Measure } from '../../measures/shared/measure.model';
import { SentimentListing } from './shared/sentiment-listing.model';
import { SentimentListingsService } from './shared/sentiment-listings.service';

@Component({
    selector: 'hm-sentiment-listings',
    templateUrl: './sentiment-listings.component.html',
    styleUrls: ['./sentiment-listings.component.scss'],
})
export class SentimentListingsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() categories: Category[];
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];

    @Input() reportDate: string;

    @Input() showTable = true;
    @Input() showGraph = true;

    dataAvailable = true;
    permissionsAvailable = false;

    multipleBoundaries = false;

    reportMeasures: Measure[] = [
        { uniqueName: 'rating_avg', title: 'Average Sentiment', format: '#,###.##' },
        {
            uniqueName: 'sentiment_listings_by_pct',
            title: 'Sentiment Listings (%)',
            format: '#,###',
        },
        {
            uniqueName: 'sentiment_listings_by_count',
            title: 'Sentiment Listings (Count)',
            format: '#,###',
        },
    ];

    selectedReportMeasure = this.reportMeasures[0];

    /** CHART CONFIGS */
    chartName = 'sentiment-listings-chart' + Math.random().toString();
    chartData: SentimentListing[] = [];

    /** TABLE CONFIGS */
    tableData: MatTableDataSource<SentimentListing> = new MatTableDataSource();

    private subscriptions: Subscription[] = [];

    constructor(
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef,
        private sentimentListingsService: SentimentListingsService
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.checkPermissions();
        this.refreshData();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
        } else if (changes.reportDate) {
            this.checkPermissions();
            if (this.permissionsAvailable === true) {
                this.dataAvailable = true;
            }
        }
        this.refreshData();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }

    checkPermissions() {
        const rights = this.store.pipe(select(selectSentiment));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = isDateValidForDataset(r[0], this.reportDate);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    selectedMeasure(selectedMeasure: Measure) {
        this.selectedReportMeasure = selectedMeasure;
        this.ref.detectChanges();
    }

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            this.subscriptions.push(
                this.sentimentListingsService
                    .getListings(
                        this.reportDate,
                        this.boundaryType as Level,
                        this.boundaries.map((boundary) => boundary.code.toString()),
                        'Selected Hotels',
                        this.categories.filter((c) => c.selected).map((c) => c.id)
                    )
                    .subscribe((res: SentimentListing[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.tableData.data = res;
                            this.chartData = res;
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }
}
