import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { PictorialChart } from './pictorial-chart.model';
import { OnChanges } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { ShortNumberPipe } from 'app/pipes/short-number.pipe';

import { selectTop3Colors } from 'app/core/whitelabel/store/whitelabel.selector';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'hm-pictorial-chart',
    templateUrl: './pictorial-chart.component.html',
    styleUrls: ['./pictorial-chart.component.scss'],
})
export class PictorialChartComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy, OnInit
{
    @Input() pictorial: PictorialChart;
    @Input() id: string = 'pictorial';
    private chart: am4charts.SlicedChart;
    public adjustedPictorial: PictorialChart;
    public top2yearPictorial: PictorialChart;
    flip: boolean = false;
    style: string = 'flaticon2-arrow-down text-red-500';
    shortNumberPipe: ShortNumberPipe;

    currentYearNo: number;
    previousYearNo1: number;
    previousYearNo2: number;
    previousYearNo3: number;

    previousYearNo1Change: number;
    previousYearNo2Change: number;
    previousYearNo3Change: number;

    currentYear: string;
    previousYear1: string;
    previousYear2: string;
    previousYear3: string;

    top3Colors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {
        this.shortNumberPipe = new ShortNumberPipe();
    }

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnInit() {
        this.store
            .pipe(select(selectTop3Colors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.top3Colors = colors;
            });
        this.adjustedPictorial = { ...this.pictorial };
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            // Themes end
            this.chart = am4core.create(this.id, am4charts.SlicedChart);
            this.chart.fontSize = '1.1rem';

            this.createPictorial();
        });
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
    ngOnChanges(changes: SimpleChanges) {
        // Update the data in shown in graph if the data in catchment table change
        if (changes.pictorial && !changes.pictorial.isFirstChange()) {
            this.chart.series.clear();
            this.createPictorial();
        }
    }

    createPictorial() {
        if (this.pictorial.data.length > 0) {
            this.currentYear = this.pictorial.data[0].name;
            this.previousYear1 = this.pictorial.data[1].name;
            this.previousYear2 = this.pictorial.data[2].name;
            this.previousYear3 = this.pictorial.data[3].name;

            this.currentYearNo = this.pictorial.data[0].realValue;
            this.previousYearNo1 = this.pictorial.data[1].realValue;
            this.previousYearNo2 = this.pictorial.data[2].realValue;
            this.previousYearNo3 = this.pictorial.data[3].realValue;

            this.top2yearPictorial = { ...this.pictorial };
            this.top2yearPictorial.data.pop();
            this.top2yearPictorial.data.pop(); // remove the last 2 entries as the pictorial is only a comparison between the last 2 years

            this.previousYearNo1Change = (1 - this.currentYearNo / this.previousYearNo1) * -1;
            this.previousYearNo2Change = (1 - this.currentYearNo / this.previousYearNo2) * -1;
            this.previousYearNo3Change = (1 - this.currentYearNo / this.previousYearNo3) * -1;

            if (
                this.top2yearPictorial.data[0].realValue > this.top2yearPictorial.data[1].realValue
            ) {
                this.adjustedPictorial.data[0].value =
                    this.top2yearPictorial.data[0].realValue -
                    this.top2yearPictorial.data[1].realValue;
                this.adjustedPictorial.data[1].value = this.top2yearPictorial.data[1].realValue;

                this.flip = false;
            } else if (
                this.top2yearPictorial.data[1].realValue >= this.top2yearPictorial.data[0].realValue
            ) {
                this.adjustedPictorial.data[0].value = this.top2yearPictorial.data[0].realValue;
                this.adjustedPictorial.data[1].value =
                    this.top2yearPictorial.data[1].realValue -
                    this.top2yearPictorial.data[0].realValue;
                this.flip = true;
            }

            if (this.flip) {
                this.adjustedPictorial.data = this.adjustedPictorial.data.reverse();
            }

            this.chart.data = this.adjustedPictorial.data;
            if (this.flip) {
                this.chart.data[1].color = am4core.color(this.top3Colors[1]);
                this.chart.data[0].color = am4core.color(this.top3Colors[0]);
            } else {
                this.chart.data[0].color = am4core.color(this.top3Colors[1]);
                this.chart.data[1].color = am4core.color(this.top3Colors[0]);
            }
        }

        var series = this.chart.series.push(new am4charts.PictorialStackedSeries());
        series.dataFields.value = 'value';
        series.dataFields.category = 'name';
        series.labels.template.disabled = true;

        // Hiding AmCharts logo
        this.chart.logo.height = -20000;
        series.slices.template.propertyFields.fill = 'color';
        series.slices.template.propertyFields.stroke = 'color';
        series.slices.template.tooltipText = '{category} : {value}';

        series.startLocation = 0.3;
        series.endLocation = 1;
        series.slicesContainer.background.fill = am4core.color('#ccc');
        if (this.top2yearPictorial) {
            series.maskSprite.path = this.top2yearPictorial.pictureType.toString();
        }
    }
}
