import { Injectable } from '@angular/core';
import { of, Observable, defer } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import {
    CategoryActionType,
    CategoryLoadAction,
    CategoryLoadSuccessAction,
    CategoryLoadFailAction,
} from '../actions/category.actions';
import { Category } from '../../shared/category.model';
import { currentAuthToken } from '../../../../../../core/auth';
import { AppState } from '../../../../../../core/reducers';
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { ListingsService } from 'app/views/pages/hotels/shared/listings.service';

@Injectable()
export class CategoryEffects {
    // API
    accessToken: Observable<string> = this.store.pipe(select(currentAuthToken));
    constructor(
        private service: ListingsService,
        private actions$: Actions,
        private store: Store<AppState>
    ) {}

    public loadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType<CategoryLoadAction>(CategoryActionType.Loading),
            switchMap(() =>
                this.service.getCategories().pipe(
                    map(
                        (response: Category[]) =>
                            new CategoryLoadSuccessAction({ categories: response }),
                        catchError((error) => of(new CategoryLoadFailAction(error)))
                    )
                )
            )
        )
    );
}
