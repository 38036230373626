import _ from 'lodash';
import { Field, Filter, FilterGroup, FilterGroupType, Value } from './model';

export class MembersRequest {
    readonly type = 'members';
    /**
     * Name of the target cube.
     */
    index: string;
    /**
     * Field to get members for.
     */
    field: Field;
    /**
     * Specifies which filters should be applied.
     */
    filter?: FilterGroup;
    /**
     * The page number. It can be used to load members by parts.
     * If the response contains pageTotal parameter, additional requests
     * will be performed to load the remaining pages. Starts from 0.
     */
    page?: number;
}

export interface MembersResponse {
    members: {
        value: Value;
    }[];
    page: number;
    pageTotal: number;
    sorted: boolean;
}

export class MembersBuilder {
    private request: MembersRequest;
    private filterRef: FilterGroup;

    constructor(req?: MembersRequest) {
        if (req) {
            this.request = req;
            this.filterRef = req.filter;
        } else {
            this.request = new MembersRequest();
        }
    }

    /**
     * Initialize query for a particular cube/index field and page number
     */
    query(index: string, field: string, page: number = 0): MembersBuilder {
        this.request.index = index;
        this.request.field = new Field(field);
        this.request.page = page;
        return this;
    }

    /**
     * Add filters
     */
    filter(type: FilterGroupType, ...filters: Array<Filter | FilterGroup>): MembersBuilder {
        if (!this.filterRef) {
            this.filterRef = this.request.filter = new FilterGroup(type, filters);
        } else if (this.filterRef.type == type) {
            this.filterRef.value.push(...filters);
        } else {
            throw new Error(
                `Can't add filters of type ${type} to FilterGroup of type ${this.filterRef.type}`
            );
        }
        return this;
    }

    /**
     * Build and return the request.
     */
    build(): MembersRequest {
        return _.cloneDeep(this.request);
    }
}
