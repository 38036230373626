import { Whitelabel } from '../shared/whitelabel.model';

export interface WhitelabelState {
    whitelabel: Whitelabel;
    isLoaded: boolean;
}

export const initialWhitelabelState: WhitelabelState = {
    whitelabel: undefined,
    isLoaded: false,
};
