import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { SelectedPoint } from '../../../../pages/hotels/shared/selected-point.model';
import { Observable } from 'rxjs';
import { Category } from '../../category/shared/category.model';
import { TargomoService } from '../shared/targomo.service';

@Component({
    selector: 'kt-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent {
    @Input() showMapSpinner: boolean;
    @Input() mapMessage: string;
    @Input() categories: Observable<Category>;
    @Output() toggleCategory = new EventEmitter<Category>();

    @Input() catchments: SelectedPoint[];
    @Output() removeCatchmentById = new EventEmitter<number>();
    @Output() zoomToPoint = new EventEmitter<SelectedPoint>();

    @Input() travelTimes: number[];
    @Input() travelTimeColours: string[];

    @Input() travelModes: string[] = ['walk', 'bike', 'car'];

    @Output() travelTimeEvent = new EventEmitter<string>();
    @Output() travelModeEvent = new EventEmitter<string>();

    constructor(private targomoService: TargomoService) {}

    // Add/remove multiple catchments in a list
    onCatchmentChange(catchment: number) {
        this.removeCatchmentById.emit(catchment);
    }

    onZoomToPoint(point: SelectedPoint) {
        this.zoomToPoint.emit(point);
    }

    // Filters which category of hotels to show on map
    onCategoryChange(category) {
        this.toggleCategory.emit(category);
    }

    // Targomo travel time
    updateTravelTime(travelTime): void {
        this.travelTimeEvent.emit(travelTime);
        // Map legend values are data binding to travel time values
        this.travelTimes = this.targomoService.travelTimes;
    }

    // Targomo transport mode
    updateTravelOption(travelOption): void {
        this.travelModeEvent.emit(travelOption);
    }
}
