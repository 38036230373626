import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CategoryState, categoryAdapter } from '../states/category.state';
import { Category } from '../../shared/category.model';

export const {
    selectIds: _selectCategoryDataIds,
    selectEntities: _selectCategoryEntities,
    selectAll: _selectAllCategory,
} = categoryAdapter.getSelectors();

export const selectCategoryState = createFeatureSelector<CategoryState>('categories');

export const selectCategoryIds = createSelector(selectCategoryState, _selectCategoryDataIds);

export const selectCategoryEntities = createSelector(selectCategoryState, _selectCategoryEntities);

export const selectAllCategory = createSelector(selectCategoryState, _selectAllCategory);

export const selectCategoriesSelected = createSelector(
    selectAllCategory,
    (categories: Category[]) => categories.filter((c) => c.selected)
);

export const selectCategoryError = createSelector(
    selectCategoryState,
    (state: CategoryState): boolean => state.error
);

export const selectCategoryLoading = createSelector(
    selectCategoryState,
    (state: CategoryState): boolean => state.loading
);

export const areCategoriesLoaded = createSelector(selectCategoryState, (state) => state.entities);
