import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth0Service } from 'app/core/auth';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { User } from '../../users/shared/user.model';
import { CreateResponse, Organisation, OrganisationTreeNode } from './organisation.model';
import { Role } from './role.model';

@Injectable({
    providedIn: 'root',
})
export class OrganisationsService {
    domain: string = environment.hemisphereApi;
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };

    constructor(private httpClient: HttpClient, public auth0: Auth0Service) {}

    // Create an organisation
    createOrganisation(organisation: Organisation) {
        const endpoint = `${this.domain}/organisations`;
        return this.httpClient.post<CreateResponse>(endpoint, organisation, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    getActiveOrganisations(): Observable<Organisation[]> {
        const endpoint = `${this.domain}/organisations`;
        return this.httpClient.get<any[]>(endpoint, this.httpOptions).pipe(
            map((array) => array.map((obj) => new Organisation(obj))),
            map((organisations) => organisations.filter((org) => org.active === true))
        );
    }

    // Get all organisations GET: /organisations
    getOrganisations(): Observable<Organisation[]> {
        const endpoint = `${this.domain}/organisations`;

        return this.httpClient
            .get<any[]>(endpoint, this.httpOptions)
            .pipe(map((array) => array.map((obj) => new Organisation(obj))));
    }

    getOrganisation(organisationId: number | number): Observable<Organisation> {
        const endpoint = `${this.domain}/organisations/${organisationId}`;

        return this.httpClient.get<Organisation>(endpoint, this.httpOptions);
    }

    updateOrganisation(organisationId: string | number, toUpdate: Organisation) {
        const endpoint = `${this.domain}/organisations/${organisationId}`;
        return this.httpClient.put<any>(endpoint, toUpdate, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    deleteOrganisation(organisationId: string | number) {
        const endpoint = `${this.domain}/organisations/${organisationId}`;

        return this.httpClient.delete<any>(endpoint, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    impersonateOrganisation(userId: string | number, impersonateOrgId: string | number) {
        const endpoint = `${this.domain}/users/${userId}/impersonation/${impersonateOrgId}`;

        return this.httpClient.put<HttpResponse<null>>(endpoint, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    deleteImpersonateOrganisation(userId: string | number) {
        const endpoint = `${this.domain}/users/${userId}/impersonation`;
        return this.httpClient.delete(endpoint);
    }

    roles$ = this.getRoles().pipe(shareReplay());

    getRoles() {
        const endpoint = `${this.domain}/roles`;

        return this.httpClient
            .get<any[]>(endpoint, this.httpOptions)
            .pipe(map((array) => array.map((obj) => new Role(obj))));
    }

    getValidRoles() {
        const validRoles = [
            'SYS_ADMIN',
            'CLIENT_ADMIN',
            'CONTRIBUTOR',
            'ANALYST',
            'READER',
            'SUBSCRIBER',
        ];
        const endpoint = `${this.domain}/roles`;

        return this.httpClient
            .get<any[]>(endpoint, this.httpOptions)
            .pipe(
                map((array) =>
                    array.map((o) => validRoles.indexOf(o.name) > 0).map((obj) => new Role(obj))
                )
            );
    }

    getUser(organisationId: string | number, userId: string | number) {
        const endpoint = `${this.domain}/organisations/${organisationId}/users/${userId}`;
        return this.httpClient.get<User>(endpoint, this.httpOptions);
    }

    getUsers(organisationId: string | number) {
        const endpoint = `${this.domain}/organisations/${organisationId}/users`;

        return this.httpClient
            .get<any[]>(endpoint, this.httpOptions)
            .pipe(map((array) => array.map((obj) => new User(obj))));
    }

    addUsers(newUser: User) {
        const endpoint = `${this.domain}/users`;

        return this.httpClient.post(endpoint, newUser, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    updateUser(userId: string | number, toUpdate: User) {
        const endpoint = `${this.domain}/users/${userId}`;
        return this.httpClient.put(endpoint, toUpdate, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    deleteUser(userId: string | number) {
        const endpoint = `${this.domain}/users/${userId}`;

        return this.httpClient.delete<any>(endpoint);
    }

    passwordChange(userId: string | number) {
        const endpoint = `${this.domain}/users/${userId}/password-change`;

        return this.httpClient.post<any>(endpoint, {
            ...this.httpOptions,
            observe: 'response',
        });
    }

    createTree(orgs: Organisation[], parent_id: number): OrganisationTreeNode[] {
        return this.getTreeOfOrgs(
            orgs.map((o) => ({
                ...o,
                parent_id: o.level == 0 ? 0 : o.parent_id,
            })),
            parent_id
        );
    }

    getTreeOfOrgs(orgs: Organisation[], parent_id: number): OrganisationTreeNode[] {
        return orgs
            .filter((org) => org.parent_id === parent_id)
            .reduce(
                (trees, org) => [
                    ...trees,
                    {
                        ...org,
                        children: this.getTreeOfOrgs(orgs, org.id),
                    },
                ],
                [] as OrganisationTreeNode[]
            );
    }

    flattenTree(
        node: OrganisationTreeNode,
        target: OrganisationTreeNode[]
    ): OrganisationTreeNode[] {
        if (node.level === 0) {
            target.push(node);
        }
        node.children.forEach((child) => {
            target.push(child);
            this.flattenTree(child, target);
        });
        return target;
    }

    sortByHierarchy(orgs: Organisation[], parent_id: number) {
        const orgsTree = this.createTree(orgs, parent_id);
        let results = [] as Organisation[];
        orgsTree.forEach((tree) => {
            const meow = this.flattenTree(tree, []);
            results = results.concat(meow);
        });
        return results;
    }

    pathToNode(roots: OrganisationTreeNode[], id: number): OrganisationTreeNode[] {
        let found = roots.find((n) => n.id == id);
        if (found) {
            return [found];
        }
        for (let root of roots || []) {
            let path = this.pathToNode(root.children, id);
            if (path.length > 0) {
                return [root, ...path];
            }
        }
        return [];
    }
}
