import { Injectable } from '@angular/core';
import { PrintService } from 'app/views/partials/layout/print/print.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Injectable({
    providedIn: 'root',
})
export class ExportPdfService {
    constructor(private printService: PrintService) {}

    open(element: string, type: string = 'pdf', name = 'report') {
        const convert = document.getElementById(element);

        html2canvas(convert, {
            useCORS: true,
            allowTaint: true,
            logging: true,
            foreignObjectRendering: false, // Turning this on causes icons to fail to convert
            scale: 1.3, // To prevent gray vertical lines

            ignoreElements(element) {
                /* Remove element with id="MyElementIdHere" */
                // if ('MyElementIdHere' == element.id) {
                //     return true;
                // }

                /* Remove the comparisons buttons */
                if (element.classList.contains('comparisonBox')) {
                    return true;
                }
                // Remove the bookmarks id
                if (element.classList.contains('kt-sticky-toolbar')) {
                    return true;
                }
                if (element.classList.contains('print-container')) {
                    return true;
                }

                if (element.classList.contains('containter-buttons')) {
                    return true;
                }
            },
            onclone(doc: Document, el: HTMLElement) {
                doc.querySelectorAll('hm-data-notice').forEach(
                    (el: HTMLElement) => (el.style.display = 'block')
                );
                doc.querySelectorAll('.printonly').forEach(
                    (el: HTMLElement) => (el.style.display = 'block')
                );
            },
        }).then((canvas) => {
            if (type === 'pdf') {
                const imgData = canvas.toDataURL('image/jpeg', 1);
                const imgWidth = 210;
                const pageHeight = 295;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                const doc = new jsPDF('p', 'mm');
                let position = 0;

                doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                this.printService.printOff();
                doc.save(name + '.pdf');
            } else {
                const imgData = canvas.toDataURL('image/pdf');
                this.printService.printOff();
                const link = document.createElement('a');
                link.href = imgData;
                link.download = name + '.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    }
}
