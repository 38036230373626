import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';

export enum AuthActionTypes {
    StartLogin = '[Auth] Login Start',
    LoginComplete = '[Auth] Login Complete',
    Logout = '[Auth] Logout',
    UserRequested = '[Auth] User Requested',
    UserLoaded = '[Auth] User Loaded',
    ReloadUserRequested = '[Auth] User Reload',
}

export class StartLogin implements Action {
    readonly type = AuthActionTypes.StartLogin;
    constructor(public payload: { targetRoute?: string }) {}
}

export class LoginComplete implements Action {
    readonly type = AuthActionTypes.LoginComplete;
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class ReloadUserRequested implements Action {
    readonly type = AuthActionTypes.ReloadUserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User; accessToken: string }) {}
}

export type AuthActions =
    | Logout
    | UserRequested
    | UserLoaded
    | StartLogin
    | LoginComplete
    | ReloadUserRequested;
