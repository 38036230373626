import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';

import { MultipleAxesLineChartConfig } from './multiple-axes-line-chart.model';
import { Subject, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-multiple-axes-line-chart',
    template: `<div id="{{ id }}" class="multiple-axes-line-chart"></div>`,
    styleUrls: ['./multiple-axes-line-chart.component.scss'],
})
export class MultipleAxesLineChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() id = 'multiple-axes-line-chart';
    @Input() config: MultipleAxesLineChartConfig;

    private chart: am4charts.XYChart;
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            // Themes end

            // Create chart instance
            this.chart = am4core.create(this.id, am4charts.XYChart);

            // Hiding AmCharts logo
            this.chart.logo.height = -20000;

            // Show Legend
            if (this.config.showLegend) {
                this.chart.legend = new am4charts.Legend();
                this.chart.legend.maxHeight = 120;
                this.chart.legend.scrollable = true;
                this.chart.legend.itemContainers.template.paddingTop = 0;
                this.chart.legend.itemContainers.template.paddingBottom = 0;
            }

            // Add data
            this.chart.data = this.config.data;

            // Set input format for the dates
            // M -> 1..2 months
            // d -> 1..2 days
            this.chart.dateFormatter.inputDateFormat = 'yyyy-M-d';

            // Create axes
            const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());

            dateAxis.renderer.minGridDistance = 50;

            // Add cursor
            this.chart.cursor = new am4charts.XYCursor();
            this.chart.cursor.xAxis = dateAxis;

            this.setColours();

            this.draw();

            // this.resize();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.series.clear();
            // Clear all existing y axes
            this.chart.yAxes.clear();
            this.draw();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        this.chart.colors.list = colourList;
    }

    draw() {
        // For every group of measures, create a new axis
        for (const measureGroup of this.config.measures) {
            const newValueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
            if (this.chart.yAxes.indexOf(newValueAxis) !== 0) {
                newValueAxis.syncWithAxis = this.chart.yAxes.getIndex(
                    0
                ) as am4charts.ValueAxis<am4charts.AxisRenderer>;

                newValueAxis.renderer.opposite = true;
            } else {
                newValueAxis.renderer.opposite = false;
            }

            measureGroup.forEach((m) => {
                this.createSeries(m.uniqueName, m.title, newValueAxis);
            });
        }
    }

    createSeries(field, name, valueAxis) {
        // Create series
        const series = this.chart.series.push(new am4charts.LineSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.dateX = 'date';
        series.yAxis = valueAxis;
        series.strokeWidth = 2;
        series.showOnInit = true;
        series.minBulletDistance = 15;
        if (!this.config.active.includes(field)) {
            series.hidden = true;
        }

        series.tooltip.pointerOrientation = 'vertical';
        series.tooltipText = '{name}: [b]{valueY}[/]';

        const scrollbarX = new am4core.Scrollbar();
        scrollbarX.startGrip.icon.disabled = true;
        scrollbarX.endGrip.icon.disabled = true;
        this.chart.scrollbarX = scrollbarX;

        const circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.stroke = am4core.color('#fff');
        circleBullet.circle.strokeWidth = 2;

        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{value}';
        labelBullet.label.dy = -20;
    }

    resize() {
        // Resize chart based on number of plots
        const cellSize = 30;
        this.chart.events.on('datavalidated', (ev) => {
            // Get objects of interest
            const chart = ev.target;
            const categoryAxis = chart.yAxes.getIndex(0);

            // Calculate how we need to adjust chart height
            const adjustHeight = chart.data.length * cellSize - categoryAxis.pixelHeight;

            // get current chart height
            const targetHeight = chart.pixelHeight + adjustHeight;

            // Set it on chart's container
            chart.svgContainer.htmlElement.style.height = targetHeight + 'px';

            this.chart = chart;
        });
    }
}
