import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';
import { Category } from 'app/views/partials/content/category/shared/category.model';
import { selectAllCategory } from 'app/views/partials/content/category/store/selectors/category.selector';
import { Measure } from 'app/views/partials/content/measures/shared/measure.model';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'hm-ifi-int-demographics-graph',
    template: `<div id="ifi-int-demographics-chart"></div>`,
    styleUrls: ['./ifi-int-demographics-graph.component.scss'],
})
export class IfiIntDemographicsGraphComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input() data: any[] = [];
    @Input() selectedMeasure: Measure;

    private chart: am4charts.XYChart;
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    // If the previous category had no data, the current category will be assigned the colour of the previous category.
    // categoriesWithData ensures that we only push categories that has data associated with it
    // categoriesWithData = new Set();

    categories$: Observable<Category[]>;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        public store: Store<AppState>,
        private zone: NgZone
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
        this.categories$ = this.store.select(selectAllCategory);
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            // Chart instance
            am4core.useTheme(am4themes_animated);
            const chart = am4core.create('ifi-int-demographics-chart', am4charts.XYChart);
            chart.hiddenState.properties.opacity = 0;

            // Chart colours
            const colourList = [];
            this.amColors.map((colour) => {
                colourList.push(am4core.color(colour));
            });
            chart.colors.list = colourList;

            // Data
            chart.data = [];

            // Axes
            // Horizontal Axes
            const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'cust_behavioral_segment';
            categoryAxis.title.text = 'Categories';
            // Determines how many of the labels to show when the resolution is tiny
            categoryAxis.renderer.minGridDistance = 20;

            let label = categoryAxis.renderer.labels.template;
            label.wrap = true;
            label.fontSize = 12;
            label.maxWidth = 380;

            // Vertical Axes
            const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = 'No metric selected';

            // Hiding AmCharts logo
            chart.logo.height = -20000;

            // Rounding to two decimal places
            chart.numberFormatter.numberFormat = this.selectedMeasure.format;

            chart.exporting.menu = new am4core.ExportMenu();

            this.chart = chart;
        });
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // Update the data in shown in graph if the data in catchment table changes
        if (
            (changes.data && !changes.data.isFirstChange()) ||
            (changes.selectedMeasure && !changes.selectedMeasure.isFirstChange())
        ) {
            this.chart.series.clear();
            this.setData();
            this.createSeries();
        }
    }

    removeData() {
        this.chart.data = [];
        this.chart.validateData();
    }

    setData() {
        this.removeData();
        const chart = this.chart;
        const newSelectedMeasure = this.selectedMeasure;
        const newData = this.data;
        let emptyState = false;
        const chartData = [];
        const valueName = newSelectedMeasure.uniqueName;
        chart.numberFormatter.numberFormat = this.selectedMeasure.format;
        newData.forEach((element) => {
            // To be used in updateColours()
            // this.categoriesWithData.add(element['category_id']);

            const newItem = {};
            if (element[valueName] !== -1) {
                emptyState = false;
                newItem['cust_behavioral_segment'] = element['cust_behavioral_segment'];
                newItem[valueName] = element[valueName];
                chartData.push(newItem);
            } else {
                emptyState = true;
            }
        });

        chart.data = chartData;
        if (emptyState) {
            chart.yAxes.getIndex(0).title.text = 'Coming Soon';
            chart.xAxes.getIndex(0).title.text = 'Coming Soon';
        } else {
            chart.xAxes.getIndex(0).title.text = newSelectedMeasure.title;
            chart.yAxes.getIndex(0).title.text = 'Categories';
        }

        this.chart = chart;
    }

    createSeries() {
        const chart = this.chart;
        // Create series
        const series = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.valueX = 'value';
        series.dataFields.categoryY = 'cust_behavioral_segment';
        series.name = 'Statistics';
        series.columns.template.adapter.add('fill', function (fill, target) {
            return chart.colors.getIndex(target.dataItem.index);
        });
        // On-hover to show exact value
        const columnTemplate = series.columns.template;
        columnTemplate.tooltipText = '{categoryY}: [bold]{valueX.value}[/]';
        columnTemplate.fillOpacity = 0.8;
        columnTemplate.strokeOpacity = 0;

        chart.series.getIndex(0).dataFields.valueX = this.selectedMeasure.uniqueName;

        this.chart = chart;
    }
}
