<!-- begin::Sticky Toolbar -->
<ul class="kt-sticky-toolbar" style="margin-top: 30px">
    <li
        class="kt-sticky-toolbar__item kt-sticky-toolbar__item--success"
        id="kt_bookmarks_panel_toggle"
        matTooltip="Report bookmarks"
        matTooltipPosition="left"
    >
        <a href="javascript:;" class="" *ngxPermissionsExcept="['TRIAL']">
            <i class="flaticon2-open-text-book"></i>
        </a>

        <a href="javascript:;" (click)="showTrial($event)" class="" *ngxPermissionsOnly="['TRIAL']"
            ><i class="flaticon2-open-text-book"></i
        ></a>
    </li>
</ul>
<!-- end::Sticky Toolbar -->

<!-- begin::Demo Panel -->
<div *ngxPermissionsExcept="['TRIAL']">
    <div ktOffcanvas [options]="bookmarksPanelOptions" id="kt_bookmarks_panel" class="kt-bookmarks-panel">
        <div class="kt-bookmarks-panel__head">
            <h3 class="kt-bookmarks-panel__title">Bookmarks</h3>
            <a href="javascript:;" class="kt-bookmarks-panel__close" id="kt_bookmarks_panel_close"><i class="flaticon2-delete"></i></a>
        </div>
        <div>
            <h3 class="mat-subheading-1">Save current view as</h3>
            <form class="reportSave" #saveForm="ngForm" (ngSubmit)="saveBookmark(); saveForm.resetForm()">
                <mat-form-field appearance="legacy">
                    <mat-label>Bookmark name</mat-label>
                    <input matInput required [(ngModel)]="bookmarkName" name="bookmarkName" placeholder="Please enter a report name" />
                </mat-form-field>
                <button type="submit" mat-button color="primary" [disabled]="!saveForm.form.valid">Save</button>
            </form>
            <hr />
            <h3 class="mat-subheading-1">Bookmarked reports</h3>

            <mat-tab-group animationDuration="10ms" [selectedIndex]="type == 'simple' || type == 'dashboard' ? 0 : 1">
                <mat-tab label="Region Reports" class="bookmark">
                    <mat-nav-list dense>
                        <mat-list-item *ngFor="let bookmark of bookmarksSimple$ | async" (click)="loadBookmark(bookmark)">
                            <mat-icon mat-list-icon>note</mat-icon>
                            <h4 mat-line>{{ bookmark.name }}</h4>
                            <p mat-line>{{ simpleSummary(bookmark) }}</p>
                            <p mat-line>{{ bookmark.updated_at | date }}</p>
                            <button
                                mat-icon-button
                                color="warn"
                                type="warn"
                                (click)="$event.stopPropagation()"
                                (confirm)="deleteBookmark(bookmark)"
                                message="Delete report?"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-nav-list>
                    <mat-nav-list dense>
                        <mat-list-item *ngFor="let bookmark of bookmarksDashboard$ | async" (click)="loadBookmark(bookmark)">
                            <mat-icon mat-list-icon>note</mat-icon>
                            <h4 mat-line>{{ bookmark.name }}</h4>
                            <p mat-line>{{ compareSummary(bookmark) }}</p>
                            <p mat-line>{{ bookmark.updated_at | date }}</p>
                            <button
                                mat-icon-button
                                color="warn"
                                type="warn"
                                (click)="$event.stopPropagation()"
                                (confirm)="deleteBookmark(bookmark)"
                                message="Delete report?"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-tab>
                <mat-tab label="Region Analysis" class="bookmark">
                    <mat-nav-list dense>
                        <mat-list-item *ngFor="let bookmark of bookmarksAnalysis$ | async" (click)="loadBookmark(bookmark)">
                            <mat-icon mat-list-icon>note</mat-icon>
                            <h4 mat-line>{{ bookmark.name }}</h4>
                            <p mat-line>{{ analysisSummary(bookmark) }}</p>
                            <p mat-line>{{ bookmark.updated_at | date }}</p>
                            <button
                                mat-icon-button
                                color="warn"
                                type="warn"
                                (click)="$event.stopPropagation()"
                                (confirm)="deleteBookmark(bookmark)"
                                message="Delete report?"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-nav-list>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<!-- end::Demo Panel -->
