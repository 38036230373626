import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectDomesticTransaction } from 'app/core/session-info/store/selectors/session-info.selector';
import { isDateValidForDataset } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { Subscription } from 'rxjs';
import { Category } from '../../../category/shared/category.model';
import { Measure } from '../../../measures/shared/measure.model';
import { ProfilerService } from '../profiler.service';
import { IfiDomesticSegmented } from './shared/transaction-ifi-domestic.model';

@Component({
    selector: 'hm-transaction-ifi-domestic',
    templateUrl: './transaction-ifi-domestic.component.html',
    styleUrls: ['./transaction-ifi-domestic.component.scss'],
})
export class TransactionIfiDomesticComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input() categories: Category[];
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];
    @Input() showTable: boolean = true;
    @Input() showGraph: boolean = true;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('exporter') exporter: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() reportDate: string;

    /** GRAPH METRIC SELECTOR DROPDOWN MENU */
    // There is a serialization problem between JSON objects and Typescript model due to camelCase and snakeCase discrepancies. TBF
    reportMeasures: Measure[] = [
        // Value of Transactions
        {
            uniqueName: 'val_tran',
            title: 'Domestic Expenditure (Residents)',
            segment: 'resident',
        },
        {
            uniqueName: 'val_tran',
            title: 'Domestic Expenditure (Locals)',
            segment: 'local',
        },
        {
            uniqueName: 'val_tran',
            title: 'Domestic Expenditure (Intrastate)',
            segment: 'intrastate',
        },
        {
            uniqueName: 'val_tran',
            title: 'Domestic Expenditure (Interstate)',
            segment: 'interstate',
        },
        {
            uniqueName: 'val_tran',
            title: 'Domestic Expenditure (Total)',
            segment: 'total',
        },
    ];

    /** LISTINGS GRAPH */
    selectedReportMeasure = this.reportMeasures[1];
    aggregateData: IfiDomesticSegmented[] = [];

    /** LISTINGS TABLE */
    displayedColumns = ['category', 'resident', 'local', 'intrastate', 'interstate', 'total'];
    dataSource: MatTableDataSource<IfiDomesticSegmented> = new MatTableDataSource();
    // Value displayed on unique_count is dependant on this
    multipleBoundaries = false;
    dataAvailable: boolean = true;
    permissionsAvailable = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private profilerService: ProfilerService,
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef
    ) {}
    ngOnInit() {}

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.checkPermissions();
        this.refreshData();
    }

    checkPermissions() {
        const rights = this.store.pipe(select(selectDomesticTransaction));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = isDateValidForDataset(r[0], this.reportDate);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
            this.refreshData();
        } else if (changes.reportDate) {
            this.checkPermissions();
            if (this.permissionsAvailable == true) {
                this.dataAvailable = true;
                this.refreshData();
            }
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'transaction_ifi_domestic' }); // File name: name of tab + timestamp
    }

    selectedMeasure(selectedMeasure: Measure) {
        const key = selectedMeasure.segment ? 'segment' : 'uniqueName';

        if (selectedMeasure.segment) {
            this.selectedReportMeasure = this.reportMeasures.find(
                (m) => m[key] === selectedMeasure[key]
            );
        }
        this.ref.detectChanges();
        this.dataSource.sort = this.sort;
    }

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            this.subscriptions.push(
                this.profilerService
                    .getIfiDomesticSegmented(
                        this.boundaryType as Level,
                        this.boundaries.map((boundary) => boundary.code),
                        this.reportDate
                    )
                    .subscribe((res: IfiDomesticSegmented[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.aggregateData = res;

                            this.dataSource.data = res;
                            this.dataSource.sort = this.sort;
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }
}
