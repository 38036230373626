import { Action } from '@ngrx/store';
import { Dataset } from '../shared/dataset.model';

export enum DatasetActionType {
    DatasetLoadingAll = '[All Dataset] Loading',
    DatasetLoadSuccessAll = '[All Dataset] LoadSuccess',
    DatasetLoadFailureAll = '[All Dataset] LoadFailure',
}

export class LoadingAllAction implements Action {
    public readonly type = DatasetActionType.DatasetLoadingAll;
    constructor() {}
}

export class LoadSuccessAllAction implements Action {
    public readonly type = DatasetActionType.DatasetLoadSuccessAll;
    constructor(public payload: { dataset: Dataset[] }) {}
}

export class LoadingFailureAllAction implements Action {
    public readonly type = DatasetActionType.DatasetLoadFailureAll;
    constructor(public error: any) {}
}

export type DatasetActions = LoadingAllAction | LoadSuccessAllAction | LoadingFailureAllAction;
