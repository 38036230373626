<div *ngIf="showCategories">
    <mat-divider></mat-divider>
    <mat-list-item *ngIf="categories as cat">
        <div class="categories">
            <mat-icon mat-list-icon><i class="flaticon2-architecture-and-city"></i> </mat-icon>
            <span>Categories</span>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="hem-category">
                    <div *ngFor="let category of cat; let i = index">
                        <mat-checkbox
                            [checked]="category.selected"
                            (change)="category.selected = !category.selected; onCategoryChange(category)"
                        >
                            <mat-icon [ngStyle]="{ color: category.color }">brightness_1</mat-icon>
                            {{ category.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </mat-list-item>
</div>
