<div *ngIf="showGraph">
    <hm-ifi-domestic-demographics-graph *ngIf="dataAvailable" [selectedMeasure]="selectedReportMeasure" [data]="sentimentData">
    </hm-ifi-domestic-demographics-graph>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>

<loading></loading>
<div class="report" *ngIf="showTable">
    <div class="row justify-content-end">
        <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
            <mat-icon>file_download</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportAs()">CSV</button>
        </mat-menu>
    </div>

    <hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

    <table
        class="numerical-table"
        *ngIf="dataAvailable"
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        matSort
        [matSortActive]="selectedReportMeasure.uniqueName"
        matSortDirection="desc"
        matSortDisableClear
        #exporter="matTableExporter"
    >
        <!-- CUSTOMER BEHAVIORAL SEGMENT -->
        <ng-container matColumnDef="cust_behavioral_segment" sticky>
            <mat-header-cell *matHeaderCellDef mat-sort-header>Customer Behavioral Segments</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.cust_behavioral_segment }}</mat-cell>
        </ng-container>
        <!-- UNIQUE VISITS LOCAL -->
        <ng-container matColumnDef="unique_count_local">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Unique Count (Local)</mat-header-cell>
            <mat-cell
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.unique_count_local < 10 && report.unique_count_local > 0,
                    neutral: report.unique_count_local >= 10 && report.unique_count_local <= 80
                }"
            >
                <ng-container *ngIf="report.unique_count_local === -1; else available"> Coming Soon </ng-container>
                <ng-template #available>
                    {{ report.unique_count_local | shortNumber }}
                </ng-template>
            </mat-cell>
        </ng-container>
        <!-- UNIQUE VISITS VISITOR -->
        <ng-container matColumnDef="unique_count_visitor">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Unique Count (Visitor)</mat-header-cell>
            <mat-cell
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.unique_count_visitor < 10 && report.unique_count_visitor > 0,
                    neutral: report.unique_count_visitor >= 10 && report.unique_count_visitor <= 80
                }"
            >
                <ng-container *ngIf="report.unique_count_visitor === -1; else available"> Coming Soon </ng-container>
                <ng-template #available>
                    {{ report.unique_count_visitor | shortNumber }}
                </ng-template>
            </mat-cell>
        </ng-container>
        <!-- VOLUME LOCAL/VISITOR -->
        <ng-container matColumnDef="vol_tran_local">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Volume (Local)</mat-header-cell>
            <mat-cell
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.vol_tran_local < 10 && report.vol_tran_local > 0,
                    neutral: report.vol_tran_local >= 10 && report.vol_tran_local <= 80
                }"
            >
                {{ report.vol_tran_local | shortNumber }}</mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="vol_tran_visitor">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Volume (Visitor)</mat-header-cell>
            <mat-cell
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.vol_tran_visitor < 10 && report.vol_tran_visitor > 0,
                    neutral: report.vol_tran_visitor >= 10 && report.vol_tran_visitor <= 80
                }"
                >{{ report.vol_tran_visitor | shortNumber }}</mat-cell
            >
        </ng-container>
        <!-- VALUE LOCAL/VISITOR -->
        <ng-container matColumnDef="val_tran_local">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Value (Local)</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.val_tran_local | shortNumber : 'd' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="val_tran_visitor">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Value (Visitor)</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.val_tran_visitor | shortNumber : 'd' }}</mat-cell>
        </ng-container>
        <!-- RATIO LOCAL/VISITOR -->
        <ng-container matColumnDef="local_ratio">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Local Ratio</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.local_ratio | percent : '1.0-0' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="visitor_ratio">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Visitor Ratio</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.visitor_ratio | percent : '1.0-0' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButton></mat-paginator>
</div>
