import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Measure } from './measure.model';

@Injectable()
export class MeasureService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'replace-with-bearer-token',
        }),
    };
    constructor(private http: HttpClient) {}

    getMeasures(accessToken: Observable<string>): Observable<Measure[]> {
        accessToken.subscribe((token) => {
            this.options.headers = this.options.headers.set('Authorization', `Bearer ${token}`);
        });
        return this.http.get<Measure[]>(`${this.apiBase}/listings/bookings/measures`, this.options);
    }
}
