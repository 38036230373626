export const aus = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [136.2013, -13.86],
                            [136.1725, -13.7692],
                            [136.1051, -13.8153],
                            [136.114, -13.732],
                            [136.2018, -13.6646],
                            [136.2446, -13.6721],
                            [136.2889, -13.7303],
                            [136.2419, -13.8466],
                            [136.2013, -13.86],
                        ],
                    ],
                    [
                        [
                            [130.4936, -11.8386],
                            [130.3277, -11.7759],
                            [130.0963, -11.8336],
                            [130.0405, -11.8229],
                            [130.0175, -11.7725],
                            [130.0726, -11.6737],
                            [130.1566, -11.7029],
                            [130.1936, -11.6672],
                            [130.1908, -11.5281],
                            [130.1739, -11.485],
                            [130.2557, -11.3441],
                            [130.344, -11.3255],
                            [130.491, -11.6886],
                            [130.5617, -11.7067],
                            [130.6366, -11.7729],
                            [130.6138, -11.8225],
                            [130.4936, -11.8386],
                        ],
                    ],
                    [
                        [
                            [136.5034, -11.4564],
                            [136.5072, -11.4099],
                            [136.6431, -11.2164],
                            [136.7287, -11.0452],
                            [136.7739, -11.0212],
                            [136.7249, -11.207],
                            [136.5622, -11.4325],
                            [136.5034, -11.4564],
                        ],
                    ],
                    [
                        [
                            [136.5247, -15.6461],
                            [136.4988, -15.6333],
                            [136.5195, -15.5497],
                            [136.6055, -15.5249],
                            [136.5877, -15.6347],
                            [136.5247, -15.6461],
                        ],
                    ],
                    [
                        [
                            [130.9589, -11.9389],
                            [130.8613, -11.8588],
                            [130.678, -11.7847],
                            [130.4934, -11.642],
                            [130.4186, -11.4486],
                            [130.3647, -11.253],
                            [130.3927, -11.1634],
                            [130.5319, -11.2833],
                            [130.5733, -11.3495],
                            [130.705, -11.3903],
                            [130.8451, -11.3578],
                            [130.8996, -11.3078],
                            [131.0042, -11.3522],
                            [131.1215, -11.2635],
                            [131.2051, -11.2421],
                            [131.2715, -11.1903],
                            [131.4184, -11.2483],
                            [131.5286, -11.3919],
                            [131.5371, -11.4669],
                            [131.2819, -11.7308],
                            [131.2495, -11.7354],
                            [131.1264, -11.8133],
                            [130.9589, -11.9389],
                        ],
                    ],
                    [
                        [
                            [132.5961, -11.3447],
                            [132.5667, -11.3136],
                            [132.5316, -11.2181],
                            [132.5022, -11.0514],
                            [132.5789, -11.0225],
                            [132.6264, -11.1778],
                            [132.6246, -11.2763],
                            [132.5961, -11.3447],
                        ],
                    ],
                    [
                        [
                            [136.9088, -14.1793],
                            [136.9673, -14.1517],
                            [136.9416, -14.2778],
                            [136.8892, -14.2978],
                            [136.7507, -14.2617],
                            [136.6322, -14.2786],
                            [136.378, -14.2164],
                            [136.4131, -14.0445],
                            [136.4054, -13.9705],
                            [136.4572, -13.8389],
                            [136.5921, -13.8136],
                            [136.624, -13.7896],
                            [136.5949, -13.7269],
                            [136.6789, -13.6581],
                            [136.7144, -13.7067],
                            [136.6756, -13.7331],
                            [136.7129, -13.8379],
                            [136.808, -13.8542],
                            [136.8305, -13.7861],
                            [136.8813, -13.7528],
                            [136.9184, -13.8096],
                            [136.82, -13.9083],
                            [136.7673, -13.9417],
                            [136.7061, -14.0756],
                            [136.708, -14.1678],
                            [136.7366, -14.1917],
                            [136.8704, -14.2117],
                            [136.9088, -14.1793],
                        ],
                    ],
                    [
                        [
                            [137.0545, -15.83],
                            [136.9972, -15.7892],
                            [136.9344, -15.6989],
                            [136.953, -15.6475],
                            [137.0102, -15.5931],
                            [137.0556, -15.6475],
                            [137.093, -15.7664],
                            [137.0545, -15.83],
                        ],
                    ],
                    [
                        [
                            [136.1828, -11.6908],
                            [136.1826, -11.6444],
                            [136.2737, -11.5722],
                            [136.3488, -11.5686],
                            [136.4812, -11.4672],
                            [136.4721, -11.5172],
                            [136.3733, -11.5925],
                            [136.1828, -11.6908],
                        ],
                    ],
                    [
                        [
                            [138.0003, -16.5547],
                            [138.0005, -25.9995],
                            [131.2333, -26],
                            [129.9006, -25.9994],
                            [129.0059, -26.0049],
                            [129, -17.2664],
                            [129, -15.3992],
                            [129, -14.8711],
                            [129.0894, -14.8994],
                            [129.1855, -14.98],
                            [129.1744, -15.1625],
                            [129.2564, -15.1041],
                            [129.2184, -14.8614],
                            [129.2296, -14.8392],
                            [129.3327, -14.8672],
                            [129.462, -14.9306],
                            [129.6686, -15.1547],
                            [129.6634, -15.0955],
                            [129.6265, -15.0217],
                            [129.6139, -14.9469],
                            [129.6473, -14.8378],
                            [129.8015, -14.864],
                            [129.8647, -14.843],
                            [129.9444, -14.7678],
                            [129.7658, -14.8227],
                            [129.6754, -14.7661],
                            [129.6036, -14.6744],
                            [129.5866, -14.6281],
                            [129.6599, -14.5722],
                            [129.5401, -14.5503],
                            [129.4549, -14.503],
                            [129.3585, -14.4137],
                            [129.3702, -14.3333],
                            [129.4306, -14.2222],
                            [129.4925, -14.1422],
                            [129.7328, -13.9947],
                            [129.7875, -13.7442],
                            [129.7823, -13.6985],
                            [129.8288, -13.5169],
                            [129.8999, -13.445],
                            [129.9095, -13.5079],
                            [129.9441, -13.5273],
                            [130.0412, -13.5099],
                            [130.1641, -13.4292],
                            [130.2644, -13.3253],
                            [130.192, -13.204],
                            [130.1158, -13.1476],
                            [130.1246, -12.9703],
                            [130.1822, -12.9079],
                            [130.2257, -12.9467],
                            [130.3276, -12.894],
                            [130.353, -12.8388],
                            [130.344, -12.7061],
                            [130.3544, -12.6726],
                            [130.509, -12.6044],
                            [130.6041, -12.6767],
                            [130.5755, -12.5709],
                            [130.6023, -12.5068],
                            [130.6285, -12.3851],
                            [130.7704, -12.4296],
                            [130.8966, -12.6075],
                            [130.9541, -12.5364],
                            [130.8158, -12.4447],
                            [130.8472, -12.3731],
                            [130.8858, -12.3542],
                            [131.0269, -12.3583],
                            [131.0264, -12.2407],
                            [130.9985, -12.1868],
                            [131.0243, -12.1496],
                            [131.1141, -12.1537],
                            [131.2133, -12.2175],
                            [131.3441, -12.2236],
                            [131.4364, -12.2892],
                            [131.4926, -12.2972],
                            [131.9315, -12.2681],
                            [132.0627, -12.3091],
                            [132.2236, -12.2],
                            [132.3609, -12.2024],
                            [132.4173, -12.3072],
                            [132.4447, -12.3021],
                            [132.4245, -12.1856],
                            [132.4443, -12.1503],
                            [132.6385, -12.0786],
                            [132.6276, -12.0328],
                            [132.635, -11.745],
                            [132.6915, -11.6582],
                            [132.5403, -11.5475],
                            [132.4899, -11.477],
                            [132.3727, -11.4378],
                            [132.2295, -11.4613],
                            [132.0868, -11.5247],
                            [131.9902, -11.4297],
                            [131.9706, -11.3644],
                            [131.8652, -11.3092],
                            [131.7643, -11.3067],
                            [131.8741, -11.1772],
                            [131.9843, -11.1274],
                            [132.0902, -11.23],
                            [132.1659, -11.4064],
                            [132.205, -11.4098],
                            [132.2355, -11.3553],
                            [132.1924, -11.2222],
                            [132.1467, -11.14],
                            [132.1812, -11.1318],
                            [132.2761, -11.1636],
                            [132.3405, -11.1301],
                            [132.5034, -11.2645],
                            [132.5136, -11.3085],
                            [132.672, -11.5082],
                            [132.7264, -11.5195],
                            [132.7725, -11.4839],
                            [132.8747, -11.3334],
                            [132.918, -11.337],
                            [132.9965, -11.4197],
                            [133.1462, -11.6878],
                            [133.2514, -11.737],
                            [133.3075, -11.6994],
                            [133.3567, -11.7014],
                            [133.4072, -11.7742],
                            [133.5489, -11.8328],
                            [133.6168, -11.8346],
                            [133.7622, -11.7676],
                            [133.7994, -11.7183],
                            [133.9083, -11.7361],
                            [133.9194, -11.7672],
                            [133.8501, -11.8061],
                            [133.8394, -11.8542],
                            [133.9427, -11.9123],
                            [134.0504, -11.8445],
                            [134.1855, -11.9464],
                            [134.1877, -12.0461],
                            [134.2066, -12.0617],
                            [134.37, -12.0378],
                            [134.4211, -12.0588],
                            [134.5161, -12.0667],
                            [134.5986, -12.0564],
                            [134.6727, -12.0012],
                            [134.7714, -11.9958],
                            [134.8707, -12.1317],
                            [134.9972, -12.1964],
                            [135.0839, -12.2666],
                            [135.2261, -12.2755],
                            [135.2669, -12.1719],
                            [135.3283, -12.1117],
                            [135.3797, -12.0903],
                            [135.4378, -12.1153],
                            [135.5861, -12.0983],
                            [135.6627, -12.0372],
                            [135.5724, -12.033],
                            [135.5932, -11.9577],
                            [135.6858, -11.9333],
                            [135.7536, -11.935],
                            [135.8805, -11.835],
                            [135.8764, -11.7648],
                            [135.9466, -11.8042],
                            [135.9164, -11.8525],
                            [135.746, -11.9945],
                            [135.7883, -12.0436],
                            [135.6735, -12.1595],
                            [135.6887, -12.2375],
                            [135.7355, -12.2809],
                            [135.7863, -12.2587],
                            [135.8012, -12.2183],
                            [135.8836, -12.1518],
                            [136.023, -12.112],
                            [135.9381, -12.2172],
                            [135.9718, -12.2677],
                            [136.0459, -12.2341],
                            [136.0635, -12.2641],
                            [135.9832, -12.3779],
                            [136.0397, -12.4716],
                            [136.081, -12.4455],
                            [136.1568, -12.4376],
                            [136.2401, -12.455],
                            [136.2937, -12.4143],
                            [136.3639, -12.2397],
                            [136.3374, -12.2057],
                            [136.2429, -12.2172],
                            [136.1778, -12.1669],
                            [136.2817, -12.0655],
                            [136.4511, -11.9542],
                            [136.5622, -11.9344],
                            [136.5392, -12.0097],
                            [136.6019, -12.1831],
                            [136.6732, -12.2845],
                            [136.7185, -12.2834],
                            [136.754, -12.2343],
                            [136.694, -12.1915],
                            [136.7754, -12.1717],
                            [136.8764, -12.2227],
                            [136.9784, -12.3582],
                            [136.8039, -12.4775],
                            [136.7452, -12.5442],
                            [136.6436, -12.7041],
                            [136.6208, -12.8253],
                            [136.4944, -12.7792],
                            [136.4843, -12.846],
                            [136.5461, -12.958],
                            [136.5453, -13.055],
                            [136.5283, -13.15],
                            [136.4488, -13.2205],
                            [136.3677, -13.2472],
                            [136.3718, -13.0692],
                            [136.3255, -13.0613],
                            [136.2861, -13.1664],
                            [136.2002, -13.2391],
                            [136.0972, -13.1847],
                            [135.9933, -13.2247],
                            [135.9272, -13.2778],
                            [135.8915, -13.3385],
                            [135.935, -13.3969],
                            [135.9086, -13.4558],
                            [135.8642, -13.4917],
                            [135.8458, -13.6039],
                            [135.889, -13.7287],
                            [135.9826, -13.7358],
                            [136.0203, -13.7625],
                            [135.9886, -13.8666],
                            [135.9247, -13.9619],
                            [135.8969, -14.1428],
                            [135.8692, -14.1946],
                            [135.7528, -14.2711],
                            [135.6214, -14.4375],
                            [135.5405, -14.5736],
                            [135.5383, -14.6467],
                            [135.4133, -14.7294],
                            [135.3727, -14.7289],
                            [135.434, -14.9024],
                            [135.4764, -14.9646],
                            [135.5429, -15.0193],
                            [135.6705, -15.0644],
                            [135.8519, -15.1775],
                            [135.9445, -15.2595],
                            [136.2122, -15.3945],
                            [136.2411, -15.4184],
                            [136.2651, -15.5404],
                            [136.32, -15.6031],
                            [136.5686, -15.7183],
                            [136.6711, -15.8003],
                            [136.7658, -15.9044],
                            [137.0306, -15.9147],
                            [137.1366, -15.9686],
                            [137.3691, -16.1276],
                            [137.4883, -16.1764],
                            [137.5437, -16.1749],
                            [137.7377, -16.2517],
                            [137.8588, -16.4381],
                            [138.0003, -16.5547],
                        ],
                    ],
                ],
            },
            properties: {
                name: 'Northern Territory',
                id: 'AU-NT',
                CNTRY_NAME: 'Australia',
                TYPE: 'Territory',
            },
            id: 'AU-NT',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [129, -14.8711],
                            [129, -15.3992],
                            [129, -17.2664],
                            [129.0059, -26.0049],
                            [129.0003, -31.6926],
                            [128.793, -31.7694],
                            [128.5544, -31.8883],
                            [128.1827, -32.0317],
                            [128.0124, -32.0896],
                            [127.7375, -32.1347],
                            [127.3347, -32.2669],
                            [127.125, -32.2985],
                            [126.6872, -32.3159],
                            [126.3994, -32.2922],
                            [126.1797, -32.2385],
                            [126.0693, -32.285],
                            [125.9723, -32.2668],
                            [125.8065, -32.3436],
                            [125.515, -32.5468],
                            [125.4443, -32.5733],
                            [125.3023, -32.5988],
                            [124.9955, -32.7422],
                            [124.8795, -32.8299],
                            [124.7466, -32.8978],
                            [124.4028, -32.9456],
                            [124.2819, -32.9856],
                            [124.1901, -33.0492],
                            [124.1064, -33.1394],
                            [124.0025, -33.3936],
                            [123.9559, -33.5596],
                            [123.86, -33.6158],
                            [123.7697, -33.6953],
                            [123.735, -33.7797],
                            [123.6908, -33.8206],
                            [123.5408, -33.9058],
                            [123.369, -33.8952],
                            [123.2827, -33.9712],
                            [123.1681, -34.0186],
                            [123.1007, -33.8869],
                            [123.0178, -33.8575],
                            [122.845, -33.9069],
                            [122.7303, -33.8933],
                            [122.5938, -33.898],
                            [122.5757, -33.9133],
                            [122.4333, -33.9258],
                            [122.3545, -33.913],
                            [122.2623, -33.9659],
                            [122.2514, -34.0167],
                            [122.1183, -34.0286],
                            [122.0715, -33.8932],
                            [122.0137, -33.8311],
                            [121.9168, -33.8364],
                            [121.8617, -33.8805],
                            [121.781, -33.8995],
                            [121.5226, -33.8215],
                            [121.3361, -33.8156],
                            [121.0516, -33.857],
                            [120.8625, -33.8567],
                            [120.7914, -33.888],
                            [120.5403, -33.9172],
                            [120.417, -33.9739],
                            [120.253, -33.9397],
                            [120.005, -33.9289],
                            [119.8129, -33.9781],
                            [119.7389, -34.0458],
                            [119.6161, -34.1],
                            [119.5636, -34.1497],
                            [119.4809, -34.2704],
                            [119.4675, -34.3318],
                            [119.3255, -34.447],
                            [119.2086, -34.5044],
                            [119.0789, -34.4661],
                            [118.9116, -34.4531],
                            [118.7397, -34.55],
                            [118.7542, -34.607],
                            [118.6379, -34.6804],
                            [118.477, -34.7237],
                            [118.418, -34.7741],
                            [118.3877, -34.8403],
                            [118.2816, -34.9055],
                            [118.0817, -34.9939],
                            [117.8379, -35.0302],
                            [117.8585, -35.114],
                            [117.7266, -35.048],
                            [117.6328, -35.0694],
                            [117.6097, -35.1383],
                            [117.5334, -35.0883],
                            [117.3325, -35.0189],
                            [116.9483, -35.0158],
                            [116.873, -35.0567],
                            [116.7244, -35.0169],
                            [116.6019, -35.033],
                            [116.4605, -34.9996],
                            [116.2169, -34.8662],
                            [115.9736, -34.8195],
                            [115.9133, -34.7032],
                            [115.8142, -34.6078],
                            [115.648, -34.4678],
                            [115.4957, -34.3836],
                            [115.314, -34.3049],
                            [115.1683, -34.3128],
                            [115.1222, -34.3628],
                            [115.0089, -34.2625],
                            [115.0103, -34.1759],
                            [114.9577, -33.8661],
                            [114.9537, -33.6923],
                            [114.9971, -33.5241],
                            [115.0397, -33.5338],
                            [115.1013, -33.6112],
                            [115.2206, -33.6533],
                            [115.3155, -33.646],
                            [115.4297, -33.6051],
                            [115.7126, -33.264],
                            [115.6694, -32.982],
                            [115.5944, -32.6707],
                            [115.6176, -32.6028],
                            [115.6727, -32.7458],
                            [115.716, -32.7156],
                            [115.7619, -32.5725],
                            [115.7325, -32.3209],
                            [115.7553, -32.1918],
                            [115.7378, -32.0953],
                            [115.7433, -31.8933],
                            [115.7058, -31.7164],
                            [115.6817, -31.6606],
                            [115.4378, -31.2796],
                            [115.3686, -31.1022],
                            [115.3103, -30.9866],
                            [115.1675, -30.772],
                            [115.0472, -30.5047],
                            [115.0545, -30.4772],
                            [115.0236, -30.2742],
                            [114.9572, -30.0756],
                            [114.9372, -30.0603],
                            [114.9419, -29.6706],
                            [114.978, -29.4878],
                            [114.9282, -29.3356],
                            [114.9028, -29.2992],
                            [114.8873, -29.2058],
                            [114.8349, -29.0965],
                            [114.6193, -28.8711],
                            [114.5917, -28.7975],
                            [114.5841, -28.6331],
                            [114.5028, -28.488],
                            [114.3075, -28.2299],
                            [114.2317, -28.1889],
                            [114.1543, -28.091],
                            [114.0947, -27.8516],
                            [114.141, -27.7072],
                            [114.1214, -27.6033],
                            [114.0728, -27.4511],
                            [113.9369, -27.1989],
                            [113.7992, -26.9674],
                            [113.5858, -26.6908],
                            [113.2811, -26.3994],
                            [113.2244, -26.2392],
                            [113.2705, -26.1564],
                            [113.3341, -26.2786],
                            [113.3655, -26.1189],
                            [113.5143, -26.2842],
                            [113.5346, -26.338],
                            [113.5464, -26.5081],
                            [113.5697, -26.5697],
                            [113.6435, -26.6543],
                            [113.7419, -26.5942],
                            [113.784, -26.6035],
                            [113.8558, -26.5075],
                            [113.8614, -26.3365],
                            [113.7892, -26.2506],
                            [113.6522, -26.1695],
                            [113.5512, -26.075],
                            [113.4927, -25.8588],
                            [113.3911, -25.7105],
                            [113.4086, -25.6282],
                            [113.4695, -25.5409],
                            [113.4977, -25.5603],
                            [113.5911, -25.7122],
                            [113.6974, -25.7952],
                            [113.7341, -25.889],
                            [113.7099, -26.0076],
                            [113.6819, -26.0349],
                            [113.6843, -26.1196],
                            [113.714, -26.1969],
                            [113.7986, -26.1683],
                            [113.8789, -26.0289],
                            [113.9312, -26.2636],
                            [113.9589, -26.3278],
                            [114.0693, -26.4616],
                            [114.1949, -26.3729],
                            [114.2214, -26.2925],
                            [114.1719, -26.1826],
                            [114.2064, -25.9891],
                            [114.2569, -25.9705],
                            [114.2581, -25.8478],
                            [114.0343, -25.6337],
                            [113.9151, -25.4317],
                            [113.8066, -25.1813],
                            [113.7011, -25.1224],
                            [113.6111, -24.8892],
                            [113.6141, -24.7537],
                            [113.5516, -24.66],
                            [113.4257, -24.5253],
                            [113.3897, -24.4294],
                            [113.3841, -24.2308],
                            [113.4244, -24.1345],
                            [113.4464, -24.0114],
                            [113.5322, -23.7573],
                            [113.6012, -23.6303],
                            [113.743, -23.5272],
                            [113.7683, -23.4417],
                            [113.782, -23.3267],
                            [113.7603, -23.1939],
                            [113.8183, -23.0306],
                            [113.8075, -22.9333],
                            [113.753, -22.7939],
                            [113.6711, -22.6861],
                            [113.6564, -22.6047],
                            [113.7997, -22.3342],
                            [113.9333, -21.9761],
                            [114.0303, -21.8416],
                            [114.0893, -21.8108],
                            [114.176, -21.8228],
                            [114.1266, -21.9102],
                            [114.0778, -22.1742],
                            [114.1419, -22.3014],
                            [114.1103, -22.4865],
                            [114.1539, -22.5278],
                            [114.3725, -22.4425],
                            [114.4, -22.3356],
                            [114.4608, -22.1903],
                            [114.6254, -21.9229],
                            [114.6511, -21.84],
                            [114.9427, -21.6887],
                            [115.0527, -21.6811],
                            [115.2455, -21.5919],
                            [115.4519, -21.5178],
                            [115.6194, -21.3319],
                            [115.68, -21.2875],
                            [115.8065, -21.2365],
                            [115.9252, -21.069],
                            [116.1598, -20.9658],
                            [116.1855, -20.9019],
                            [116.4755, -20.8061],
                            [116.6063, -20.7301],
                            [116.7075, -20.6491],
                            [116.7885, -20.6656],
                            [116.8757, -20.7175],
                            [117.0667, -20.6217],
                            [117.1652, -20.6538],
                            [117.2679, -20.7176],
                            [117.4062, -20.7299],
                            [117.6854, -20.6764],
                            [117.8503, -20.6084],
                            [118.0691, -20.41],
                            [118.1785, -20.3487],
                            [118.235, -20.3742],
                            [118.5452, -20.3253],
                            [118.6578, -20.3311],
                            [118.8011, -20.2859],
                            [118.95, -20.1169],
                            [118.9799, -20.0413],
                            [119.0803, -19.9688],
                            [119.1889, -19.9594],
                            [119.4341, -20.0169],
                            [119.5818, -20.0708],
                            [119.7855, -19.9716],
                            [120.2395, -19.9087],
                            [120.3372, -19.8785],
                            [120.8786, -19.6652],
                            [121.0275, -19.5922],
                            [121.2767, -19.395],
                            [121.4886, -19.1231],
                            [121.6394, -18.8139],
                            [121.7774, -18.6368],
                            [121.765, -18.5561],
                            [121.8241, -18.4584],
                            [121.8899, -18.4737],
                            [122.0136, -18.3922],
                            [122.0667, -18.3175],
                            [122.1286, -18.2886],
                            [122.3375, -18.1314],
                            [122.3676, -18.0605],
                            [122.3322, -17.9795],
                            [122.2528, -17.9584],
                            [122.2111, -17.8936],
                            [122.1998, -17.7046],
                            [122.1408, -17.558],
                            [122.1439, -17.363],
                            [122.175, -17.2433],
                            [122.2566, -17.1078],
                            [122.3791, -16.9961],
                            [122.4474, -16.9542],
                            [122.5743, -16.9534],
                            [122.5239, -16.8447],
                            [122.5665, -16.7899],
                            [122.6397, -16.7997],
                            [122.7522, -16.7622],
                            [122.7366, -16.6974],
                            [122.7605, -16.6006],
                            [122.8414, -16.5587],
                            [122.8943, -16.5023],
                            [122.9203, -16.4146],
                            [122.9917, -16.3897],
                            [123.0603, -16.4556],
                            [122.9895, -16.476],
                            [122.9562, -16.5868],
                            [123.0197, -16.6703],
                            [123.0961, -16.7161],
                            [123.1164, -16.7928],
                            [123.3078, -17.1375],
                            [123.5534, -17.515],
                            [123.5636, -17.3672],
                            [123.619, -17.2061],
                            [123.574, -17.0729],
                            [123.5926, -16.9967],
                            [123.6558, -16.9948],
                            [123.7825, -17.1425],
                            [123.8558, -17.2064],
                            [123.7964, -16.998],
                            [123.8917, -16.8934],
                            [123.8583, -16.8764],
                            [123.7664, -16.8882],
                            [123.7177, -16.7881],
                            [123.6078, -16.6733],
                            [123.6136, -16.5642],
                            [123.5014, -16.566],
                            [123.4933, -16.4972],
                            [123.6411, -16.5295],
                            [123.7089, -16.4303],
                            [123.7075, -16.3402],
                            [123.5958, -16.3191],
                            [123.5602, -16.2883],
                            [123.5709, -16.1716],
                            [123.7262, -16.1386],
                            [123.8064, -16.199],
                            [123.7839, -16.2461],
                            [123.7327, -16.2595],
                            [123.8391, -16.368],
                            [123.8928, -16.3399],
                            [123.9644, -16.2455],
                            [124.0862, -16.2625],
                            [124.1664, -16.3025],
                            [124.2298, -16.4042],
                            [124.333, -16.4099],
                            [124.3844, -16.3528],
                            [124.4758, -16.3958],
                            [124.5786, -16.4056],
                            [124.7291, -16.3849],
                            [124.6973, -16.3469],
                            [124.5624, -16.3242],
                            [124.48, -16.3475],
                            [124.4005, -16.3294],
                            [124.3761, -16.2219],
                            [124.4305, -16.1025],
                            [124.4708, -16.0933],
                            [124.5187, -16.1636],
                            [124.593, -16.1142],
                            [124.5853, -16.0228],
                            [124.6147, -15.9186],
                            [124.7267, -15.809],
                            [124.6701, -15.7869],
                            [124.6162, -15.8023],
                            [124.5347, -15.9364],
                            [124.4878, -15.9372],
                            [124.4002, -15.8643],
                            [124.3719, -15.6683],
                            [124.4572, -15.4783],
                            [124.4889, -15.4652],
                            [124.5818, -15.5168],
                            [124.6741, -15.455],
                            [124.6633, -15.263],
                            [124.7053, -15.2533],
                            [124.9125, -15.3561],
                            [124.9938, -15.4318],
                            [125.0764, -15.4304],
                            [125.1186, -15.3232],
                            [125.0973, -15.3018],
                            [125.0114, -15.3005],
                            [124.9121, -15.336],
                            [124.9007, -15.2789],
                            [124.9375, -15.2284],
                            [125.0043, -15.2282],
                            [125.0128, -15.1536],
                            [124.8817, -15.2374],
                            [124.8544, -15.2361],
                            [124.8249, -15.1603],
                            [124.9005, -15.1004],
                            [124.9592, -15.1172],
                            [125.0329, -15.0747],
                            [125.0183, -15.0408],
                            [125.0783, -14.9997],
                            [125.1472, -15.1514],
                            [125.1746, -15.1195],
                            [125.1616, -15.0339],
                            [125.2793, -15.1012],
                            [125.3216, -15.156],
                            [125.4148, -15.1515],
                            [125.3602, -15.0987],
                            [125.39, -15.0708],
                            [125.2811, -14.9955],
                            [125.1829, -14.9483],
                            [125.2431, -14.902],
                            [125.136, -14.7474],
                            [125.2433, -14.5981],
                            [125.3361, -14.523],
                            [125.3883, -14.545],
                            [125.5889, -14.5494],
                            [125.5994, -14.427],
                            [125.58, -14.3089],
                            [125.6183, -14.2224],
                            [125.7287, -14.2732],
                            [125.7228, -14.4043],
                            [125.843, -14.4649],
                            [125.825, -14.5725],
                            [125.9028, -14.6436],
                            [125.991, -14.5472],
                            [126.0376, -14.5152],
                            [126.0664, -14.3442],
                            [126.1433, -14.1925],
                            [126.1467, -14.13],
                            [126.0675, -13.9155],
                            [126.148, -13.9275],
                            [126.2175, -13.9619],
                            [126.2089, -14.0373],
                            [126.1572, -14.0591],
                            [126.1917, -14.1684],
                            [126.2341, -14.1797],
                            [126.2878, -14.2331],
                            [126.3133, -14.1936],
                            [126.2946, -14.1406],
                            [126.3342, -14.0504],
                            [126.4313, -13.975],
                            [126.5025, -13.9647],
                            [126.4553, -14.0775],
                            [126.5683, -14.2206],
                            [126.6005, -14.2297],
                            [126.7039, -14.1238],
                            [126.7891, -13.9714],
                            [126.7459, -13.7954],
                            [126.8579, -13.751],
                            [126.9559, -13.7282],
                            [127.0177, -13.7764],
                            [127.0747, -13.8486],
                            [127.0576, -13.8863],
                            [127.1284, -13.9715],
                            [127.1542, -13.9],
                            [127.2419, -13.8964],
                            [127.4252, -13.954],
                            [127.5211, -14.0861],
                            [127.658, -14.176],
                            [127.9572, -14.5817],
                            [128.0001, -14.5607],
                            [128.1694, -14.7028],
                            [128.1883, -14.8003],
                            [128.1399, -14.8677],
                            [128.0694, -15.1008],
                            [128.0797, -15.2061],
                            [128.069, -15.4718],
                            [128.0971, -15.4343],
                            [128.1321, -15.214],
                            [128.1817, -15.2334],
                            [128.2576, -15.306],
                            [128.2919, -15.3038],
                            [128.2244, -15.1905],
                            [128.1921, -15.0652],
                            [128.3063, -14.9128],
                            [128.3263, -14.9165],
                            [128.353, -15.0443],
                            [128.4483, -15.0471],
                            [128.4582, -15.0017],
                            [128.4186, -14.932],
                            [128.3877, -14.8],
                            [128.536, -14.7585],
                            [129, -14.8711],
                        ],
                    ],
                    [
                        [
                            [113.2056, -26.1448],
                            [113.1761, -26.1231],
                            [113.0275, -25.9203],
                            [112.9525, -25.7845],
                            [112.9072, -25.6275],
                            [112.9139, -25.5397],
                            [112.9538, -25.4878],
                            [113.0032, -25.499],
                            [112.9872, -25.5708],
                            [113.1053, -25.8764],
                            [113.207, -26.0428],
                            [113.2271, -26.1088],
                            [113.2056, -26.1448],
                        ],
                    ],
                    [
                        [
                            [125.1383, -14.6486],
                            [125.086, -14.621],
                            [125.1153, -14.4866],
                            [125.1622, -14.439],
                            [125.208, -14.4891],
                            [125.1853, -14.5989],
                            [125.1383, -14.6486],
                        ],
                    ],
                    [
                        [
                            [115.3689, -20.8808],
                            [115.3028, -20.816],
                            [115.4052, -20.6861],
                            [115.45, -20.6737],
                            [115.4597, -20.7728],
                            [115.3689, -20.8808],
                        ],
                    ],
                    [
                        [
                            [124.5225, -15.4453],
                            [124.4605, -15.3691],
                            [124.4943, -15.2862],
                            [124.5597, -15.26],
                            [124.6465, -15.4007],
                            [124.5225, -15.4453],
                        ],
                    ],
                ],
            },
            properties: {
                name: 'Western Australia',
                id: 'AU-WA',
                CNTRY_NAME: 'Australia',
                TYPE: 'State',
            },
            id: 'AU-WA',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [149.051, -35.9172],
                        [148.9267, -35.8728],
                        [148.7935, -35.7115],
                        [148.7672, -35.6522],
                        [148.7689, -35.5133],
                        [148.8172, -35.3203],
                        [149.0797, -35.1617],
                        [149.1966, -35.216],
                        [149.2488, -35.2672],
                        [149.4024, -35.3275],
                        [149.358, -35.3603],
                        [149.2648, -35.3499],
                        [149.1895, -35.3792],
                        [149.1417, -35.4341],
                        [149.0851, -35.6209],
                        [149.1069, -35.8275],
                        [149.051, -35.9172],
                    ],
                ],
            },
            properties: {
                name: 'Australian Capital Territory',
                id: 'AU-ACT',
                CNTRY_NAME: 'Australia',
                TYPE: 'Territory',
            },
            id: 'AU-ACT',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [149.9777, -37.5126],
                        [148.2049, -36.7974],
                        [148.115, -36.7914],
                        [148.1963, -36.679],
                        [148.2061, -36.6071],
                        [148.114, -36.4551],
                        [148.051, -36.3895],
                        [147.999, -36.0596],
                        [147.8906, -36.0036],
                        [147.7233, -35.945],
                        [147.6372, -35.9719],
                        [147.4965, -35.9499],
                        [147.4031, -35.9624],
                        [147.3102, -36.0564],
                        [147.2197, -36.0633],
                        [147.0523, -36.1111],
                        [146.9175, -36.1037],
                        [146.6941, -36.0469],
                        [146.6045, -35.99],
                        [146.5011, -35.9771],
                        [146.2985, -36.0508],
                        [146.0916, -36.0361],
                        [145.8058, -35.9923],
                        [145.7359, -35.9683],
                        [145.5457, -35.8302],
                        [145.445, -35.8414],
                        [145.3572, -35.8805],
                        [145.2362, -35.8515],
                        [145.1133, -35.8514],
                        [144.9876, -35.878],
                        [144.9341, -35.9995],
                        [144.9494, -36.1011],
                        [144.7727, -36.1414],
                        [144.6103, -36.0749],
                        [144.4, -35.875],
                        [144.3489, -35.7791],
                        [144.2688, -35.745],
                        [144.1674, -35.671],
                        [144.0902, -35.5842],
                        [143.9191, -35.5095],
                        [143.7655, -35.4136],
                        [143.653, -35.4003],
                        [143.5748, -35.3412],
                        [143.5974, -35.2835],
                        [143.5587, -35.2347],
                        [143.478, -35.2182],
                        [143.403, -35.18],
                        [143.3572, -35.0968],
                        [143.3309, -34.9715],
                        [143.3545, -34.872],
                        [143.3429, -34.7929],
                        [143.1302, -34.6972],
                        [142.9814, -34.6892],
                        [142.8636, -34.6497],
                        [142.7878, -34.588],
                        [142.7133, -34.6248],
                        [142.6808, -34.7425],
                        [142.6355, -34.7899],
                        [142.5508, -34.7763],
                        [142.4652, -34.6054],
                        [142.3783, -34.5392],
                        [142.3738, -34.3955],
                        [142.2344, -34.2012],
                        [142.1541, -34.1557],
                        [142.0388, -34.1123],
                        [141.8825, -34.1391],
                        [141.7344, -34.107],
                        [141.6464, -34.1351],
                        [141.5906, -34.1994],
                        [141.5233, -34.2006],
                        [141.4115, -34.1436],
                        [141.2474, -34.0803],
                        [141.0748, -34.0698],
                        [140.9978, -34.0241],
                        [141.0027, -29.0002],
                        [141.1219, -28.9991],
                        [143.1302, -29],
                        [144.6869, -29],
                        [145.9567, -29],
                        [148.9528, -29],
                        [149.0294, -28.9664],
                        [149.1461, -28.8378],
                        [149.466, -28.598],
                        [149.5764, -28.5714],
                        [149.6176, -28.6049],
                        [149.7055, -28.6278],
                        [150.0137, -28.6043],
                        [150.157, -28.5551],
                        [150.3205, -28.5459],
                        [150.397, -28.6161],
                        [150.4661, -28.6573],
                        [150.6333, -28.6614],
                        [150.7843, -28.6338],
                        [151.0371, -28.7355],
                        [151.2925, -28.9293],
                        [151.3047, -29.0817],
                        [151.3454, -29.1612],
                        [151.4166, -29.1591],
                        [151.4649, -29.1147],
                        [151.5555, -28.9531],
                        [151.7279, -28.8861],
                        [151.7647, -28.9488],
                        [151.8268, -28.9751],
                        [152.0181, -28.8937],
                        [152.0506, -28.7305],
                        [151.9606, -28.5501],
                        [152.0575, -28.4822],
                        [152.1695, -28.4332],
                        [152.3685, -28.3754],
                        [152.4866, -28.263],
                        [152.5306, -28.2685],
                        [152.5963, -28.3281],
                        [152.7601, -28.3601],
                        [152.9469, -28.3427],
                        [153.1122, -28.3529],
                        [153.1889, -28.2632],
                        [153.3221, -28.2413],
                        [153.4317, -28.2036],
                        [153.506, -28.1501],
                        [153.5776, -28.2079],
                        [153.5915, -28.2739],
                        [153.5597, -28.4916],
                        [153.5785, -28.6055],
                        [153.6242, -28.661],
                        [153.606, -28.8622],
                        [153.4481, -29.0437],
                        [153.3377, -29.3283],
                        [153.3655, -29.3984],
                        [153.2539, -29.9525],
                        [153.205, -30.1147],
                        [153.0186, -30.5686],
                        [153.0055, -30.8353],
                        [153.0571, -30.8877],
                        [153.075, -30.9487],
                        [152.973, -31.2433],
                        [152.9542, -31.3594],
                        [152.8488, -31.6566],
                        [152.7602, -31.8106],
                        [152.5575, -32.0291],
                        [152.5118, -32.1311],
                        [152.5414, -32.2636],
                        [152.5297, -32.4036],
                        [152.3557, -32.5283],
                        [152.2809, -32.6014],
                        [152.1274, -32.6822],
                        [152.1448, -32.7705],
                        [151.9527, -32.827],
                        [151.8122, -32.8888],
                        [151.6689, -33.07],
                        [151.5741, -33.2672],
                        [151.4546, -33.3168],
                        [151.4447, -33.3622],
                        [151.4868, -33.3936],
                        [151.4547, -33.5007],
                        [151.3402, -33.6286],
                        [151.297, -33.8975],
                        [151.2728, -33.9695],
                        [151.1783, -33.9875],
                        [151.1952, -34.0568],
                        [151.1141, -34.1664],
                        [151.0238, -34.222],
                        [150.9342, -34.3317],
                        [150.8405, -34.5583],
                        [150.8814, -34.5992],
                        [150.8313, -34.786],
                        [150.767, -34.8196],
                        [150.7469, -34.8775],
                        [150.7819, -34.9617],
                        [150.7754, -35.012],
                        [150.6852, -35.0422],
                        [150.7033, -35.127],
                        [150.6475, -35.1786],
                        [150.5403, -35.2267],
                        [150.4067, -35.5278],
                        [150.3558, -35.595],
                        [150.1624, -35.9405],
                        [150.1375, -36.1117],
                        [150.1356, -36.3282],
                        [150.0772, -36.3889],
                        [150.0234, -36.6281],
                        [149.9664, -36.7947],
                        [149.9025, -36.9233],
                        [149.9067, -37.0692],
                        [150.0065, -37.1618],
                        [150.0198, -37.2314],
                        [149.9479, -37.2819],
                        [149.9494, -37.3984],
                        [149.9777, -37.5126],
                    ],
                    [
                        [148.9267, -35.8728],
                        [149.051, -35.9172],
                        [149.1069, -35.8275],
                        [149.0851, -35.6209],
                        [149.1417, -35.4341],
                        [149.1895, -35.3792],
                        [149.2648, -35.3499],
                        [149.358, -35.3603],
                        [149.4024, -35.3275],
                        [149.2488, -35.2672],
                        [149.1966, -35.216],
                        [149.0797, -35.1617],
                        [148.8172, -35.3203],
                        [148.7689, -35.5133],
                        [148.7672, -35.6522],
                        [148.7935, -35.7115],
                        [148.9267, -35.8728],
                    ],
                ],
            },
            properties: {
                name: 'New South Wales',
                id: 'AU-NSW',
                CNTRY_NAME: 'Australia',
                TYPE: 'State',
            },
            id: 'AU-NSW',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [137.9109, -35.7294],
                            [138.0628, -35.7632],
                            [138.1119, -35.8175],
                            [138.1127, -35.8697],
                            [138.0403, -35.9227],
                            [137.9211, -35.8756],
                            [137.761, -35.866],
                            [137.6197, -35.9199],
                            [137.6144, -36.0095],
                            [137.4555, -36.0853],
                            [137.3575, -36.0045],
                            [137.226, -35.984],
                            [137.1501, -36.0442],
                            [137.0317, -36.0355],
                            [136.8997, -36.0522],
                            [136.7122, -36.0567],
                            [136.6803, -36.0132],
                            [136.5385, -35.9174],
                            [136.5339, -35.8822],
                            [136.5811, -35.7694],
                            [136.9475, -35.6808],
                            [137.1205, -35.6622],
                            [137.3172, -35.5907],
                            [137.5261, -35.6047],
                            [137.585, -35.6508],
                            [137.5979, -35.747],
                            [137.6933, -35.7575],
                            [137.7915, -35.8032],
                            [137.8507, -35.8067],
                            [137.9109, -35.7294],
                        ],
                    ],
                    [
                        [
                            [129.0003, -31.6926],
                            [129.0059, -26.0049],
                            [129.9006, -25.9994],
                            [131.2333, -26],
                            [138.0005, -25.9995],
                            [141.0005, -25.9997],
                            [141.0027, -29.0002],
                            [140.9978, -34.0241],
                            [140.9719, -33.9954],
                            [140.9681, -38.0642],
                            [140.8506, -38.0547],
                            [140.6986, -38.0719],
                            [140.53, -38.0004],
                            [140.3559, -37.8617],
                            [140.2383, -37.6719],
                            [140.1213, -37.5307],
                            [140.0498, -37.4904],
                            [139.9938, -37.4925],
                            [139.8144, -37.2997],
                            [139.7514, -37.1997],
                            [139.7803, -37.1078],
                            [139.7337, -37.0143],
                            [139.7519, -36.9216],
                            [139.8229, -36.8746],
                            [139.8618, -36.7947],
                            [139.8608, -36.6608],
                            [139.8235, -36.5546],
                            [139.648, -36.2097],
                            [139.3719, -35.9106],
                            [139.4039, -35.8872],
                            [139.584, -36.0885],
                            [139.6041, -36.0414],
                            [139.448, -35.8842],
                            [139.0996, -35.6125],
                            [139.0976, -35.524],
                            [139.1597, -35.5039],
                            [139.2351, -35.5572],
                            [139.2261, -35.6826],
                            [139.3358, -35.6914],
                            [139.3613, -35.6039],
                            [139.3731, -35.4704],
                            [139.3566, -35.3745],
                            [139.2125, -35.3167],
                            [139.1347, -35.379],
                            [138.97, -35.4085],
                            [138.9594, -35.4756],
                            [139.0358, -35.5099],
                            [138.9911, -35.5574],
                            [138.7215, -35.5388],
                            [138.6505, -35.563],
                            [138.5363, -35.6535],
                            [138.175, -35.6696],
                            [138.0932, -35.6192],
                            [138.153, -35.5329],
                            [138.2856, -35.479],
                            [138.4388, -35.3438],
                            [138.4675, -35.2117],
                            [138.465, -35.1131],
                            [138.5142, -35.025],
                            [138.4811, -34.8629],
                            [138.4961, -34.7289],
                            [138.4352, -34.6422],
                            [138.275, -34.4801],
                            [138.2197, -34.3149],
                            [138.0922, -34.135],
                            [138.0681, -34.1397],
                            [138.0069, -34.2572],
                            [138.019, -34.3442],
                            [137.9245, -34.4255],
                            [137.8891, -34.5252],
                            [137.8755, -34.7111],
                            [137.7491, -35.0497],
                            [137.7482, -35.1328],
                            [137.6744, -35.1816],
                            [137.5322, -35.1289],
                            [137.4211, -35.12],
                            [137.2336, -35.1782],
                            [137.1668, -35.2482],
                            [137.0276, -35.2307],
                            [136.9499, -35.2866],
                            [136.8923, -35.3041],
                            [136.8315, -35.2518],
                            [136.9367, -35.1472],
                            [136.9604, -35.0998],
                            [136.977, -34.9589],
                            [137.024, -34.902],
                            [137.1077, -34.9296],
                            [137.2815, -34.9064],
                            [137.3847, -34.9608],
                            [137.4541, -34.8969],
                            [137.4985, -34.6586],
                            [137.4811, -34.4717],
                            [137.4933, -34.2864],
                            [137.4517, -34.1604],
                            [137.5148, -34.1433],
                            [137.5949, -33.9558],
                            [137.6029, -33.884],
                            [137.7778, -33.6973],
                            [137.9486, -33.5593],
                            [137.9161, -33.4414],
                            [137.8143, -33.278],
                            [137.8449, -33.202],
                            [137.955, -33.1494],
                            [138.0469, -33.1294],
                            [138.0089, -33.0288],
                            [137.9541, -33.0091],
                            [137.8964, -32.7872],
                            [137.8438, -32.688],
                            [137.8163, -32.5669],
                            [137.763, -32.5325],
                            [137.7551, -32.6958],
                            [137.8012, -32.741],
                            [137.8076, -32.8479],
                            [137.7748, -32.9928],
                            [137.5955, -33.0297],
                            [137.4883, -33.1278],
                            [137.3852, -33.3003],
                            [137.3611, -33.4293],
                            [137.2099, -33.6661],
                            [137.142, -33.7137],
                            [137.0203, -33.7173],
                            [136.9497, -33.681],
                            [136.8638, -33.807],
                            [136.5799, -33.9319],
                            [136.4134, -34.041],
                            [136.3565, -34.0902],
                            [136.3061, -34.1989],
                            [136.2066, -34.3321],
                            [136.1259, -34.362],
                            [136.0403, -34.4836],
                            [135.9369, -34.5369],
                            [135.875, -34.6383],
                            [135.8038, -34.8152],
                            [135.8729, -34.8199],
                            [136.0066, -34.7425],
                            [136.02, -34.7952],
                            [136.0046, -34.9918],
                            [135.9564, -35.0083],
                            [135.8466, -34.8903],
                            [135.7867, -34.8603],
                            [135.724, -34.8678],
                            [135.6797, -34.9538],
                            [135.6061, -34.893],
                            [135.4747, -34.7511],
                            [135.3263, -34.6248],
                            [135.2159, -34.5651],
                            [135.1123, -34.5948],
                            [135.1517, -34.5039],
                            [135.2132, -34.4981],
                            [135.3444, -34.6142],
                            [135.3965, -34.644],
                            [135.3931, -34.4953],
                            [135.3491, -34.2892],
                            [135.2726, -34.1203],
                            [135.2611, -34.0065],
                            [135.1938, -33.9194],
                            [135.0336, -33.7686],
                            [134.8407, -33.6378],
                            [134.8684, -33.5415],
                            [134.858, -33.472],
                            [134.8086, -33.3528],
                            [134.7075, -33.1772],
                            [134.5935, -33.14],
                            [134.4086, -33.1645],
                            [134.3295, -33.2028],
                            [134.2693, -33.12],
                            [134.1957, -32.9545],
                            [134.0745, -32.7209],
                            [134.1547, -32.7259],
                            [134.2082, -32.8076],
                            [134.2765, -32.7287],
                            [134.2971, -32.6775],
                            [134.2764, -32.5886],
                            [134.2055, -32.5036],
                            [134.1141, -32.4534],
                            [133.9555, -32.4958],
                            [133.8907, -32.5482],
                            [133.8529, -32.5418],
                            [133.8544, -32.4604],
                            [133.8833, -32.4047],
                            [133.9508, -32.3983],
                            [133.8263, -32.2508],
                            [133.7255, -32.2006],
                            [133.6059, -32.0981],
                            [133.5667, -32.1657],
                            [133.4851, -32.2098],
                            [133.1666, -32.1908],
                            [133.0655, -32.1199],
                            [132.9736, -32.0917],
                            [132.8329, -31.9779],
                            [132.7852, -31.9562],
                            [132.5844, -31.9359],
                            [132.4889, -31.9525],
                            [132.4141, -32.0117],
                            [132.3278, -32.0378],
                            [132.1959, -32.0269],
                            [132.0269, -31.8819],
                            [131.7691, -31.7222],
                            [131.365, -31.5434],
                            [131.1486, -31.474],
                            [131.1152, -31.4775],
                            [131.0125, -31.5489],
                            [130.844, -31.6047],
                            [130.7936, -31.6103],
                            [130.2633, -31.5762],
                            [129.9358, -31.5934],
                            [129.8383, -31.6144],
                            [129.2319, -31.6583],
                            [129.0003, -31.6926],
                        ],
                    ],
                ],
            },
            properties: {
                name: 'South Australia',
                id: 'AU-SA',
                CNTRY_NAME: 'Australia',
                TYPE: 'State',
            },
            id: 'AU-SA',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [145.3541, -38.57],
                            [145.2714, -38.5209],
                            [145.117, -38.5311],
                            [145.1903, -38.467],
                            [145.3097, -38.466],
                            [145.3541, -38.57],
                        ],
                    ],
                    [
                        [
                            [140.9681, -38.0642],
                            [140.9719, -33.9954],
                            [140.9978, -34.0241],
                            [141.0748, -34.0698],
                            [141.2474, -34.0803],
                            [141.4115, -34.1436],
                            [141.5233, -34.2006],
                            [141.5906, -34.1994],
                            [141.6464, -34.1351],
                            [141.7344, -34.107],
                            [141.8825, -34.1391],
                            [142.0388, -34.1123],
                            [142.1541, -34.1557],
                            [142.2344, -34.2012],
                            [142.3738, -34.3955],
                            [142.3783, -34.5392],
                            [142.4652, -34.6054],
                            [142.5508, -34.7763],
                            [142.6355, -34.7899],
                            [142.6808, -34.7425],
                            [142.7133, -34.6248],
                            [142.7878, -34.588],
                            [142.8636, -34.6497],
                            [142.9814, -34.6892],
                            [143.1302, -34.6972],
                            [143.3429, -34.7929],
                            [143.3545, -34.872],
                            [143.3309, -34.9715],
                            [143.3572, -35.0968],
                            [143.403, -35.18],
                            [143.478, -35.2182],
                            [143.5587, -35.2347],
                            [143.5974, -35.2835],
                            [143.5748, -35.3412],
                            [143.653, -35.4003],
                            [143.7655, -35.4136],
                            [143.9191, -35.5095],
                            [144.0902, -35.5842],
                            [144.1674, -35.671],
                            [144.2688, -35.745],
                            [144.3489, -35.7791],
                            [144.4, -35.875],
                            [144.6103, -36.0749],
                            [144.7727, -36.1414],
                            [144.9494, -36.1011],
                            [144.9341, -35.9995],
                            [144.9876, -35.878],
                            [145.1133, -35.8514],
                            [145.2362, -35.8515],
                            [145.3572, -35.8805],
                            [145.445, -35.8414],
                            [145.5457, -35.8302],
                            [145.7359, -35.9683],
                            [145.8058, -35.9923],
                            [146.0916, -36.0361],
                            [146.2985, -36.0508],
                            [146.5011, -35.9771],
                            [146.6045, -35.99],
                            [146.6941, -36.0469],
                            [146.9175, -36.1037],
                            [147.0523, -36.1111],
                            [147.2197, -36.0633],
                            [147.3102, -36.0564],
                            [147.4031, -35.9624],
                            [147.4965, -35.9499],
                            [147.6372, -35.9719],
                            [147.7233, -35.945],
                            [147.8906, -36.0036],
                            [147.999, -36.0596],
                            [148.051, -36.3895],
                            [148.114, -36.4551],
                            [148.2061, -36.6071],
                            [148.1963, -36.679],
                            [148.115, -36.7914],
                            [148.2049, -36.7974],
                            [149.9777, -37.5126],
                            [149.8994, -37.5521],
                            [149.7855, -37.5605],
                            [149.6728, -37.6963],
                            [149.4571, -37.7833],
                            [149.3094, -37.7953],
                            [148.8262, -37.7978],
                            [148.6586, -37.8166],
                            [148.4939, -37.8116],
                            [148.3091, -37.8216],
                            [147.964, -37.9024],
                            [147.7592, -37.9825],
                            [147.5411, -38.1134],
                            [147.213, -38.3631],
                            [146.9694, -38.5853],
                            [146.8736, -38.6517],
                            [146.6499, -38.6738],
                            [146.4311, -38.7172],
                            [146.3527, -38.6979],
                            [146.2193, -38.716],
                            [146.1847, -38.7574],
                            [146.2965, -38.9166],
                            [146.4693, -38.8057],
                            [146.4837, -39.0763],
                            [146.4251, -39.1347],
                            [146.3469, -39.1301],
                            [146.247, -38.9489],
                            [146.1423, -38.846],
                            [146.0652, -38.82],
                            [146.0027, -38.8635],
                            [145.9044, -38.857],
                            [145.8236, -38.7286],
                            [145.8161, -38.6523],
                            [145.7279, -38.6414],
                            [145.6586, -38.6772],
                            [145.5662, -38.6652],
                            [145.416, -38.5458],
                            [145.4322, -38.4522],
                            [145.5203, -38.4231],
                            [145.5552, -38.3743],
                            [145.4855, -38.2481],
                            [145.4443, -38.227],
                            [145.2555, -38.2376],
                            [145.213, -38.3492],
                            [145.0304, -38.4974],
                            [144.9013, -38.5059],
                            [144.7611, -38.3778],
                            [144.8804, -38.3778],
                            [144.985, -38.3405],
                            [145.1313, -38.137],
                            [145.0992, -38.043],
                            [144.9755, -37.892],
                            [144.9177, -37.8686],
                            [144.8247, -37.9025],
                            [144.6912, -38.0039],
                            [144.5269, -38.1011],
                            [144.386, -38.1131],
                            [144.3745, -38.1628],
                            [144.513, -38.1816],
                            [144.6371, -38.134],
                            [144.7063, -38.1492],
                            [144.7105, -38.2225],
                            [144.6339, -38.2997],
                            [144.5486, -38.2838],
                            [144.3951, -38.3096],
                            [144.0617, -38.4846],
                            [143.8423, -38.6966],
                            [143.7319, -38.7203],
                            [143.6732, -38.7839],
                            [143.5429, -38.8593],
                            [143.3874, -38.7684],
                            [143.18, -38.7161],
                            [143.0482, -38.6371],
                            [142.9717, -38.6293],
                            [142.8033, -38.576],
                            [142.5352, -38.4128],
                            [142.3793, -38.3639],
                            [142.1604, -38.4001],
                            [141.9647, -38.2921],
                            [141.8214, -38.2675],
                            [141.6794, -38.2829],
                            [141.6202, -38.3219],
                            [141.6502, -38.3998],
                            [141.5714, -38.4172],
                            [141.4541, -38.3725],
                            [141.3675, -38.2894],
                            [141.1931, -38.1606],
                            [141.1036, -38.1139],
                            [140.9681, -38.0642],
                        ],
                    ],
                    [
                        [
                            [145.3559, -38.4311],
                            [145.2838, -38.409],
                            [145.2957, -38.2987],
                            [145.4556, -38.3248],
                            [145.3559, -38.4311],
                        ],
                    ],
                ],
            },
            properties: { name: 'Victoria', id: 'AU-VIC', CNTRY_NAME: 'Australia', TYPE: 'State' },
            id: 'AU-VIC',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [153.0816, -25.7959],
                            [153.038, -25.7873],
                            [152.9977, -25.7378],
                            [152.943, -25.5583],
                            [152.9966, -25.4394],
                            [153.0643, -25.3386],
                            [153.0495, -25.2308],
                            [153.0908, -25.1523],
                            [153.1945, -25.0755],
                            [153.2402, -24.993],
                            [153.2377, -24.9142],
                            [153.1781, -24.8247],
                            [153.1961, -24.7508],
                            [153.2816, -24.6992],
                            [153.29, -24.9042],
                            [153.371, -25.0141],
                            [153.1271, -25.5364],
                            [153.0828, -25.6678],
                            [153.0974, -25.7486],
                            [153.0816, -25.7959],
                        ],
                    ],
                    [
                        [
                            [153.506, -28.1501],
                            [153.4317, -28.2036],
                            [153.3221, -28.2413],
                            [153.1889, -28.2632],
                            [153.1122, -28.3529],
                            [152.9469, -28.3427],
                            [152.7601, -28.3601],
                            [152.5963, -28.3281],
                            [152.5306, -28.2685],
                            [152.4866, -28.263],
                            [152.3685, -28.3754],
                            [152.1695, -28.4332],
                            [152.0575, -28.4822],
                            [151.9606, -28.5501],
                            [152.0506, -28.7305],
                            [152.0181, -28.8937],
                            [151.8268, -28.9751],
                            [151.7647, -28.9488],
                            [151.7279, -28.8861],
                            [151.5555, -28.9531],
                            [151.4649, -29.1147],
                            [151.4166, -29.1591],
                            [151.3454, -29.1612],
                            [151.3047, -29.0817],
                            [151.2925, -28.9293],
                            [151.0371, -28.7355],
                            [150.7843, -28.6338],
                            [150.6333, -28.6614],
                            [150.4661, -28.6573],
                            [150.397, -28.6161],
                            [150.3205, -28.5459],
                            [150.157, -28.5551],
                            [150.0137, -28.6043],
                            [149.7055, -28.6278],
                            [149.6176, -28.6049],
                            [149.5764, -28.5714],
                            [149.466, -28.598],
                            [149.1461, -28.8378],
                            [149.0294, -28.9664],
                            [148.9528, -29],
                            [145.9567, -29],
                            [144.6869, -29],
                            [143.1302, -29],
                            [141.1219, -28.9991],
                            [141.0027, -29.0002],
                            [141.0005, -25.9997],
                            [138.0005, -25.9995],
                            [138.0003, -16.5547],
                            [138.1948, -16.7074],
                            [138.2908, -16.7425],
                            [138.4869, -16.7878],
                            [138.6396, -16.7793],
                            [138.6873, -16.8126],
                            [138.8433, -16.8758],
                            [139.0105, -16.8991],
                            [139.1413, -17.0742],
                            [139.1628, -17.2031],
                            [139.213, -17.2971],
                            [139.2605, -17.3425],
                            [139.3367, -17.3678],
                            [139.4372, -17.3792],
                            [139.5354, -17.4418],
                            [139.6165, -17.5208],
                            [139.8142, -17.5753],
                            [139.8827, -17.6142],
                            [139.9794, -17.6972],
                            [140.1322, -17.7192],
                            [140.3966, -17.6733],
                            [140.5342, -17.6244],
                            [140.6199, -17.58],
                            [140.758, -17.4778],
                            [140.8333, -17.4519],
                            [140.8858, -17.3597],
                            [140.9422, -17.1511],
                            [140.9569, -17.0011],
                            [141.1945, -16.7039],
                            [141.2861, -16.5033],
                            [141.3155, -16.3575],
                            [141.4266, -16.0744],
                            [141.4005, -15.9058],
                            [141.4605, -15.5361],
                            [141.6346, -15.1493],
                            [141.6655, -15.0265],
                            [141.5665, -14.7636],
                            [141.521, -14.4813],
                            [141.5361, -14.3986],
                            [141.5932, -14.2556],
                            [141.6001, -14.1438],
                            [141.5781, -14.0654],
                            [141.4775, -13.9485],
                            [141.4689, -13.8281],
                            [141.5419, -13.5328],
                            [141.6887, -13.254],
                            [141.6309, -13.1595],
                            [141.5856, -12.9864],
                            [141.6068, -12.9346],
                            [141.7086, -12.871],
                            [141.7477, -12.8244],
                            [141.7969, -12.6913],
                            [141.8567, -12.7093],
                            [141.8894, -12.7635],
                            [141.89, -12.8436],
                            [141.9404, -12.865],
                            [141.8838, -12.7041],
                            [141.8394, -12.6725],
                            [141.7178, -12.4934],
                            [141.6622, -12.4511],
                            [141.6269, -12.5696],
                            [141.5942, -12.5317],
                            [141.6883, -12.3319],
                            [141.7758, -12.2136],
                            [141.7599, -12.1789],
                            [141.8054, -12.0517],
                            [141.8491, -11.9885],
                            [141.8924, -11.969],
                            [141.9169, -12.0889],
                            [142.0237, -12.0678],
                            [142.0316, -12.0435],
                            [141.9506, -11.9654],
                            [141.9689, -11.8085],
                            [142.1228, -11.3726],
                            [142.1589, -11.1494],
                            [142.1382, -10.9736],
                            [142.1846, -10.9229],
                            [142.343, -10.8954],
                            [142.3996, -10.822],
                            [142.4445, -10.7097],
                            [142.5188, -10.7067],
                            [142.6132, -10.7508],
                            [142.5163, -10.8582],
                            [142.609, -10.8725],
                            [142.7448, -10.9865],
                            [142.7883, -11.0805],
                            [142.8659, -11.3913],
                            [142.84, -11.5112],
                            [142.8675, -11.7158],
                            [142.8598, -11.8332],
                            [142.9687, -11.9274],
                            [143.0375, -11.9328],
                            [143.1033, -11.9036],
                            [143.1992, -11.9875],
                            [143.1022, -12.1403],
                            [143.082, -12.225],
                            [143.0775, -12.3343],
                            [143.1602, -12.3408],
                            [143.2758, -12.4131],
                            [143.2794, -12.5176],
                            [143.43, -12.6168],
                            [143.3751, -12.7428],
                            [143.3623, -12.8489],
                            [143.4058, -12.8704],
                            [143.5044, -12.8592],
                            [143.5141, -12.8792],
                            [143.4983, -13.0986],
                            [143.5286, -13.3405],
                            [143.5947, -13.432],
                            [143.5867, -13.5261],
                            [143.5308, -13.7564],
                            [143.6014, -13.9361],
                            [143.6857, -14.0233],
                            [143.6985, -14.1854],
                            [143.737, -14.3192],
                            [143.7822, -14.4133],
                            [143.8876, -14.4872],
                            [144.0119, -14.4877],
                            [144.1536, -14.38],
                            [144.4351, -14.2541],
                            [144.4762, -14.163],
                            [144.5159, -14.1717],
                            [144.5786, -14.2632],
                            [144.567, -14.3585],
                            [144.6145, -14.4847],
                            [144.6768, -14.5574],
                            [144.8282, -14.6139],
                            [144.8966, -14.6182],
                            [144.9593, -14.7538],
                            [145.2064, -14.8625],
                            [145.3158, -14.9456],
                            [145.3097, -15.0045],
                            [145.2343, -15.1345],
                            [145.2377, -15.1931],
                            [145.2769, -15.257],
                            [145.2894, -15.3311],
                            [145.2486, -15.4464],
                            [145.3049, -15.5547],
                            [145.3595, -15.7343],
                            [145.3592, -15.9154],
                            [145.4099, -15.9647],
                            [145.4623, -16.0831],
                            [145.4462, -16.2875],
                            [145.4088, -16.3382],
                            [145.4021, -16.441],
                            [145.4644, -16.5022],
                            [145.8061, -16.9131],
                            [145.8389, -16.8862],
                            [145.9318, -16.8711],
                            [145.9554, -16.8991],
                            [145.8781, -17.0404],
                            [145.8828, -17.0717],
                            [145.9539, -17.1886],
                            [146.0319, -17.3644],
                            [146.0855, -17.5528],
                            [146.1042, -17.6917],
                            [146.0861, -17.7872],
                            [146.1008, -17.8897],
                            [146.094, -17.9559],
                            [146.0017, -18.1422],
                            [146.0094, -18.2381],
                            [146.2119, -18.4917],
                            [146.3336, -18.5356],
                            [146.3262, -18.6474],
                            [146.2811, -18.7472],
                            [146.2682, -18.8536],
                            [146.3357, -18.9604],
                            [146.4235, -19.0255],
                            [146.448, -19.0711],
                            [146.5652, -19.1436],
                            [146.6544, -19.1839],
                            [146.7636, -19.1868],
                            [146.8858, -19.303],
                            [146.9535, -19.3058],
                            [147.0722, -19.3428],
                            [147.1394, -19.4028],
                            [147.2534, -19.4258],
                            [147.4319, -19.4124],
                            [147.4588, -19.37],
                            [147.5552, -19.5354],
                            [147.5949, -19.7272],
                            [147.6695, -19.8247],
                            [147.7701, -19.8263],
                            [147.7614, -19.71],
                            [147.8512, -19.7402],
                            [147.8636, -19.8508],
                            [147.9718, -19.9186],
                            [148.0969, -19.913],
                            [148.22, -19.9472],
                            [148.2988, -20.0379],
                            [148.2747, -20.0766],
                            [148.3339, -20.1453],
                            [148.4134, -20.2064],
                            [148.4664, -20.1845],
                            [148.453, -20.0636],
                            [148.5222, -20.088],
                            [148.673, -20.2161],
                            [148.7689, -20.2324],
                            [148.8033, -20.2767],
                            [148.8667, -20.4448],
                            [148.8786, -20.5214],
                            [148.7903, -20.4569],
                            [148.7411, -20.4758],
                            [148.6672, -20.5617],
                            [148.7291, -20.7175],
                            [148.8497, -20.8361],
                            [148.9214, -20.8831],
                            [149.0284, -20.9186],
                            [149.1606, -21.0153],
                            [149.2147, -21.08],
                            [149.2049, -21.1544],
                            [149.24, -21.2786],
                            [149.2894, -21.3186],
                            [149.3285, -21.4378],
                            [149.3855, -21.5314],
                            [149.443, -21.5825],
                            [149.43, -21.7732],
                            [149.5208, -22.0772],
                            [149.5899, -22.2333],
                            [149.6533, -22.3181],
                            [149.6966, -22.3506],
                            [149.6994, -22.4525],
                            [149.7363, -22.4633],
                            [149.8147, -22.3839],
                            [150.0383, -22.641],
                            [149.9864, -22.4756],
                            [149.9208, -22.3506],
                            [149.9214, -22.3033],
                            [149.9599, -22.2083],
                            [149.9995, -22.1603],
                            [150.0789, -22.1628],
                            [150.1553, -22.2722],
                            [150.2004, -22.3917],
                            [150.3219, -22.4375],
                            [150.48, -22.5386],
                            [150.593, -22.5861],
                            [150.5353, -22.4587],
                            [150.5344, -22.3835],
                            [150.5619, -22.3311],
                            [150.6345, -22.343],
                            [150.7055, -22.4414],
                            [150.6758, -22.5049],
                            [150.6836, -22.5536],
                            [150.8191, -22.7319],
                            [150.7558, -23.1303],
                            [150.8122, -23.238],
                            [150.8004, -23.3807],
                            [150.8671, -23.505],
                            [150.9485, -23.5462],
                            [151.0231, -23.5565],
                            [151.0521, -23.6172],
                            [151.1202, -23.6651],
                            [151.1681, -23.7925],
                            [151.3183, -23.8583],
                            [151.4242, -23.9786],
                            [151.5466, -24.036],
                            [151.6142, -24.0528],
                            [151.616, -23.9983],
                            [151.6839, -23.9889],
                            [151.7881, -24.0989],
                            [151.9334, -24.2179],
                            [152.01, -24.4264],
                            [152.1318, -24.6082],
                            [152.2771, -24.71],
                            [152.4183, -24.7603],
                            [152.468, -24.8122],
                            [152.4977, -24.8773],
                            [152.5077, -24.9978],
                            [152.6715, -25.2451],
                            [152.8089, -25.2826],
                            [152.9079, -25.2889],
                            [152.9466, -25.4364],
                            [152.9343, -25.5248],
                            [152.8934, -25.5624],
                            [152.8986, -25.6792],
                            [152.9205, -25.7354],
                            [152.9841, -25.7718],
                            [153.0379, -25.8906],
                            [153.182, -25.9494],
                            [153.1375, -26.0678],
                            [153.072, -26.3085],
                            [153.0902, -26.3733],
                            [153.1152, -26.6695],
                            [153.1431, -26.73],
                            [153.1572, -27.0827],
                            [153.0957, -27.1004],
                            [153.0346, -27.1767],
                            [153.0648, -27.3133],
                            [153.1747, -27.3889],
                            [153.256, -27.4797],
                            [153.3188, -27.6712],
                            [153.4033, -27.7773],
                            [153.4328, -27.8483],
                            [153.4103, -27.9819],
                            [153.4603, -28.0927],
                            [153.506, -28.1501],
                        ],
                    ],
                    [
                        [
                            [153.4253, -27.3628],
                            [153.3587, -27.1967],
                            [153.3583, -27.0615],
                            [153.4445, -27.0193],
                            [153.4664, -27.0342],
                            [153.4144, -27.2089],
                            [153.4253, -27.3628],
                        ],
                    ],
                    [
                        [
                            [146.29, -18.4939],
                            [146.2205, -18.4683],
                            [146.2016, -18.3766],
                            [146.1575, -18.3194],
                            [146.1111, -18.2981],
                            [146.1275, -18.245],
                            [146.2697, -18.3083],
                            [146.3522, -18.4229],
                            [146.29, -18.4939],
                        ],
                    ],
                    [
                        [
                            [139.4283, -17.1461],
                            [139.4001, -17.0888],
                            [139.5027, -16.9967],
                            [139.5541, -17.0318],
                            [139.5703, -17.105],
                            [139.4528, -17.1028],
                            [139.4283, -17.1461],
                        ],
                    ],
                    [
                        [
                            [139.1483, -16.7608],
                            [139.1386, -16.7025],
                            [139.165, -16.6006],
                            [139.2225, -16.5239],
                            [139.3067, -16.4625],
                            [139.4797, -16.4422],
                            [139.5344, -16.4139],
                            [139.7273, -16.4443],
                            [139.7329, -16.5028],
                            [139.6238, -16.5358],
                            [139.5808, -16.5025],
                            [139.4891, -16.5381],
                            [139.5036, -16.5692],
                            [139.442, -16.6678],
                            [139.3303, -16.7089],
                            [139.1853, -16.735],
                            [139.1483, -16.7608],
                        ],
                    ],
                    [
                        [
                            [151.2271, -23.7867],
                            [151.1725, -23.7403],
                            [151.1336, -23.6613],
                            [151.0661, -23.6053],
                            [151.0277, -23.5258],
                            [151.0184, -23.4564],
                            [151.0628, -23.4471],
                            [151.203, -23.5289],
                            [151.2871, -23.6725],
                            [151.299, -23.7505],
                            [151.2271, -23.7867],
                        ],
                    ],
                    [
                        [
                            [142.1833, -10.7703],
                            [142.1206, -10.7206],
                            [142.1158, -10.6575],
                            [142.2169, -10.6103],
                            [142.2658, -10.6836],
                            [142.1833, -10.7703],
                        ],
                    ],
                    [
                        [
                            [153.4069, -27.7311],
                            [153.3952, -27.6114],
                            [153.4336, -27.4161],
                            [153.5409, -27.4181],
                            [153.4749, -27.6139],
                            [153.454, -27.7263],
                            [153.4069, -27.7311],
                        ],
                    ],
                    [
                        [
                            [142.2799, -10.2656],
                            [142.2105, -10.2368],
                            [142.1894, -10.2042],
                            [142.2286, -10.1455],
                            [142.2841, -10.1357],
                            [142.34, -10.1914],
                            [142.2799, -10.2656],
                        ],
                    ],
                ],
            },
            properties: {
                name: 'Queensland',
                id: 'AU-QLD',
                CNTRY_NAME: 'Australia',
                TYPE: 'State',
            },
            id: 'AU-QLD',
        },
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [146.9166, -43.6178],
                            [146.6864, -43.6033],
                            [146.5995, -43.5561],
                            [146.5144, -43.5428],
                            [146.296, -43.5347],
                            [146.2315, -43.4888],
                            [146.1103, -43.5154],
                            [146.0383, -43.498],
                            [145.9327, -43.3763],
                            [145.9919, -43.3458],
                            [146.1026, -43.358],
                            [146.2325, -43.391],
                            [146.2345, -43.3252],
                            [146.1633, -43.2823],
                            [146.1247, -43.3333],
                            [145.8589, -43.3087],
                            [145.8369, -43.2973],
                            [145.7269, -43.1333],
                            [145.5953, -42.9792],
                            [145.5116, -42.9656],
                            [145.4232, -42.8467],
                            [145.3539, -42.6585],
                            [145.26, -42.6124],
                            [145.1976, -42.3134],
                            [145.2501, -42.2748],
                            [145.4263, -42.3746],
                            [145.4694, -42.5231],
                            [145.552, -42.3511],
                            [145.4583, -42.3264],
                            [145.2802, -42.1812],
                            [145.2479, -42.0349],
                            [145.1846, -41.9383],
                            [145.0549, -41.8467],
                            [144.955, -41.7133],
                            [144.6858, -41.2166],
                            [144.6677, -41.0752],
                            [144.6372, -41.032],
                            [144.6187, -40.9311],
                            [144.6997, -40.8755],
                            [144.7013, -40.7592],
                            [144.7622, -40.7281],
                            [144.986, -40.7487],
                            [145.1164, -40.8224],
                            [145.275, -40.8028],
                            [145.3357, -40.8422],
                            [145.5391, -40.8928],
                            [145.8722, -41.0428],
                            [146.1932, -41.1569],
                            [146.4054, -41.1717],
                            [146.5819, -41.1515],
                            [146.6604, -41.0888],
                            [146.7844, -41.0823],
                            [146.8638, -41.0284],
                            [147.0171, -40.9761],
                            [147.1474, -41.0089],
                            [147.3561, -40.9764],
                            [147.4165, -41.0178],
                            [147.4884, -40.985],
                            [147.5891, -40.853],
                            [147.6749, -40.8308],
                            [147.8032, -40.8928],
                            [147.9018, -40.8632],
                            [147.9511, -40.7613],
                            [148.0142, -40.746],
                            [148.0794, -40.7689],
                            [148.221, -40.849],
                            [148.2734, -40.9011],
                            [148.3283, -40.9954],
                            [148.2643, -41.1673],
                            [148.3135, -41.2593],
                            [148.3169, -41.3347],
                            [148.2738, -41.4541],
                            [148.2804, -41.5392],
                            [148.3143, -41.613],
                            [148.2647, -41.8146],
                            [148.298, -42.035],
                            [148.3587, -42.1087],
                            [148.3638, -42.2224],
                            [148.2753, -42.2555],
                            [148.3097, -42.1406],
                            [148.2388, -41.9982],
                            [148.1952, -41.9454],
                            [148.0791, -42.1172],
                            [148.004, -42.5225],
                            [147.9438, -42.6139],
                            [147.9549, -42.7175],
                            [147.8832, -42.7723],
                            [147.9109, -42.8408],
                            [147.9742, -42.8695],
                            [148.0048, -42.9769],
                            [147.967, -42.9955],
                            [147.9515, -43.0823],
                            [148.0047, -43.1709],
                            [147.9708, -43.2291],
                            [147.8995, -43.1834],
                            [147.7897, -43.247],
                            [147.6972, -43.1636],
                            [147.619, -43.0177],
                            [147.6739, -42.9451],
                            [147.7818, -43.0511],
                            [147.8995, -43.0268],
                            [147.8258, -42.932],
                            [147.5916, -42.8267],
                            [147.5021, -42.8608],
                            [147.5527, -42.9789],
                            [147.5256, -43.0184],
                            [147.4272, -43.0418],
                            [147.408, -42.8897],
                            [147.3486, -42.9047],
                            [147.3261, -43.0086],
                            [147.2682, -43.0604],
                            [147.2419, -43.1336],
                            [147.2625, -43.2039],
                            [147.2475, -43.2692],
                            [147.1782, -43.2822],
                            [147.0983, -43.2445],
                            [146.9701, -43.1371],
                            [146.9693, -43.2043],
                            [147.0954, -43.2887],
                            [147.0023, -43.4226],
                            [146.9166, -43.6178],
                        ],
                    ],
                    [
                        [
                            [148.1289, -40.2745],
                            [148.0386, -40.2363],
                            [148.0183, -40.1402],
                            [147.9039, -39.9756],
                            [147.7608, -39.878],
                            [147.8819, -39.7542],
                            [147.9677, -39.7255],
                            [148.1655, -39.9294],
                            [148.2794, -39.9658],
                            [148.3352, -40.1922],
                            [148.3033, -40.239],
                            [148.1289, -40.2745],
                        ],
                    ],
                    [
                        [
                            [143.9216, -40.1364],
                            [143.8864, -40.1168],
                            [143.8919, -39.9847],
                            [143.8348, -39.9275],
                            [143.8553, -39.7119],
                            [143.9336, -39.667],
                            [143.9354, -39.583],
                            [143.9775, -39.5738],
                            [144.0668, -39.6161],
                            [144.1086, -39.6625],
                            [144.1222, -39.825],
                            [144.1464, -39.9294],
                            [144.1061, -40.0364],
                            [143.9216, -40.1364],
                        ],
                    ],
                    [
                        [
                            [148.3391, -40.5034],
                            [148.3183, -40.4353],
                            [148.0866, -40.4581],
                            [147.997, -40.4282],
                            [147.9986, -40.3898],
                            [148.0652, -40.3482],
                            [148.2027, -40.3613],
                            [148.343, -40.3067],
                            [148.4791, -40.4307],
                            [148.3391, -40.5034],
                        ],
                    ],
                    [
                        [
                            [147.3027, -43.5134],
                            [147.1755, -43.5016],
                            [147.1231, -43.422],
                            [147.3002, -43.2628],
                            [147.365, -43.3858],
                            [147.3027, -43.5134],
                        ],
                    ],
                    [
                        [
                            [158.8797, -54.7539],
                            [158.8339, -54.6689],
                            [158.8911, -54.52],
                            [158.9455, -54.5764],
                            [158.8883, -54.6875],
                            [158.8797, -54.7539],
                        ],
                    ],
                    [
                        [
                            [147.3617, -43.2631],
                            [147.293, -43.1571],
                            [147.3572, -43.075],
                            [147.3965, -43.1197],
                            [147.4327, -43.242],
                            [147.3617, -43.2631],
                        ],
                    ],
                    [
                        [
                            [148.0142, -42.7531],
                            [148.0187, -42.6197],
                            [148.128, -42.5902],
                            [148.1729, -42.6553],
                            [148.0977, -42.6661],
                            [148.0142, -42.7531],
                        ],
                    ],
                    [
                        [
                            [144.8889, -40.7294],
                            [144.8659, -40.6711],
                            [144.9263, -40.6173],
                            [145.0161, -40.6955],
                            [144.8889, -40.7294],
                        ],
                    ],
                ],
            },
            properties: { name: 'Tasmania', id: 'AU-TAS', CNTRY_NAME: 'Australia', TYPE: 'State' },
            id: 'AU-TAS',
        },
    ],
};

export const centers = {
    'Australian Capital Territory': {
        capital: {
            title: 'Canberra \n Region',
            length: 100,
            latitude: -35.2802,
            longitude: 149.131,
        },
        regional: {
            title: 'Regional \n ACT',
            length: 40,
            latitude: -35.4735,
            longitude: 149.0124,
        },
    },
    'New South Wales': {
        capital: {
            title: 'Sydney \n Region',
            length: 100,
            latitude: -33.8688,
            longitude: 151.2093,
        },
        regional: {
            title: 'Regional \n NSW',
            length: 40,
            latitude: -31.840233,
            longitude: 145.612793,
        },
    },
    'Northern Territory': {
        capital: {
            title: 'Darwin \n Region',
            length: 100,
            latitude: -21.462827,
            longitude: 130.841782,
        },
        regional: {
            title: 'Regional \n NT',
            length: 40,
            latitude: -19.491411,
            longitude: 132.550964,
        },
    },
    Queensland: {
        capital: {
            title: 'Brisbane \n Region',
            length: 100,
            latitude: -27.4705,
            longitude: 153.026,
        },
        regional: {
            title: 'Regional \n QLD',
            length: 40,
            latitude: -20.917574,
            longitude: 142.702789,
        },
    },
    'South Australia': {
        capital: {
            title: 'Adelaide \n Region',
            length: 100,
            latitude: -34.9285,
            longitude: 138.6007,
        },
        regional: {
            title: 'Regional \n SA',
            length: 40,
            latitude: -34.000233,
            longitude: 136.209152,
        },
    },
    Tasmania: {
        capital: {
            title: 'Hobart \n Region',
            length: 100,
            latitude: -42.8826,
            longitude: 147.3257,
        },
        regional: {
            title: 'Regional \n TAS',
            length: 40,
            latitude: -42.0409,
            longitude: 146.8087,
        },
    },
    Victoria: {
        capital: {
            title: 'Melbourne \n Region',
            length: 100,
            latitude: -37.8136,
            longitude: 144.9631,
        },
        regional: {
            title: 'Regional \n VIC',
            length: 40,
            latitude: -33.9848,
            longitude: 143.3906,
        },
    },
    'Western Australia': {
        capital: {
            title: 'Perth Region',
            length: 100,
            latitude: -31.9523,
            longitude: 115.8613,
        },
        regional: {
            title: 'Regional WA',
            length: 40,
            latitude: -30.042261,
            longitude: 117.793221,
        },
    },
};
