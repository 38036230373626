import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';

@Injectable()
export class OverlayService {
    private overlaySubject = new BehaviorSubject<boolean>(false);
    private overlayMessage = '';
    loading$: Observable<boolean> = this.overlaySubject.asObservable();

    showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
        return of(null).pipe(
            tap(() => this.overlayOn()),
            concatMap(() => obs$),
            finalize(() => this.overlayOff())
        );
    }
    overlaySetMessage(message: string) {
        this.overlayMessage = message;
    }
    overlayGetMessage() {
        return this.overlayMessage;
    }

    overlayOn() {
        this.overlaySubject.next(true);
    }

    overlayOff() {
        this.overlaySubject.next(false);
    }
}
