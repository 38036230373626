import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'hm-map-controls',
    templateUrl: './map-controls.component.html',
    styleUrls: ['./map-controls.component.scss'],
})
export class MapControlsComponent {
    @Input() map: any;
    @Input() light: string;

    changeTime(time: string) {
        if (this.map) {
            this.map.setConfigProperty('basemap', 'lightPreset', time);
        }
    }
}
