import { ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import {
    ViewChild,
    ElementRef,
    Input,
    EventEmitter,
    Output,
    Component,
    OnInit,
    SimpleChange,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';

@Component({
    selector: 'hm-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
    @Input() label: string = '';
    @Input() selectedDate: string;
    @Input() enableMonths: boolean = true;
    @Input() enableQuarters: boolean = true;
    @Input() minDate: string;
    @Input() maxDate: string;

    @Output() selectedDateChange = new EventEmitter<string>();
    @ViewChild('filterIcon', { static: false }) filterIcon: ElementRef;
    date: string;

    constructor(public dialog: MatDialog, private ref: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedDate && !changes.selectedDate.isFirstChange()) {
            this.selectedDate = changes.selectedDate.currentValue;
            this.date = changes.selectedDate.currentValue;
            this.ref.markForCheck();
        }
    }

    ngOnInit() {
        this.date = this.selectedDate;
    }

    openDialog(): void {
        // open datepicker above or below based on position (may need to fiddle)
        let offset = 300;
        if (this.filterIcon.nativeElement.getBoundingClientRect().bottom < 460) {
            offset = -10;
        }
        const filterData = {
            top: this.filterIcon.nativeElement.getBoundingClientRect().bottom - offset,
            left: this.filterIcon.nativeElement.getBoundingClientRect().right,
            date: this.selectedDate,
            enableMonths: this.enableMonths,
            enableQuarters: this.enableQuarters,
        };
        if (this.minDate) {
            filterData['minDate'] = this.minDate;
        }
        if (this.maxDate) {
            filterData['maxDate'] = this.maxDate;
        }

        let dialogRef = this.dialog.open(DialogComponent, {
            width: '232px',
            data: filterData,
            hasBackdrop: true,
            panelClass: 'filter-popup',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.date = result;
            } else {
                this.date = this.selectedDate;
            }
            this.returnDate();
        });
    }

    returnDate() {
        this.selectedDate = this.date;
        this.selectedDateChange.emit(this.date);
    }
}
