import { OrganisationDataset } from 'app/core/session-info/shared/organisation-dataset.model';
import {
    isAfter,
    isBefore,
    isSameOrAfter,
    isSameOrBefore,
    toFormat,
    YEAR_MONTH_FMT,
    YEAR_MONTH_NUMBER_DAY_FMT,
} from './date';

export type Dataset =
    | 'DEVICE-GPS-SEGMENTED'
    | 'DEVICE-GPS'
    | 'IFI-DOMESTIC'
    | 'IFI-DOMESTIC-SEGMENTED'
    | 'IFI-INTERNATIONAL'
    | 'TELCO'
    | 'LISTINGS'
    | 'TIER1-BANK';

export interface DatasetAdditional {
    key: string;
    value: any;
}

export function checkDates(dataset: any, dateStart: Date, dateEnd: Date): boolean {
    let permissionsAvailable = false;
    if (dataset !== undefined) {
        const stDate = new Date(dataset.start_date);
        stDate.setDate(stDate.getDate() - 1); // Minus 1 day for now to avoid local date problems
        const enDate = new Date(dataset.end_date);

        const stDateB = stDate >= dateStart;
        const stDateBB = stDate >= dateEnd;
        const enDateB = enDate >= dateStart;
        const enDateBB = enDate >= dateEnd;

        if (dataset.end_date == null) {
            permissionsAvailable = true;
        } else if (stDateB == false && stDateBB == false && enDateB == false && enDateBB == false) {
            permissionsAvailable = false;
        } else if (stDateB == true && stDateBB == true && enDateB == true && enDateBB == true) {
            permissionsAvailable = false;
        } else {
            permissionsAvailable = true;
        }
    }
    return permissionsAvailable;
}

export function isDateValidForDataset(
    dataset: OrganisationDataset,
    end: string,
    start?: string
): boolean {
    end = toFormat(end, YEAR_MONTH_FMT, YEAR_MONTH_NUMBER_DAY_FMT, true);
    if (start) {
        start = toFormat(start, YEAR_MONTH_FMT, YEAR_MONTH_NUMBER_DAY_FMT);
    }

    if (dataset.end_date === undefined) {
        return start
            ? !(
                  isBefore(start, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date) &&
                  isBefore(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date)
              ) && isSameOrBefore(start, YEAR_MONTH_NUMBER_DAY_FMT, end)
            : !isBefore(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date);
    }

    return start
        ? !(
              isBefore(start, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date) &&
              isBefore(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date)
          ) &&
              !(
                  isAfter(start, YEAR_MONTH_NUMBER_DAY_FMT, dataset.end_date) &&
                  isAfter(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.end_date)
              ) &&
              isSameOrBefore(start, YEAR_MONTH_NUMBER_DAY_FMT, end)
        : isSameOrBefore(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.end_date) &&
              isSameOrAfter(end, YEAR_MONTH_NUMBER_DAY_FMT, dataset.start_date);
}

// Readjust start and end date according to the bounds of the dataset's dates
export function setDatesByDataset(
    dataset: OrganisationDataset,
    start: string,
    end: string
): [string, string] {
    const dsStart = toFormat(dataset.start_date, YEAR_MONTH_NUMBER_DAY_FMT, YEAR_MONTH_FMT);
    const dsEnd = toFormat(dataset.end_date, YEAR_MONTH_NUMBER_DAY_FMT, YEAR_MONTH_FMT);

    if (isSameOrBefore(start, YEAR_MONTH_FMT, dsStart)) {
        start = toFormat(dataset.start_date, YEAR_MONTH_NUMBER_DAY_FMT, YEAR_MONTH_FMT);
    }

    if (isSameOrAfter(end, YEAR_MONTH_FMT, dsEnd)) {
        end = toFormat(dataset.end_date, YEAR_MONTH_NUMBER_DAY_FMT, YEAR_MONTH_FMT);
    }

    return [start, end];
}
