<!-- splash screen -->
<div #splashScreen class="kt-splash-screen">
    <div class="loading_screen">
        <svg
            version="1.1"
            id="world"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1080 1080"
            style="enable-background: new 0 0 1080 1080;"
            xml:space="preserve"
        >
            <g id="N8H7Db_1_">
                <g>
                    <path
                        class="r0"
                        d="M1043.72,473.77c-3.87-41.1-15.01-80.44-29.49-118.89C955.97,200.21,821.4,82.12,660.31,43.05
                    C609.07,30.63,556.95,26.36,505,30.58C379.74,40.77,271.78,90.35,182.82,180.04C106.47,257.03,60.21,349.22,42.68,455.98
                    c-2.11,12.82-2.9,25.86-4.32,38.8c-0.2,1.85-0.54,3.69-0.82,5.54c0,21.62,0,43.24,0,64.86c0.55,7.49,0.9,15.01,1.68,22.48
                    c5.25,50.47,17.63,99.23,38.05,145.6c59.4,134.91,158.63,227.69,297.23,278c36.03,13.08,73.2,22.72,111.37,25.67
                    c31.38,2.43,63.2,3.21,94.57,1c204.45-14.4,393.7-155.95,451.7-379.49c7.74-29.81,12.16-60.1,13.48-90.86
                    c0.09-2.18,0.55-4.35,0.84-6.53c0-18.53,0-37.06,0-55.6C1045.55,494.9,1044.72,484.33,1043.72,473.77z M669.74,108.29
                    c0.1-1.49,3.2-3.95,4.94-3.96c5.58-0.04,8.49-2.84,11.28-7.29c4.17-6.67,13.39-8.91,19.24-5.46c5.46,3.22,5.73,6.36,0.62,10.03
                    c-12.2,8.78-10.29,7.26-4.05,18.77c0.33,0.6,0.81,1.16,0.96,1.8c0.45,2,0.77,4.02,1.14,6.04c-2.37-0.36-4.79-0.54-7.08-1.17
                    c-1.21-0.34-2.2-1.48-4.1-2.83c-1.69,3.88-2.87,7.26-4.64,10.31c-0.74,1.28-3.14,2.9-4.02,2.53c-1.72-0.72-3.95-2.63-4.1-4.22
                    c-0.25-2.75-1.06-4.1-3.21-6.04C671.46,122.05,669.29,115.36,669.74,108.29z M619.96,317.95c3.5,0.97,5.95,7.8,5.27,11.86
                    c-0.43,2.55-0.37,5.74,0.81,7.91c3.67,6.74,8.09,13.05,11.99,19.67c1.44,2.46,2.62,5.21,3.24,7.98c1.37,6.15-0.25,7.24-6.09,9.03
                    c-4.68,1.42-8.9,4.56-13.07,7.33c-3.03,2.01-5.63,4.65-8.42,7.02c-0.65-0.36-1.31-0.72-1.96-1.09
                    c-0.5-10.12-0.83-20.36,5.12-29.38c4.91-7.44,5.17-8.08-1.49-14.49c-6.57-6.32-8.02-13.24-4.02-20.3
                    C613.17,320.21,615.23,316.64,619.96,317.95z M387.2,69.47c3.09-0.96,4.62-0.34,4.59,3.04c-0.03,3.93,0.87,7.35,4.47,9.74
                    c0.88,0.58,1.49,2.42,1.32,3.56c-0.72,4.97-9.56,8.76-14.88,6.01c2.33-3.29,4.62-6.52,6.91-9.75c-0.35-0.41-0.7-0.82-1.05-1.23
                    c-4.43,3.71-8.87,7.42-14.03,11.74C373.49,79.76,377.97,72.34,387.2,69.47z M389.11,123.46c1.87,0.18,3.72,0.57,5.57,0.92
                    c6.8,1.28,7.76,3.03,5.43,9.45c-1.98,5.45-6.11,8.32-11.55,8.91c-7.84,0.84-14.5-2.07-19.67-7.97c-3.02-3.44-3.27-7.67-1.14-11.54
                    c1.96-3.56,4.38-6.98,7.2-9.88c1.57-1.62,4.44-2.87,6.59-2.68c1.07,0.09,1.83,3.78,2.33,4.94
                    C382.6,121.38,385.02,123.07,389.11,123.46z M304.18,108.02c5.67-4,11.8-9.22,18.16-9.87c6.39-0.65,11.26-3.11,16.57-5.55
                    c0.53-0.24,1.27-0.03,2.58-0.03c-0.99,3.1-1.83,5.74-3.14,9.87c3.82-0.87,7.03-0.85,9.34-2.28c7.4-4.6,13.66-1.1,20.79,1.73
                    c-1.18,1.91-1.89,3.45-2.96,4.7c-4.1,4.8-5.83,10.22-5.67,16.57c0.07,2.92-1.3,5.87-2.2,9.52c-8.64-8.61-12.26-18.19-14.97-28.49
                    c-7.94,15.34-22.5,12.71-35.05,14.51c-1.8,0.26-5.44-1.72-5.6-3C301.73,113.19,302.42,109.27,304.18,108.02z M457.89,990.28
                    c-2.47,0.96-4.96,1.89-7.44,2.83c-0.28-0.87-0.56-1.74-0.84-2.61c2.69-0.59,5.39-1.19,8.15-1.8
                    C457.87,989.97,457.93,990.26,457.89,990.28z M474.07,738.16c-9,19.22-8.19,40.08-10.31,60.46c-0.55,5.28-1.26,10.56-2.34,15.75
                    c-0.79,3.8-3.26,5.83-7.47,6.63c-3.65,0.7-7.04,2.96-10.43,4.76c-2.25,1.19-4.25,2.85-6.45,4.15c-4.32,2.56-6.11,7.56-4.65,12.41
                    c3.1,10.32-0.03,19.84-3.95,29.27c-0.84,2.03-1.96,4.72-1.28,6.46c2,5.12,0.08,8.2-4.03,10.75c-1.02,0.63-1.9,1.56-2.98,2.01
                    c-3.77,1.58-4.05,3.6-2.71,7.55c1.34,3.96,1.53,8.79,0.6,12.86c-0.57,2.5-3.9,4.96-6.56,6.22c-2.34,1.11-3.85,1.77-3.42,4.58
                    c0.47,3.09,0.51,5.47-3.89,6.07c-3.36,0.46-2.59,4.08-0.48,4.83c9.3,3.28,6.81,9.84,5.33,16.41c-0.56,2.49-0.53,5.11-0.81,7.67
                    c-0.42,3.92,1.25,6.58,4.05,9.39c2.61,2.61,5.2,6.38,5.59,9.88c1.23,11.05,5.62,20.37,12.74,28.65c1.05,1.22,1.67,2.82,2.07,4.88
                    c-9.75-1.05-19.44-2.08-26.27-10.11c-8.94-10.52-17.73-21.21-24.79-33.13c-1.4-2.36-2.23-5.45-2.17-8.18
                    c0.07-3.34-0.8-5.36-3.66-7.15c-6.88-4.32-10.98-9.88-10.6-18.93c0.18-4.26-2.91-9.14-5.64-12.94c-4.79-6.68-7.15-13.65-7.4-21.93
                    c-0.15-5.13-2.43-10.26-4.11-15.27c-5.23-15.55-10.65-31.04-15.97-46.56c-2.27-6.64-4.13-13.43-6.75-19.92
                    c-4.67-11.58-13.07-20.05-23.83-26.11c-15.32-8.62-28.56-19.37-38.42-34.21c-3.37-5.07-8.12-9.28-12.59-13.52
                    c-3.28-3.11-4.78-6.45-4.2-10.95c1.06-8.11,0.12-15.95-2.39-23.83c-0.94-2.95,0.02-7.01,1.3-10.07c2.04-4.86,5.55-9.09,7.59-13.95
                    c4.34-10.3-3.47-26.78-13.88-29.49c-0.53,1.71-1.07,3.44-1.68,5.42c-0.81,0-1.74,0.26-2.41-0.04
                    c-12.55-5.54-25.37-10.74-33.88-22.37c-5.13-7.01-11.75-12.06-19.94-14.75c-13-4.28-26.06-8.38-39.19-12.24
                    c-11.25-3.31-22.31-7.1-31.61-14.37c-3.11-2.43-5.25-6.45-7.06-10.12c-2.87-5.8-4.5-12.26-7.69-17.85
                    c-7.65-13.39-15.86-26.46-23.94-39.61c-1.38-2.24-3.23-4.19-4.86-6.27c-0.67,0.32-1.35,0.64-2.02,0.97
                    c5.33,14.7,10.65,29.4,15.98,44.1c-0.64,0.4-1.27,0.8-1.91,1.2c-1.85-1.93-4.02-3.65-5.5-5.83c-6.64-9.8-10.73-20.56-14.72-31.81
                    c-4.9-13.82-5.18-27.23-4-40.92c2.99-34.6,10.07-68.46,20.52-101.59c26.38-83.69,72.59-154.74,136.74-214.32
                    c2.98-2.77,6.31-5.15,9.99-8.11c1.29,2.86,1.97,5.43,3.45,7.38c2.53,3.35,5.14,6.92,8.5,9.28c3.53,2.48,6.76,0.61,6.48-3.66
                    c-0.42-6.45,1.18-11.22,6.21-15.76c4.29-3.87,4.82-9.93,4.34-15.85c-0.16-1.99,0.4-4.97,1.76-6c8.47-6.39,17.22-12.43,26-18.4
                    c1.46-0.99,3.59-1.63,5.3-1.43c1.67,0.2,3.49,1.42,4.6,2.73c0.39,0.46-0.89,2.7-1.78,3.83c-4.97,6.26-5.01,6.23-2.02,12.26
                    c-0.97,0.45-2.22,1.04-3.28,1.54c2.14,4.54,4.94,3.75,7.62,2.21c4.91-2.81,9.61-5.98,14.57-8.68c2.56-1.39,5.5-2.09,8.27-3.1
                    c0.35,0.39,0.7,0.78,1.06,1.17c-0.66,1.77-1.05,3.7-2.02,5.27c-5.24,8.45-10.52,16.88-15.99,25.18
                    c-4.52,6.85-8.09,13.94-7.71,22.44c0.15,3.43-1.52,4.63-4.82,5.92c-4.87,1.9-8.74,1.58-13.44-0.37c-2.05-0.85-5.64,1.06-8,2.55
                    c-0.58,0.37,0.37,4.37,1.53,6.09c5.41,8.04,14.99,8.47,21.46,1.47c4.39-4.74,8.87-9.48,13.83-13.6
                    c5.87-4.88,9.11-11.01,12.47-17.75c4.82-9.67,12.06-18.1,17.88-27.3c3.99-6.31,8.76-7.42,15.4-4.48
                    c4.46,1.97,9.31,3.05,15.58,5.02c-4.61,3.2-7.87,6.94-11.78,7.86c-7.11,1.67-11.5,6-14.86,11.68c-2.6,4.39-4.15,9.38-6.54,13.91
                    c-2.08,3.95-4.99,7.48-6.92,11.5c-0.9,1.86-1.31,5.24-0.25,6.53c3.96,4.83,4.03,9.47,1.19,14.65c-0.35,0.63-0.05,1.62-0.05,3.35
                    c8.35-5.56,15.05-11.71,19.86-19.75c1.6-2.67-0.16-4.32-2.57-4.97c-5.24-1.41-4.66-3.86-1.78-7.28c1.28-1.52,2.36-3.47,2.79-5.39
                    c3.5-15.61,15.7-22.51,28.62-28.48c3.9-1.8,5.45-0.55,7.14,3.54c2.27,0,4.48-0.11,6.68,0.02c7.54,0.46,11.54,4.92,11.08,12.34
                    c-0.2,3.25-0.68,6.48-0.91,9.73c-0.24,3.42,1.59,6.05,2.81,9.04c2.19,5.36,6.02,9.08,8.67,13.66c2.78,4.79,0.46,11.12-3.11,15.85
                    c-4.07,5.39-4.04,6.3,0.01,11.33c1.21,1.5,1.14,4.03,1.62,6.1c0.67,2.9,0.65,6.19,2.12,8.59c1.68,2.75,1.65,4.69-0.3,6.87
                    c-2.51,2.81-5.01,5.65-7.7,8.29c-2.35,2.29-4.56,2.14-6.06-1.09c-0.22-0.47-0.32-1.03-0.66-1.38c-1.65-1.71-3.35-3.37-5.04-5.05
                    c-0.75,2.47-1.56,4.92-2.2,7.42c-0.15,0.59,0.26,1.33,0.41,2c2.91,12.8-1.32,19.81-13.95,23.32c-2.41,0.67-3.82,0.52-5.51-1.9
                    c-3.81-5.46-8.01-10.71-12.59-15.52c-1.94-2.04-5.16-3.48-7.98-3.89c-3.69-0.54-6.56-0.99-6.03-5.58
                    c0.49-4.19,4.64-7.93,8.28-6.37c5.33,2.28,8.03-0.79,10.99-3.77c3.62-3.65,7.15-7.42,10.44-11.36c6.82-8.19,3.56-27.28-5.49-33.03
                    c-5.24-3.33-9.65-1.74-11.79,4.06c-4.27,11.57-8.54,23.14-13.14,34.57c-0.6,1.5-3.04,2.85-4.82,3.17
                    c-4.78,0.87-9.66,1.15-15.85,1.8c2.98,4.23,5.11,7.07,7.05,10.04c3.31,5.05,2.24,8.15-3.5,9.33c-5.63,1.15-11.37,1.8-18.76,2.93
                    c3.42-9.19,6.46-17.38,9.92-26.67c-2.25,1.03-3.95,1.52-5.29,2.49c-5.83,4.21-11.72,8.37-17.29,12.91
                    c-7.43,6.07-14.51,12.57-21.89,18.72c-9.26,7.71-15.68,17.06-18.14,29.05c-1.21,5.87-0.3,10.74,4.12,15.01
                    c6.54,6.32,12.7,13.04,19.37,19.21c2.92,2.7,3.54,5.31,2.87,9.02c-0.91,5.04-1.52,10.19-1.66,15.3c-0.05,1.89,1.58,3.82,2.44,5.73
                    c1.73-1.17,4.01-1.97,5.08-3.58c1.87-2.81,3.3-6.02,4.39-9.24c2.52-7.4,6.86-13.37,13.11-17.99c0.41-0.31,0.81-0.64,1.21-0.96
                    c12.93-10.45,12.93-10.45,5.49-25.48c1.45-1.28,3.3-2.4,4.43-4.02c3.38-4.82,6.6-9.76,9.63-14.81c0.93-1.55,0.87-3.67,1.55-5.41
                    c1.63-4.13,1.76-9.59,7.91-10.1c6.69-0.55,13,3.28,15.94,8.82c0.92,1.74,2.85,3.4,4.7,4.1c4.5,1.69,5.49,3.37,3.47,7.98
                    c-1.51,3.44-3.62,6.68-4.59,10.25c-0.59,2.17-0.28,6.16,1.01,6.9c1.84,1.04,5.64,0.75,7.37-0.58c3.84-2.95,6.99-6.83,10.24-10.49
                    c1.28-1.44,2.06-3.32,3.07-4.99c0.55,0.09,1.1,0.17,1.64,0.26c0.19,3.24,0.55,6.49,0.55,9.73c-0.02,7.7-0.59,15.42-0.16,23.09
                    c0.18,3.2,1.54,7.07,3.73,9.29c6.12,6.23,7.09,13.83,7.47,21.79c0.37,7.71,0.66,15.43,0.95,23.14c0.12,3.25,1.11,5.55,4.82,6.17
                    c1.25,0.21,3.04,1.64,3.25,2.76c0.98,5.12,1.18,10.34-2.51,14.67c-2.96,3.46-7.12,4.6-10.5,1.67c-4.85-4.21-9.27-8.6-16.89-6.88
                    c-0.1-0.57-0.21-1.13-0.31-1.7c6.26-8.27,12.53-16.54,18.79-24.81c-0.44-0.54-0.88-1.09-1.32-1.63
                    c-4.14,1.72-8.59,2.95-12.37,5.27c-8.51,5.21-17.1,7.2-26.88,3.92c-3.05-1.02-6.74-0.16-10.13-0.16
                    c-0.12,0.68-0.23,1.35-0.35,2.03c2.17,1.52,4.19,3.31,6.53,4.49c2.67,1.34,3.86,2.93,2.07,5.58c-2.6,3.85-0.46,5.85,2.59,7.83
                    c3.3,2.14,6.74,4.2,9.58,6.86c5.02,4.7,3.88,8.75-2.6,10.9c-3.41,1.13-6.85,2.22-10.13,3.67c-6.23,2.74-11.7,5.27-19.33,2.16
                    c-8.91-3.64-12.34-1.02-16.54,7.73c-1.26,2.63-2.57,5.23-3.88,7.83c-1.66,3.29-4.12,4.89-7.99,5.5c-3.1,0.49-6.85,2.13-8.68,4.51
                    c-7.84,10.17-15.23,20.63-17.36,33.92c-0.47,2.92-3.31,5.91-5.8,7.96c-6.07,5.01-12.87,9.15-18.85,14.26
                    c-9.23,7.9-9.85,12.53-5.54,23.97c2.1,5.56,3.57,11.41,4.77,17.24c0.38,1.84-1.04,4.04-1.64,6.08c-1.85-0.97-4.49-1.46-5.41-2.99
                    c-3.4-5.69-6.59-11.56-9.18-17.66c-3.5-8.22-13.22-15.26-21.65-14.2c-2.78,0.35-6.15,1.89-7.79,4.02
                    c-2.82,3.67-6.12,4.58-10.26,4.45c-5.66-0.18-11.32-0.51-16.97-0.36c-7.09,0.19-12.6,3.28-15.34,10.12
                    c-5.44,13.6-8.53,27.58-4.98,42.18c2.76,11.39,11.04,17.79,22.63,18.13c5.55,0.16,9.4-2.4,11.91-7.35
                    c5.42-10.7,6.43-11.34,18.92-13.19c0.99,6.42,0.35,12.56-2.75,18.35c-0.97,1.81-2.09,3.54-3.04,5.37
                    c-3.01,5.81-1.85,8.75,4.6,9.87c6.55,1.15,13.23,1.57,20.31,2.36c0.51,6.02,1.06,12.14,1.52,18.25
                    c0.98,13.04,3.39,15.5,16.48,16.67c1.54,0.14,3.11,0.04,4.62,0.3c13.51,2.39,24.81-0.4,32.48-12.93c2.33-3.8,6.14-4.44,10.44-2.82
                    c3.03,1.14,6.36,1.48,9.38,2.63c2.62,0.99,5.62,2.08,7.35,4.08c2.82,3.26,5.98,4.33,9.98,4c4.1-0.34,8.31-0.21,12.28-1.12
                    c4.52-1.04,8.83-3,13.71-4.72c-0.01-0.01,0.7,0.59,0.87,1.32c4.14,17.3,17.66,24.16,32.81,28.02
                    c12.7,3.24,20.19,11.17,25.79,22.16c1.69,3.32,3.93,7.21,6.98,8.83c12.81,6.81,25.62,13.46,40.58,15.23
                    c12.03,1.42,22.13,8.02,30.63,16.68C473.78,730.44,476.1,733.83,474.07,738.16z M308.57,595.55c-3.67,0.65-6.72,1.19-10.4,1.84
                    C303.58,591.19,305.2,590.85,308.57,595.55z M241.83,582.69c-7.5-7.62-16.35-10.91-26.84-10.64c-1.76,0.05-3.54-0.63-5.31-0.98
                    c-0.03-0.51-0.06-1.02-0.09-1.53c6.66-3.38,13.49-6.79,21.19-3.33c9.83,4.41,19.46,9.3,29.13,14.08c1.25,0.62,2.24,1.78,3.49,2.79
                    C257.74,587.12,246.24,587.18,241.83,582.69z M249.72,598.96c-2.14,0.46-4.28,0.99-6.44,1.23c-0.17,0.02-0.57-1.92-0.86-2.95
                    c2.23-0.41,4.46-0.81,6.69-1.22C249.31,597,249.52,597.98,249.72,598.96z M251.36,554.56c0.42-0.26,0.84-0.51,1.27-0.77
                    c4.24,2.2,5.55,6.23,6.34,10.59c-0.45,0.21-0.89,0.41-1.34,0.62C255.54,561.52,253.45,558.04,251.36,554.56z M293.62,595.06
                    c-11.58,0.51-22.63,5.06-33.86-0.01C263.87,584.14,286.11,581.99,293.62,595.06z M519.98,238.68c-1.14,0.04-2.38,0.5-3.37,1.09
                    c-9.54,5.69-19.11,11.31-28.51,17.23c-6.94,4.37-12.79,9.22-16.27,17.61c-3.94,9.49-10.8,17.75-16.29,26.61
                    c-1.63,2.64-3.45,2.32-5.54,0.84c-5.76-4.07-10.94-9.07-11.76-16.21c-1.56-13.57-3.05-27.28-2.73-40.88
                    c0.14-5.89,4.46-11.9,7.64-17.42c1.91-3.32,5.2-5.89,8.01-8.66c2.82-2.78,3.36-5.05,0.84-8.83c-1.88-2.82-2.42-7.1-2.15-10.62
                    c0.29-3.81,1.81-7.69,3.51-11.19c6.47-13.37,7.85-27.59,7.39-42.11c-0.26-8.11-6.05-12.71-14.1-11.63
                    c-2.86,0.38-5.72,0.81-9.16,1.3c-0.37-7.83-0.57-14.7,4.99-20.79c2.44-2.67,3.21-7.09,4.13-10.87c1-4.12,3.05-6.65,7.24-7.65
                    c4.66-1.12,9.41-2.16,13.79-3.99c2.59-1.08,5.24-3.25,6.66-5.64c2.38-4.01,3.81-8.6,6.03-13.89c-2.59,1.13-4.76,2.01-6.87,3.02
                    c-5.06,2.41-10.37,4.44-15.05,7.44c-5.24,3.37-10.05,7.48-14.76,11.59c-2.81,2.45-4.71,6.03-7.66,8.24
                    c-7.62,5.72-14.33,12.07-19.47,20.23c-3.22,5.11-6.05,4.93-10.98,1.17c-1.71-1.3-3.97-1.86-5.88-2.93
                    c-3.13-1.76-7.16-3.03-8.9-5.73c-1.04-1.62,0.94-6,2.64-8.37c2.05-2.85,5.24-4.88,7.93-7.28c2.53-2.26,5.04-4.55,8.56-7.72
                    c-4.59,0.41-7.96,0.71-12.31,1.1c4.51-10.79,8.12-21.08,15.15-29.59c1.49-1.8,4.25-2.94,6.63-3.57
                    c27.24-7.18,54.91-11.82,83.02-13.83c0.34-0.02,0.69-0.04,1.03-0.04c9.02,0.04,10.06,1.55,6.17,9.57c2.32,0,4.5,0.23,6.61-0.05
                    c4.65-0.62,8.85-0.81,8.66,6.21c0.45,0.05,0.89,0.09,1.34,0.14c2-2.88,4-5.76,6-8.63c0.09,0.44,0.17,0.88,0.26,1.32
                    c3.34-0.48,6.74-0.7,10-1.51c2.29-0.57,4.51-1.71,6.53-2.96c10.77-6.67,22.3-5.9,33.84-3.22c3.33,0.77,6.67,2.82,9.29,5.1
                    c6.25,5.45,6.12,8.9,0.56,14.99c-1.19,1.3-1.81,3.12-2.69,4.71c1.77,0.81,3.49,2.16,5.32,2.31c3.74,0.3,7.53-0.03,11.29-0.12
                    c3.62-0.09,5.62,2,4.2,5.09c-1.69,3.68-4.44,6.96-7.11,10.09c-1.92,2.25-5.14,3.56-6.62,6c-7.09,11.63-11.91,24.02-14.08,37.72
                    c-1.88,11.87-5.67,23.45-8.96,35.06c-1.4,4.94-4.49,8.61-9.79,10.39c-4.97,1.67-7.4,9.92-3.8,15.55
                    c6.22,9.72,2.76,17.19-4.02,24.38C546.43,229.35,535.48,238.17,519.98,238.68z M601.33,369.23c-4.97,5.15-8.98,4.02-10.23-2.95
                    c-0.8-4.49-0.72-9.17-0.6-13.76c0.04-1.45,1.15-3.55,2.37-4.18c4.18-2.13,8.62-3.74,14.16-6.04
                    C607.97,353,608.32,361.99,601.33,369.23z M548.2,270.33c-1.48-5.57-2.36-11.33-3.05-17.07c-0.33-2.73,1.2-4.53,4.39-4.24
                    c10.71,0.98,21.42,1.9,32.65,2.9c0.04,0.81,0.08,1.66,0.13,2.51c1.17,20.24-13.73,25.57-29.34,20
                    C551.09,273.75,548.65,272.03,548.2,270.33z M884,760.71c-5.78,17.33-11.98,34.52-18.1,51.73c-1.63,4.58-4.72,7.04-9.87,5.93
                    c-4.56-0.98-11.31-7.31-11.25-10.91c0.02-1.44,0.83-2.92,1.5-4.27c2.05-4.14,4.42-8.12,6.27-12.34
                    c3.06-6.99,5.78-13.51,12.87-18.32c5.62-3.81,8.95-11.03,13.18-16.83c2.09-2.87,3.91-5.94,5.85-8.91c0.51,0.15,1.02,0.3,1.53,0.45
                    C885.35,751.75,885.41,756.48,884,760.71z M1025.77,608.5c-4.93-13.96-9.65-27.4-14.43-40.82c-2.62-7.35-6.43-13.25-15.42-13.67
                    c-1.3-0.06-2.72-2.43-3.65-3.99c-1.38-2.31-1.83-5.36-3.61-7.2c-9.3-9.64-20.08-16.56-34.13-16.57c-4.63,0-9.26,0.01-13.9,0
                    c-5.31-0.02-10.37-0.27-15.3-3.22c-2.86-1.72-6.92-1.74-10.47-1.92c-10.27-0.52-19.53-3.19-26.99-10.79
                    c-1.59-1.62-4-2.44-6.03-3.63c-0.42,0.4-0.84,0.79-1.26,1.19c0.54,1.84,0.6,4.06,1.71,5.45c3.19,3.97,6.75,7.63,10.26,11.33
                    c2.1,2.22,5.51,3.93,6.32,6.5c1.69,5.37,5.12,4.84,8.88,3.87c3.3-0.86,6.44-2.34,9.7-3.39c4.26-1.38,7.33-0.25,10.09,3.66
                    c2.58,3.66,6.04,6.85,9.58,9.66c4.22,3.35,3.94,7.34,2.59,11.58c-2.58,8.12-7.73,14.67-14.33,19.7
                    c-17.52,13.33-36.06,24.96-57.42,31.33c-1.64,0.49-3.53,0.81-4.74,1.85c-0.01,0.01-0.02,0.02-0.04,0.03
                    c0.36-2.04-8.87-14.34-9.41-15.8c-2.8-7.61-5.61-15.3-9.37-22.47c-7.75-14.8-15.92-29.4-24.29-43.86
                    c-2.37-4.09-6.04-7.42-9.32-11.34c-4.15,4.71-4.17,9.06-2.68,13.11c1.81,4.93,4.4,9.59,6.83,14.28
                    c5.08,9.83,10.52,19.49,15.35,29.44c4.35,8.98,8.69,17.89,16.48,24.45c1.39,1.17,14.37,11.49,16.37,12.23
                    c-1.7,1.47-4.16,3.74-3.9,5.25c0.57,3.31,2.8,6.96,7.02,5.9c9.65-2.44,20.37-0.3,29.05-7.21c3.13-2.49,4.64,0.79,4.09,3.19
                    c-1.33,5.76-2.23,12.13-5.38,16.88c-9.35,14.12-19.38,27.83-29.76,41.21c-7.03,9.06-14.98,17.42-22.74,25.89
                    c-4.58,5.01-5.64,10.68-4.75,17.13c1.12,8.15,2.01,16.33,3.03,24.49c1.38,11.08-1.37,20.62-9.89,28.34
                    c-5.71,5.18-11.01,10.82-16.54,16.2c-3.52,3.43-4.84,7.18-2.9,12.05c2.89,7.23,0.63,12.99-4.74,18.55
                    c-3.91,4.06-6.75,9.27-9.55,14.27c-11.44,20.43-24.53,38.75-50.29,41.93c-2.88,0.36-5.81,0.61-8.7,0.49
                    c-5.09-0.21-7.91-2.96-9.22-8.03c-1.96-7.58-4.49-15.01-6.81-22.49c-2.74-8.83-5.53-17.64-8.27-26.46
                    c-2.64-8.5-5.05-17.07-7.89-25.5c-4.2-12.42-3.7-24.09,3.65-35.41c1.58-2.43,1.3-6.28,1.29-9.48c-0.01-4.07-1.35-8.25-0.79-12.19
                    c0.77-5.44-0.76-9.73-3.89-13.86c-4.24-5.6-8.65-11.1-12.53-16.95c-4.6-6.93-6.87-14.23-3.91-22.86
                    c1.43-4.17,0.77-9.12,0.74-13.71c-0.02-3.69-2.44-5.79-5.9-5.89c-8.94-0.28-16.57-3.98-23.85-8.64c-2.3-1.48-4.13-1.75-6.4,0.04
                    c-7.23,5.7-15.74,7.14-24.67,7.28c-9.09,0.15-18.18,0.71-27.26,0.71c-2.06,0-4.69-1.04-6.06-2.53c-4-4.35-7.99-8.23-13.93-10.07
                    c-2.23-0.69-3.83-3.64-5.55-5.7c-4.57-5.46-8.67-11.38-13.68-16.4c-4.59-4.61-6.75-9.88-6.89-16.05
                    c-0.2-8.74,0.07-17.5-0.05-26.24c-0.05-3.58-0.13-7.3-1.08-10.71c-1.54-5.55-0.4-10.45,2.03-15.36
                    c6.18-12.48,12.37-24.89,24.29-33.26c2.11-1.48,3.47-4.18,4.83-6.52c4.2-7.22,8.2-14.55,12.36-21.79
                    c1.28-2.22,2.96-4.22,4.22-6.45c1.2-2.11,2.07-3.99,2.59-5.71c5.79-2.17,19.73-7.2,22.77-8.98c18.39-10.76,38.55-9.2,58.57-8.91
                    c4.27,0.06,6.18,2.31,6.44,6.35c0.38,5.78,0.77,11.57,1.2,17.94c10.15-0.55,17.83,4.68,24.97,11.1c0.89,0.8,1.87,1.63,2.96,2.02
                    c3.09,1.11,6.25,1.99,9.39,2.96c0.32-2.95,0.98-5.91,0.89-8.85c-0.21-6.89,1.24-8.53,8-7.12c9.21,1.92,18.4,4.03,27.48,6.49
                    c13.56,3.67,25.76,1.5,36.69-7.41c6.53-5.31,8.84-16.12,4.94-23.75c-4.49,1.59-9,3.18-13.51,4.79
                    c-9.65,3.44-18.41,1.82-26.71-4.14c-6.57-4.72-10.78-10.68-12.82-18.56c-1.72-6.63-4.45-7.22-9.89-2.98
                    c-0.81,0.63-1.79,1.04-3.15,1.82c4.2,3.8,7.55,6.68,10.71,9.75c2.76,2.68,3.78,5.93,1.08,9.16c-3.02,3.62-15.12,1.89-17.98-1.9
                    c-3.18-4.2-6.73-8.21-10.59-11.79c-3.69-3.42-5.02-2.75-6.47,2.16c-2.01,6.84-6.04,12.3-11.87,16.27
                    c-3.42,2.33-9.29,0.34-10.89-3.53c-1.79-4.34,0.46-6.46,4.33-8.25c1.92-0.89,3.81-3.27,4.39-5.35c0.94-3.41,0.09-6.85-3.33-8.98
                    c-4.94-3.07-9.84-6.22-14.86-9.16c-3.94-2.3-7.93-5.35-12.19-0.2c-0.65,0.78-2.58,0.68-3.92,0.68c-3.77,0.03-7.58-0.44-11.3-0.06
                    c-2.94,0.3-6.79,0.68-8.48,2.58c-5.26,5.96-10.91,12.11-14.12,19.22c-3.55,7.88-8.34,13.53-15.85,17.28
                    c-1.62,0.81-11.29,4.87-16.45,7.18c0.56-4.32-1.6-7.66-7.12-11.34c-7.54-5.03-10.13-11.17-7.58-19.7
                    c0.29-0.98,0.43-2.01,0.67-3.01c4.06-17.03,5.3-18.13,22.55-20.2c2.89-0.35,5.77-0.76,8.64-1.26c4.37-0.76,6.91-3.64,8.11-7.69
                    c1.2-4.06-0.26-7.48-3.31-10.16c-2.31-2.03-4.8-3.89-7.36-5.6c-1.84-1.23-3.92-2.08-5.89-3.1c0.62-0.7,1.25-1.41,1.87-2.11
                    c0.76-0.34,1.52-1.01,2.27-0.99c10.95,0.3,18.04-6.72,24.97-13.58c1.89-1.88,1.99-5.5,3.14-8.23c0.93-2.19,1.76-4.71,3.41-6.26
                    c4.23-3.97,8.95-7.42,13.36-11.2c7.61-6.54,12.72-14.32,11.86-24.95c-0.11-1.32,0.34-2.71,0.68-4.03
                    c0.16-0.63,0.64-1.17,1.62-2.86c0.51,2.38,0.88,3.73,1.07,5.11c0.39,2.89,0.42,5.85,1.07,8.67c1.3,5.65,6.34,10.15,11.31,9.25
                    c9.24-1.68,18.44-3.76,27.47-6.33c6.41-1.82,9.83-6.6,9.64-13.54c-0.12-4.48,1.16-7.7,6.46-7.89c2.88-0.1,4.28-1.85,3.75-4.87
                    c-0.24-1.35-0.32-2.73-0.51-4.08c-0.84-6.12-0.19-6.83,5.9-7.31c3.92-0.31,7.87-0.67,11.68-1.55c1.55-0.35,2.78-2.12,4.15-3.25
                    c-1.37-1.09-2.59-2.8-4.13-3.14c-5.5-1.24-11.12-1.96-16.67-3c-2.35-0.44-4.71-0.98-6.94-1.81c-7.59-2.83-10.56-7.93-8.6-15.73
                    c1.33-5.29,3.73-10.3,5.22-15.56c1.07-3.79,3.49-8.89-1.44-10.97c-4.59-1.93-6.26,3.47-7.69,6.51
                    c-4.06,8.65-7.49,17.6-11.08,26.46c-1.88,4.63-1.36,9.07,1.61,13.16c4.46,6.15,5.81,11.89-0.03,18.47
                    c-2.91,3.28-3.98,8.26-5.67,12.55c-2.95,7.53-5.18,8.86-13.98,7.72c-0.39-9.15-3.46-17.38-9.28-25.23
                    c-2.83,2.53-5.07,4.86-7.63,6.75c-5.86,4.33-10.07,3.42-13.16-3.11c-1.82-3.83-3.1-7.97-4.18-12.09
                    c-2.22-8.39-0.14-15.44,7.76-19.8c13.26-7.31,19.1-19.65,22.98-33.3c1.73-6.09,2.83-12.35,4.27-18.52
                    c0.46-1.99,0.84-4.06,1.67-5.91c6.15-13.63,31.56-27.64,46.25-25.69c3.67,0.49,6.21,1.64,7.55,5.69c0.72,2.17,3.43,4.36,5.72,5.18
                    c4.8,1.71,9.87,2.92,14.93,3.61c9.49,1.28,15.98,6.16,20.43,14.53c2.4,4.51,5.64,8.57,8.51,12.83c0.81-0.35,1.62-0.7,2.43-1.05
                    c0-1.92,0.15-3.85-0.03-5.75c-0.45-4.76-1.35-9.5-1.44-14.26c-0.03-1.64,1.79-3.31,2.77-4.97c1.62,1.01,3.81,1.64,4.78,3.08
                    c4.69,6.97,5.2,7.15,11.62,1.68c2.61-2.23,5.12-4.57,7.77-6.74c2.28-1.87,4.54-3.58,7.86-1.6c0.99,0.59,2.91-0.21,4.35-0.58
                    c2.47-0.63,4.87-1.7,7.37-2.07c2.72-0.4,5.12-0.45,4.96-4.26c-0.15-3.65-0.9-6.52-5.26-6.69c-3.26-0.13-6.62,0.27-9.75-0.43
                    c-6.94-1.54-14.03-2.96-20.52-5.71c-4.92-2.09-4.98-7.76-5.21-12.62c-0.46-9.73-1.34-19.47-1.16-29.18
                    c0.09-4.94,1.77-9.97,3.38-14.74c1.07-3.18,2.9-6.6,7.57-4.29c5.12,2.54,6.31,4.81,3.82,9.71c-4.48,8.81-4.26,17.8-2.07,27.05
                    c2.21,9.35,7.52,16.49,16.49,19.62c8.82,3.08,18.08,5.06,27.29,6.84c5.27,1.02,6.68-1.38,4.24-6.2c-1.62-3.2-3.8-6.12-5.44-9.31
                    c-2.27-4.39-4.07-8.96-2.43-15.19c14.81,12.16,28.64,24.17,37.78,41.03c0.69-1.02,1.21-1.79,2.03-2.99
                    c50.57,53.4,87.17,114.99,109.57,184.81C1031.65,457.66,1037.32,532.42,1025.77,608.5z M675.89,438.08
                    c0.19-0.47,2.86-0.66,3.06-0.28c1.53,2.91,2.73,5.99,4.36,9.77c-1.57,2.44-2.96,4.62-4.36,6.8c-2.07-2.28-5.45-4.35-5.87-6.91
                    C672.6,444.54,674.63,441.14,675.89,438.08z"
                    />
                </g>
            </g>
        </svg>

        <!-- <svg width='200%' height='200%' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="ripple">
        <g>
            <circle cx="50" cy="50" r="44" stroke="#eeeeee" fill="none" stroke-width=".5" stroke-linecap="round" class="circ-1"></circle>
        </g>
        <g>
        <circle cx="50" cy="50" r="44" stroke="#eeeeee" fill="none" stroke-width=".5" stroke-linecap="round" class="circ-2"></circle>
            </g>
          </svg> -->

        <svg id="loading">
            <g>
                <path class="ld-l" fill="#67666D" d="M43.6,33.2h9.2V35H41.6V15.2h2V33.2z" />
                <path
                    class="ld-o"
                    fill="#67666D"
                    d="M74.7,25.1c0,1.5-0.3,2.9-0.8,4.2c-0.5,1.3-1.2,2.4-2.2,3.3c-0.9,0.9-2,1.6-3.3,2.2
                c-1.3,0.5-2.6,0.8-4.1,0.8s-2.8-0.3-4.1-0.8c-1.3-0.5-2.4-1.2-3.3-2.2s-1.6-2-2.2-3.3C54.3,28,54,26.6,54,25.1s0.3-2.9,0.8-4.2
                c0.5-1.3,1.2-2.4,2.2-3.3s2-1.6,3.3-2.2c1.3-0.5,2.6-0.8,4.1-0.8s2.8,0.3,4.1,0.8c1.3,0.5,2.4,1.2,3.3,2.2c0.9,0.9,1.6,2,2.2,3.3
                C74.4,22.2,74.7,23.6,74.7,25.1z M72.5,25.1c0-1.2-0.2-2.3-0.6-3.3c-0.4-1-0.9-2-1.6-2.8c-0.7-0.8-1.6-1.4-2.6-1.9
                c-1-0.5-2.2-0.7-3.4-0.7c-1.3,0-2.4,0.2-3.4,0.7c-1,0.5-1.9,1.1-2.6,1.9c-0.7,0.8-1.3,1.7-1.6,2.8c-0.4,1-0.6,2.1-0.6,3.3
                c0,1.2,0.2,2.3,0.6,3.3c0.4,1,0.9,2,1.6,2.7c0.7,0.8,1.6,1.4,2.6,1.9c1,0.5,2.2,0.7,3.4,0.7c1.3,0,2.4-0.2,3.4-0.7
                c1-0.5,1.9-1.1,2.6-1.9c0.7-0.8,1.3-1.7,1.6-2.7C72.4,27.4,72.5,26.3,72.5,25.1z"
                />
                <path
                    class="ld-a"
                    fill="#67666D"
                    d="M78.2,35H76l8.6-19.8h2L95.1,35h-2.2l-2.2-5.2H80.4L78.2,35z M81.1,27.9h8.7l-4.4-10.5L81.1,27.9z"
                />
                <path
                    class="ld-d"
                    fill="#67666D"
                    d="M98,15.2h6.6c1.2,0,2.5,0.2,3.7,0.6c1.2,0.4,2.4,1,3.4,1.9c1,0.8,1.8,1.9,2.4,3.1s0.9,2.7,0.9,4.3
                c0,1.7-0.3,3.1-0.9,4.3s-1.4,2.3-2.4,3.1c-1,0.8-2.1,1.5-3.4,1.9c-1.2,0.4-2.5,0.6-3.7,0.6H98V15.2z M100,33.2h4
                c1.5,0,2.8-0.2,3.9-0.7c1.1-0.5,2-1.1,2.8-1.8c0.7-0.8,1.3-1.6,1.6-2.6s0.5-2,0.5-3c0-1-0.2-2-0.5-3c-0.4-1-0.9-1.8-1.6-2.6
                c-0.7-0.8-1.6-1.4-2.8-1.8c-1.1-0.5-2.4-0.7-3.9-0.7h-4V33.2z"
                />
                <path class="ld-i" fill="#67666D" d="M121.2,35h-2V15.2h2V35z" />
                <path
                    class="ld-n"
                    fill="#67666D"
                    d="M140.5,32.1L140.5,32.1l0.1-16.9h2V35h-2.5l-11.5-17.1h-0.1V35h-2V15.2h2.5L140.5,32.1z"
                />
                <path
                    class="ld-g"
                    fill="#67666D"
                    d="M162.9,18.8c-0.7-0.7-1.5-1.3-2.5-1.7c-1-0.4-2-0.6-3.3-0.6c-1.3,0-2.4,0.2-3.4,0.7s-1.9,1.1-2.6,1.9
                c-0.7,0.8-1.3,1.7-1.6,2.8c-0.4,1-0.6,2.1-0.6,3.3c0,1.2,0.2,2.3,0.6,3.3c0.4,1,0.9,2,1.6,2.7c0.7,0.8,1.6,1.4,2.6,1.9
                s2.2,0.7,3.4,0.7c1.1,0,2.1-0.1,3.1-0.4c0.9-0.2,1.7-0.5,2.3-0.9v-6h-4.6v-1.8h6.6v9c-1.1,0.7-2.2,1.1-3.5,1.5
                c-1.3,0.3-2.5,0.5-3.9,0.5c-1.5,0-2.9-0.3-4.1-0.8s-2.4-1.2-3.3-2.2c-0.9-0.9-1.6-2-2.1-3.3s-0.8-2.7-0.8-4.2s0.3-2.9,0.8-4.2
                c0.5-1.3,1.2-2.4,2.2-3.3c0.9-0.9,2-1.6,3.3-2.2c1.3-0.5,2.6-0.8,4.1-0.8c1.6,0,3,0.2,4.1,0.7s2.2,1.1,3,2L162.9,18.8z"
                />
            </g>
        </svg>
    </div>
</div>
