import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BookmarkedReport } from '../shared/bookmarked-report.model';

/**  A sentinel ID used for a BookmarkedReport that is being optimistically saved */
export const ADDING_ID = -1;

export interface BookmarkState extends EntityState<BookmarkedReport> {
    error: Object | null;
    loading: boolean;
    deleting: BookmarkedReport;
}

export const bookmarkAdapter: EntityAdapter<BookmarkedReport> = createEntityAdapter<
    BookmarkedReport
>({ sortComparer: reportComparer });

export const initialBookmarkState: BookmarkState = bookmarkAdapter.getInitialState({
    error: null,
    loading: false,
    deleting: null,
});

function reportComparer(a: BookmarkedReport, b: BookmarkedReport): number {
    const [aDate, bDate] = [new Date(a.updated_at), new Date(b.updated_at)];
    if (aDate < bDate) {
        return -1;
    } else if (bDate < aDate) {
        return 1;
    }
    return 0;
}
