import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { MapboxStyles, Whitelabel } from './whitelabel.model';

@Injectable()
export class WhitelabelService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };

    constructor() {}

    getWhitelabel(): Observable<Whitelabel> {
        const fullDomain = window.location.hostname;
        if (fullDomain === 'localhost2') {
            return of({
                name: 'localhost',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#6E016B',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#EDF8FB',
                    '#BFD3E6',
                    '#9EBCDA',
                    '#8C96C6',
                    '#8C6BB1',
                    '#88419D',
                    '#6E016B',
                    '#E9E9EC',
                ],
                siteIconColors: ['#B63C8E', '#3C388E'],
                top3Colors: ['#B63C8E', '#3C388E', '#3EB6E9'],
                amColors: [
                    '#E3F1FC',
                    '#502582',
                    '#B63C8E',
                    '#3C388E',
                    '#1ED9CD',
                    '#931F1D',
                    '#E83151',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (
            fullDomain === 'app.hemisphere.digital' ||
            fullDomain === 'staging.hemisphere.digital' ||
            fullDomain === 'dev.hemisphere.digital' ||
            fullDomain === 'localhost'
        ) {
            return of({
                name: 'app.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#6E016B',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#EDF8FB',
                    '#BFD3E6',
                    '#9EBCDA',
                    '#8C96C6',
                    '#8C6BB1',
                    '#88419D',
                    '#6E016B',
                    '#E9E9EC',
                ],
                top3Colors: ['#B63C8E', '#3C388E', '#3EB6E9'],
                siteIconColors: ['#B63C8E', '#3C388E'],
                amColors: [
                    '#3EB6E9',
                    '#502582',
                    '#B63C8E',
                    '#3C388E',
                    '#1ED9CD',
                    '#931F1D',
                    '#E83151',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'nsw.hemisphere.digital') {
            return of({
                name: 'nsw.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#08589E',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#F0F9E8',
                    '#CCEBC5',
                    '#A8DDB5',
                    '#7BCCC4',
                    '#4EB3D3',
                    '#2B8CBE',
                    '#08589E',
                    '#E9E9EC',
                ],
                siteIconColors: ['#f7941e', '#006f00'],
                top3Colors: ['#003B5C', '#84BD00', '#ED8B00'],
                amColors: [
                    '#003B5C',
                    '#84BD00',
                    '#ED8B00',
                    '#59CBE8',
                    '#FAE100',
                    '#4A7729',
                    '#DC582A',
                    '#DA291C',
                    '#A4343A',
                    '#00AEC7',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'qms.hemisphere.digital') {
            return of({
                name: 'qms.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#FF197D',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#F1EEF6',
                    '#EAD6F4',
                    '#EABCEC',
                    '#F09FDC',
                    '#F77EC3',
                    '#FD57A4',
                    '#FF197D',
                    '#E9E9EC',
                ],
                siteIconColors: ['#f7941e', '#ea3a7d'],
                top3Colors: ['#EA3A7D', '#52B4F9', '#FAE24C'],
                amColors: [
                    '#EA3A7D',
                    '#52B4F9',
                    '#FAE24C',
                    '#8C4FF1',
                    '#5DCA73',
                    '#A5A5A5',
                    '#41D4FA',
                    '#75704E',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                    '#E9E9EC',
                ],
            });
        } else if (fullDomain === 'fonto.hemisphere.digital') {
            return of({
                name: 'fonto.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#08589E',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#F0F9E8',
                    '#CCEBC5',
                    '#A8DDB5',
                    '#7BCCC4',
                    '#4EB3D3',
                    '#2B8CBE',
                    '#08589E',
                    '#E9E9EC',
                ],
                siteIconColors: ['#f7941e', '#06c0a6'],
                top3Colors: ['#06c0a6', '#4EB3D3', '#CCEBC5'],
                amColors: [
                    '#F0F9E8',
                    '#CCEBC5',
                    '#A8DDB5',
                    '#7BCCC4',
                    '#4EB3D3',
                    '#2B8CBE',
                    '#08589E',
                    '#DA291C',
                    '#A4343A',
                    '#00AEC7',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'ttf.hemisphere.digital') {
            return of({
                name: 'ttf.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#E31B23',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#111111',
                siteIconColors: ['#f7941e', '#006f00'],
                top3Colors: ['#9d2d1f', '#EAAB0A', '#f2633b'],
                mapboxColors: [
                    '#FFFFCC',
                    '#F5E49F',
                    '#F0C776',
                    '#EDA752',
                    '#EB8436',
                    '#E85C26',
                    '#E31B23',
                    '#E9E9EC',
                ],
                amColors: [
                    '#D52B1E',
                    '#E17000',
                    '#EAAB0A',
                    '#5F5D5C',
                    '#AA272F',
                    '#000000',
                    '#e6e0d9',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'visa.hemisphere.digital') {
            return of({
                name: 'visa.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#091D49',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                mapboxColors: [
                    '#FFFDF7',
                    '#DFD1C6',
                    '#C3A59D',
                    '#A57B7F',
                    '#7F546A',
                    '#50345A',
                    '#091D49',
                    '#E9E9EC',
                ],
                siteIconColors: ['#f7941e', '#006f00'],
                top3Colors: ['#10357f', '#394b9b', '#f2633b'],
                amColors: [
                    '#10357f',
                    '#394b9b',
                    '#8687be',
                    '#f18a21',
                    '#ffc709',
                    '#55565a',
                    '#808285',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'au.hemisphere.digital') {
            return of({
                name: 'au.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#fbc834',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#1f497d', '#006f00', '#ffc000'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#1f497d',
                    '#006f00',
                    '#ffc000',
                    '#ff0000',
                    '#7436a2',
                    '#996633',
                    '#00b0f0',
                    '#E9E9EC',
                ],
                amColors: [
                    '#1f497d',
                    '#006f00',
                    '#ffc000',
                    '#ff0000',
                    '#7436a2',
                    '#996633',
                    '#00b0f0',
                    '#aa4298',
                    '#ffadef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'brg.hemisphere.digital') {
            return of({
                name: 'brg.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#B32442',
                mapboxNoRightsColor: '#F7F5EB',
                mapLineColor: '#111111',
                top3Colors: ['#00263D', '#B32442', '#004F42'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#00263D',
                    '#B32442',
                    '#004F42',
                    '#FF8933',
                    '#303030',
                    '#C0C0C0',
                    '#6E7844',
                    '#E9E9EC',
                ],
                amColors: [
                    '#00263D',
                    '#B32442',
                    '#004F42',
                    '#FF8933',
                    '#303030',
                    '#C0C0C0',
                    '#6E7844',
                    '#221F1F',
                    '#4B7389',
                    '#D4972A',
                    '#104131',
                    '#A14128',
                    '#61666A',
                    '#8B3F66',
                    '#6E7844',
                    '#3E2022',
                    '#8B2234',
                    '#18312E',
                    '#998060',
                    '#005B66',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'act.hemisphere.digital') {
            return of({
                name: 'act.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#472d8c',
                mapboxNoRightsColor: '#e9e9ec',
                mapLineColor: '#111111',
                top3Colors: ['#472d8c', '#aa4298', '#59CBE8'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#472d8c',
                    '#aa4298',
                    '#00adef',
                    '#404041',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#E9E9EC',
                ],
                amColors: [
                    '#472d8c',
                    '#aa4298',
                    '#00adef',
                    '#404041',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'dbca.hemisphere.digital') {
            return of({
                name: 'dbca.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#D14210',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#2D2F32', '#00a8b4', '#59CBE8'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#2D2F32',
                    '#00a8b4',
                    '#266093',
                    '#fdc82f',
                    '#D14210',
                    '#9fc13a',
                    '#CCCCCC',
                    '#E9E9EC',
                ],
                amColors: [
                    '#2D2F32',
                    '#00a8b4',
                    '#266093',
                    '#fdc82f',
                    '#D14210',
                    '#9fc13a',
                    '#CCCCCC',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (
            fullDomain === 'gfk.hemisphere.digital' ||
            fullDomain === 'gfk-demo.hemisphere.digital'
        ) {
            return of({
                name: 'gfk.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#E55A00',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#111111',
                top3Colors: ['#9d2d1f', '#E55A00', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFFDF7',
                    '#F6E7C9',
                    '#F0D09D',
                    '#ECB673',
                    '#E99B4C',
                    '#E77D29',
                    '#E55A00',
                    '#E9E9EC',
                ],
                amColors: [
                    '#E55A00',
                    '#E17000',
                    '#EAAB0A',
                    '#5F5D5C',
                    '#AA272F',
                    '#000000',
                    '#e6e0d9',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'ihg.hemisphere.digital') {
            return of({
                name: 'ihg.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#1E4456',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#111111',
                top3Colors: ['#1E4456', '#84bd00', '#E8542C'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#1E4456',
                    '#84BD00',
                    '#E8542C',
                    '#6E8A97',
                    '#FAE100',
                    '#DC582A',
                    '#00AEC7',
                    '#E9E9EC',
                ],
                amColors: [
                    '#1E4456',
                    '#84BD00',
                    '#E8542C',
                    '#6E8A97',
                    '#FAE100',
                    '#4A7729',
                    '#DC582A',
                    '#DA291C',
                    '#A4343A',
                    '#00AEC7',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'kantar.hemisphere.digital') {
            return of({
                name: 'ihg.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#00b2a9',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#201547', '#0072ce', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#201547',
                    '#0072ce',
                    '#0090da',
                    '#009ca6',
                    '#00b140',
                    '#cedc00',
                    '#f3e500',
                    '#E9E9EC',
                ],
                amColors: [
                    '#201547',
                    '#0072ce',
                    '#0090da',
                    '#009ca6',
                    '#00b140',
                    '#cedc00',
                    '#f3e500',
                    '#BFAD09',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'nt.hemisphere.digital') {
            return of({
                name: 'nt.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#EE1E60',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#1a1a4e', '#f7941e', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#1a1a4e',
                    '#f7941e',
                    '#7c7831',
                    '#9f986a',
                    '#c0baa0',
                    '#7691a2',
                    '#EE1E60',
                    '#E9E9EC',
                ],
                amColors: [
                    '#1a1a4e',
                    '#f7941e',
                    '#7c7831',
                    '#9f986a',
                    '#c0baa0',
                    '#7691a2',
                    '#EE1E60',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'qld.hemisphere.digital') {
            return of({
                name: 'qld.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#00aca9',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#00aca9', '#fdd43c', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#00aca9',
                    '#fdd43c',
                    '#e83947',
                    '#9d4f84',
                    '#e7d9b6',
                    '#315ca8',
                    '#b0bec7',
                    '#E9E9EC',
                ],
                amColors: [
                    '#00aca9',
                    '#fdd43c',
                    '#e83947',
                    '#9d4f84',
                    '#e7d9b6',
                    '#315ca8',
                    '#b0bec7',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'sa.hemisphere.digital') {
            return of({
                name: 'sa.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#cf0010',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#9d2d1f', '#d52b1c', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#000000',
                    '#5F5D5C',
                    '#A5A1A1',
                    '#EAAB0A',
                    '#E17000',
                    '#D52B1E',
                    '#AA272F',
                    '#E9E9EC',
                ],
                amColors: [
                    '#D52B1E',
                    '#E17000',
                    '#EAAB0A',
                    '#5F5D5C',
                    '#AA272F',
                    '#000000',
                    '#e6e0d9',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'tas.hemisphere.digital') {
            return of({
                name: 'tas.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#045c67',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#045c67', '#D4972A', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#045c67',
                    '#D4972A',
                    '#104131',
                    '#A14128',
                    '#61666A',
                    '#8B3F66',
                    '#6E7844',
                    '#E9E9EC',
                ],
                amColors: [
                    '#045c67',
                    '#D4972A',
                    '#54612B',
                    '#833921',
                    '#002537',
                    '#705741',
                    '#652140',
                    '#221F1F',
                    '#4B7389',
                    '#D4972A',
                    '#104131',
                    '#A14128',
                    '#61666A',
                    '#8B3F66',
                    '#6E7844',
                    '#3E2022',
                    '#8B2234',
                    '#18312E',
                    '#998060',
                    '#005B66',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'veraset.hemisphere.digital') {
            return of({
                name: 'veraset.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#045c67',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#045c67', '#D4972A', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#045c67',
                    '#D4972A',
                    '#104131',
                    '#A14128',
                    '#61666A',
                    '#8B3F66',
                    '#6E7844',
                    '#E9E9EC',
                ],
                amColors: [
                    '#045c67',
                    '#D4972A',
                    '#54612B',
                    '#833921',
                    '#002537',
                    '#705741',
                    '#652140',
                    '#221F1F',
                    '#4B7389',
                    '#D4972A',
                    '#104131',
                    '#A14128',
                    '#61666A',
                    '#8B3F66',
                    '#6E7844',
                    '#3E2022',
                    '#8B2234',
                    '#18312E',
                    '#998060',
                    '#005B66',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'vic.hemisphere.digital') {
            return of({
                name: 'vic.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#00b2a9',
                mapboxNoRightsColor: '#0072ce',
                mapLineColor: '#111111',
                top3Colors: ['#201547', '#D4972A', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#201547',
                    '#0072ce',
                    '#0090da',
                    '#009ca6',
                    '#00b140',
                    '#cedc00',
                    '#f3e500',
                    '#E9E9EC',
                ],
                amColors: [
                    '#201547',
                    '#0072ce',
                    '#0090da',
                    '#009ca6',
                    '#00b140',
                    '#cedc00',
                    '#f3e500',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'vtic.hemisphere.digital') {
            return of({
                name: 'vtic.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#EE1E60',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#CE0026', '#72148B', '#258BA8'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#CB0129',
                    '#930D67',
                    '#6C1383',
                    '#490C59',
                    '#930D67',
                    '#D41294',
                    '#AC8DFF',
                    '#F58BA8',
                    '#E9E9EC',
                ],
                amColors: [
                    '#CB0129',
                    '#930D67',
                    '#6C1383',
                    '#5A106E',
                    '#490C59',
                    '#930D67',
                    '#D41294',
                    '#AC8DFF',
                    '#F58BA8',
                    '#ACFDFF',
                    '#275A9E',
                    '#c0BC97',
                    '#658AF2',
                    '#ACaDFF',
                    '#F05C97',
                    '#AC6DFF',
                    '#B81081',
                    '#F215A9',
                    '#416ABA',
                    '#7A91FF',
                    '#F0BC97',
                    '#FF56B2',
                    '#5C7CD9',
                    '#F02397',
                    '#F07C97',
                    '#B58BA8',
                    '#ACcDFF',
                ],
            });
        } else if (fullDomain === 'wa.hemisphere.digital') {
            return of({
                name: 'wa.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#00aea5',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#00b8aa', '#37464a', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#00b8aa',
                    '#37464a',
                    '#fc625e',
                    '#f2c80f',
                    '#5f6b6d',
                    '#0073bb',
                    '#61903f',
                    '#E9E9EC',
                ],
                amColors: [
                    '#00b8aa',
                    '#37464a',
                    '#fc625e',
                    '#f2c80f',
                    '#5f6b6d',
                    '#0073bb',
                    '#61903f',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'waitoc.hemisphere.digital') {
            return of({
                name: 'waitoc.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#9C1C1F',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#DC572C', '#9C1C1F', '#F8D419'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFFDF7',
                    '#EADCC6',
                    '#D9BB98',
                    '#CA986F',
                    '#BC744C',
                    '#AD4D31',
                    '#9C1C1F',
                    '#E9E9EC',
                ],
                amColors: [
                    '#DC572C',
                    '#9C1C1F',
                    '#F8D419',
                    '#434343',
                    '#FAF8F4',
                    '#63722F',
                    '#111111',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'wejugo.hemisphere.digital') {
            return of({
                name: 'wejugo.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#2b6b36',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#121424', '#b8b0ab', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#fffdf7',
                    '#e4e2d0',
                    '#c5c8aa',
                    '#a3af88',
                    '#7f9869',
                    '#58814d',
                    '#2b6b36',
                    '#E9E9EC',
                ],
                amColors: [
                    '#121424',
                    '#b8b0ab',
                    '#f2633b',
                    '#9ccc5e',
                    '#4fb54d',
                    '#218f3d',
                    '#2b6b36',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'qtic.hemisphere.digital') {
            return of({
                name: 'qtic.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#F7941D',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#0C4DA2', '#006f00', '#F7941D'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#1f497d',
                    '#006f00',
                    '#ffc000',
                    '#ff0000',
                    '#7436a2',
                    '#996633',
                    '#00b0f0',
                    '#E9E9EC',
                ],
                amColors: [
                    '#0C4DA2',
                    '#F7941D',
                    '#f2633b',
                    '#9ccc5e',
                    '#4fb54d',
                    '#218f3d',
                    '#2b6b36',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'cop.hemisphere.digital') {
            return of({
                name: 'cop.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#8C9408',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#8C9408', '#C4C94A', '#FFD13D'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#8C9408',
                    '#C4C94A',
                    '#FFD13D',
                    '#FCEDAB',
                    '#947861',
                    '#F24A4F',
                    '#D12938',
                    '#E9E9EC',
                ],
                amColors: [
                    '#455794',
                    '#567EC0',
                    '#8C9408',
                    '#C4C94A',
                    '#FFD13D',
                    '#FCEDAB',
                    '#947861',
                    '#E6DBDB',
                    '#DBA1E3',
                    '#F24A4F',
                    '#D12938',
                    '#0C4DA2',
                    '#F7941D',
                    '#f2633b',
                    '#9ccc5e',
                    '#4fb54d',
                    '#218f3d',
                    '#2b6b36',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                ],
            });
        } else if (fullDomain === 'demo.hemisphere.digital') {
            return of({
                name: 'demo.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#08589E',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#F0F9E8',
                    '#CCEBC5',
                    '#A8DDB5',
                    '#7BCCC4',
                    '#4EB3D3',
                    '#2B8CBE',
                    '#08589E',
                    '#E9E9EC',
                ],
                top3Colors: ['#003B5C', '#84BD00', '#ED8B00'],
                siteIconColors: ['#f7941e', '#006f00'],
                amColors: [
                    '#84BD00',
                    '#502582',
                    '#ED8B00',
                    '#FAE100',
                    '#1ed9cd',
                    '#003B5C',
                    '#e83151',
                    '#111111',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'klc.hemisphere.digital') {
            return of({
                name: 'klc.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#FFBF00',
                mapboxNoRightsColor: '#C91D1D',
                mapLineColor: '#111111',
                top3Colors: ['#F7941E', '#C91D1D', '#333333'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFBF00',
                    '#C91D1D',
                    '#333333',
                    '#eaab0a',
                    '#E17000',
                    '#e9e9ec',
                    '#aa272f',
                    '#111111',
                    '#E9E9EC',
                ],
                amColors: [
                    '#FFBF00',
                    '#C91D1D',
                    '#333333',
                    '#eaab0a',
                    '#E17000',
                    '#e9e9ec',
                    '#aa272f',
                    '#111111',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'whitelabel.hemisphere.digital') {
            return of({
                name: 'whitelabel.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#3EB6E9',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#000000',
                top3Colors: ['#3EB6E9', '#502582', '#3C388E'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#3eb6e9',
                    '#502582',
                    '#b63c8e',
                    '#3c388e',
                    '#1ed9cd',
                    '#931f1d',
                    '#e83151',
                    '#E9E9EC',
                ],
                amColors: [
                    '#E83151',
                    '#F7931E',
                    '#B63C8E',
                    '#3C388E',
                    '#1ED9CD',
                    '#931F1D',
                    '#E83151',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'valmorgan.hemisphere.digital') {
            return of({
                name: 'valmorgan.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#999999',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#1C1C32',
                top3Colors: ['#35017F', '#FEFF1F', '#69E300'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#999999',
                    '#888888',
                    '#777777',
                    '#666666',
                    '#444444',
                    '#222222',
                    '#000000',
                    '#E9E9EC',
                ],
                amColors: [
                    '#35017F',
                    '#FEFF1F',
                    '#69E300',
                    '#9ECAC9',
                    '#9EB23F',
                    '#8616B6',
                    '#C9AB6F',
                    '#931F1D',
                    '#63758A',
                    '#252B33',
                    '#DF73E3',
                    '#FFD88D',
                    '#E83151',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#A7C2E6',
                    '#2F6600',
                    '#3EB6E9',
                    '#60695C',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#FAF185',
                    '#75704E',
                    '#669581',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'amta.hemisphere.digital') {
            return of({
                name: 'amta.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#4CA7DC',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#000000',
                mapboxColors: [
                    '#F0F9E8',
                    '#CCEBC5',
                    '#A8DDB5',
                    '#7BCCC4',
                    '#4EB3D3',
                    '#2B8CBE',
                    '#08589E',
                    '#E9E9EC',
                ],
                top3Colors: ['#EEBC1C', '#22BDC6', '#264A69'],
                siteIconColors: ['#EEBC1C', '#4CA7DC'],
                amColors: [
                    '#EEBC1C',
                    '#22BDC6',
                    '#00AFE8',
                    '#0070af',
                    '#264A69',
                    '#262626',
                    '#DC582A',
                    '#DA291C',
                    '#A4343A',
                    '#00AEC7',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'kauriora.hemisphere.digital') {
            return of({
                name: 'kauriora.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#425625',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#1C1C32',
                top3Colors: ['#35017F', '#FEFF1F', '#69E300'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFFDF7',
                    '#E1DECF',
                    '#C2C1A9',
                    '#A3A485',
                    '#838963',
                    '#636F43',
                    '#425625',
                    '#E9E9EC',
                ],
                amColors: [
                    '#35017F',
                    '#FEFF1F',
                    '#69E300',
                    '#9ECAC9',
                    '#9EB23F',
                    '#8616B6',
                    '#C9AB6F',
                    '#931F1D',
                    '#63758A',
                    '#252B33',
                    '#DF73E3',
                    '#FFD88D',
                    '#E83151',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#A7C2E6',
                    '#2F6600',
                    '#3EB6E9',
                    '#60695C',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#FAF185',
                    '#75704E',
                    '#669581',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'optus.hemisphere.digital') {
            return of({
                name: 'optus.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#237E8A',
                mapboxNoRightsColor: '#ececec',
                mapLineColor: '#1C1C32',
                top3Colors: ['#237E8A', '#F9CC07', '#121424'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFFDF7',
                    '#b0edf6',
                    '#95d6df',
                    '#7bbfc9',
                    '#60a9b4',
                    '#44939f',
                    '#237e8a',
                    '#E9E9EC',
                ],
                amColors: [
                    '#121424',
                    '#b8b0ab',
                    '#f2633b',
                    '#9ccc5e',
                    '#4fb54d',
                    '#218f3d',
                    '#2b6b36',
                    '#00adef',
                    '#f26c23',
                    '#9fc13a',
                    '#dfcb21',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#06A5FE',
                    '#87A2B2',
                    '#A6D0D6',
                    '#C4D600',
                    '#BFAD09',
                    '#ACC09C',
                    '#CBE394',
                    '#F0A9A4',
                    '#CAB4D6',
                    '#500778',
                ],
            });
        } else if (fullDomain === 'vodafone.hemisphere.digital') {
            return of({
                name: 'vodafone.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#E61500',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#111111',
                top3Colors: ['#E61500', '#EAAB0A', '#f2633b'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#FFFFCC',
                    '#F5E49F',
                    '#F0C776',
                    '#EDA752',
                    '#EB8436',
                    '#E85C26',
                    '#E61500',
                    '#E9E9EC',
                ],
                amColors: [
                    '#E61500',
                    '#E17000',
                    '#EAAB0A',
                    '#5F5D5C',
                    '#AA272F',
                    '#000000',
                    '#e6e0d9',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'telstra.hemisphere.digital') {
            return of({
                name: 'telstra.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#0E54FE',
                mapboxNoRightsColor: '#808080',
                mapLineColor: '#111111',
                top3Colors: ['#0E54FE', '#F86449', '#F5ECE2'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#e4deff',
                    '#cec5ff',
                    '#b7adff',
                    '#9e96ff',
                    '#807fff',
                    '#5b69ff',
                    '#0e54fe',
                    '#E9E9EC',
                ],
                amColors: [
                    '#0E54FE',
                    '#F86449',
                    '#F5ECE2',
                    '#5F5D5C',
                    '#AA272F',
                    '#000000',
                    '#e6e0d9',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else if (fullDomain === 'hungryjacks.hemisphere.digital') {
            return of({
                name: 'hungryjacks.hemisphere.digital',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#d0021b',
                mapboxNoRightsColor: '#cecece',
                mapLineColor: '#111111',
                top3Colors: ['#d0021b', '#f6bc25', '#62a744'],
                siteIconColors: ['#d0021b', '#f6bc25'],
                mapboxColors: [
                    '#d0021b',
                    '#f6bc25',
                    '#62a744',
                    '#00B0F0',
                    '#9a9793',
                    '#945200',
                    '#4b4a48',
                    '#000000',
                ],
                amColors: [
                    '#d0021b',
                    '#f6bc25',
                    '#62a744',
                    '#00B0F0',
                    '#9a9793',
                    '#945200',
                    '#4b4a48',
                    '#000000',
                    '#CCD859',
                    '#F46036',
                    '#004F2D',
                    '#E3B505',
                    '#1C7C54',
                    '#F7E733',
                    '#1C1C32',
                    '#60695C',
                    '#BE7977',
                    '#F18397',
                    '#F8A086',
                    '#EED369',
                    '#FAF185',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        } else {
            return of({
                name: 'other',
                mapboxStyle: MapboxStyles.Hemisphere,
                mapboxSelectedColor: '#003B5C',
                mapboxNoRightsColor: '#ACA995',
                mapLineColor: '#111111',
                top3Colors: ['#003B5C', '#84BD00', '#ED8B00'],
                siteIconColors: ['#f7941e', '#006f00'],
                mapboxColors: [
                    '#003B5C',
                    '#84BD00',
                    '#ED8B00',
                    '#59CBE8',
                    '#FAE100',
                    '#DC582A',
                    '#4A7729',
                    '#111111',
                    '#E9E9EC',
                ],
                amColors: [
                    '#003B5C',
                    '#84BD00',
                    '#ED8B00',
                    '#59CBE8',
                    '#FAE100',
                    '#4A7729',
                    '#DC582A',
                    '#DA291C',
                    '#A4343A',
                    '#00AEC7',
                    '#021C2A',
                    '#004C97',
                    '#0A7D8E',
                    '#95E4F8',
                    '#4A7729',
                    '#FFC845',
                    '#F9D7A8',
                    '#F1BCA9',
                    '#EEA1CC',
                    '#D40F7D',
                    '#75704E',
                    '#E0E89B',
                    '#669581',
                    '#77B098',
                    '#ACA995',
                    '#C2BEC8',
                    '#D8CFE8',
                    '#EFDCED',
                    '#D6D3EB',
                    '#E3F1FC',
                    '#D2F7F5',
                ],
            });
        }
    }
}
