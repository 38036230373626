<div ngbDropdown placement="bottom-right" class="kt-header__topbar-item">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <span class="kt-header__topbar-icon">
            <i class="flaticon2-download-1"></i>
        </span>
    </div>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-anim">
        <ul class="kt-nav">
            <li class="kt-nav__section kt-nav__section--first">
                <span class="kt-nav__section-text">Export</span>
            </li>
            <li class="kt-nav__item" *ngIf="showPng">
                <a href="javascript:;" class="kt-nav__link nocaret" (click)="downloadPNG()" ngbDropdownToggle>
                    <i class="kt-nav__link-icon flaticon2-graphic-1"></i>
                    <span class="kt-nav__link-text">Save as PNG</span>
                </a>
            </li>
            <li class="kt-nav__item" *ngIf="showPdf">
                <a href="javascript:;" class="kt-nav__link nocaret" (click)="downloadPDF()" ngbDropdownToggle>
                    <i class="kt-nav__link-icon flaticon2-file-2"></i>
                    <span class="kt-nav__link-text">Save as PDF</span>
                </a>
            </li>
        </ul>
    </div>
</div>
<overlay
    message="This feature is currently not part of your subscription. Get in touch with our team to upgrade of find out more."
></overlay>
