import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { Measure } from 'app/views/partials/content/measures/shared/measure.model';
import { AggFunc } from '../cubes';

/**
 * Dataviz Definitions
 */
export interface DatavizConfig {
    boundaryType: string;
    boundaries: Boundary[];
    startDate?: string;
    endDate: string;
    schemaConfig: SchemaConfig;
    extras?: DatavizExtras;
}

export interface DatavizExtras {
    selectedMeasure?: Measure;
    [key: string]: any;
}

/**
 * Chart Dataviz Definitions
 */

export interface ChartDatavizConfig extends DatavizConfig {
    chartType: ChartType;
    controls: boolean;
    axes?: {
        horizontal?: {
            title: string[];
        };
        vertical?: {
            title: string;
        };
    };
}

export type ChartType = 'line' | 'bar' | 'table';

/**
 * Schema Definitions
 */

type SchemaOptionsFlags<T> = {
    [K in keyof T]?: T[K];
};

interface SchemaConfig {
    name: SchemaName;
    overrides?: SchemaOptionsFlags<SchemaOptions>;
}

export type SchemaName =
    | 'DEVICE-GPS'
    | 'DEVICE-GPS-SEGMENTED'
    | 'IFI-DOMESTIC'
    | 'IFI-DOMESTIC-SEGMENTED'
    | 'IFI-DOMESTIC-SEGMENTED-INDEX'
    | 'IFI-INTERNATIONAL'
    | 'IFI-INTERNATIONAL-INDEX'
    | 'EXPERIENCES-SALES'
    | 'EXPERIENCES-NPS'
    | 'EXPERIENCES-AVAILABILITY';

interface SchemaOptions {
    measures: Measure[];
    datasetConfig: DatasetConfig;
    cubeConfig: CubeConfig;
}

interface DatasetConfig {
    name: string;
    source: string;
}

export interface CubeConfig {
    index: string;
    keys?: string[];
    values?: CubeValue[];
}

export interface CubeValue {
    key: string;
    uniqueName?: string;
    aggregation: AggFunc;
}

const SchemaDict: { [key in SchemaName]: SchemaOptions } = {
    'DEVICE-GPS': {
        measures: [
            { uniqueName: 'unique_visits_local', title: 'Unique Visits (Local)', format: '#,###' },
            {
                uniqueName: 'unique_visits_visitor',
                title: 'Unique Visits (Visitor)',
                format: '#,###',
            },
            { uniqueName: 'local_ratio', title: 'Ratio (Local)', format: '#,###.##%' },
            { uniqueName: 'visitor_ratio', title: 'Ratio (Visitor)', format: '#,###.##%' },
            // {
            //     uniqueName: 'total_visits_local',
            //     title: 'Total Visits (Local)',
            //     format: '#,###',
            // },
            // {
            //     uniqueName: 'total_visits_visitor',
            //     title: 'Total Visits (Visitor)',
            //     format: '#,###',
            // },
        ],
        datasetConfig: {
            name: 'DEVICE_GPS',
            source: 'GPS / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'region:device-gps',
            values: [
                {
                    key: 'Local Ratio',
                    uniqueName: 'local_ratio',
                    aggregation: 'average',
                },
                {
                    key: 'Visitor Ratio',
                    uniqueName: 'visitor_ratio',
                    aggregation: 'average',
                },
                {
                    key: 'Resident Population',
                    uniqueName: 'resident_population',
                    aggregation: 'sum',
                },
                {
                    key: 'Total Visits Local',
                    uniqueName: 'total_visits_local',
                    aggregation: 'sum',
                },
                {
                    key: 'Total Visits Visitor',
                    uniqueName: 'total_visits_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Visits Local',
                    uniqueName: 'unique_visits_local',
                    aggregation: 'average',
                },
                {
                    key: 'Unique Visits Visitor',
                    uniqueName: 'unique_visits_visitor',
                    aggregation: 'average',
                },
            ],
        },
    },
    'DEVICE-GPS-SEGMENTED': {
        measures: [
            {
                uniqueName: 'unique_visitors',
                title: 'Unique Visitor Devices (Residents)',
                format: '#,###',
                segment: 'intra_region',
            },
            {
                uniqueName: 'unique_visitors',
                title: 'Unique Visitors Devices (Locals)',
                format: '#,###',
                segment: 'inter_region_less_than_50km',
            },
            {
                uniqueName: 'unique_visitors',
                title: 'Unique Visitors Devices (Intrastate)',
                format: '#,###',
                segment: 'intrastate_inter_region_greater_than_50km',
            },
            {
                uniqueName: 'unique_visitors',
                title: 'Unique Visitors Devices (Interstate)',
                format: '#,###',
                segment: 'interstate_greater_than_50km',
            },
        ],
        datasetConfig: {
            name: 'DEVICE_GPS',
            source: 'GPS / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:device-gps-segmented',
            values: [
                {
                    key: 'Unique Visitors',
                    uniqueName: 'unique_visitors',
                    aggregation: 'average',
                },
                {
                    key: 'Total Visits',
                    uniqueName: 'total_visits',
                    aggregation: 'sum',
                },
            ],
        },
    },
    'IFI-DOMESTIC': {
        measures: [
            {
                uniqueName: 'vol_tran_local',
                title: 'Volume (Local)',
                format: '#,###.',
            },
            {
                uniqueName: 'vol_tran_visitor',
                title: 'Volume (Visitor)',
                format: '#,###.',
            },
            {
                uniqueName: 'val_tran_local',
                title: 'Value (Local)',
                format: '$#,###.##',
            },
            {
                uniqueName: 'val_tran_visitor',
                title: 'Value (Visitor)',
                format: '$#,###.##',
            },
            {
                uniqueName: 'unique_count_local',
                title: 'Unique Count (Local)',
                format: '#,###.',
            },
            {
                uniqueName: 'unique_count_visitor',
                title: 'Unique Count (Visitor)',
                format: '#,###.',
            },
            {
                uniqueName: 'local_ratio',
                title: 'Local Ratio',
                format: '#,###.##%',
            },
            {
                uniqueName: 'visitor_ratio',
                title: 'Visitor Ratio',
                format: '#,###.##%',
            },
        ],
        datasetConfig: {
            name: 'IFI_DOMESTIC_TRANSACTION',
            source: 'Visa / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:ifi-domestic',
            values: [
                {
                    key: 'Local Ratio',
                    uniqueName: 'local_ratio',
                    aggregation: 'average',
                },
                {
                    key: 'Visitor Ratio',
                    uniqueName: 'visitor_ratio',
                    aggregation: 'average',
                },
                {
                    key: 'Resident Population',
                    uniqueName: 'resident_population',
                    aggregation: 'sum',
                },
                {
                    key: 'Volume of Transactions Local',
                    uniqueName: 'vol_tran_local',
                    aggregation: 'sum',
                },
                {
                    key: 'Volume of Transactions Visitor',
                    uniqueName: 'vol_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Value of Transactions Local',
                    uniqueName: 'val_tran_local',
                    aggregation: 'sum',
                },
                {
                    key: 'Value of Transactions Visitor',
                    uniqueName: 'val_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Customers Local',
                    uniqueName: 'unique_count_local',
                    aggregation: 'average',
                },
                {
                    key: 'Unique Customers Visitor',
                    uniqueName: 'unique_count_visitor',
                    aggregation: 'average',
                },
            ],
        },
    },
    'IFI-DOMESTIC-SEGMENTED': {
        measures: [
            // Value of Transactions
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure (Total)',
                segment: 'total',
            },
            // Volume of Transactions
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions (Total)',
                segment: 'total',
            },
            // Unique Counts
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders (Total)',
                segment: 'total',
            },
        ],
        datasetConfig: {
            name: 'IFI_DOMESTIC_TRANSACTION',
            source: 'Visa / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:ifi-domestic-segmented',
            values: [
                {
                    key: 'Value of Transactions',
                    uniqueName: 'val_tran',
                    aggregation: 'sum',
                },
                {
                    key: 'Volume of Transactions',
                    uniqueName: 'vol_tran',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Count of Cardholders',
                    uniqueName: 'unique_count',
                    aggregation: 'average',
                },
            ],
        },
    },
    'IFI-DOMESTIC-SEGMENTED-INDEX': {
        measures: [
            // Value of Transactions
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure Index (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure Index (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure Index (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure Index (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'val_tran',
                title: 'Domestic Expenditure Index (Total)',
                segment: 'total',
            },
            // Volume of Transactions
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions Index (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions Index (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions Index (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions Index (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'vol_tran',
                title: 'Volume of Transactions Index (Total)',
                segment: 'total',
            },
            // Unique Counts
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders Index (Residents)',
                segment: 'resident',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders Index (Locals)',
                segment: 'local',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders Index (Intrastate)',
                segment: 'intrastate',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders Index (Interstate)',
                segment: 'interstate',
            },
            {
                uniqueName: 'unique_count',
                title: 'Unique Count of Cardholders Index (Total)',
                segment: 'total',
            },
        ],
        datasetConfig: {
            name: 'IFI_DOMESTIC_TRANSACTION_INDEX',
            source: 'Visa / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:ifi-domestic-segmented-index',
            values: [
                {
                    key: 'Volume of Transactions',
                    uniqueName: 'vol_tran',
                    aggregation: 'sum',
                },
                {
                    key: 'Value of Transactions',
                    uniqueName: 'val_tran',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Count of Cardholders',
                    uniqueName: 'unique_count',
                    aggregation: 'average',
                },
            ],
        },
    },
    'IFI-INTERNATIONAL': {
        measures: [
            {
                uniqueName: 'vol_tran_visitor',
                title: 'Volume (Visitor)',
                format: '#,###.',
            },
            {
                uniqueName: 'val_tran_visitor',
                title: 'Value Expenditure (Total International)',
                format: '$#,###.##',
            },
            {
                uniqueName: 'unique_count_visitor',
                title: 'Unique Count (Visitor)',
                format: '#,###.',
            },
            // {
            //     uniqueName: 'visitor_ratio',
            //     title: 'Visitor Ratio',
            //     format: '#,###.##%',
            // },
        ],
        datasetConfig: {
            name: 'IFI_INTERNATIONAL_TRANSACTION',
            source: 'Visa / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:ifi-international',
            values: [
                // {
                //     key: 'Visitor Ratio',
                //     uniqueName: 'visitor_ratio',
                //     aggregation: 'average',
                // },
                {
                    key: 'Volume of Transactions Visitor',
                    uniqueName: 'vol_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Value of Transactions Visitor',
                    uniqueName: 'val_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Customers Visitor',
                    uniqueName: 'unique_count_visitor',
                    aggregation: 'average',
                },
            ],
        },
    },
    'IFI-INTERNATIONAL-INDEX': {
        measures: [
            {
                uniqueName: 'val_tran_visitor',
                title: 'Value Expenditure Index (Total International)',
            },
            {
                uniqueName: 'vol_tran_visitor',
                title: 'Volume Expenditure Index (Total International)',
            },
            {
                uniqueName: 'unique_count_visitor',
                title: 'Unique Count Index (Total International)',
            },
        ],
        datasetConfig: {
            name: 'IFI_INTERNATIONAL_TRANSACTION_INDEX',
            source: 'Visa / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'origin-dest:ifi-international-index',
            values: [
                {
                    key: 'Volume of Transactions Visitor',
                    uniqueName: 'vol_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Value of Transactions Visitor',
                    uniqueName: 'val_tran_visitor',
                    aggregation: 'sum',
                },
                {
                    key: 'Unique Customers Visitor',
                    uniqueName: 'unique_count_visitor',
                    aggregation: 'average',
                },
            ],
        },
    },
    'EXPERIENCES-SALES': {
        measures: [
            {
                uniqueName: 'total_gross_sales',
                title: 'Gross Sales',
            },
            {
                uniqueName: 'total_product_views',
                title: 'Product Views',
            },
            {
                uniqueName: 'total_product_added',
                title: 'Products Added',
            },
        ],
        datasetConfig: {
            name: 'EXPERIENCES',
            source: 'BRG / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'brg:sales',
            values: [
                {
                    key: 'Total Gross Sales Amount',
                    uniqueName: 'total_gross_sales',
                    aggregation: 'sum',
                },
                {
                    key: 'Total Product Views',
                    uniqueName: 'total_product_views',
                    aggregation: 'sum',
                },
                {
                    key: 'Total Product Added',
                    uniqueName: 'total_product_added',
                    aggregation: 'sum',
                },
            ],
        },
    },
    'EXPERIENCES-NPS': {
        measures: [
            // {
            //     uniqueName: 'star_ratings_stars_received',
            //     title: 'Star Ratings Stars Received',
            // },
            {
                uniqueName: 'product_nps_detractors',
                title: 'Product NPS Detractors',
            },
            {
                uniqueName: 'product_nps_passives',
                title: 'Product NPS Passives',
            },
            {
                uniqueName: 'product_nps_sum_promoters',
                title: 'Product NPS Sum Promoters',
            },
        ],
        datasetConfig: {
            name: 'EXPERIENCES',
            source: 'BRG / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'brg:nps',
            values: [
                {
                    key: 'Star Ratings Stars Received',

                    aggregation: 'sum',
                },
                {
                    key: 'Product NPS Sum Promoters',
                    uniqueName: 'product_nps_promoters',
                    aggregation: 'sum',
                },
                {
                    key: 'Product NPS Detractors',
                    uniqueName: 'product_nps_detractors',
                    aggregation: 'sum',
                },
                {
                    key: 'Product NPS Passives',
                    uniqueName: 'product_nps_passives',
                    aggregation: 'sum',
                },
            ],
        },
    },

    'EXPERIENCES-AVAILABILITY': {
        measures: [
            {
                uniqueName: 'available_seats_next_7_days',
                title: 'Available Seats Next 7 Days',
            },
            {
                uniqueName: 'available_seats_next_14_days',
                title: 'Available Seats Next 14 Days',
            },
            {
                uniqueName: 'available_seats_next_30_days',
                title: 'Available Seats Next 30 Days',
            },
            {
                uniqueName: 'available_seats_next_60_days',
                title: 'Available Seats Next 60 Days',
            },
        ],
        datasetConfig: {
            name: 'EXPERIENCES',
            source: 'BRG / Hemisphere Digital',
        },
        cubeConfig: {
            index: 'brg:availability',
            values: [
                {
                    key: 'Available Seats Next 7 Days',
                    uniqueName: 'available_seats_next_7_days',
                    aggregation: 'average',
                },
                {
                    key: 'Available Seats Next 14 Days',
                    uniqueName: 'available_seats_next_14_days',
                    aggregation: 'average',
                },
                {
                    key: 'Available Seats Next 30 Days',
                    uniqueName: 'available_seats_next_30_days',
                    aggregation: 'average',
                },
                {
                    key: 'Available Seats Next 60 Days',
                    uniqueName: 'available_seats_next_60_days',
                    aggregation: 'average',
                },
            ],
        },
    },
};

export function getSchemaOptions(name: SchemaName): SchemaOptions {
    return SchemaDict[name];
}

export const StateToCapital = {
    NSW: 'Sydney',
    VIC: 'Melbourne',
    SA: 'Adelaide',
    WA: 'Perth',
    ACT: 'Canberra',
    NT: 'Darwin',
    QLD: 'Brisbane',
    TAS: 'Hobart',
};

export function getCapitalCity(stateName: string, boundaryType: Level): string {
    if (boundaryType === 'dn' && stateName === 'NSW') {
        return 'Greater Sydney';
    }

    return StateToCapital[stateName];
}

export const InternationalBoundaryType = {
    Country: 'COUNTRY',
    Subregion: 'SUBREGION',
    Region: 'REGION',
};
