import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'assetsSource',
})
@Injectable({
    providedIn: 'root',
})
export class AssetsSourcePipe implements PipeTransform {
    private subDomain: string;

    // This will be the url to our assets server.
    // Ex: https://our-assets.com/assets
    private assetsURL: string;

    constructor() {
        this.subDomain = window.location.hostname;
    }

    transform(imgName: string): string {
        if (this.subDomain == 'demo.hemisphere.digital')
            return `https://storage.googleapis.com/whitelabel-hd/${this.subDomain}/${imgName}?v=${
                Math.floor(Math.random() * 100) + 1
            }`;
        else return `/assets/whitelabels/${this.subDomain}/${imgName}`;
    }
}
