import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';

@Component({
    selector: 'hm-region-selector-dialog',
    template: `
        <h2 mat-dialog-title>{{ title }}</h2>
        <hm-boundary-selection-map
            [showListings]="false"
            [boundaryType]="type"
            [(selectedBoundaries)]="boundaries"
        >
        </hm-boundary-selection-map>

        <hm-region-selector [(boundaryType)]="type" [(boundaries)]="boundaries">
        </hm-region-selector>
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button
                mat-raised-button
                class="mat-primary"
                [mat-dialog-close]="{ type: type, boundaries: boundaries }"
            >
                Save
            </button>
        </mat-dialog-actions>
    `,
    styles: [
        `
            :host {
                display: block;
                padding: 25px;
            }
            ,
            .mat-list-base {
                padding-top: 8px;
                display: block;
                -webkit-tap-highlight-color: transparent;
                position: relative;
                margin-top: -600px;
                background-color: white;
                width: 300px;
                border-radius: 10px;
                margin-left: 20px;
            }
        `,
    ],
})
export class RegionSelectorDialogComponent {
    title: string = 'Select boundaries';
    type: DataPermissionType;
    boundaries: Boundary[];
    constructor(
        public dialogRef: MatDialogRef<RegionSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) { title, type, boundaries }
    ) {
        this.title = title ? title : this.title;
        this.type = type;
        this.boundaries = boundaries;
    }
}
