import { Action } from '@ngrx/store';
import { Whitelabel } from '../shared/whitelabel.model';

export enum WhitelabelActionType {
    WhitelabelGet = '[Whitelabel] Get WhitelabelInfo',
    WhitelabelLoaded = '[Whitelabel] Whitelabel Loaded',
}

export class WhitelabelGet implements Action {
    public readonly type = WhitelabelActionType.WhitelabelGet;
    constructor() {}
}

export class WhitelabelLoaded implements Action {
    public readonly type = WhitelabelActionType.WhitelabelLoaded;
    constructor(
        public payload: {
            whitelabel: Whitelabel;
        }
    ) {}
}

export type WhitelabelActions = WhitelabelGet | WhitelabelLoaded;
