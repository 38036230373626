import { Filter, FilterMember, Value } from './model';
import { include, QueryBuilder, QueryRequest, QueryResponse } from './query';

export class CompareRequest extends QueryRequest {
    type: 'compare' = 'compare';
    target: Filter;
}

export interface CompareResponse extends QueryResponse {}

export class CompareBuilder extends QueryBuilder<CompareRequest> {
    constructor(req?: CompareRequest) {
        super(req || new CompareRequest());
    }

    target(uniqueName: string, ...values: (Value | FilterMember)[]): CompareBuilder {
        this.request.target = include(uniqueName, ...values);
        return this;
    }
}
