<div class="report-controls-container">
    <div class="measure-selector">
        <mat-list>
            <!-- <div mat-subheader>Statistics</div> -->
            <mat-list-item>
                <mat-icon mat-list-icon>insert_chart</mat-icon>
                <mat-form-field>
                    <mat-select [(value)]="currentMeasure" (valueChange)="onSelectedMeasureChange($event)" [disableOptionCentering]="true">
                        <mat-option *ngFor="let m of measures" [value]="m">
                            {{ m.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="legends" *ngIf="scale">
        <div class="map-tools">
            <div class="hurricane-tabbed-legend">
                <div class="legend-header"></div>
                <div class="color-bar confirmed">
                    <span class="color-bar-item c-1"></span>
                    <span class="color-bar-item c-2"></span>
                    <span class="color-bar-item c-3"></span>
                    <span class="color-bar-item c-4"></span>
                    <span class="color-bar-item c-5"></span>
                    <span class="color-bar-item c-6"></span>
                    <span class="color-bar-item c-7"></span>
                </div>
                <div class="legend-labels confirmed">
                    <span *ngFor="let s of scale" class="legend-label-item">
                        {{ s | shortNumber : format }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
