import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { DataPermission } from './data-permission.model';
import { combineLatest } from 'rxjs';
import { selectCountry } from 'app/core/session-info/store/selectors/session-info.selector';
import { Store } from '@ngrx/store';
import { count } from 'console';
import { filter } from 'rxjs/operators';

@Injectable()
export class DataPermissionsService {
    organisationId: number;
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };
    constructor(private http: HttpClient, private store: Store) {}

    getDataPermissions(country$: Observable<string>): Observable<DataPermission[]> {
        return country$.pipe(
            filter((country) => !!country), // Wait for country to have a value
            concatMap((country) =>
                this.http
                    .get<any[]>(`${this.apiBase}/data-permissions?country=${country}`, this.options)
                    .pipe(map((array) => array.map((obj) => new DataPermission(obj))))
            )
        );
    }

    getOrganisationDataPermissions(
        organisationId$: Observable<number>,
        country$: Observable<string>
    ): Observable<DataPermission[]> {
        return combineLatest([organisationId$, country$]).pipe(
            concatMap(([id, country]) =>
                this.http
                    .get<any[]>(
                        `${this.apiBase}/organisations/${id}/data-permissions?country=${country}`
                    )
                    .pipe(map((array) => array.map((obj) => new DataPermission(obj))))
            )
        );
    }

    getOrganisationDataPermissionsById(id: string | number): Observable<DataPermission[]> {
        return this.http
            .get<any[]>(`${this.apiBase}/organisations/${id}/data-permissions`, this.options)
            .pipe(map((array) => array.map((obj) => new DataPermission(obj))));
    }

    getOrganisationPagePermissionsById(id: string | number): Observable<DataPermission[]> {
        return this.http
            .get<any[]>(`${this.apiBase}/organisations/${id}/data-permissions`, this.options)
            .pipe(
                map((array) =>
                    array.filter((f) => f.grouping == 'PAGE').map((obj) => new DataPermission(obj))
                )
            );
    }

    getOrganisationDataPermissionsByIdandCountry(
        id: string | number,
        country$: Observable<string>
    ): Observable<DataPermission[]> {
        return country$.pipe(
            concatMap((country) =>
                this.http
                    .get<any[]>(
                        `${this.apiBase}/organisations/${id}/data-permissions?country=${country}`,
                        this.options
                    )
                    .pipe(map((array) => array.map((obj) => new DataPermission(obj))))
            )
        );
    }

    setOrganisationDataPermissions(
        organisationId: number,
        permissions: DataPermission[],
        country$: Observable<string>
    ): Observable<any> {
        const ids = permissions.map((permission) => permission.id);
        const uniqIds = Array.from(new Set(ids));

        return country$.pipe(
            concatMap((country) =>
                this.http
                    .post(
                        `${this.apiBase}/organisations/${organisationId}/data-permissions?country=${country}`,
                        uniqIds,
                        {
                            observe: 'response',
                        }
                    )
                    .pipe(
                        map((data: any) => {
                            return `Updated Data Permissions = ${data.statusText} : Status Code = ${data.status}`;
                        }),
                        catchError((error) => {
                            return 'Error saving permissions. Something went wrong! Please retry.';
                        })
                    )
            )
        );
    }

    setChildOrganisationDataPermissions(organisationId: number, permissions: DataPermission[]) {
        const ids = permissions.map((permission) => permission.id);
        const uniqIds = Array.from(new Set(ids));

        return this.http.post(
            `${this.apiBase}/organisations/${organisationId}/data-permissions`,
            uniqIds,
            {
                observe: 'response',
            }
        );
    }

    updateDataPermissions(
        organisationId: Number,
        type: string,
        permissions: number[]
    ): Observable<any> {
        return this.http
            .post(`${this.apiBase}/organisations/${organisationId}/data-permissions`, permissions, {
                observe: 'response',
                params: { type: type },
            })
            .pipe(
                map((data: any) => {
                    return `Page permissions updated`;
                }),
                catchError((error) => {
                    return 'Error saving permissions.  Something went wrong! Please retry. ';
                })
            );
    }
}
