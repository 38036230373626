import { Pipe, PipeTransform } from '@angular/core';
import { Metric } from 'app/views/pages/metrics/metrics.model';

@Pipe({
    name: 'filterMetric',
})
export class FilterMetricPipe implements PipeTransform {
    transform(metrics: Metric[], metric_id: number): Metric[] {
        if (!metrics || !metric_id) {
            return metrics;
        }
        return metrics.filter((metric) => metric.id == metric_id);
    }
}
