import { toInteger } from 'lodash';
import moment from 'moment';

import { Filter, filterMember, include } from 'app/core/cubes';
import { environment } from 'environments/environment';

export const YEAR_MONTH_FMT = 'YYYY-MMM';

export const YEAR_MONTH_DAY_FMT = 'YYYY-MMM-DD';

export const YEAR_MONTH_NUMBER_DAY_FMT = 'YYYY-MM-DD';

export const YEAR_QUARTER_FMT = 'YYYY-[Q]Q';

export const DAY_MONTH_YEAR_FMT = 'DD-MMM-YYYY';

export const ISO_WEEK_FMT = 'GGGG-[W]WW';

export const MonthNameSortOrder = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
};

/**
 * Return date parts as [year, quarter, month] from a given date string.
 * For cases where the quarter or the month aren't specified, returns 'Any'.
 * Format for date: year-month or year-quarter, e.g. 2020-Mar, 2019-Q3
 */
export function dateParts(date: string) {
    const year = date.slice(0, 4);
    const rest = date.slice(5);
    switch (rest) {
        case 'Q1':
        case 'Q2':
        case 'Q3':
        case 'Q4':
            return [year, rest, 'Any'];
        case 'Jan':
        case 'Feb':
        case 'Mar':
            return [year, 'Q1', rest];
        case 'Apr':
        case 'May':
        case 'Jun':
            return [year, 'Q2', rest];
        case 'Jul':
        case 'Aug':
        case 'Sep':
            return [year, 'Q3', rest];
        case 'Oct':
        case 'Nov':
        case 'Dec':
            return [year, 'Q4', rest];
        default:
            return [year, 'Any', 'Any'];
    }
}

export function dateToDateParts(date: Date) {
    const year = date.getFullYear();
    const month = toInteger(date.getMonth() + 1);
    let quarter = 'Any';
    if (month === 1 || month === 2 || month === 3) {
        quarter = 'Q1';
    } else if (month === 4 || month === 5 || month === 6) {
        quarter = 'Q2';
    } else if (month === 7 || month === 8 || month === 9) {
        quarter = 'Q3';
    } else if (month === 10 || month === 11 || month === 12) {
        quarter = 'Q4';
    } else {
        quarter = 'Any';
    }

    return [year.toString(), quarter, monthNumberToName(month)];
}

export function dateStringToDate(date: string, format?: string): Date {
    if (format) {
        return moment(date, format).toDate();
    }

    const year = date.slice(0, 4);
    const rest = date.slice(5);
    switch (rest) {
        case 'Q1':
            return new Date(`${year}-03-30`);
        case 'Q2':
            return new Date(`${year}-06-30`);
        case 'Q3':
            return new Date(`${year}-09-30`);
        case 'Q4':
            return new Date(`${year}-12-31`);
        default:
            return new Date(Number(year), Number(monthNameToNumber(rest)) - 1, 1);
    }
}

export function dateToDateString(date: Date, format: string): string {
    const m = moment(date).format(format);
    return m;
}

/**
 * Get system default date for reports (or default end date for ranges)
 */
export function defaultDateString(): string {
    if (environment.defaultDate) {
        return environment.defaultDate;
    } else {
        const date = moment().subtract(3, 'months');
        return date.format(YEAR_MONTH_FMT);
    }
}

// For Visitor Profile and Time Series title formatting
export function yearEndString(period: string): string {
    const [year, quarter] = period.split('-');

    switch (quarter) {
        case 'Q1':
            return 'year ended March ' + year;
        case 'Q2':
            return 'year ended June ' + year;
        case 'Q3':
            return 'year ended Sept ' + year;
        case 'Q4':
            return 'year ended Dec ' + year;
        default:
            return 'year ended ??? ' + year;
    }
}

export function defaultDateQuarter(): string {
    if (environment.defaultDate) {
        return environment.defaultDate;
    } else {
        const date = moment().subtract(4, 'months').toDate(); // for quarters go back at least 4 months
        const fulldate = dateToDateParts(date);
        return fulldate[0] + '-' + fulldate[1];
    }
}

export function defaultDateArray(): Date[] {
    if (environment.defaultDate) {
        const startDate = new Date(environment.defaultDate);
        const endDate = moment(startDate).endOf('month');

        return [startDate, endDate.toDate()];
    } else {
        const dayChange = 7 + moment().subtract(3, 'months').weekday(); // always start on a Monday, 2 months ago
        const enddate = moment().subtract(dayChange, 'days').subtract(2, 'months').toDate(); // always go back 2 months
        const startdate = moment(enddate).subtract(6, 'days').toDate();

        return [startdate, enddate];
    }
}

export function defaultTrafficDateArray(): Date[] {
    const dayChange = 7 + moment().weekday(); // always start on a Monday
    const enddate = moment().subtract(dayChange, 'days').toDate();
    const startdate = moment(enddate).subtract(6, 'days').toDate();
    return [startdate, enddate];
}
/**
 * Get system default start date for reports with date range
 */
export function defaultStartDateString(): string {
    let date = moment().subtract(1, 'months');
    if (environment.defaultDate) {
        date = moment(environment.defaultDate, YEAR_MONTH_FMT);
    }
    date.subtract(1, 'year');
    return date.format(YEAR_MONTH_FMT);
}

export function defaultDateStringInQuarter(): string {
    let date = moment().format(YEAR_QUARTER_FMT);

    return date;
}

export function defaultPrevDateStringInQuarter(): string {
    let date = moment().subtract(1, 'year').format(YEAR_QUARTER_FMT);

    return date;
}

export function getCurrentDateString(format): string {
    return moment().format(format);
}

// getPrevDateString returns the previous date subtracted by the unit of time specified.
// If no input date is given, the current date is subtracted.
export function getPrevDateString(
    format: string,
    unit: moment.unitOfTime.DurationConstructor,
    subtract: number,
    input?: string
) {
    if (input) {
        return moment(input, format).subtract(subtract, unit).format(format);
    } else return moment().subtract(subtract, unit).format(format);
}

export function defaultWeekNumber(): string {
    // https://momentjs.com/docs/#/manipulating/start-of/
    // Mutates the original moment by setting it to the start of the iso week.
    const momentDate = moment().startOf('isoWeek');
    return momentDate.format(ISO_WEEK_FMT);
}

export function dateToQuarterNumber(period: string): number {
    const [year, quarter] = period.split('-');

    return parseInt(`${year}0${quarter[1]}`);
}

export function monthNumberToName(month: number): string {
    return moment()
        .month(month - 1) // momentjs months are indexed starting at 0
        .format('MMM');
}

export function monthNameToNumber(monthName: string): string {
    switch (monthName) {
        case 'Jan':
            return '01';
        case 'Feb':
            return '02';
        case 'Mar':
            return '03';
        case 'Apr':
            return '04';
        case 'May':
            return '05';
        case 'Jun':
            return '06';
        case 'Jul':
            return '07';
        case 'Aug':
            return '08';
        case 'Sep':
            return '09';
        case 'Oct':
            return '10';
        case 'Nov':
            return '11';
        case 'Dec':
            return '12';
    }
    return '';
}

export function getQuarterFromMonth(monthName: string): string {
    if (monthName == 'Jan' || monthName == 'Feb' || monthName == 'Mar' || monthName == 'Q1') {
        return 'Q1';
    } else if (
        monthName == 'Apr' ||
        monthName == 'May' ||
        monthName == 'Jun' ||
        monthName == 'Q2'
    ) {
        return 'Q2';
    } else if (
        monthName == 'Jul' ||
        monthName == 'Aug' ||
        monthName == 'Sep' ||
        monthName == 'Q3'
    ) {
        return 'Q3';
    } else if (
        monthName == 'Oct' ||
        monthName == 'Nov' ||
        monthName == 'Dec' ||
        monthName == 'Q4'
    ) {
        return 'Q4';
    } else return '';
}
/**
 * Return a Cube Filter that includes all periods between the two dateStrings, inclusive.
 */
export function includePeriodsBetween(startDate: string, endDate: string): Filter {
    const [startYear, startQuarter, startMonth] = dateParts(startDate);
    const [endYear, endQuarter, endMonth] = dateParts(endDate);
    const timeMembers = [];
    if (startMonth != 'Any' && endMonth != 'Any') {
        // for each year-month between start and end
        const curr = moment(`${startYear}-${startMonth}`, YEAR_MONTH_FMT);
        const end = moment(`${endYear}-${endMonth}`, YEAR_MONTH_FMT);
        for (; curr.isSameOrBefore(end); curr.add(1, 'month')) {
            timeMembers.push(
                filterMember(curr.format('YYYY'), include('Month', curr.format('MMM')))
            );
        }
    } else {
        // doing it in quarters
        const quarterToMonth = { Q1: 'Jan', Q2: 'Apr', Q3: 'Jul', Q4: 'Oct' };
        const curr = moment(`${startYear}-${quarterToMonth[startQuarter]}`, YEAR_MONTH_FMT);
        const end = moment(`${endYear}-${quarterToMonth[endQuarter]}`, YEAR_MONTH_FMT);
        for (; curr.isSameOrBefore(end); curr.add(1, 'quarter')) {
            timeMembers.push(
                filterMember(curr.format('YYYY'), include('Quarter', curr.format('\\QQ')))
            );
        }
    }
    return include('Year', ...timeMembers);
}

/**
 * Return a Cube Filter that includes a single Year, Quarter, Month from a dateString.
 */
export function includeYearQuarterMonth(date: string): Filter {
    const [year, quarter, month] = dateParts(date);
    return include(
        'Year',
        filterMember(year, include('Quarter', filterMember(quarter, include('Month', month))))
    );
}

export function dateRangeBetweenDays(start: string, end: string): Filter {
    const [sYear, sMonth, sDay] = start.split('-');
    const [eYear, eMonth, eDay] = end.split('-');

    const startDate = moment(`${sYear}-${sMonth}-${sDay}`, YEAR_MONTH_DAY_FMT);
    const endDate = moment(`${eYear}-${eMonth}-${eDay}`, YEAR_MONTH_DAY_FMT);

    const timeMembers = [];
    for (; startDate.isSameOrBefore(endDate); startDate.add(1, 'day')) {
        timeMembers.push(
            filterMember(
                startDate.format('YYYY'),
                include(
                    'Month',
                    filterMember(startDate.format('MMM'), include('Day', startDate.format('DD')))
                )
            )
        );
    }

    return include('Year', ...timeMembers);
}

// weekToDate() converts a Week GGGG-[W]WW to a Date YYYY-MM-DD
export function weekToDate(week: string, format?: string): string {
    const weekNumber = week.replace('-', '');
    // momentjs allows ISO 8601 inputs such as GGGG[W]WW
    const momentDate = moment.utc(weekNumber);

    if (format) {
        return momentDate.format(format);
    }
    return momentDate.format(YEAR_MONTH_NUMBER_DAY_FMT);
}

// dateToWeek() converts a Date YYYY-MM-DD to an ISO Week GGGG-[W]WW
export function dateToWeek(date: string) {
    const momentDate = moment.utc(date);

    return momentDate.format(ISO_WEEK_FMT);
}

// epochTimeToDate converts a Epoch time (ms) to Date YYYY-MM-DD
export function epochTimeToDate(epochTime: number) {
    // https://momentjs.com/docs/#/parsing/unix-timestamp-milliseconds/
    const date = moment(epochTime).format(YEAR_MONTH_NUMBER_DAY_FMT);

    return date;
}
export function formatDate(date: Date): string {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
}

// dateToEpochTime converts a Date YYYY-MM-DD object to Epoch time (ms)
export function dateToEpochTime(date: string): number {
    // Epoch Time in ms
    // x is the token for Unix timestamp in ms
    // utc() to remove local time
    const momentDate = moment.utc(date, YEAR_MONTH_NUMBER_DAY_FMT).format('x');

    return parseInt(momentDate);
}

// startOf mutates the given date and returns the start of a given unit of time
export function startOf(
    input: string,
    inputFormat: string,
    unit: moment.unitOfTime.StartOf,
    outputFormat?: string
) {
    // https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/03-start-of/
    let resFormat = YEAR_MONTH_NUMBER_DAY_FMT;
    if (outputFormat) {
        resFormat = outputFormat;
    }
    return moment(input, inputFormat).startOf(unit).format(resFormat);
}

export function endOf(
    input: string,
    inputFormat: string,
    unit: moment.unitOfTime.StartOf,
    outputFormat?: string
) {
    let resFormat = YEAR_MONTH_NUMBER_DAY_FMT;
    if (outputFormat) {
        resFormat = outputFormat;
    }
    return moment(input, inputFormat).endOf(unit).format(outputFormat);
}

export function diff(startDateString: string, endDateString: string, format: string) {
    const startDate = moment(startDateString, format);
    const endDate = moment(endDateString, format);

    return endDate.diff(startDate, 'days');
}

export function toFormat(
    dateString: string,
    inputFormat: string,
    outputFormat: string,
    end?: boolean // start or end of month
) {
    return end
        ? moment(dateString, inputFormat).endOf('month').format(outputFormat)
        : moment(dateString, inputFormat).startOf('month').format(outputFormat);
}

export function isSameOrAfter(input: string, inputFormat: string, date: string) {
    return moment(input, inputFormat).isSameOrAfter(moment(date, inputFormat));
}

export function isSameOrBefore(input: string, inputFormat: string, date: string) {
    return moment(input, inputFormat).isSameOrBefore(moment(date, inputFormat));
}

export function isBefore(input: string, inputFormat: string, date: string) {
    return moment(input, inputFormat).isBefore(moment(date, inputFormat));
}

export function isAfter(input: string, inputFormat: string, date: string) {
    return moment(input, inputFormat).isAfter(moment(date, inputFormat));
}

export function add(input, amount, unit, inputFormat?) {
    return moment(input, inputFormat).add(amount, unit);
}

// 2020 1 02 02
export function dateIdToDateString(dateId: number): string {
    const dateIdArr = dateId.toString().split('');
    const year = dateIdArr.slice(0, 4).join('');
    const quarter = dateIdArr.slice(4, 5).join('');
    const month = dateIdArr.slice(5, 7).join('');
    const date = dateIdArr.slice(7, 9).join('');

    let format: 'month' | 'date' | 'quarter' = 'date';

    if (date === '00') {
        if (month === '00') {
            format = 'quarter';
        } else {
            format = 'month';
        }
    } else {
        format = 'date';
    }

    switch (format) {
        case 'quarter':
            return `${year}-Q${quarter}`;
        case 'month':
            return `${year}-${monthNumberToName(parseInt(month))}`;
        default:
            return `${year}-${monthNumberToName(parseInt(month))}-${date}`;
    }
}

export function dateStringToDateId(dateString: string): number {
    const dateArr = dateString.split('-');
    const year = dateArr[0];
    const quarterOrMonth = dateArr[1];
    const date = dateArr[2];

    let format: 'month' | 'date' | 'quarter' = 'date';
    if (date) {
        format = 'date';
    } else {
        if (quarterOrMonth.includes('Q')) {
            format = 'quarter';
        } else {
            format = 'month';
        }
    }

    switch (format) {
        case 'quarter':
            return parseInt(`${year}${quarterOrMonth.split('')[1]}0000`);
        case 'month':
            return parseInt(
                `${year}${getQuarterFromMonth(quarterOrMonth).split('')[1]}${monthNameToNumber(
                    quarterOrMonth
                )}00`
            );
        default:
            return parseInt(
                `${year}${getQuarterFromMonth(quarterOrMonth).split('')[1]}${monthNameToNumber(
                    quarterOrMonth
                )}${date}`
            );
    }
}

export function dateMonth(datestring: string): string {
    return moment(datestring, YEAR_MONTH_FMT).format('YYYY-MMM');
}

export function dateForUpload(dateString: string): string {
    // Check and split the string based on possible delimiters
    let parts: string[] = [];
    if (dateString.includes('-')) {
        parts = dateString.split('-');
    } else if (dateString.includes('/')) {
        parts = dateString.split('/');
    }

    // If the format is yyyy-mm-dd or yyyy/mm/dd
    if (parts.length === 3 && parts[0].length === 4) {
        return `${parts[0]}-${parts[1].padStart(2, '0')}-${parts[2].padStart(2, '0')}`;
    }

    // If the format is dd/mm/yyyy
    if (parts.length === 3 && parts[2].length === 4) {
        return `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;
    }

    throw new Error(`Unrecognized date format: ${dateString}`);
}
