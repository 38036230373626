<mat-slider
    #matslider
    thumbLabel="true"
    tickInterval="10"
    min="10"
    max="30"
    step="10"
    value="30"
    (mouseup)="updateTravelTime(matslider.value)"
></mat-slider>
