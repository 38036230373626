<!-- <mat-card class="marketplace-card">
    <mat-card-actions align="end">
        <button>hhh</button>
    </mat-card-actions>
    <img mat-card-image [src]="imageUrl" alt="" />
    <mat-card-content>
        <div class="flex">
            <div class="col">
                <h5>{{title}}</h5>
                <p>
                    {{subTitle}}
                </p>
            </div>
            <div class="source">
                <img mat-card-image [src]="iconUrl" alt="" />
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">

    </mat-card-actions>
</mat-card> -->

<!--
<button mat-button color="accent">SUBSCRIBED</button> -->

<p class="enquireButton">
    <button *ngIf="noSubscription" mat-raised-button class="marketplace-btn">Enquire Now</button>
</p>

<div [class.opacity50]="noSubscription">
    <div class="relative max-w-sm bg-white shadow-md rounded-3xl p-2 mx-1 my-3 cursor-pointer">
        <div class="overflow-x-hidden rounded-2xl relative">
            <img class="h-40 rounded-2xl w-full" [src]="imageUrl" />
        </div>
        <div class="mt-4 pl-2 mb-2 flex justify-between h-6">
            <div>
                <h5>{{ title }}</h5>
                <p class="text-md text-gray-800 mt-0">{{ subTitle }}</p>
            </div>
            <div class="flex flex-col-reverse mb-1 mr-4 group cursor-pointer">
                <img class="h4 object-cover" [src]="iconUrl" />
            </div>
        </div>
    </div>
</div>
