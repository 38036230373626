import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { selectUserPermittedBoundaryTypes } from 'app/core/user-data-permissions/store/selectors/user-data-permissions.selectors';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';

@Component({
    selector: 'hm-region-selector',
    templateUrl: './region-selector.component.html',
    styleUrls: ['./region-selector.component.scss'],
})
export class RegionSelectorComponent implements OnInit {
    @Input() boundaries: Boundary[] = [];
    @Output() boundariesChange = new EventEmitter<Boundary[]>();

    @Input() boundaryType: DataPermissionType = DataPermissionType.STATE;
    @Output() boundaryTypeChange = new EventEmitter<DataPermissionType>();

    boundaryTypes$ = this.store.select(selectUserPermittedBoundaryTypes);

    constructor(private store: Store<{}>) {}

    ngOnInit() {}
}
