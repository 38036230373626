<div class="members-selector">
    <button mat-icon-button [matMenuTriggerFor]="memberMenu" *ngIf="!readOnly">
        <mat-icon>list</mat-icon>
    </button>
    <mat-menu #memberMenu="matMenu">
        <ng-container *ngIf="members">
            <mat-option [disabled]="readOnly" *ngFor="let member of members" (click)="selectMember(member)">{{ member }}</mat-option>
        </ng-container>
    </mat-menu>
</div>
