import {
    DataPermissionActions,
    DataPermissionActionType,
} from '../actions/data-permissions.actions';
import {
    initialDataPermissionState,
    DataPermissionState,
    dataPermissionAdapter,
} from '../states/data-permissions.state';

export function dataPermissionReducer(
    state = initialDataPermissionState,
    action: DataPermissionActions
): DataPermissionState {
    switch (action.type) {
        case DataPermissionActionType.DataPermissionLoadingAll: {
            return { ...state, loading: true };
        }
        case DataPermissionActionType.DataPermissionLoadSuccessAll: {
            return dataPermissionAdapter.setAll(action.payload.dataPermissions, {
                ...state,
                error: false,
                loading: false,
            });
        }
        case DataPermissionActionType.DataPermissionLoadFailureAll: {
            return dataPermissionAdapter.removeAll({
                ...state,
                error: true,
                loading: false,
            });
        }
        default:
            return state;
    }
}
