<!-- begin:: Content Head -->
<div
    class="kt-subheader kt-grid__item"
    [ngClass]="{ 'kt-container--clear': clear }"
    [hidden]="subheaderService.disabled$ | async"
    id="kt_subheader"
>
    <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
        <div class="kt-subheader__main">
            <h3 *ngIf="title" class="kt-subheader__title">{{ title }}</h3>
            <span *ngIf="desc" class="kt-subheader__separator kt-subheader__separator--v"></span>
            <h4 *ngIf="desc" class="kt-subheader__desc">{{ desc }}</h4>
        </div>
    </div>
</div>
<!-- end:: Content Head -->
