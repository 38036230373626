// Angular
import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
// Layout
import { LayoutConfigService, ToggleOptions } from '../../../core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'kt-brand',
    templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() open = true;
    logo: string = 'logo_open.png';
    open$: Observable<boolean>;

    headerLogo: string;
    headerStickyLogo: string;

    toggleOptions: ToggleOptions = {
        target: 'body',
        targetState: 'kt-aside--minimize',
        togglerState: 'kt-aside__brand-aside-toggler--active',
    };

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayoutConfigService
     * @param htmlClassService: HtmlClassService
     */
    constructor(
        private layoutConfigService: LayoutConfigService,
        public htmlClassService: HtmlClassService
    ) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.headerLogo = this.layoutConfigService.getLogo();
        this.headerStickyLogo = this.layoutConfigService.getStickyLogo();
    }
    ngOnChanges(changes): void {
        if (changes.open && changes.open.currentValue !== changes.open.previousValue) {
            this.logo = changes.open.currentValue ? 'logo-open.png' : 'logo.png';
        }
    }

    /**
     * On after view init
     */
    ngAfterViewInit(): void {}
}
