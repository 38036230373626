<mat-list class="hem-boundary-controls mt-0">
    <!-- LOADING PANEL -->
    <div mat-subheader>{{ mapMessage }}</div>
    <mat-list-item>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <mat-progress-bar *ngIf="showMapSpinner" mode="query"></mat-progress-bar>
                </div>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>

    <!-- CATCHMENTS -->
    <div mat-subheader>Catchments selected</div>
    <mat-list-item>
        <mat-icon mat-list-icon><i class="flaticon-placeholder-3"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12">
                <div *ngIf="catchments">
                    <div *ngFor="let catchment of catchments; let i = index">
                        <mat-checkbox
                            id="catchment.id"
                            (click)="onCatchmentChange(catchment.id)"
                            checked="true"
                            class="catchments"
                            >Catchment {{ i + 1 }} - {{ catchment.name }}</mat-checkbox
                        >&nbsp;
                        <span (click)="onZoomToPoint(catchment)">
                            &nbsp; <mat-icon class="zoomIcon">zoom_in</mat-icon>Zoom&nbsp;&nbsp;
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>

    <!-- CATEGORIES -->
    <div mat-subheader>Categories selected</div>
    <mat-list-item *ngIf="categories as cat">
        <mat-icon mat-list-icon><i class="flaticon2-architecture-and-city"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12">
                <div class="hem-category">
                    <div *ngFor="let category of cat">
                        <mat-checkbox
                            [checked]="category.selected"
                            (change)="
                                category.selected = !category.selected; onCategoryChange(category)
                            "
                        >
                            <mat-icon [ngStyle]="{ color: category.color }">brightness_1</mat-icon>
                            {{ category.name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>

    <!-- TRAVEL OPTIONS -->
    <div mat-subheader>Travel Options</div>
    <mat-list-item>
        <div class="row">
            <div class="col-sm-6">
                <mat-card class="mat-elevation-z1">
                    <mat-card-header>
                        <mat-card-subtitle>Travel Mode</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <kt-transport-mode
                            [travelModes]="travelModes"
                            (travelModeEvent)="updateTravelOption($event)"
                        ></kt-transport-mode>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-6">
                <mat-card class="mat-elevation-z1">
                    <mat-card-header>
                        <mat-card-subtitle
                            >Travel Time - {{ travelTimes[2] / 60 }} Minutes</mat-card-subtitle
                        >
                    </mat-card-header>
                    <mat-card-content>
                        <div class="sliderContainer">
                            <kt-travel-time
                                (travelTimeEvent)="updateTravelTime($event)"
                            ></kt-travel-time>
                            <kt-legend
                                [travelTimes]="travelTimes"
                                [travelTimeColours]="travelTimeColours"
                            ></kt-legend>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-list-item>
    <br />
    <mat-divider></mat-divider>
</mat-list>
