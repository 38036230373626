import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { SurveyMirrorBarChartConfig } from './survey-mirror-bar-chart.model';
import { Subject, takeUntil } from 'rxjs';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-survey-mirror-bar-chart',
    template: `<div id="{{ id }}" class="survey-mirror-bar-chart"></div>`,
    styleUrls: ['./survey-mirror-bar-chart.component.scss'],
})
export class SurveyMirrorBarChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() id = 'survey-mirror-bar-chart';
    @Input() config: SurveyMirrorBarChartConfig;

    private container: am4core.Container;
    private mirrorChart: am4charts.XYChart;
    private mirrorXAxisMale: am4charts.ValueAxis;
    private mirrorXAxisFemale: am4charts.ValueAxis;

    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        public store: Store<AppState>,
        private zone: NgZone
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });

        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            const container = am4core.create(this.id, am4core.Container);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            container.layout = 'horizontal';

            // Hiding AmCharts logo
            container.logo.height = -20000;

            const mirrorChart = container.createChild(am4charts.XYChart);

            mirrorChart.numberFormatter.numberFormat = '#a';

            mirrorChart.data = this.config.data;

            const mirrorXAxisMale = mirrorChart.xAxes.push(new am4charts.ValueAxis());
            mirrorXAxisMale.min = 0;
            mirrorXAxisMale.renderer.labels.template.adapter.add('text', (text, label) => {
                return label.dataItem['value'] + '%';
            });

            const maleRange = mirrorXAxisMale.axisRanges.create();
            maleRange.value = 0;

            const mirrorXAxisFemale = mirrorChart.xAxes.push(new am4charts.ValueAxis());
            mirrorXAxisFemale.min = 0;
            mirrorXAxisFemale.renderer.labels.template.adapter.add('text', (text, label) => {
                return label.dataItem['value'] + '%';
            });

            mirrorXAxisFemale.renderer.inversed = true;

            const femaleRange = mirrorXAxisFemale.axisRanges.create();
            femaleRange.value = 0;

            mirrorChart.bottomAxesContainer.layout = 'horizontal';

            const mirrorYAxis = mirrorChart.yAxes.push(new am4charts.CategoryAxis());
            mirrorYAxis.dataFields.category = this.config.category;
            mirrorYAxis.renderer.minGridDistance = 10;
            mirrorYAxis.renderer.grid.template.location = 0;
            mirrorYAxis.renderer.inversed = true;

            this.mirrorXAxisFemale = mirrorXAxisFemale;
            this.mirrorXAxisMale = mirrorXAxisMale;

            mirrorChart.legend = new am4charts.Legend();
            mirrorChart.legend.align = 'right';
            mirrorChart.legend.valign = 'middle';
            mirrorChart.legend.tooltip.disabled = true;

            mirrorChart.events.on('ready', () => {
                const relativeMax = Math.max(
                    mirrorXAxisMale.maxZoomed,
                    mirrorXAxisFemale.maxZoomed
                );
                mirrorXAxisMale.max = mirrorXAxisFemale.max = Math.min(100, relativeMax);
            });

            this.mirrorChart = mirrorChart;

            this.setColours();
            this.createFSeries();
            this.createMSeries();
            this.container = container;
        });
    }
    getMax(data: any[]) {
        let max: any = 0;
        let value: any;
        data.map((d) => {
            for (const [key, value] of Object.entries(d)) {
                if (Number(value)) {
                    if (Number.isInteger(Number(value))) {
                        if (value > max) {
                            max = value;
                        }
                    }
                }
            }
        });
        return max;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.mirrorChart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.mirrorChart.series.clear();
            this.createFSeries();
            this.createMSeries();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.container.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        this.mirrorChart.colors.list = colourList;
    }

    createMSeries() {
        const mirrorSeriesMale = this.mirrorChart.series.push(new am4charts.ColumnSeries());
        mirrorSeriesMale.dataFields.categoryY = this.config.category;
        mirrorSeriesMale.dataFields.valueX = this.config.mValue;
        mirrorSeriesMale.name = this.config.mValue; // So that the legends have labels
        mirrorSeriesMale.xAxis = this.mirrorXAxisMale;
        mirrorSeriesMale.clustered = false;
        mirrorSeriesMale.columns.template.strokeOpacity = 0;

        mirrorSeriesMale.yAxis.renderer.inside = false;
        mirrorSeriesMale.calculatePercent = true;

        mirrorSeriesMale.columns.template.tooltipText = "{valueX.formatNumber('#.')}%";
        mirrorSeriesMale.columns.template.showTooltipOn = 'always';

        mirrorSeriesMale.tooltip.pointerOrientation = 'left';
        mirrorSeriesMale.tooltip.fontSize = '1.1rem';
        mirrorSeriesMale.tooltip.fontWeight = '600';
    }

    createFSeries() {
        const mirrorSeriesFemale = this.mirrorChart.series.push(new am4charts.ColumnSeries());
        mirrorSeriesFemale.calculatePercent = true;
        mirrorSeriesFemale.dataFields.categoryY = this.config.category;
        mirrorSeriesFemale.dataFields.valueX = this.config.fValue;
        mirrorSeriesFemale.name = this.config.fValue;
        mirrorSeriesFemale.xAxis = this.mirrorXAxisFemale;
        mirrorSeriesFemale.clustered = false;
        mirrorSeriesFemale.columns.template.strokeOpacity = 0;

        mirrorSeriesFemale.yAxis.renderer.inside = false;

        mirrorSeriesFemale.columns.template.tooltipText = "{valueX.formatNumber('#.')}%";
        mirrorSeriesFemale.columns.template.showTooltipOn = 'always';

        mirrorSeriesFemale.tooltip.pointerOrientation = 'right';
        mirrorSeriesFemale.tooltip.fontSize = '1.1rem';
        mirrorSeriesFemale.tooltip.fontWeight = '600';
    }
}
