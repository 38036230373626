import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { dataNotice } from 'app/core/dataset/shared/notice.util';

@Component({
    selector: 'hm-data-notice',
    templateUrl: './data-notice.component.html',
    styleUrls: ['./data-notice.component.scss'],
})
export class DataNoticeComponent implements OnInit, OnChanges {
    @Input() datasets: string[] = [];
    notice: string;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.datasets) {
            this.notice = dataNotice(this.datasets);
        }
    }
}
