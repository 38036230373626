import { PercentPipe } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ShortNumberPipe } from 'app/pipes/short-number.pipe';
import { Measure } from '../../measures/shared/measure.model';
import { TimeSeriesTableConfig } from './time-series-table.model';

@Component({
    selector: 'hm-time-series-table',
    templateUrl: './time-series-table.component.html',
    styleUrls: ['./time-series-table.component.scss'],
})
export class TimeSeriesTableComponent implements AfterViewInit, OnChanges {
    @ViewChild('exporter', { static: false }) exporter: any;

    @Input() config: TimeSeriesTableConfig;

    tableData: MatTableDataSource<any> = new MatTableDataSource();

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            this.tableData.data = this.config.data;
        }
    }

    ngAfterViewInit() {
        this.tableData.data = this.config.data;
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'time-series' }); // File name: name of tab + timestamp
    }
}
