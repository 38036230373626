import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { CubesService, MembersBuilder, Field, MembersResponse, Value } from 'app/core/cubes';
import { dateIdToDateString, epochTimeToDate, formatDate } from 'app/core/utilities/date';
import { format } from 'path';

@Component({
    selector: 'hm-member-selector',
    templateUrl: './member-selector.component.html',
    styleUrls: ['./member-selector.component.scss'],
})
export class MemberSelectorComponent implements OnInit, OnChanges {
    @Input() field: string;
    @Input() index: string;
    @Input() type: string;
    @Input() j: number; // Add input for index
    @Input() readOnly: boolean;
    @Output() fieldValueSelected: EventEmitter<string> = new EventEmitter<string>();

    members: string[] = [];

    constructor(public cubesService: CubesService) {}

    ngOnInit(): void {
        if (this.field != '') {
            this.fetchMembers();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes.field && changes.field.currentValue) ||
            (changes.type && changes.type.currentValue)
        ) {
            this.fetchMembers();
        }
    }

    fetchMembers(): void {
        const req = new MembersBuilder({
            type: 'members',
            field: new Field(this.field),
            index: this.index,
        }).build();
        if (this.field != '') {
            this.cubesService.members(req).subscribe((data: MembersResponse) => {
                this.members = data.members.map((m) => {
                    if (this.type == 'date') {
                        let date = new Date(m.value);
                        return formatDate(date);
                    } else return m.value.toString();
                });
            });
        }
    }

    showMemberDropdown(): void {
        this.fetchMembers();
    }

    selectMember(value: string): void {
        this.fieldValueSelected.emit(value);
    }
}
