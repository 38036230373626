<span>{{ (organisation$ | async).name }}</span>

<button
    *ngxPermissionsOnly="['SYS_ADMIN', 'CLIENT_ADMIN']"
    class="toggle"
    mat-icon-button
    matTooltip="Edit organisation"
    routerLink="/admin/organisations/edit/{{ (organisation$ | async).id }}"
    (click)="$event.stopPropagation()"
>
    <mat-icon>create</mat-icon>
</button>

<button class="toggle" mat-icon-button matTooltip="Switch organisation" [matMenuTriggerFor]="switchMenu" (click)="$event.stopPropagation()">
    <mat-icon>switch_camera</mat-icon>
</button>
<mat-menu class="organisation-switch-menu" #switchMenu="matMenu" xPosition="before">
    <ng-template matMenuContent>
        <div class="switch">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node mat-menu-item *matTreeNodeDef="let node" matTreeNodePadding (click)="switchTo(node)">
                    <!-- leaf -->
                    <button class="tree-icon" mat-icon-button disabled></button>
                    {{ node.name }}
                </mat-tree-node>

                <mat-tree-node mat-menu-item *matTreeNodeDef="let node; when: hasChildren" matTreeNodePadding (click)="switchTo(node)">
                    <!-- non leaf -->
                    <button
                        class="tree-icon"
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.area-label]="'Toggle ' + node.name"
                        (click)="$event.stopPropagation()"
                    >
                        <mat-icon>
                            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                        </mat-icon>
                    </button>
                    {{ node.name }}
                </mat-tree-node>
            </mat-tree>
        </div>
    </ng-template>
</mat-menu>
