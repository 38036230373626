<!-- begin:: Brand -->
<div class="kt-aside__brand kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_aside_brand">
    <div class="kt-aside__brand-logo">
        <a href="javascript:;" routerLink="/">
            <!-- <img alt="logo" src="./assets/media/whitelabel/logo.png" /> -->
            <img alt="logo" [src]="logo | assetsSource" />
        </a>
    </div>
</div>

<!-- end:: Brand -->
