import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'app/views/partials/content/category/shared/category.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// @TODO: centralise these declarations together with region levels/data permissions in core
export type Level =
    | 'sa2'
    | 'sa3'
    | 'sa4'
    | 'tr'
    | 'state'
    | 'lga'
    | 'dn'
    | 'geometry'
    | 'hotel'
    | 'wdpa';

@Injectable({
    providedIn: 'root',
})
export class ListingsService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };

    constructor(private http: HttpClient) {}

    getCategories(categoryType?: string): Observable<Category[]> {
        if (categoryType) {
            this.options.params = this.options.params.set('category_type', categoryType);
        }

        return this.http
            .get<any[]>(`${this.apiBase}/listings/categories`, this.options)
            .pipe(map((array) => array.map((obj) => new Category(obj))));
    }

    extractDate(datepart: string) {
        // expects a string like // 2020-Q1 or 2020-JAN
        const year = datepart.slice(0, 4);
        let month = null;
        let quarter = null;
        const restOfDate = datepart.slice(5, datepart.length);
        switch (restOfDate) {
            case 'Jan':
                month = 1;
                break;
            case 'Feb':
                month = 2;
                break;
            case 'Mar':
                month = 3;
                break;
            case 'Apr':
                month = 4;
                break;
            case 'May':
                month = 5;
                break;
            case 'Jun':
                month = 6;
                break;
            case 'Jul':
                month = 7;
                break;
            case 'Aug':
                month = 8;
                break;
            case 'Sep':
                month = 9;
                break;
            case 'Oct':
                month = 10;
                break;
            case 'Nov':
                month = 11;
                break;
            case 'Dec':
                month = 12;
                break;
            case 'Q1':
                quarter = 1;
                break;
            case 'Q2':
                quarter = 2;
                break;
            case 'Q3':
                quarter = 3;
                break;
            case 'Q4':
                quarter = 4;
                break;
            default:
                month = 1;
        }
        return [year, month, quarter];
    }
}
