import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Measure } from '../../shared/measure.model';
import { string } from '@amcharts/amcharts4/core';

export interface MeasureState extends EntityState<Measure> {
    error: boolean;
    loading: boolean;
}

export const measureAdapter: EntityAdapter<Measure> = createEntityAdapter<Measure>({
    selectId: (measure: Measure) => measure.uniqueName,
});

export const initialMeasureState: MeasureState = measureAdapter.getInitialState({
    error: false,
    loading: true,
});
