import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    QueryList,
    ViewChild,
} from '@angular/core';
import { SliderItemDirective } from './slider-item-directive';

@Component({
    selector: 'hm-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterContentInit {
    @ContentChildren(SliderItemDirective, { read: ElementRef }) items: QueryList<
        ElementRef<HTMLDivElement>
    >;
    @ViewChild('slides') slidesContainer: ElementRef<HTMLDivElement>;

    public slidesIndex = 0;

    get currentItem(): ElementRef<HTMLDivElement> {
        return this.items.find((item, index) => index === this.slidesIndex);
    }
    getTotal() {
        return this.items.length;
    }

    ngAfterContentInit() {}

    ngAfterViewInit() {}

    onClickLeft() {
        this.slidesContainer.nativeElement.scrollLeft -=
            this.currentItem.nativeElement.offsetWidth + 40;

        if (this.slidesIndex > 0) {
            this.slidesIndex--;
        }
    }

    onClickRight() {
        this.slidesContainer.nativeElement.scrollLeft +=
            this.currentItem.nativeElement.offsetWidth + 40;

        if (this.slidesIndex < this.items.length - 1) {
            this.slidesIndex++;
        }
    }
}
