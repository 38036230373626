import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'hm-boundary-type-select',
    templateUrl: './boundary-type-select.component.html',
    styleUrls: ['./boundary-type-select.component.scss'],
})
export class BoundaryTypeSelectComponent implements OnInit, OnChanges {
    @Input() label: string = '';
    @Input() boundaryType: DataPermissionType = DataPermissionType.STATE;
    @Output() boundaryTypeChange = new EventEmitter<DataPermissionType>();

    @Input() boundaryTypes: DataPermissionType[];
    boundaryTypeControl = new UntypedFormControl(this.boundaryType);

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaryType && this.boundaryTypeControl.value != this.boundaryType) {
            this.boundaryTypeControl.setValue(this.boundaryType);
        }
    }

    ngOnInit() {
        this.boundaryTypeControl.valueChanges.subscribe((v) => {
            this.boundaryType = v;
            this.boundaryTypeChange.emit(v);
        });
    }

    changes(): Observable<DataPermissionType> {
        return this.boundaryTypeControl.valueChanges.pipe(startWith(this.boundaryType));
    }

    changeBoundary(e, type) {
        e.stopPropagation();
        e.preventDefault();
        this.boundaryType = type;
        this.boundaryTypeChange.emit(type);
    }
}
