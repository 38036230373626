import {
    UserDataPermissionActions,
    UserDataPermissionActionType,
} from '../actions/user-data-permissions.action';
import {
    initialUserDataPermissionState,
    UserDataPermissionState,
    userDataPermissionAdapter,
} from '../states/user-data-permissions.state';

export function userDataPermissionReducer(
    state = initialUserDataPermissionState,
    action: UserDataPermissionActions
): UserDataPermissionState {
    switch (action.type) {
        case UserDataPermissionActionType.LoadUserDataPermissions: {
            return { ...state, loading: true };
        }
        case UserDataPermissionActionType.LoadUserDataPermissionsSuccess: {
            return userDataPermissionAdapter.setAll(action.payload.userDataPermission, {
                ...state,
                error: false,
                loading: false,
            });
        }
        case UserDataPermissionActionType.LoadUserDataPermissionsFailure: {
            return userDataPermissionAdapter.removeAll({
                ...state,
                error: true,
                loading: false,
            });
        }
        default:
            return state;
    }
}
