import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ExportPdfService } from 'app/core/_base/layout/services/export-pdf.service';
import { filter } from 'rxjs';
import { OverlayService } from '../../overlay/overlay.service';
import { PrintService } from '../../print/print.service';

@Component({
    selector: 'hm-export-menu',
    templateUrl: './export-menu.component.html',
    styleUrls: ['./export-menu.component.scss'],
})
export class ExportMenuComponent implements OnDestroy {
    @Input() printContainer = 'print-container';
    @Input() showPdf = false;
    @Input() showPng = true;

    constructor(
        //  private router: Router,  // if we want to use the page as the download name
        private pdfExporter: ExportPdfService,
        private printService: PrintService,
        private overlayService: OverlayService,
        private router: Router
    ) {
        router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            let path = event as NavigationEnd;
            if (path.url.includes('survey')) {
                this.showPdf = true;
            } else {
                this.showPdf = false;
            }
        });
    }

    ngOnDestroy(): void {}

    downloadPDF() {
        // window.resizeTo(1800, 1200);
        window.resizeTo(window.screen.availWidth / 2, window.screen.availHeight / 2);
        window.scroll(0, 0);

        // this.printService.printOn();
        window.setTimeout(() => window.print(), 2);
    }

    downloadPNG() {
        window.scroll(0, 0);
        this.printService.printOn();
        window.setTimeout(() => this.pdfExporter.open(this.printContainer, 'png', 'download'), 2);
    }

    showTrial(e) {
        e.preventDefault();
        this.overlayService.overlayOn();
    }
}
