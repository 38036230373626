import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../../category/shared/category.model';

@Component({
    selector: 'hm-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
    @Input() categories: Category[];
    @Input() showCategories: boolean = true;
    @Output() toggleCategory = new EventEmitter<Category>();

    constructor() {}

    ngOnInit() {}
    onCategoryChange(category) {
        this.toggleCategory.emit(category);
    }
}
