import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnquireForm } from 'app/views/partials/content/enquire/shared/enquire.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MarketplaceService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };

    constructor(private http: HttpClient) {}

    enquire(request: EnquireForm) {
        const endpoint = `${this.apiBase}/marketplace/enquire`;

        return this.http.post<EnquireForm>(endpoint, request, {
            ...this.options,
            observe: 'response',
        });
    }
}
