import { CategoryActions, CategoryActionType } from '../actions/category.actions';
import { initialCategoryState, CategoryState, categoryAdapter } from '../states/category.state';

export function categoryReducer(
    state = initialCategoryState,
    action: CategoryActions
): CategoryState {
    switch (action.type) {
        case CategoryActionType.Loading: {
            return { ...state, loading: true };
        }
        case CategoryActionType.LoadSuccess: {
            return categoryAdapter.setAll(action.payload.categories, {
                ...state,
                error: false,
                loading: false,
            });
        }
        case CategoryActionType.LoadFailure: {
            return categoryAdapter.removeAll({
                ...state,
                error: true,
                loading: false,
            });
        }
        case CategoryActionType.ToggleCategory: {
            return categoryAdapter.updateOne(action.payload, state);
        }
        default:
            return state;
    }
}
