// Angular
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Layout
import { MenuHorizontalService, OffcanvasOptions } from '../../../../core/_base/layout';
// HTML Class
import { HtmlClassService } from '../../html-class.service';

@Component({
    selector: 'kt-menu-horizontal',
    templateUrl: './menu-horizontal.component.html',
    styleUrls: ['./menu-horizontal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalComponent implements OnInit {
    // Public properties
    currentRouteUrl: any = '';
    offcanvasOptions: OffcanvasOptions = {
        overlay: true,
        baseClass: 'kt-header-menu-wrapper',
        closeBy: 'kt_header_menu_mobile_close_btn',
        toggleBy: {
            target: 'kt_header_mobile_toggler',
            state: 'kt-header-mobile__toolbar-toggler--active',
        },
    };
    constructor(
        public htmlClassService: HtmlClassService,
        public menuHorService: MenuHorizontalService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.currentRouteUrl = this.router.url;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                this.currentRouteUrl = this.router.url;
                this.cdr.markForCheck();
            });
    }
}
