import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'kt-travel-time',
    templateUrl: './travel-time.component.html',
    styleUrls: ['./travel-time.component.scss'],
})
export class TravelTimeComponent implements OnInit {
    @Output() travelTimeEvent = new EventEmitter<string>();
    constructor() {}

    ngOnInit() {}

    // When travel time changes, emit the changes to travelTimeEvent and Output it to HotelsComponent
    updateTravelTime(travelTime): void {
        this.travelTimeEvent.emit(travelTime);
    }
}
