import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Measure } from 'app/views/partials/content/measures/shared/measure.model';
import { GraphModel } from '../../shared/graph.model';
import { DeviceGPSDG } from '../shared/device-gps-demographics.model';
import { Subject, takeUntil } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectTop3Colors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-mobile-graph-demographics',
    template: `<div class="chart" id="{{ this.chartName }}"></div>`,
    styleUrls: ['./device-gps-demographics-graph.component.scss'],
})
export class DeviceGPSDemographicGraphComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
    @Input() data: DeviceGPSDG[];
    @Input() selectedMeasure: Measure;

    private chart: am4charts.XYChart;
    chartName = 'devicegpschartdiv' + Math.random().toString();

    private mChart: am4charts.XYChart;
    private fChart: am4charts.XYChart;

    emptyState = false;
    top3Colors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngOnInit() {
        this.store
            .pipe(select(selectTop3Colors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.top3Colors = colors;
            });
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            am4core.options.autoDispose = true;
            const mainContainer = am4core.create(this.chartName, am4core.Container);
            mainContainer.width = am4core.percent(100);
            mainContainer.height = am4core.percent(100);
            mainContainer.layout = 'horizontal';

            // Hiding AmCharts logo
            mainContainer.logo.height = -20000;

            const mChart = mainContainer.createChild(am4charts.XYChart);
            mChart.paddingRight = 0;
            mChart.paddingLeft = 0;
            mChart.colors.list = [am4core.color(this.top3Colors[0])];

            mChart.data = [];

            const mCategoryAxis = mChart.yAxes.push(new am4charts.CategoryAxis());
            mCategoryAxis.dataFields.category = 'age_band';
            mCategoryAxis.renderer.grid.template.location = 0;
            mCategoryAxis.renderer.minGridDistance = 15;

            const mValueAxis = mChart.xAxes.push(new am4charts.ValueAxis());
            mValueAxis.renderer.inversed = true;
            mValueAxis.min = 0;
            mValueAxis.numberFormatter = new am4core.NumberFormatter();
            mValueAxis.numberFormatter.numberFormat = this.selectedMeasure.format;

            const fChart = mainContainer.createChild(am4charts.XYChart);
            fChart.paddingRight = 0;
            fChart.paddingLeft = 0;
            fChart.colors.list = [am4core.color(this.top3Colors[1])];

            fChart.data = [];

            const fCategoryAxis = fChart.yAxes.push(new am4charts.CategoryAxis());
            fCategoryAxis.renderer.opposite = true;
            fCategoryAxis.dataFields.category = 'age_band';
            fCategoryAxis.renderer.grid.template.location = 0;
            fCategoryAxis.renderer.minGridDistance = 15;

            const fValueAxis = fChart.xAxes.push(new am4charts.ValueAxis());
            fValueAxis.min = 0;
            fValueAxis.numberFormatter = new am4core.NumberFormatter();
            fValueAxis.numberFormatter.numberFormat = this.selectedMeasure.format;
            fValueAxis.renderer.minLabelPosition = 0.01;

            this.mChart = mChart;
            this.fChart = fChart;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.data && !changes.data.isFirstChange()) ||
            (changes.selectedMeasure && !changes.selectedMeasure.isFirstChange())
        ) {
            this.mChart.series.clear();
            this.fChart.series.clear();
            this.setData();
            this.createSeries();
        }
    }

    setData() {
        this.removeData();
        const mChart = this.mChart;
        const fChart = this.fChart;

        const mData = [];
        const fData = [];

        const newSelectedMeasure = this.selectedMeasure;
        const newData = this.data;
        const valueName = newSelectedMeasure.uniqueName;

        newData.forEach((element) => {
            const newItem: GraphModel = new GraphModel();
            if (element[valueName] !== -1) {
                let gender = element['gender'];
                this.emptyState = false;
                if (gender === 'M') {
                    newItem['age_band'] = element['age_band'];
                    newItem['M'] = element[valueName];
                    newItem['F'] = 0;
                    mData.push(newItem);
                } else {
                    newItem['age_band'] = element['age_band'];
                    newItem['F'] = element[valueName];
                    newItem['M'] = 0;
                    fData.push(newItem);
                }
            } else {
                this.emptyState = true;
            }
        });

        mChart.invalidateRawData();
        fChart.invalidateRawData();

        mChart.numberFormatter.numberFormat = this.selectedMeasure.format;
        fChart.numberFormatter.numberFormat = this.selectedMeasure.format;

        mChart.data = mData;
        fChart.data = fData;

        this.mChart = mChart;
        this.fChart = fChart;
    }

    removeData() {
        this.mChart.data = [];
        this.fChart.data = [];
        this.mChart.validateData();
        this.fChart.validateData();
    }

    createSeries() {
        const mChart = this.mChart;
        const fChart = this.fChart;

        const mSeries = mChart.series.push(new am4charts.ColumnSeries());
        mSeries.dataFields.valueX = 'M';
        mSeries.dataFields.valueXShow = 'value';
        mSeries.calculatePercent = false;
        mSeries.fill = mChart.colors.getIndex(0);
        mSeries.stroke = mSeries.fill;
        mSeries.dataFields.categoryY = 'age_band';
        mSeries.interpolationDuration = 1000;
        mSeries.columns.template.tooltipText = 'Males, age{categoryY}: {valueX}';

        const fSeries = fChart.series.push(new am4charts.ColumnSeries());
        fSeries.dataFields.valueX = 'F';
        fSeries.dataFields.valueXShow = 'value';
        fSeries.calculatePercent = false;
        fSeries.fill = fChart.colors.getIndex(0);
        fSeries.stroke = fSeries.fill;
        fSeries.dataFields.categoryY = 'age_band';
        fSeries.interpolationDuration = 1000;
        fSeries.columns.template.tooltipText = 'Females, age{categoryY}: {valueX}';

        this.mChart = mChart;
        this.fChart = fChart;
    }
}
