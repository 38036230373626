import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

import {
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { permissionToBoundary } from 'app/core/data-permissions/shared/data-permission.model';
import { selectUserDataPermissionsByType } from 'app/core/user-data-permissions/store/selectors/user-data-permissions.selectors';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { BoundaryTypeSelectorComponent } from '../boundary-type-selector/boundary-type-selector.component';

@Component({
    selector: 'hm-boundary-autocomplete-selector',
    templateUrl: './boundary-autocomplete-selector.component.html',
    styleUrls: ['./boundary-autocomplete-selector.component.scss'],
})
export class BoundaryAutocompleteSelectorComponent implements OnInit {
    @Input() label: string = '';
    @Input() boundaries: Boundary[] = [];
    @Output() boundariesChange = new EventEmitter<Boundary[]>();

    @Input() typeSelector: BoundaryTypeSelectorComponent;

    filteredBoundaries: Observable<Boundary[]>;
    boundaryControl = new UntypedFormControl();
    @ViewChild('boundaryInput', { static: true }) boundaryInput: ElementRef<HTMLInputElement>;

    constructor(private store: Store<{}>) {}

    ngOnInit() {
        this.filteredBoundaries = combineLatest([
            this.typeSelector.changes(),
            this.boundaryControl.valueChanges.pipe(
                debounceTime(500),
                startWith(''),
                map((input: string) => input.toLowerCase())
            ),
        ]).pipe(
            switchMap(([type, input]) =>
                this.store.select(selectUserDataPermissionsByType(), { type }).pipe(
                    map((perms) => perms.filter((p) => p.title.toLowerCase().indexOf(input) > -1)),
                    map((perms) => perms.map(permissionToBoundary)),
                    map((bdys) =>
                        bdys.filter((b) =>
                            this.boundaries.every((existing) => existing.code != b.code)
                        )
                    )
                )
            )
        );
    }

    boundarySelected(event: MatAutocompleteSelectedEvent) {
        this.boundaries = [event.option.value];
        this.boundariesChange.emit(this.boundaries);
        this.boundaryInput.nativeElement.value = '';
        this.boundaryControl.setValue('');
    }

    removeBoundary(boundary: Boundary) {
        this.boundaries = this.boundaries.filter((b) => b.code != boundary.code);
        this.boundariesChange.emit(this.boundaries);
        this.boundaryInput.nativeElement.value = '';
        this.boundaryControl.setValue('');
    }

    reopenPanel(event: Event, trigger: MatAutocompleteTrigger) {
        event.stopPropagation();
        trigger.openPanel();
    }
}
