import { Injectable } from '@angular/core';
import { CubesService, exclude, include, SelectBuilder, SelectResponse } from 'app/core/cubes';
import {
    dateRangeBetweenDays,
    includeYearQuarterMonth,
    monthNameToNumber,
} from 'app/core/utilities/date';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DigitalIntegrationTrend } from './digital-integration.model';
import { DigitalIntegrationFromWhere } from './from-where.model';

@Injectable({
    providedIn: 'root',
})
export class DigitalIntegrationService {
    constructor(private cubes: CubesService) {}

    getTrend(
        category: string[],
        level: Level,
        boundaryIDs: number[] | string[],
        start: string,
        end: string
    ): Observable<DigitalIntegrationTrend[]> {
        const request = new SelectBuilder()
            .query('digitalperf:device-gps-atdw-digitalperf-profile')
            .aggregate()
            .value('Number of Products', 'sum')
            .value('Views', 'sum')
            .value('Visits', 'sum')
            .value('Leads', 'sum')
            .value('Unique Visits', 'sum')
            .value('Time per Visit', 'average')
            .value('Time on Site', 'average')
            .value('Total Visits Local', 'sum')
            .value('Total Visits Visitor', 'sum')
            .value('Unique Visits Local', 'sum')
            .value('Unique Visits Visitor', 'sum')
            .byRows('Day', 'Month', 'Year')
            .totals(false)
            .filter(
                'and',
                include('Category', ...category),
                include('Region Type', level.toUpperCase()),
                include('Region Code', ...boundaryIDs),
                include('Visitor Type', 'Any'),
                include('Traffic Type', 'Any'),
                dateRangeBetweenDays(start, end) // 2020-Feb-01 -> 2020-Feb-05
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, DigitalIntegrationTrend[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          year: agg.keys['Year'] as number,
                          quarter_number: null,
                          month_number: Number(monthNameToNumber(agg.keys['Month'] as string)),
                          day_in_month: agg.keys['Day'] as number,
                          boundary_type: null,
                          boundary_state: null,
                          boundary_code: null,
                          boundary_name: null,
                          listing_category: null,
                          visitor_type: null,
                          traffic_type: null,
                          listing_count: agg.values['Number of Products']['sum'],
                          views: agg.values['Views']['sum'],
                          visits: agg.values['Visits']['sum'],
                          leads: agg.values['Leads']['sum'],
                          unique_visits: agg.values['Unique Visits']['sum'],
                          time_per_visit: agg.values['Time per Visit']['average'],
                          time_on_site: agg.values['Time on Site']['average'],
                          unique_visits_local: agg.values['Unique Visits Local']['sum'],
                          unique_visits_visitor: agg.values['Unique Visits Visitor']['sum'],
                          total_visits_local: agg.values['Total Visits Local']['sum'],
                          total_visits_visitor: agg.values['Total Visits Visitor']['sum'],
                      }))
                    : []
            )
        );
    }

    getFromWhere(
        level: Level,
        boundaryIDs: number[] | string[],
        month: string
    ): Observable<DigitalIntegrationFromWhere[]> {
        const request = new SelectBuilder()
            .query('digitalperf:device-gps-ifi-domestic-origin')
            .aggregate()
            // Visitation
            .value('Total Visits Local', 'sum')
            .value('Total Visits Visitor', 'sum')
            .value('Unique Visits Local', 'sum')
            .value('Unique Visits Visitor', 'sum')
            // ATDW
            .value('Volume Local', 'average')
            .value('Volume Visitor', 'average')
            .value('Value Local', 'average')
            .value('Value Visitor', 'average')
            .value('Volume Local 12 Month Avg', 'average')
            .value('Volume Visitor 12 Month Avg', 'average')
            .value('Value Local 12 Month Avg', 'average')
            .value('Value Visitor 12 Month Avg', 'average')

            .byRows('Origin State', 'Origin Code', 'Origin Name')
            .totals(false)
            .filter(
                'and',
                exclude('Origin State', 'Any'),
                include('Origin Type', level.toUpperCase()),
                include('Destination Code', ...boundaryIDs),
                includeYearQuarterMonth(month) // 2020-Feb
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, DigitalIntegrationFromWhere[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          year: null,
                          quarter_number: null,
                          month_number: null,
                          day_in_month: null,
                          boundary_type: null,
                          boundary_state: agg.keys['Origin State'] as string,
                          boundary_code: agg.keys['Origin Code'] as string,
                          boundary_name: agg.keys['Origin Name'] as string,
                          // Visitation
                          unique_visits_local: agg.values['Unique Visits Local']['sum'],
                          unique_visits_visitor: agg.values['Unique Visits Visitor']['sum'],
                          total_visits_local: agg.values['Total Visits Local']['sum'],
                          total_visits_visitor: agg.values['Total Visits Visitor']['sum'],
                          // ATDW
                          vol_tran_local: agg.values['Volume Local']['average'],
                          vol_tran_visitor: agg.values['Volume Visitor']['average'],
                          val_tran_local: agg.values['Value Local']['average'],
                          val_tran_visitor: agg.values['Value Visitor']['average'],
                          vol_tran_local_12months:
                              agg.values['Volume Local 12 Month Avg']['average'],
                          vol_tran_visitor_12months:
                              agg.values['Volume Visitor 12 Month Avg']['average'],
                          val_tran_local_12months:
                              agg.values['Value Local 12 Month Avg']['average'],
                          val_tran_visitor_12months:
                              agg.values['Value Visitor 12 Month Avg']['average'],
                      }))
                    : []
            )
        );
    }
}
