import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DataPermission } from '../../shared/data-permission.model';

export interface DataPermissionState extends EntityState<DataPermission> {
    error: boolean;
    loading: boolean;
}

export const dataPermissionAdapter: EntityAdapter<DataPermission> = createEntityAdapter<
    DataPermission
>({
    selectId: (dataPermission: DataPermission) => dataPermission.id,
});

export const initialDataPermissionState: DataPermissionState = dataPermissionAdapter.getInitialState(
    {
        error: false,
        loading: true,
    }
);
