import { Role } from '../../organisations/shared/role.model';

export class User {
    public id: string;
    public email: string;
    public auth0ID: string;
    public givenName: string;
    public familyName: string;
    public name: string;
    public phoneNumber: string;
    public roles: Role[];
    public organisationID: number;
    public originalOrganisationID: number;
    public domain?: string;

    constructor(obj: any) {
        Object.assign(this, obj);
        if (!this.familyName) {
            this.name = this.givenName;
        } else if (!this.givenName) {
            this.name = this.familyName;
        } else {
            this.name = this.givenName + ' ' + this.familyName;
        }
    }
}
