import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-resizer',
    templateUrl: './resizer.component.html',
    styleUrls: ['./resizer.component.scss'],
})
export class ResizerComponent implements OnInit {
    @Output() expanded = new EventEmitter();

    expandedState: string = '010';
    constructor() {}
    ngOnInit(): void {}

    chevron() {
        this.expandedState = '010';
        this.expanded.emit(this.expandedState);
    }
    chevronLeft() {
        this.expandedState = '100';
        this.expanded.emit(this.expandedState);
    }

    chevronRight() {
        this.expandedState = '001';
        this.expanded.emit(this.expandedState);
    }
    chevronLine() {
        if (this.expandedState == '010') {
            this.chevronLeft();
        } else if ((this.expandedState = '100')) {
            this.expandedState = '010';
            this.chevron();
        }
    }
}
