import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LngLat } from 'mapbox-gl';

export type RoutingProfile = 'walking' | 'cycling' | 'driving' | 'driving-traffic';

@Injectable({
    providedIn: 'root',
})
export class MapService {
    base = 'https://api.mapbox.com/';

    isochroneEndpoint = 'isochrone/v1/mapbox/';

    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };

    constructor(private http: HttpClient) {}

    getIsochrone(
        profile: RoutingProfile,
        coordinates: LngLat,
        contourMinutes: number[],
        polygons: boolean
    ) {
        let url = `${this.base}${this.isochroneEndpoint}`;
        url = url.concat(profile, '/', coordinates.toArray().join(','));

        this.options.params = new HttpParams({});
        this.options.params = this.options.params.set(
            'access_token',
            environment.mapbox.accessToken
        );

        this.options.params = this.options.params.set('contours_minutes', contourMinutes.join(','));

        this.options.params = this.options.params.set('denoise', 1);

        this.options.params = this.options.params.set('polygons', polygons);

        return this.http.get<GeoJSON.FeatureCollection>(url, { ...this.options });
    }
}
