export interface DigitalIntegrationFromWhere {
    boundary_state: string;
    boundary_code: string;
    boundary_name: string;
    boundary_type: string;
    unique_visits_local: number;
    total_visits_local: number;
    unique_visits_visitor: number;
    total_visits_visitor: number;
    vol_tran_local: number;
    vol_tran_visitor: number;
    val_tran_local: number;
    val_tran_visitor: number;
    vol_tran_local_12months: number;
    vol_tran_visitor_12months: number;
    val_tran_local_12months: number;
    val_tran_visitor_12months: number;
}

export interface DIFWCalculated {
    boundary_state: string;
    boundary_code: string;
    boundary_name: string;
    boundary_type: string;
    unique_visits_local: number;
    unique_visits_visitor: number;
    spend_per_cardholder_local: number | string;
    spend_per_cardholder_visitor: number | string;
    spend_per_cardholder_local_12months: number | string;
    spend_per_cardholder_visitor_12months: number | string;
}
