import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectDigitalIntegration } from 'app/core/session-info/store/selectors/session-info.selector';
import { dateToDateString, YEAR_MONTH_FMT } from 'app/core/utilities/date';
import { checkDates } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { isNumber } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Category } from '../../category/shared/category.model';
import { DataTableConfig } from '../data-table/data-table.model';
import { HeatMapConfig } from '../heat-map/shared/heat-map.model';
import { DigitalIntegrationService } from '../shared/digital-integration.service';
import { DigitalIntegrationFromWhere } from '../shared/from-where.model';
import { ProfileDataService } from './shared/profile-data.service';

@Component({
    selector: 'hm-profile-data',
    templateUrl: './profile-data.component.html',
    styleUrls: ['./profile-data.component.scss'],
})
export class ProfileDataComponent implements OnInit, OnChanges, OnDestroy {
    @Input() categories: Category[];
    @Input() boundaries: Boundary[];
    @Input() boundaryType: string;
    @Input() dateRange: Date[];

    dateToDisplay: string;

    dataAvailable = true;
    permissionsAvailable = false;

    multipleBoundaries = false;

    fromWhere: DigitalIntegrationFromWhere[];

    heatMapConfig: HeatMapConfig;
    private heatMapSubject = new BehaviorSubject<boolean>(false);
    heatMap$: Observable<boolean> = this.heatMapSubject.asObservable();

    fromWhereTableConfig: DataTableConfig;
    private fromWhereTableSubject = new BehaviorSubject<boolean>(false);
    fromWhereTable$: Observable<boolean> = this.fromWhereTableSubject.asObservable();

    currencyPipe: CurrencyPipe;

    private subscriptions: Subscription[] = [];

    constructor(
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private profileDataService: ProfileDataService,
        private digitalIntegrationService: DigitalIntegrationService
    ) {
        this.currencyPipe = new CurrencyPipe('en-AU');
    }

    ngOnInit(): void {}

    ngOnChanges() {
        this.checkPermissions();
        if (this.permissionsAvailable) {
            this.getData();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    checkPermissions() {
        const rights = this.store.pipe(select(selectDigitalIntegration));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = checkDates(r[0], this.dateRange[0], this.dateRange[1]);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    getData() {
        this.loadingService.loadingOn();
        const selectedDate = dateToDateString(this.dateRange[1], YEAR_MONTH_FMT);
        const date = selectedDate.split('-');
        this.dateToDisplay = `${date[1]} ${date[0]}`;

        const fromWhere$ = this.digitalIntegrationService.getFromWhere(
            this.boundaryType as Level,
            this.boundaries.map((boundary) => boundary.code),
            selectedDate
        );

        this.subscriptions.push(
            fromWhere$.subscribe({
                next: (res: any) => {
                    this.fromWhere = res;

                    if (this.fromWhere.length > 0) {
                        this.dataAvailable = true;
                        const calculatedData = this.profileDataService.getCalculatedFields(
                            this.fromWhere
                        );

                        const formattedTableData = calculatedData.map((d) => {
                            return {
                                ...d,
                                spend_per_cardholder_local: isNumber(d.spend_per_cardholder_local)
                                    ? this.currencyPipe.transform(
                                          d.spend_per_cardholder_local,
                                          'AUD',
                                          'symbol-narrow',
                                          '1.2-2'
                                      )
                                    : d.spend_per_cardholder_local,
                                spend_per_cardholder_visitor: isNumber(
                                    d.spend_per_cardholder_visitor
                                )
                                    ? this.currencyPipe.transform(
                                          d.spend_per_cardholder_visitor,
                                          'AUD',
                                          'symbol-narrow',
                                          '1.2-2'
                                      )
                                    : d.spend_per_cardholder_visitor,
                                spend_per_cardholder_local_12months: isNumber(
                                    d.spend_per_cardholder_local_12months
                                )
                                    ? this.currencyPipe.transform(
                                          d.spend_per_cardholder_local_12months,
                                          'AUD',
                                          'symbol-narrow',
                                          '1.2-2'
                                      )
                                    : d.spend_per_cardholder_local_12months,
                                spend_per_cardholder_visitor_12months: isNumber(
                                    d.spend_per_cardholder_visitor_12months
                                )
                                    ? this.currencyPipe.transform(
                                          d.spend_per_cardholder_visitor_12months,
                                          'AUD',
                                          'symbol-narrow',
                                          '1.2-2'
                                      )
                                    : d.spend_per_cardholder_visitor_12months,
                            };
                        });

                        this.heatMapConfig = this.profileDataService.getHeatMapConfig(
                            calculatedData,
                            this.boundaryType as Level
                        );

                        this.fromWhereTableConfig = {
                            ...this.profileDataService.getDataTableConfig(0, formattedTableData),
                        };
                        this.fromWhereTableConfig.watermark = 'Pending Licence Agreement';
                        this.heatMapSubject.next(true);
                        this.fromWhereTableSubject.next(true);
                    } else {
                        this.dataAvailable = false;
                    }
                },
                complete: () => {
                    this.loadingService.loadingOff();
                },
            })
        );
    }
}
