import { Action, createAction, props } from '@ngrx/store';
import { Filters, FiltersSelect } from '../shared/filters.model';

export enum CurrentFilterActionTypes {
    SetCurrentFilter = '[Filter] Set current filter',
}

export class SetCurrentFilter implements Action {
    readonly type = CurrentFilterActionTypes.SetCurrentFilter;

    constructor(public payload: { filter: Filters }) {}
}

export type CurrentFilterActions = SetCurrentFilter;

/* Search Filter */
export enum SearchFilterActionTypes {
    SetCurrentFilterSearch = '[Filter] Set search filter',
}

export class SetCurrentFilterSearch implements Action {
    readonly type = SearchFilterActionTypes.SetCurrentFilterSearch;

    constructor(public payload: { filter: FiltersSelect }) {}
}

export type SearchFilterActions = SetCurrentFilterSearch;
