import { Subscription } from 'rxjs';
// Angular
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService } from './core/_base/layout';
import { environment } from '../environments/environment';
import { AppState } from './core/reducers';
import { select, Store } from '@ngrx/store';
import { isUserLoaded, currentUser, User, isLoggedIn } from './core/auth';
import { DOCUMENT } from '@angular/common';
import { WhitelabelGet } from './core/whitelabel/store/whitelabel.actions';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[kt-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    // Public properties
    title = 'Hemisphere';
    loader: boolean;
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     * @param router: Router
     * @param layoutConfigService: LayoutCongifService
     * @param splashScreenService: SplashScreenService
     */
    constructor(
        private router: Router,
        private layoutConfigService: LayoutConfigService,
        private splashScreenService: SplashScreenService,
        private store: Store<AppState>,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document
    ) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */

    private style?: HTMLLinkElement;
    ngOnInit(): void {
        this.store.dispatch(new WhitelabelGet());
        let defaultColors = false;
        let cssPath = `/assets/whitelabels/${window.location.hostname}/wl.css`;
        if (window.location.hostname == 'demo.hemisphere.digital') {
            cssPath =
                'https://storage.googleapis.com/whitelabel-hd/' +
                window.location.hostname +
                `/wl.css?v=${Math.floor(Math.random() * 100) + 1}`;
            defaultColors = true;
        } else {
            cssPath = `assets/whitelabels/${window.location.hostname}/wl.css`;
        }

        if (defaultColors === true) {
            const style2: HTMLLinkElement = this.renderer.createElement('link') as HTMLLinkElement;
            this.renderer.appendChild(this.document.head, style2);
            this.renderer.setProperty(style2, 'rel', 'stylesheet');
            this.renderer.setProperty(
                style2,
                'href',
                'assets/whitelabels/demo.hemisphere.digital/scale.css'
            );
        }
        this.style = this.renderer.createElement('link') as HTMLLinkElement;
        this.renderer.appendChild(this.document.head, this.style);
        this.renderer.setProperty(this.style, 'rel', 'stylesheet');
        this.renderer.setProperty(this.style, 'href', cssPath);

        this.loader = this.layoutConfigService.getConfig('loader.enabled');

        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('kt-page--loaded');
                }, 500);
            }
        });
        this.unsubscribe.push(routerSubscription);
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.renderer.removeChild(this.document.head, this.style);
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
