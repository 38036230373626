<!-- begin:: Page -->
<ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
    <!-- begin:: Header Mobile -->
    <kt-header-mobile></kt-header-mobile>
    <!-- end:: Header Mobile -->

    <div class="kt-grid kt-grid--hor kt-grid--root">
        <!-- begin::Body -->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <!-- begin:: Aside Left -->
            <ng-container *ngIf="asideDisplay">
                <button class="kt-aside-close" id="kt_aside_close_btn"><i class="la la-close"></i></button>
                <kt-aside-left></kt-aside-left>
            </ng-container>
            <!-- end:: Aside Left -->

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                <!-- begin:: Header -->
                <!-- <kt-header *ngIf="desktopHeaderDisplay"></kt-header> -->
                <!-- end:: Header -->
                <kt-header> </kt-header>

                <div id="print-container">
                    <hm-print></hm-print>
                    <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
                        <!-- begin:: Content Head -->

                        <!-- end:: Content Head -->

                        <!-- begin:: Content Body -->
                        <div class="kt-container kt-grid__item kt-grid__item--fluid" [ngClass]="{ 'kt-container--fluid': fluid }">
                            <router-outlet></router-outlet>
                        </div>
                        <!-- end:: Content Body -->
                    </div>
                </div>
                <!-- end:: Content -->

                <!-- <kt-footer></kt-footer> -->
            </div>
        </div>
        <!-- end:: Body -->

        <kt-scroll-top></kt-scroll-top>
        <hm-impersonate></hm-impersonate>
    </div>
</ng-container>
<!-- end:: Page -->

<ng-template #blankLayout>
    <router-outlet></router-outlet>
</ng-template>
