import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { environment } from 'environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { Auth0Guard } from './core/auth';
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../app/views/pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [Auth0Guard],
        children: [
            {
                path: 'admin',
                loadChildren: () =>
                    import('./views/pages/admin/admin.module').then((m) => m.AdminModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['SYS_ADMIN', 'CLIENT_ADMIN'],
                        redirectTo: '/',
                    },
                },
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('./views/pages/users/users.module').then((m) => m.UsersModule),
            },
            {
                path: 'marketplace',
                loadChildren: () =>
                    import('./views/pages/marketplace/marketplace.module').then(
                        (m) => m.MarketplaceModule
                    ),
            },
            {
                path: 'regions',
                loadChildren: () =>
                    import('./views/pages/regions/regions.module').then((m) => m.RegionsModule),
            },
            {
                path: 'analysis',
                loadChildren: () =>
                    import('./views/pages/analysis/analysis.module').then((m) => m.AnalysisModule),
            },
            {
                path: 'traffic',
                loadChildren: () =>
                    import('./views/pages/traffic/traffic.module').then((m) => m.TrafficModule),
            },
            {
                path: 'kad',
                loadChildren: () =>
                    import('./views/pages/key-account-data/key-account-data.module').then(
                        (m) => m.KeyAccountDataModule
                    ),
            },
            {
                path: 'demo',
                loadChildren: () =>
                    import('./views/pages/demo/demo.module').then((m) => m.DemoModule),
            },
            {
                path: 'survey',
                loadChildren: () =>
                    import('./views/pages/survey/survey.module').then((m) => m.SurveyModule),
            },
            {
                path: 'digital-integration',
                loadChildren: () =>
                    import('./views/pages/digital-integration/digital-integration.module').then(
                        (m) => m.DigitalIntegrationModule
                    ),
            },
            {
                path: 'data-centre',
                loadChildren: () =>
                    import('./views/pages/data-centre/data-centre.module').then(
                        (m) => m.DataCentreModule
                    ),
            },
            {
                path: 'hotels',
                loadChildren: () =>
                    import('./views/pages/hotels/hotels.module').then((m) => m.HotelsModule),
            },
            {
                path: 'metrics',
                loadChildren: () =>
                    import('./views/pages/metrics/metrics.module').then((m) => m.MetricsModule),
            },
            {
                path: 'downloads',
                loadChildren: () =>
                    import('./views/pages/download-reports/download-reports.module').then(
                        (m) => m.DownloadReportsModule
                    ),
            },
            {
                path: 'download-cubes',
                loadChildren: () =>
                    import('./views/pages/download-cubes/download-cubes.module').then(
                        (m) => m.DownloadCubesModule
                    ),
            },
            {
                path: 'testing',
                loadChildren: () =>
                    import('./views/pages/testing/testing.module').then((m) => m.TestingModule),
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: {
                    type: 'error-v6',
                    code: 403,
                    title: '403... Access forbidden',
                    desc: `Looks like you don't have permission to access the
                        requested page.<br> Please, contact the administrator`,
                },
            },
            { path: '**', redirectTo: environment.defaultHome, pathMatch: 'full' },
        ],
    },
    { path: 'error/:type', component: ErrorPageComponent },
    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.licenseKey),
        NgxGoogleAnalyticsRouterModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
