import { createSelector, createFeatureSelector } from '@ngrx/store';
import {
    DataPermission,
    DataPermissionType,
} from 'app/core/data-permissions/shared/data-permission.model';
import {
    UserDataPermissionState,
    userDataPermissionAdapter,
} from '../states/user-data-permissions.state';

const { selectIds, selectEntities, selectAll } = userDataPermissionAdapter.getSelectors();

export const selectUserDataPermissionState =
    createFeatureSelector<UserDataPermissionState>('user-data-permissions');
export const selectUserDataPermissionIds = createSelector(selectUserDataPermissionState, selectIds);
export const selectUserDataPermissionEntities = createSelector(
    selectUserDataPermissionState,
    selectEntities
);
export const selectAllUserDataPermission = createSelector(selectUserDataPermissionState, selectAll);

export const selectUserDataPermissionsBoundaries = createSelector(
    selectAllUserDataPermission,
    (state) => state.filter((x) => x.type != DataPermissionType.SETTING)
);

export const dataPermissionsUserSettings = createSelector(selectAllUserDataPermission, (state) =>
    state.filter((x) => x.type == DataPermissionType.SETTING)
);

export const pagePermissionsUserSettings = createSelector(selectAllUserDataPermission, (state) =>
    state.filter((x) => x.type == DataPermissionType.SETTING && x.grouping == 'PAGE')
);

export const selectUserDataPermissionsByType = () =>
    createSelector(
        selectUserDataPermissionState,
        (state: UserDataPermissionState, props: { type: DataPermissionType }) =>
            selectAll(state).filter((p) => p.type == props.type)
    );

export const selectUserPermittedBoundaryTypes = createSelector(
    selectUserDataPermissionState,
    (state) =>
        Array.from(
            new Set(
                selectAll(state)
                    .map((perm) => perm.type)
                    .filter((t) => t != DataPermissionType.SETTING)
            )
        )
);

export const selectUserDataPermissionError = createSelector(
    selectUserDataPermissionState,
    (state: UserDataPermissionState): boolean => state.error
);
export const selectUserDataPermissionLoading = createSelector(
    selectUserDataPermissionState,
    (state: UserDataPermissionState): boolean => state.loading
);

export const areUserDataPermissionsLoaded = createSelector(
    selectUserDataPermissionState,
    (state) => state.entities
);

export const userPermissionsLoaded = createSelector(
    selectUserDataPermissionIds,
    (state) => state.length > 0
);
