import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { SelectedPoint } from '../../../../pages/hotels/shared/selected-point.model';
import { Category } from '../../category/shared/category.model';
import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';

@Component({
    selector: 'kt-region-controls',
    templateUrl: './region-controls.component.html',
    styleUrls: ['./region-controls.component.scss'],
})
export class RegionControlsComponent implements OnChanges {
    @Input() showMapSpinner: boolean;
    @Input() mapMessage: string;
    @Input() categories: Category[];
    @Input() showCategories: boolean = true;
    @Input() availableBoundaryTypes: DataPermissionType[];
    @Input() areas: SelectedPoint[];

    @Output() removeArea = new EventEmitter<SelectedPoint>();
    @Output() zoomToPoint = new EventEmitter<SelectedPoint>();
    @Output() toggleCategory = new EventEmitter<Category>();

    // two-way data binding
    @Input() boundaryType: DataPermissionType = DataPermissionType.STATE;
    @Output() boundaryTypeChange = new EventEmitter<DataPermissionType>();

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.availableBoundaryTypes) {
            if (!this.availableBoundaryTypes.includes(this.boundaryType)) {
                this.onSelectBoundaryType(this.availableBoundaryTypes[0]);
            }
        }
        if (changes.boundaryType) {
            if (!this.availableBoundaryTypes.includes(this.boundaryType)) {
                this.onSelectBoundaryType(changes.boundaryType.previousValue);
            }
        }
    }

    onAreaChange(area: SelectedPoint) {
        this.removeArea.emit(area);
    }

    onZoomToPoint(point: SelectedPoint) {
        this.zoomToPoint.emit(point);
    }

    onSelectBoundaryType(boundaryType: DataPermissionType) {
        this.boundaryType = boundaryType;
        this.boundaryTypeChange.emit(boundaryType);
    }

    onCategoryChange(category) {
        this.toggleCategory.emit(category);
    }
}
