import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { SubheaderService } from 'app/core/_base/layout';

@Component({
    selector: 'hm-downloads-header',
    templateUrl: './downloads-header.component.html',
    styleUrls: ['./downloads-header.component.scss'],
})
export class DownloadsHeaderComponent implements OnInit {
    title: string;
    constructor(
        private store: Store<AppState>,
        private subheaderService: SubheaderService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.activatedRoute.data.subscribe((data) => {
            this.title = data.title;
        });
    }
}
