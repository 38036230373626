import { Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'hm-fullscreen-button',
    templateUrl: './fullscreen-button.component.html',
    styleUrls: ['./fullscreen-button.component.scss'],
})
export class FullscreenButtonComponent implements OnInit {
    @Input() element: HTMLElement;
    fullscreen = false;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    ngOnInit() {}

    toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
        if (!this.document.fullscreenElement) {
            this.element.requestFullscreen().catch((err) => {
                console.log(`Error while entering fullscreen mode: ${err.message} (${err.name})`);
            });
        } else {
            this.document.exitFullscreen();
        }
    }
}
