import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SentimentListing } from './sentiment-listing.model';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SentimentListingsService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };
    constructor(private http: HttpClient) {}

    getListings(
        datepart: string,
        level: string,
        bdycode: string[],
        group: string,
        categoryIDs: number[]
    ): Observable<SentimentListing[]> {
        const [year, month, quarter] = this.extractDate(datepart);
        this.options.params = this.options.params.set('year', year);
        this.options.params = this.options.params.set('level', level);
        this.options.params = this.options.params.set('bdy_code', bdycode.join(','));
        this.options.params = this.options.params.set('group', group);

        if (categoryIDs.length > 0) {
            this.options.params = this.options.params.set('category_ids', categoryIDs.join(','));
        }

        if (month) {
            this.options.params = this.options.params.set('month_number', month);
        }
        if (quarter) {
            this.options.params = this.options.params.set('quarter_number', quarter);
        }

        return this.http.get<SentimentListing[]>(
            `${this.apiBase}/listings/sentiment`,
            this.options
        );
    }

    extractDate(datepart: string) {
        // expects a string like // 2020-Q1 or 2020-JAN
        const year = datepart.slice(0, 4);
        let month = null;
        let quarter = null;
        const restOfDate = datepart.slice(5, datepart.length);
        switch (restOfDate) {
            case 'Jan':
                month = 1;
                break;
            case 'Feb':
                month = 2;
                break;
            case 'Mar':
                month = 3;
                break;
            case 'Apr':
                month = 4;
                break;
            case 'May':
                month = 5;
                break;
            case 'Jun':
                month = 6;
                break;
            case 'Jul':
                month = 7;
                break;
            case 'Aug':
                month = 8;
                break;
            case 'Sep':
                month = 9;
                break;
            case 'Oct':
                month = 10;
                break;
            case 'Nov':
                month = 11;
                break;
            case 'Dec':
                month = 12;
                break;
            case 'Q1':
                quarter = 1;
                break;
            case 'Q2':
                quarter = 2;
                break;
            case 'Q3':
                quarter = 3;
                break;
            case 'Q4':
                quarter = 4;
                break;
            default:
                month = 1;
        }
        return [year, month, quarter];
    }
}
