import { Component, Input, OnInit } from '@angular/core';
import { SurveyCardConfig } from './survey-card.model';

@Component({
    selector: 'hm-survey-card',
    templateUrl: './survey-card.component.html',
    styleUrls: ['./survey-card.component.scss'],
})
export class SurveyCardComponent implements OnInit {
    @Input() config: SurveyCardConfig;
    @Input() icon: string;

    constructor() {}

    ngOnInit(): void {}
}
