import { Injectable } from '@angular/core';
import { CubesService, exclude, include, SelectBuilder, SelectResponse } from 'app/core/cubes';
import { includeYearQuarterMonth, monthNameToNumber } from 'app/core/utilities/date';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getSchemaOptions, InternationalBoundaryType } from 'app/core/dataviz/dataviz.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { DeviceGPS } from './mobile-gps/shared/device-gps.model';
import { Sentiment } from './sentiment/sentiment.model';
import {
    IfiDomesticRP,
    IfiDomesticSegmented,
} from './transaction-ifi-domestic/shared/transaction-ifi-domestic.model';
import { IfiIntRP } from './transaction-ifi-int/shared/transaction-ifi-int.model';

@Injectable({ providedIn: 'root' })
export class ProfilerService {
    // 02/08/2023:
    // Very similar to Trend Analysis by Category, except that we only look at one month.
    // Can be refactored.
    constructor(private cubes: CubesService) {}

    getDeviceGPS(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<DeviceGPS[]> {
        const request = new SelectBuilder()
            .query('region:device-gps')
            .aggregate()
            .value('Visitor Ratio', 'average')
            .value('Local Ratio', 'average')
            .value('Resident Population', 'sum')
            .value('Total Visits Local', 'sum')
            .value('Total Visits Visitor', 'sum')
            .value('Unique Visits Local', 'sum')
            .value('Unique Visits Visitor', 'sum')
            .byRow('Dwell Time Band')
            .totals(false)
            .filter(
                'and',
                exclude('Dwell Time Band', 'Any'),
                include('Region Type', level.toUpperCase()),
                include('Region Code', ...boundaryIDs),
                include('Customer State', 'Any'),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, DeviceGPS[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          dwell_timeband: agg.keys['Dwell Time Band'] as string,
                          month_number: null,
                          year: null,
                          bdy_name: null,
                          local_ratio: agg.values['Local Ratio']['average'],
                          visitor_ratio: agg.values['Visitor Ratio']['average'],
                          resident_population: agg.values['Resident Population']['sum'],
                          total_visits_local: agg.values['Total Visits Local']['sum'],
                          total_visits_visitor: agg.values['Total Visits Visitor']['sum'],
                          unique_visits_local: agg.values['Unique Visits Local']['sum'],
                          unique_visits_visitor: agg.values['Unique Visits Visitor']['sum'],
                          dwell_minutes_local: 0,
                          dwell_minutes_visitor: 0,
                      }))
                    : []
            )
        );
    }

    getSentiment(
        level: Level,
        date: string,
        boundaryIDs: number[] | string[]
    ): Observable<Sentiment[]> {
        const request = new SelectBuilder()
            .query('region:sentiment')
            .aggregate()
            .value('Rating 1 Pct', 'average')
            .value('Rating 2 Pct', 'average')
            .value('Rating 3 Pct', 'average')
            .value('Rating 4 Pct', 'average')
            .value('Rating 5 Pct', 'average')
            // .value('Rating 1 Count', 'sum')
            // .value('Rating 2 Count', 'sum')
            // .value('Rating 3 Count', 'sum')
            // .value('Rating 4 Count', 'sum')
            // .value('Rating 5 Count', 'sum')
            .value('Average Sentiment', 'average')
            .value('Median Sentiment', 'median')
            .byRows('Category')
            .totals(false)
            .filter(
                'and',
                exclude('Category', 'Any'),
                include('Geo Type', level.toUpperCase()),
                include('Region Geo Code', ...boundaryIDs),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, Sentiment[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          category: agg.keys['Category'] as string,
                          rating_1_pct: agg.values['Rating 1 Pct']['average'],
                          rating_2_pct: agg.values['Rating 2 Pct']['average'],
                          rating_3_pct: agg.values['Rating 3 Pct']['average'],
                          rating_4_pct: agg.values['Rating 4 Pct']['average'],
                          rating_5_pct: agg.values['Rating 5 Pct']['average'],
                          //   rating_1_count: agg.values['Rating 1 Count']['sum'],
                          //   rating_2_count: agg.values['Rating 2 Count']['sum'],
                          //   rating_3_count: agg.values['Rating 3 Count']['sum'],
                          //   rating_4_count: agg.values['Rating 4 Count']['sum'],
                          //   rating_5_count: agg.values['Rating 5 Count']['sum'],
                          avg_sentiment: agg.values['Average Sentiment']['average'],
                          median_sentiment: agg.values['Median Sentiment']['median'],
                      }))
                    : []
            )
        );
    }

    getIfiDomestic(
        level: Level,
        boundaryIDs: number[] | string[],
        date: string
    ): Observable<IfiDomesticRP[]> {
        const request = new SelectBuilder()
            .query('region:ifi-domestic')
            .aggregate()
            .value('Visitor Ratio', 'average')
            .value('Local Ratio', 'average')
            .value('Resident Population', 'sum')
            .value('Unique Customers Local', 'sum')
            .value('Unique Customers Visitor', 'sum')
            .value('Volume of Transactions Local', 'sum')
            .value('Volume of Transactions Visitor', 'sum')
            .value('Value of Transactions Local', 'sum')
            .value('Value of Transactions Visitor', 'sum')
            .byRow('Category')
            .totals(false)
            .filter(
                'and',
                exclude('Category', 'Any'),
                include('Region Type', level.toUpperCase()),
                include('Region Code', ...boundaryIDs),
                includeYearQuarterMonth(date)
            )
            .build();

        return this.cubes.select(request).pipe(
            map<SelectResponse, IfiDomesticRP[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          category: agg.keys['Category'] as string,
                          bdy_name: null,
                          year: null,
                          month_number: null,
                          local_ratio: agg.values['Local Ratio']['average'],
                          visitor_ratio: agg.values['Visitor Ratio']['average'],
                          resident_population: agg.values['Resident Population']['sum'],
                          unique_count_local: agg.values['Unique Customers Local']['sum'],
                          unique_count_visitor: agg.values['Unique Customers Visitor']['sum'],
                          vol_tran_local: agg.values['Volume of Transactions Local']['sum'],
                          vol_tran_visitor: agg.values['Volume of Transactions Visitor']['sum'],
                          val_tran_local: agg.values['Value of Transactions Local']['sum'],
                          val_tran_visitor: agg.values['Value of Transactions Visitor']['sum'],
                      }))
                    : []
            )
        );
    }

    getIfiDomesticSegmented(level: Level, boundaryIds: number[] | string[], endDate: string) {
        const cubeConfig = getSchemaOptions('IFI-DOMESTIC-SEGMENTED').cubeConfig;
        let selectRequest = new SelectBuilder().query(cubeConfig.index).aggregate();
        cubeConfig.values.forEach((cv) => {
            selectRequest.value(cv.key, cv.aggregation);
        });

        selectRequest = selectRequest
            .byRows('Year', 'Month', 'Category', 'Visitor Segment')
            .totals(false)
            .filter(
                'and',
                include('Destination Region Type', level.toUpperCase()),
                include('Destination Region Code', ...boundaryIds),
                include('Origin Type', level.toUpperCase()),
                include('Origin Code', 'Any'),
                includeYearQuarterMonth(endDate),
                exclude('Category', 'Any')
            );

        return this.cubes.select(selectRequest.build()).pipe(
            map<SelectResponse, IfiDomesticSegmented[]>((data) => {
                if (!data.aggs) {
                    return [];
                }

                const results = [];
                data.aggs.forEach((agg) => {
                    const item = {
                        category: agg.keys['Category'] as string,
                        bdy_name: agg.keys['Destination Region Name'] as string,
                        bdy_code: agg.keys['Destination Region Code'] as string,
                        bdy_type: agg.keys['Destination Region Type'] as string,
                        month_number: monthNameToNumber(agg.keys['Month'] as string),
                        visitor_segment: agg.keys['Visitor Segment'] as string,
                        year: agg.keys['Year'] as string,
                    };
                    cubeConfig.values.map((cv) => {
                        item[cv.uniqueName] = agg.values[cv.key][cv.aggregation];
                    });
                    results.push(item);
                });

                return results;
            })
        );
    }

    getIfiInternational(
        level: Level,
        boundaryIds: number[] | string[],
        endDate: string
    ): Observable<IfiIntRP[]> {
        const selectRequest = new SelectBuilder()
            .query('origin-dest:ifi-international')
            .aggregate()
            .value('Unique Customers Visitor', 'average')
            .value('Volume of Transactions Visitor', 'sum')
            .value('Value of Transactions Visitor', 'sum')
            .byRows('Year', 'Month', 'Category')
            .totals(false)
            .filter(
                'and',
                include('Destination Region Type', level.toUpperCase()),
                include('Destination Region Code', ...boundaryIds),
                include('Origin Type', InternationalBoundaryType.Region),
                include('Visitor Segment', 'total'),
                exclude('Category', 'Any'),
                includeYearQuarterMonth(endDate)
            );

        return this.cubes.select(selectRequest.build()).pipe(
            map<SelectResponse, IfiIntRP[]>((data) =>
                data.aggs
                    ? data.aggs.map((agg) => ({
                          category: agg.keys['Category'] as string,
                          bdy_name: null,
                          year: null,
                          month_number: null,
                          local_ratio: null,
                          visitor_ratio: null,
                          unique_count_local: null,
                          unique_count_visitor: agg.values['Unique Customers Visitor']['average'],
                          vol_tran_local: null,
                          vol_tran_visitor: agg.values['Volume of Transactions Visitor']['sum'],
                          val_tran_local: null,
                          val_tran_visitor: agg.values['Value of Transactions Visitor']['sum'],
                      }))
                    : []
            )
        );
    }
}
