import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// Angular
import { Component, OnDestroy } from '@angular/core';

@Component({
    selector: 'kt-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    enablePrintPdf: boolean = false;

    constructor(private router: Router) {
        // Enable 'PDF Export' only for Tourism Survey module
        this.subscriptions.add(
            this.router.events.subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url.includes('survey')) {
                        this.enablePrintPdf = true;
                    } else {
                        this.enablePrintPdf = false;
                    }
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
