import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { currentAuthToken } from '../../../../../../core/auth';
import { AppState } from '../../../../../../core/reducers';
import { Store, select } from '@ngrx/store';
import { MeasureService } from '../../shared/measure.service';
import {
    MeasureLoadAction,
    MeasureActionType,
    MeasureLoadSuccessAction,
    MeasureLoadFailAction,
} from '../actions/measure.action';
import { Measure } from '../../shared/measure.model';

@Injectable()
export class MeasureEffects {
    // API
    accessToken: Observable<string> = this.store.pipe(select(currentAuthToken));
    constructor(
        private service: MeasureService,
        private actions$: Actions,
        private store: Store<AppState>
    ) {}

    public loadMeasures$ = createEffect(() =>
        this.actions$.pipe(
            ofType<MeasureLoadAction>(MeasureActionType.Loading),
            switchMap(() =>
                this.service.getMeasures(this.accessToken).pipe(
                    map(
                        (response: Measure[]) =>
                            new MeasureLoadSuccessAction({ measures: response }),
                        catchError((error) => of(new MeasureLoadFailAction(error)))
                    )
                )
            )
        )
    );
}
