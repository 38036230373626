import * as am4charts from '@amcharts/amcharts4/charts';
import { TreeMapSeriesDataItem } from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { SurveyDrilldownTreemapChartConfig } from './survey-drilldown-treemap-chart.model';

import { Subject, takeUntil } from 'rxjs';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-survey-drilldown-treemap-chart',
    template: `<div id="{{ id }}" class="survey-drilldown-treemap-chart"></div>`,
    styleUrls: ['./survey-drilldown-treemap-chart.component.scss'],
})
export class SurveyDrilldownTreemapChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() id = 'survey-drilldown-treemap-chart';
    @Input() config: SurveyDrilldownTreemapChartConfig;

    private chart: am4charts.TreeMap;
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        public store: Store<AppState>,
        private zone: NgZone
    ) {}

    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });

        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            const chart = am4core.create(this.id, am4charts.TreeMap);
            chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

            chart.data = this.config.data;

            chart.maxLevels = 1;

            // Hiding AmCharts logo
            chart.logo.height = -20000;

            // Chart title
            // const title = chart.titles.create();
            // title.text = this.config.title;
            // title.fontSize = 15;
            // title.marginBottom = 30;

            // define data fields
            chart.dataFields.name = this.config.category;
            chart.dataFields.value = this.config.value;

            chart.showTooltipOn = 'always';

            this.chart = chart;

            this.setColours();

            this.createSeries();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.seriesTemplates.clear();
            this.createSeries();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const chart = this.chart;
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        chart.colors.list = colourList;
        this.chart = chart;
    }

    createSeries() {
        const chart = this.chart;

        const lvl0Series = chart.seriesTemplates.create('0');
        lvl0Series.strokeWidth = 2;

        lvl0Series.bulletsContainer.hiddenState.properties.opacity = 1;
        lvl0Series.bulletsContainer.hiddenState.properties.visible = true;
        // create hover state
        const columnTemplate = lvl0Series.columns.template;
        const hoverState = columnTemplate.states.create('hover');

        // darken
        hoverState.adapter.add('fill', (fill) => {
            if (fill instanceof am4core.Color) {
                return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
            }
            return fill;
        });

        lvl0Series.tooltip.label.maxWidth = 120;
        lvl0Series.tooltip.label.wrap = true;

        lvl0Series.columns.template.showTooltipOn = 'always';

        lvl0Series.tooltip.pointerOrientation = 'left';

        //  lvl0Series.numberFormatter.numberFormat = "#.";
        chart.numberFormatter.numberFormat = '#.';
        if (this.config.calculatePercentage) {
            lvl0Series.calculatePercent = true;
            lvl0Series.columns.template.tooltipText = `{name}\n {percent}%`;
        } else {
            // Percent may already be calculated at raw
            lvl0Series.columns.template.tooltipText = `{name}\n {value}%`;
        }

        lvl0Series.columns.template.tooltipX = 20;
        this.chart = chart;
    }
}
