// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';

export interface AuthState {
    loggedIn: boolean;
    authToken: string;
    user: User;
    isUserLoaded: boolean;
    impersonate?: Number;
    impersonateOrg?: string;
}

export const initialAuthState: AuthState = {
    loggedIn: false,
    authToken: undefined,
    user: undefined,
    isUserLoaded: false,
    impersonate: null,
    impersonateOrg: null,
};

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
    switch (action.type) {
        case AuthActionTypes.Logout:
            return initialAuthState;

        case AuthActionTypes.LoginComplete: {
            //const _token: string = action.payload.authToken;
            return {
                loggedIn: true,
                authToken: 'sddfdsdfs',
                user: undefined,
                isUserLoaded: false,
                impersonate: null,
            };
        }

        case AuthActionTypes.ReloadUserRequested: {
            return {
                ...state,
                isUserLoaded: false,
                loggedIn: true,
            };
        }

        case AuthActionTypes.UserRequested: {
            return {
                ...state,
                isUserLoaded: false,
                loggedIn: true,
            };
        }

        case AuthActionTypes.StartLogin:
            return {
                ...state,
                isUserLoaded: false,
            };

        case AuthActionTypes.UserLoaded: {
            const _user: User = action.payload.user;
            const _accessToken = action.payload.accessToken;

            return {
                ...state,
                user: _user,
                isUserLoaded: true,
                loggedIn: true,
                authToken: _accessToken,
            };
        }

        default:
            return state;
    }
}
