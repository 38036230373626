<mat-form-field class="boundary-selector">
    <mat-chip-list #chipList aria-label="Listing selection">
        <mat-chip *ngFor="let boundary of boundaries" [selectable]="true" [removable]="true" (removed)="removeBoundary(boundary)">
            {{ boundary.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
            class="boundary-input"
            type="text"
            #boundaryInput
            #trigger="matAutocompleteTrigger"
            matInput
            [formControl]="boundaryControl"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="chipList"
            placeholder="Enter a region"
        />
    </mat-chip-list>
    <mat-autocomplete panelWidth="260px" autoActiveFirstOption #autocomplete="matAutocomplete" (optionSelected)="boundarySelected($event)">
        <mat-option *ngFor="let option of filteredBoundaries | async" [value]="option" (click)="reopenPanel($event, trigger)">
            {{ option.name }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
