import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { select, Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AppState } from 'app/core/reducers';
import {
    selectCurrentOrganisation,
    selectCurrentUser,
} from 'app/core/session-info/store/selectors/session-info.selector';
import {
    Organisation,
    OrganisationTreeNode,
} from 'app/views/pages/organisations/shared/organisation.model';
import { OrganisationsService } from 'app/views/pages/organisations/shared/organisations.service';

interface OrganisationFlatNode extends Organisation {
    expandable: boolean;
}

@Component({
    selector: 'hm-organisations-switch',
    templateUrl: './organisations-switch.component.html',
    styleUrls: ['./organisations-switch.component.scss'],
})
export class OrganisationsSwitchComponent implements OnInit {
    organisation$: Observable<Organisation>;

    treeControl = new FlatTreeControl<OrganisationFlatNode>(
        (n) => n.level,
        (n) => n.expandable
    );
    treeFlattener = new MatTreeFlattener(
        this.flatTransformer,
        (n) => n.level,
        (n) => n.expandable,
        (n) => n.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor(
        private store: Store<AppState>,
        private organisationsService: OrganisationsService,
        private snack: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.organisation$ = this.store.pipe(select(selectCurrentOrganisation));
        this.organisationsService.getActiveOrganisations().subscribe((organisations) => {
            organisations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            this.dataSource.data = this.organisationsService.createTree(organisations, 0);
        });
    }

    ngOnDestroy() {}

    switchTo(node: OrganisationFlatNode) {
        this.snack.open(`Switching organisation to ${node.name}`);
        this.store
            .select(selectCurrentUser)
            .pipe(
                take(1),
                switchMap((user) =>
                    this.organisationsService.impersonateOrganisation(user.id, node.id)
                )
            )
            .subscribe({
                next: (_) => location.reload(),
                error: (err) => this.snack.open(`Error switching organisation: ${err}`),
            });
    }

    hasChildren = (_: number, node: OrganisationFlatNode) => node.expandable;

    private flatTransformer(node: OrganisationTreeNode, level: number): OrganisationFlatNode {
        return {
            ...node,
            expandable: !!node.children && node.children.length > 0,
        };
    }
}
