export interface ColourPalette {
    colour: string;
}

export const Colours: ColourPalette[] = [
    { colour: '#3EB6E9' },
    { colour: '#502582' },
    { colour: '#B63C8E' },
    { colour: '#3C388E' },
    { colour: '#1ED9CD' },
    { colour: '#931F1D' },
    { colour: '#E83151' },
    { colour: '#CCD859' },
    { colour: '#F46036' },
    { colour: '#004F2D' },
    { colour: '#E3B505' },
    { colour: '#1C7C54' },
    { colour: '#F7E733' },
    { colour: '#1C1C32' },
    { colour: '#60695C' },
    { colour: '#BE7977' },
    { colour: '#F18397' },
    { colour: '#F8A086' },
    { colour: '#EED369' },
    { colour: '#FAF185' },
    { colour: '#75704E' },
    { colour: '#E0E89B' },
    { colour: '#669581' },
    { colour: '#77B098' },
    { colour: '#ACA995' },
    { colour: '#C2BEC8' }, // Prun from here
    { colour: '#D8CFE8' },
    { colour: '#EFDCED' },
    { colour: '#D6D3EB' },
    { colour: '#E3F1FC' },
    { colour: '#D2F7F5' },
];
