<div class="chart-stats">
    <div id="{{ id }}" class="chartdiv"></div>
    <div class="statistics" *ngIf="top2yearPictorial">
        <!-- <h3>{{ top2yearPictorial.title }}</h3> -->
        <div class="info">
            <div *ngIf="!flip">
                <div>
                    <div class="box gold"></div>
                    {{ top2yearPictorial.data[0].name }}
                    <span>{{ top2yearPictorial.symbol }}{{ top2yearPictorial.data[0].realValue | shortNumber }}</span>
                </div>
                <div>
                    <div class="box green"></div>
                    {{ top2yearPictorial.data[1].name }}
                    <span>{{ top2yearPictorial.symbol }}{{ top2yearPictorial.data[1].realValue | shortNumber }}</span>
                </div>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo1Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo1Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo1Change | percent }} vs {{ previousYear1 }} YoY
                </p>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo2Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo2Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo2Change | percent }} vs {{ previousYear2 }} YoY
                </p>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo3Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo3Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo3Change | percent }} vs {{ previousYear3 }} YoY
                </p>
            </div>
            <div *ngIf="flip">
                <div>
                    <div class="box gold"></div>
                    {{ top2yearPictorial.data[1].name }}
                    <span>{{ top2yearPictorial.symbol }}{{ top2yearPictorial.data[1].realValue | shortNumber }}</span>
                </div>
                <div>
                    <div class="box green"></div>
                    {{ top2yearPictorial.data[0].name }}
                    <span>{{ top2yearPictorial.symbol }}{{ top2yearPictorial.data[0].realValue | shortNumber }}</span>
                </div>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo1Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo1Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo1Change | percent }} vs {{ previousYear1 }} YoY
                </p>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo2Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo2Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo2Change | percent }} vs {{ previousYear2 }} YoY
                </p>

                <p class="text-gray-500">
                    <i
                        [ngClass]="{
                            'flaticon2-arrow-down text-red-500': previousYearNo3Change < 0,
                            'flaticon2-arrow-up text-green-500': previousYearNo3Change >= 0
                        }"
                    ></i>
                    {{ previousYearNo3Change | percent }} vs {{ previousYear3 }} YoY
                </p>
            </div>
        </div>
    </div>
</div>
