<div>
    <div class="row">
        <div>
            <div class="icon icon-shape text-white rounded-circle shadow">
                <i [ngClass]="icon"></i>
            </div>
        </div>
        <div>
            <div *ngIf="config">
                <div *ngFor="let c of config.data">
                    <div class="a">
                        <h5 class="text-uppercase text-muted mb-0 card-title">{{ c.key }}: {{ c.format }}{{ c.value }}</h5>
                    </div>
                </div>
                <div class="a">
                    <b class="text-muted mb-0 card-title">{{ config.footer }} </b>
                </div>
            </div>
        </div>
    </div>
</div>
