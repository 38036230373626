<div class="map_box_container">
    <mgl-map
        *ngIf="dataAvailable"
        [style]="style | async"
        [zoom]="3"
        [center]="[133.7751, -25.2744]"
        (mapLoad)="onLoad($event)"
        [cursorStyle]="mpCursorStyle"
        preserveDrawingBuffer="true"
        [projection]="'mercator'"
    >
        <mgl-control mglFullscreen></mgl-control>
        <mgl-control mglNavigation position="top-right"></mgl-control>
        <mgl-control mglScale position="top-right"></mgl-control>
        <mgl-vector-source id="tileserver-source" [url]="tileserverUrl"> </mgl-vector-source>

        <!-- REGION OUTLINES -->
        <mgl-layer
            id="state-borders"
            type="line"
            source="tileserver-source"
            sourceLayer="boundary"
            [paint]="{
                'line-color': '#000000',
                'line-width': 1.1
            }"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'listing_count' && startup == true"
            id="listing_count"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="listingCountPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'views'"
            id="views"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="viewsPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'visits'"
            id="visits"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="visitsPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'leads'"
            id="leads"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="leadsPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>

        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'unique_visits_local'"
            id="unique_visits_local"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="uniqueVisitsLocalPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'total_visits_local'"
            id="total_visits_local"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="totalVisitsLocalPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>

        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'unique_visits_visitor'"
            id="unique_visits_visitor"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="uniqueVisitsVisitorPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>
        <mgl-layer
            *ngIf="selectedReportMeasure.uniqueName == 'total_visits_visitor'"
            id="total_visits_visitor"
            source="tileserver-source"
            [sourceLayer]="sourceLayer$ | async"
            type="fill"
            [paint]="totalVisitsVisitorPaint"
            (layerMouseMove)="activateHoverOn($event)"
            (layerMouseLeave)="disableHover()"
            before="state-borders"
        >
        </mgl-layer>

        <!-- <div *ngIf="dataPresent">
            <mgl-popup *ngIf="selectedLngLat" [lngLat]="selectedLngLat">
                <h4>{{ popupHTML.title }}</h4>
                <table class="popup">
                    <tr *ngFor="let row of reportMeasures">
                        <td>{{ row.title }}</td>
                        <div [ngSwitch]="true">
                            <td
                                *ngSwitchCase="
                                    row.uniqueName === 'unique_visits_local' ||
                                    row.uniqueName === 'unique_visits_visitor' ||
                                    row.uniqueName === 'total_visits_local' ||
                                    row.uniqueName === 'total_visits_visitor'
                                "
                            >
                                {{ selectedData[row.uniqueName] | shortNumber: 'n' }}
                            </td>
                            <td *ngSwitchCase="row.uniqueName === 'local_ratio' || row.uniqueName === 'visitor_ratio'">
                                {{ selectedData[row.uniqueName] | percent: '1.0-0' }}
                            </td>

                            <td *ngSwitchDefault>{{ selectedData[row.uniqueName] }}</td>
                        </div>
                    </tr>
                </table>
            </mgl-popup>
        </div> -->
    </mgl-map>

    <loading></loading>

    <hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

    <div *ngIf="permissionsAvailable && dataAvailable">
        <hm-report-controls
            [measures]="reportMeasures"
            [scale]="scale"
            (selectedMeasureChange)="selectedMeasure($event)"
            [selectedMeasure]="selectedReportMeasure"
        ></hm-report-controls>

        <div class="report" *ngIf="showTable">
            <div class="row justify-content-end">
                <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
                    <mat-icon>file_download</mat-icon>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="exportAs()">CSV</button>
                </mat-menu>
            </div>

            <table
                class="numerical-table"
                *ngIf="dataAvailable"
                mat-table
                [dataSource]="dataSource"
                matTableExporter
                matSort
                [matSortActive]="selectedReportMeasure.uniqueName"
                matSortDirection="desc"
                matSortDisableClear
                #exporter="matTableExporter"
            >
                <!-- Boundary Destination -->
                <ng-container matColumnDef="boundary_name" sticky>
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Origin </mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.boundary_name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="listing_count">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Listings Count</mat-header-cell>
                    <mat-cell
                        *matCellDef="let report"
                        [ngClass]="{
                            negative: report.listing_count < 10 && report.listing_count > 0,
                            neutral: report.unique_visits_local >= 10 && report.listing_count <= 80
                        }"
                    >
                        <ng-container *ngIf="report.listing_count === -1; else available"> Coming Soon </ng-container>
                        <ng-template #available>
                            {{ report.listing_count | shortNumber }}
                        </ng-template>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="views">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Views</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.views | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="visits">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Visits</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.visits | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="leads">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Leads</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.leads | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="unique_visits">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Unique Visits</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.unique_visits | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="time_per_visit">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Average Time per Visit</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.time_per_visit | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="time_on_site">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Average Time on Site</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.time_on_site | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="unique_visits_local">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Unique Visits Local</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.unique_visits_local | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="total_visits_local">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Total Visits Local</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.total_visits_local | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="unique_visits_visitor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Unique Visits Visitor</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.unique_visits_visitor | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="total_visits_visitor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Sum of Total Visits Visitor</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.total_visits_visitor | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="monthly_expenditure_prev_month">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before"
                        >Average Monthly Expenditure Prev Month</mat-header-cell
                    >
                    <mat-cell *matCellDef="let report">{{ report.monthly_expenditure_prev_month | shortNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="monthly_expenditure_prev_12months">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before"
                        >Average Monthly Expenditure 12 Months</mat-header-cell
                    >
                    <mat-cell *matCellDef="let report">{{ report.monthly_expenditure_prev_12months | shortNumber }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="visitor_ratio">
                    <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Visitor Ratio</mat-header-cell>
                    <mat-cell *matCellDef="let report">{{ report.visitor_ratio | shortNumber }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
