// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// RxJS
import { tap } from 'rxjs/operators';
import { AppState } from '../../reducers';
// Auth actions
import {
    AuthActionTypes,
    LoginComplete,
    Logout,
    StartLogin,
    UserRequested,
} from '../_actions/auth.actions';
import { Auth0Service } from '../_services/index';

@Injectable()
export class AuthEffects {
    startLogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<StartLogin>(AuthActionTypes.StartLogin),
                tap((action) => {
                    this.auth0.login(action.payload.targetRoute);
                })
            ),
        { dispatch: false }
    );

    loginComplete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LoginComplete>(AuthActionTypes.LoginComplete),
                tap((result) => {
                    this.store.dispatch(new UserRequested());
                })
            ),
        { dispatch: true }
    );

    logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<Logout>(AuthActionTypes.Logout),
                tap(() => {
                    // Need to also logout of auth0
                    this.auth0.logout();
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private auth0: Auth0Service,
        private store: Store<AppState>
    ) {}
}
