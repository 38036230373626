import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { first, flatMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { currentAuthToken } from 'app/core/auth';
import { AppState } from 'app/core/reducers';
import { environment } from 'environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = localStorage.getItem('token');
        if (token && req.url.startsWith(environment.hemisphereApi)) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token),
            });
            return next.handle(cloned);
        } else {
            return next.handle(req);
        }
    }
}
