import { MeasureActions, MeasureActionType } from '../actions/measure.action';
import { MeasureState, initialMeasureState, measureAdapter } from '../states/measure.state';

export function measureReducer(state = initialMeasureState, action: MeasureActions): MeasureState {
    switch (action.type) {
        case MeasureActionType.Loading: {
            return { ...state, loading: true };
        }
        case MeasureActionType.LoadSuccess: {
            return measureAdapter.setAll(action.payload.measures, {
                ...state,
                error: false,
                loading: false,
            });
        }
        case MeasureActionType.LoadFailure: {
            return measureAdapter.removeAll({
                ...state,
                error: true,
                loading: false,
            });
        }
        default:
            return state;
    }
}
