import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import createAuth0Client from '@auth0/auth0-spa-js';
import { Auth0Client, IdToken } from '@auth0/auth0-spa-js/dist/typings';
import { Store } from '@ngrx/store';
import { LoadingAllAction as LoadingAllDataPermissionsAction } from 'app/core/data-permissions/store/actions/data-permissions.actions';
import {
    isSessionInfoLoaded,
    selectHomepage,
} from 'app/core/session-info/store/selectors/session-info.selector';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest, from, Observable, throwError } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    shareReplay,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CategoryLoadAction } from '../../../views/partials/content/category/store/actions/category.actions';
import { MeasureLoadAction } from '../../../views/partials/content/measures/store/actions/measure.action';
import { LoadingAllAction as LoadingAllDatasetAction } from '../../dataset/store/dataset.actions';
import { AppState } from '../../reducers';
import { SessionInfoGet } from '../../session-info/store/actions/session-info.actions';
import { selectUserDataPermissionLoading } from '../../user-data-permissions/store/selectors/user-data-permissions.selectors';
import { UserLoaded } from '../_actions/auth.actions';

@Injectable({
    providedIn: 'root',
})
export class Auth0Service {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (
        from(
            createAuth0Client({
                domain: environment.auth0.domain,
                client_id: environment.auth0.clientId,
                redirect_uri: `${window.location.origin}/auth`, // redirect to /auth to avoid the Auth0Guard
                audience: environment.auth0.audience,
                // cacheLocation: 'localstorage',
            })
        ) as Observable<Auth0Client>
    ).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError((err) => {
            console.log('auth0 create client error', err);
            return throwError(err);
        })
    );

    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated()))
    );

    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    // Create subject and public observable for handleAuthCallback status
    // Completes auth0.guard: canActivate()
    private handleAuthCallbackCompleteSubject$ = new BehaviorSubject<boolean>(false);
    handleAuthCallbackComplete$ = this.handleAuthCallbackCompleteSubject$.asObservable();

    private jwtHelper = new JwtHelperService();

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private http: HttpClient,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService
    ) {
        // On initial load, check authentication state with authorization server
        // Set up local auth streams if user is already authenticated
        // this.localAuthSetup();
        // Handle redirect from Auth0 login
        this.handleAuthCallback();
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options)))
        );
    }

    getTokenSilently$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
        );
    }

    getIdTokenClaims$(options?): Observable<IdToken> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options)))
        );
    }

    login(redirectPath: string = '/') {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                appState: { target: redirectPath },
            });
        });
    }

    handleAuthReload(url) {
        return combineLatest([this.getUser$(), this.getTokenSilently$()])
            .pipe(
                switchMap(([user, authToken]) => this.completeAuth(user, authToken)),
                withLatestFrom(this.store.select(selectHomepage))
            )
            .subscribe(([done, homepage]) => {
                if (['/', environment.defaultHome].includes(url) && homepage) {
                    url = homepage;
                }
                this.router.navigateByUrl(url);
            });
    }

    // Called when app reloads after user logs in with Auth0
    handleAuthCallback() {
        const params = window.location.search;

        if (params.includes('code=') && params.includes('state=')) {
            // Scenario: redirect from Auth0 after successful authentication
            let targetUrl: string; // Path to redirect to after login processed
            this.handleRedirectCallback$
                .pipe(
                    tap((res) => {
                        // Get target redirect route from callback results
                        targetUrl = (res.appState && res.appState.target) || '/';
                    }),
                    concatMap(() => combineLatest([this.getUser$(), this.getTokenSilently$()])),
                    switchMap(([user, authToken]) => this.completeAuth(user, authToken)),
                    withLatestFrom(this.store.select(selectHomepage))
                )
                .subscribe(([done, homepage]) => {
                    this.handleAuthCallbackCompleteSubject$.next(true);
                    if (['/', environment.defaultHome].includes(targetUrl) && homepage) {
                        targetUrl = homepage;
                    }
                    this.router.navigateByUrl(targetUrl);
                });
        } else {
            // Scenario: landing on the login page with nothing to resolve
            this.handleAuthCallbackCompleteSubject$.next(true);
        }
    }

    private completeAuth(user: any, authToken: string): Observable<boolean> {
        const decodeUser = this.decode(authToken);
        const newUser = {
            ...user,
            organisation: decodeUser.organisation,
            roles: decodeUser.roles,
        };
        this._setSession(authToken, decodeUser.exp);

        this.store.dispatch(new UserLoaded({ user: newUser, accessToken: authToken }));

        return this.loadUserData(newUser.roles);
    }

    // Adds all session info, geo and page permission into the Ngrx store
    // Returns observable that emits once all data has been added to the Ngrx store
    private loadUserData(roles: any): Observable<boolean> {
        // SessionInfoGet() retrieves the session info that contains the org's dataset config
        // SessionInfoGet() also triggers an action that retrieve the org's page/geo permissions
        this.store.dispatch(new SessionInfoGet());
        this.store.dispatch(new LoadingAllDatasetAction());
        this.store.dispatch(new CategoryLoadAction());
        this.store.dispatch(new MeasureLoadAction());
        if (roles.includes('SYS_ADMIN')) {
            this.loadAdminData();
        }

        return combineLatest({
            infoLoaded: this.store.select(isSessionInfoLoaded),
            dataPermissionLoading: this.store.select(selectUserDataPermissionLoading),
        }).pipe(
            filter(({ infoLoaded, dataPermissionLoading }) => infoLoaded && !dataPermissionLoading),
            map(() => true)
        );
    }

    private loadAdminData(): void {
        this.store.dispatch(new LoadingAllDataPermissionsAction());
        // load this up if the user is a sys admin only on the admin page
    }

    private _setSession(authToken, expiresAt) {
        // Store expiration in local storage to access in constructor
        localStorage.setItem('expires_at', JSON.stringify(expiresAt));
        localStorage.setItem('token', authToken);
    }

    private _clearExpiration() {
        // Remove token expiration from localStorage
        localStorage.removeItem('expires_at');
        localStorage.removeItem('token');
    }

    logout() {
        this._clearExpiration();
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            client.logout({
                client_id: environment.auth0.clientId,
                returnTo: `${window.location.origin}/auth/login`,
            });
        });
    }

    // Decodes ID or Access Token
    decode(token: string): any {
        let decoded = this.jwtHelper.decodeToken(token);
        const organisation = decoded['https://auth0.hemisphere.wejugo/groups'][0] || '';
        const roles = decoded['https://auth0.hemisphere.wejugo/roles'];
        const permissions = decoded['https://auth0.hemisphere.wejugo/permissions'];
        const userPermissions: string[] = permissions.map((p) => p.trim());
        const userRoles: string[] = roles.map((role) => role.trim());
        this.permissionsService.flushPermissions();

        userRoles.map((role) => {
            this.rolesService.addRole(role, null);
            if (
                role == 'SYS_ADMIN' ||
                role == 'CLIENT_ADMIN' ||
                role == 'CONTRIBUTOR' ||
                role == 'DEMO'
            ) {
                this.permissionsService.addPermission(role);
            }
        });
        return {
            organisation: organisation,
            roles: userRoles,
            exp: decoded.exp,
        };
    }
}
