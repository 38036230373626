import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { currentUser, Logout, User } from 'app/core/auth';
import { AppState } from 'app/core/reducers';

@Component({
    selector: 'kt-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    user$: Observable<User>;

    @Input() avatar = true;
    @Input() greeting = true;
    @Input() badge: boolean;
    @Input() icon: boolean;

    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser));
    }

    logout() {
        this.store.dispatch(new Logout());
    }
}
