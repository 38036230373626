import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import { LoadUserDataPermissionsAction } from '../../../user-data-permissions/store/actions/user-data-permissions.action';
import { SessionInfo } from '../../shared/session-info.model';
import { SessionInfoService } from '../../shared/session-info.service';
import {
    SessionInfoActionType,
    SessionInfoGet,
    SessionInfoLoaded,
} from '../actions/session-info.actions';

@Injectable()
export class SessionInfoEffects {
    // API
    constructor(
        private service: SessionInfoService,
        private actions$: Actions,
        private store: Store<AppState>,
        private permissionsService: NgxPermissionsService
    ) {}

    public loadSessionInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SessionInfoGet>(SessionInfoActionType.SessionInfoGet),
            switchMap(() =>
                this.service.getSessionInfo().pipe(
                    map(
                        (response: SessionInfo) =>
                            new SessionInfoLoaded({
                                user: response.user,
                                organisation: response.organisation,
                                setting: response.setting,
                                dataset: response.dataset,
                            })
                    )
                )
            )
        )
    );

    public loadOrganisationDataPermissions$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<SessionInfoLoaded>(SessionInfoActionType.SessionInfoLoaded),
                tap((action) => {
                    const datasets = action.payload.dataset;
                    if (datasets && datasets.length > 0) {
                        const datasetKeys = datasets.map((d) => d.key);
                        this.permissionsService.addPermission(datasetKeys);
                    }
                }),
                // tap(() => this.store.dispatch(new LoadUserDataPermissionsAction()))
                switchMap(async () => this.store.dispatch(new LoadUserDataPermissionsAction()))
            ),
        { dispatch: false }
    );
}
