<div *ngxPermissionsOnly="permission" ngbDropdown placement="bottom" class="kt-header__topbar-item">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <span class="kt-header__topbar-icon">
            <i class="flaticon2-gear"></i>
        </span>
    </div>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim">
        <ul class="kt-nav">
            <li class="kt-nav__section kt-nav__section--first">
                <span class="kt-nav__section-text">Administration</span>
            </li>
            <ng-container *ngFor="let item of menu">
                <ng-template *ngIf="item.permission" [ngxPermissionsOnly]="item.permission" [ngxPermissionsExcept]="item.except">
                    <li class="kt-nav__item" routerLinkActive="kt-nav__item--active">
                        <a [routerLink]="item.page" routerLinkActive="kt-nav__link--active" class="kt-nav__link">
                            <i *ngIf="item.icon" class="kt-menu__link-icon" [ngClass]="item.icon"></i>
                            <span class="kt-nav__link-text">{{ item.title }}</span>
                        </a>
                    </li>
                </ng-template>
            </ng-container>
        </ul>
    </div>
</div>
