// Angular
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

// Store
import { environment } from 'environments/environment';
import { StartLogin } from '../../../../core/auth';
import { AppState } from '../../../../core/reducers';

@Component({
    selector: 'kt-login',
    templateUrl: './welcome.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class WelcomeComponent {
    externalUris = environment.externalUris;

    constructor(private store: Store<AppState>, private route: ActivatedRoute) {}
    submit() {
        this.route.queryParamMap.subscribe((params) => {
            this.store.dispatch(new StartLogin({ targetRoute: params.get('target') }));
        });
    }
}
