import _ from 'lodash';
import {
    AggField,
    AggFunc,
    Aggs,
    AggValues,
    Field,
    Filter,
    FilterGroup,
    FilterGroupType,
    FilterMember,
    Query,
    Value,
} from './model';

export class QueryRequest {
    type: string;
    /**
     * Name of the target cube
     */
    index: string;
    /**
     * A query object describing what to return from the cube.
     */
    query: Query;
    /**
     * Page number. It can be used to load results by parts.
     * If a response contains the `pageTotal` parameter, additional requests will
     * be performed to load the remaining pages. Starts from 0.
     */
    page: number = 0;
}

export interface QueryResponse {
    /**
     * Fields (columns) included in the response when requesting flat table data.
     */
    fields?: Field[];
    /**
     * A two-dimensional array containing flat data. Each `hits[i]` is a data row.
     * Each `hits[i][j]` is a data point, where `j` is a field (column) index.
     */
    hits?: Value[][];
    /**
     * Aggregated data.
     */
    aggs?: AggValues[];
    /**
     * Current page number. Starts from 0.
     */
    page: number;
    /**
     * The total number of pages. It can be used to load data by parts.
     */
    pageTotal: number;
}

/**
 * A builder for query requests in the cube API.
 */
export class QueryBuilder<Request extends QueryRequest> {
    protected request: Request;
    private aggsRef: Aggs;
    private filterRef: FilterGroup;

    constructor(req: Request) {
        this.request = req;
        this.aggsRef = req.query?.aggs;
        this.filterRef = req.query?.filter;
    }

    /**
     * Initialize query for a particular cube/index and page number
     */
    query(index: string, page: number = 0): QueryBuilder<Request> {
        this.request.index = index;
        this.request.page = page;
        this.request.query = new Query();
        return this;
    }

    /**
     * Set query to return aggregated values
     */
    aggregate(): QueryBuilder<Request> {
        this.aggsRef = this.request.query.aggs = new Aggs();
        return this;
    }

    /**
     * Set whether totals and subtotals should be calculated
     */
    totals(totals: boolean): QueryBuilder<Request> {
        this.aggsRef.totals = totals;
        return this;
    }

    /**
     * Add a field to aggregate over to the rows dimension
     */
    byRow(uniqueName: string, interval?: string): QueryBuilder<Request> {
        return this.byFields('rows', new Field(uniqueName, interval));
    }

    /**
     * Add a field to aggregate over to the columns dimension
     */
    byCol(uniqueName: string, interval?: string): QueryBuilder<Request> {
        return this.byFields('cols', new Field(uniqueName, interval));
    }

    /**
     * Add fields to aggregate over to the rows dimension
     */
    byRows(...uniqueNames: string[]): QueryBuilder<Request> {
        return this.byFields('rows', ...uniqueNames.map((n) => new Field(n)));
    }

    /**
     * Add fields to aggregate over to the columns dimension
     */
    byCols(...uniqueNames: string[]): QueryBuilder<Request> {
        return this.byFields('cols', ...uniqueNames.map((n) => new Field(n)));
    }

    setFields(fields: Field[]): QueryBuilder<Request> {
        if (!this.request.query.fields) {
            this.request.query.fields = [];
        }
        this.request.query.fields.push(...fields);
        return this;
    }

    private byFields(dim: 'rows' | 'cols', ...fields: Field[]): QueryBuilder<Request> {
        if (!this.aggsRef.by) {
            this.aggsRef.by = { rows: [], cols: [] };
        }
        this.aggsRef.by[dim].push(...fields);
        return this;
    }

    /**
     * Add an aggregated field
     */
    value(uniqueName: string, func: AggFunc, interval?: string): QueryBuilder<Request> {
        this.aggsRef.values.push(new AggField(new Field(uniqueName, interval), func));
        return this;
    }

    /**
     * Add filters
     */
    filter(type: FilterGroupType, ...filters: (Filter | FilterGroup)[]): QueryBuilder<Request> {
        if (!this.filterRef) {
            this.filterRef = this.request.query.filter = new FilterGroup(type, filters);
        } else if (this.filterRef.type == type) {
            this.filterRef.value.push(...filters);
        } else {
            throw new Error(
                `Can't add filters of type ${type} to FilterGroup of type ${this.filterRef.type}`
            );
        }
        return this;
    }

    /**
     * Add an inclusion filter.
     * Shortcut for `filter(include(uniqueName, ...values))`
     */
    include(uniqueName: string, ...values: (Value | FilterMember)[]): QueryBuilder<Request> {
        return this.filter('and', include(uniqueName, ...values));
    }

    /**
     * Add an exclusion filter
     * Shortcut for `filter(exclude(uniqueName, ...values))`
     */
    exclude(uniqueName: string, ...values: (Value | FilterMember)[]): QueryBuilder<Request> {
        return this.filter('and', exclude(uniqueName, ...values));
    }

    /**
     * Build and return the request.
     */
    build(): Request {
        return _.cloneDeep(this.request);
    }
}

/**
 * Create a Member with a sub-filter.
 */
export function filterMember(value: Value, filter: Filter): FilterMember {
    return new FilterMember(value, filter);
}

/**
 * Create an inclusion Filter
 */
export function include(uniqueName: string, ...values: (Value | FilterMember)[]): Filter {
    return new Filter(
        new Field(uniqueName),
        values.map((v) => (v instanceof FilterMember ? v : new FilterMember(v)))
    );
}

/**
 * Create an exclusion Filter
 */
export function exclude(uniqueName: string, ...values: (Value | FilterMember)[]): Filter {
    return new Filter(
        new Field(uniqueName),
        undefined,
        values.map((v) => (v instanceof FilterMember ? v : new FilterMember(v)))
    );
}

export function between(uniqueName: string, range: string[] | number[]): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { between: range });
}

export function notBetween(uniqueName: string, range: string[] | number[]): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { not_between: range });
}

export function equal(uniqueName: string, value: Value): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { equal: value });
}

export function notEqual(uniqueName: string, value: Value): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { not_equal: value });
}

export function begin(uniqueName: string, value: string): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { begin: value });
}

export function notBegin(uniqueName: string, value: string): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { not_begin: value });
}

export function end(uniqueName: string, value: string): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { end: value });
}

export function notEnd(uniqueName: string, value: string): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { not_end: value });
}

export function contain(uniqueName: string, value: string[] | number[]): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { contain: value });
}

export function notContain(uniqueName: string, value: string[] | number[]): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { not_contain: value });
}

export function greaterEqual(uniqueName: string, value: number): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { greater_equal: value });
}

export function lessEqual(uniqueName: string, value: number): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { less_equal: value });
}

export function afterEqual(uniqueName: string, value: number): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { after_equal: value });
}

export function beforeEqual(uniqueName: string, value: number): Filter {
    return new Filter(new Field(uniqueName), undefined, undefined, { before_equal: value });
}

/**
 * Create a filter group
 */
export function filterGroup(
    type: FilterGroupType,
    ...values: Array<Filter | FilterGroup>
): FilterGroup {
    return new FilterGroup(type, values);
}
