<div class="choropleth-legend-container">
    <div class="title-col cursor-pointer">
        <b>Spend</b>
        <div>High <- Low</div>
    </div>
    <div class="grid-and-title">
        <div class="legend">
            <div *ngFor="let row of coloursToDisplay">
                <div class="item" [ngStyle]="{ background: row }">{{ row }}</div>
            </div>
        </div>
        <div class="title-row cursor-pointer">
            <b>Rainfall</b>
            <div>Low -> High</div>
        </div>
    </div>
</div>
