import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Campaign } from 'app/views/pages/traffic/campaigns/shared/campaign.model';
import { Pack } from 'app/views/pages/traffic/packs/shared/packs.model';
import { Site } from 'app/views/pages/traffic/sites/shared/sites.model';
import { SearchType } from 'app/views/pages/traffic/traffic-header/shared/traffic-header.model';
import { Category } from 'app/views/partials/content/category/shared/category.model';
import { Polygon } from 'geojson';

export interface Filters {
    // Common
    mapExtended?: boolean;
    searchSaved?: boolean;
    categories?: Category[];
    pivotFilters?: any[];
    // Traffic
    trafficSearchSaved?: boolean;
    startDateUsingDay?: string;
    endDateUsingDay?: string;
    startDateUsingWeek?: string;
    endDateUsingWeek?: string;
    searchType?: SearchType;
    sites?: Site[];
    packs?: Pack[];
    campaigns?: Campaign[];
    showFields?: boolean;
    // Region
    boundaryType?: DataPermissionType;
    boundaries?: Boundary[];
    startDateUsingMonth?: string;
    endDateUsingMonth?: string;
}

export enum Tool {
    Region = 'Region',
    Viewport = 'Viewport',
    Radius = 'Radius',
    Draw = 'Draw',
    Sites = 'Sites',
    Travel = 'Travel',
}

export interface FiltersSelect {
    boundaryType?: DataPermissionType;
    boundaries?: Boundary[];
    tool: Tool;
    data?: Polygon[] | any;
}
