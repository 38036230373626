<div id="map"></div>

<mat-icon #tooltip="matTooltip" matTooltip="help tools" class="icon-display help" (click)="help()">help</mat-icon>
<mat-icon
    *ngxPermissionsOnly="['SYS_ADMIN']"
    #tooltip="matTooltip"
    matTooltip="upload geojson"
    class="icon-display upload"
    (click)="addFiles()"
    >file_upload</mat-icon
>
<input type="file" #file style="display: none" (change)="onFilesAdded()" />
<div class="outer" [ngClass]="isExpanded ? 'show' : 'hide'">
    <div class="close" (click)="help()"><mat-icon>close</mat-icon></div>
    <div class="instructions">
        <div class="hcard">
            <ul>
                <h5>Creating a new effective area</h5>
                <li>
                    Click the draw polygon icon to enable draw mode.
                    <img src="/assets/media/icons/polygon-selected.png" height="20px" />
                </li>
                <li>Click on the map to start drawing. Each single click will generate a new vertex.</li>
                <li>Double click on the last point to finish the polygon and exit draw mode.</li>
                <li>Click outside the polygon and click save</li>
            </ul>
        </div>
        <div class="hcard">
            <ul>
                <h5>Editing an existing effective area</h5>
                <li>
                    Ensure the draw polygon is disabled (greyed out)
                    <img src="/assets/media/icons/polygon-unselected.png" height="20px" />
                </li>
                <li>Double click inside the polygon to enter edit mode. This will reveal draggable points.</li>
                <li>Drag on the polygon dots to make changes to the polygon</li>
                <li>Double click on the last point to finish the polygon and exit draw mode.</li>
                <li>Click outside the polygon and click save</li>
            </ul>
        </div>
        <div class="hcard">
            <ul>
                <h5>Deleting an effective area</h5>
                <li>
                    Ensure the draw polygon is disabled (greyed out)
                    <img src="/assets/media/icons/polygon-unselected.png" height="20px" />
                </li>
                <li>Click once inside the polygon to highlight the polygon</li>
                <li>Click the delete icon <img src="/assets/media/icons/bin.png" height="20px" /></li>
            </ul>
        </div>
        <div class="hcard">
            <ul>
                <h5>Move an entire polygon</h5>
                <li>
                    Ensure the draw polygon is disabled (greyed out)
                    <img src="/assets/media/icons/polygon-unselected.png" height="20px" />
                </li>
                <li>Click inside the polygon to select it</li>
                <li>Hold the spacebar and use the mouse to move the entire polygon</li>
            </ul>
        </div>
    </div>
</div>

<div class="read-only" *ngIf="!editable">
    <h3>Read-only access</h3>
    <p>Please contact your administrator to be given read, edit and delete access to polygon shapes.</p>
</div>
