import { Action } from '@ngrx/store';
import { Category } from '../../shared/category.model';
import { Update } from '@ngrx/entity';

export enum CategoryActionType {
    Loading = '[Profiler/Categories] Loading',
    LoadSuccess = '[Profiler/Categories] LoadSuccess',
    LoadFailure = '[Profiler/Categories] LoadFailure',
    ToggleCategory = '[Profiler/Categories] Toggle Category',
}

export class CategoryLoadAction implements Action {
    public readonly type = CategoryActionType.Loading;
    constructor() {}
}

export class CategoryLoadSuccessAction implements Action {
    public readonly type = CategoryActionType.LoadSuccess;
    constructor(public payload: { categories: Category[] }) {}
}

export class CategoryLoadFailAction implements Action {
    public readonly type = CategoryActionType.LoadFailure;
    constructor(public error: any) {}
}

export class CategoryToggle implements Action {
    public readonly type = CategoryActionType.ToggleCategory;
    constructor(public payload: Update<Category>) {}
}

export type CategoryActions =
    | CategoryLoadAction
    | CategoryLoadSuccessAction
    | CategoryLoadFailAction
    | CategoryToggle;
