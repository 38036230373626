<div class="date-dialog">
    <h1 mat-dialog-title>{{ selectedYear.toString() }}</h1>
    <div class="year-buttons">
        <button mat-icon-button aria-label="Decrease year" (click)="decrementYear()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-icon-button aria-label="Increase year" (click)="incrementYear()">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="months-container">
    <div class="flex-grid">
        <div class="flex-col">
            <button
                [disabled]="!checkValidQuarter()"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Q1' }"
                (click)="calcDate('Q1')"
            >
                Q1&nbsp;
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Jan')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Jan' }"
                (click)="calcDate('Jan')"
            >
                JAN
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Feb')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Feb' }"
                (click)="calcDate('Feb')"
            >
                FEB
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Mar')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Mar' }"
                (click)="calcDate('Mar')"
            >
                MAR
            </button>
        </div>
    </div>
    <div class="flex-grid">
        <div class="flex-col">
            <button
                [disabled]="!checkValidQuarter()"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Q2' }"
                (click)="calcDate('Q2')"
            >
                Q2&nbsp;
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Apr')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Apr' }"
                (click)="calcDate('Apr')"
            >
                APR
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('May')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'May' }"
                (click)="calcDate('May')"
            >
                MAY
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Jun')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Jun' }"
                (click)="calcDate('Jun')"
            >
                JUN
            </button>
        </div>
    </div>
    <div class="flex-grid">
        <div class="flex-col">
            <button
                [disabled]="!checkValidQuarter()"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Q3' }"
                (click)="calcDate('Q3')"
            >
                Q3&nbsp;
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Jul')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Jul' }"
                (click)="calcDate('Jul')"
            >
                JUL
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Aug')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Aug' }"
                (click)="calcDate('Aug')"
            >
                AUG
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Sep')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Sep' }"
                (click)="calcDate('Sep')"
            >
                SEP
            </button>
        </div>
    </div>
    <div class="flex-grid">
        <div class="flex-col">
            <button
                [disabled]="!checkValidQuarter()"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Q4' }"
                (click)="calcDate('Q4')"
            >
                Q4&nbsp;
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Oct')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Oct' }"
                (click)="calcDate('Oct')"
            >
                OCT
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Nov')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Nov' }"
                (click)="calcDate('Nov')"
            >
                NOV
            </button>
        </div>
        <div class="flex-col">
            <button
                [disabled]="!checkValidMonth('Dec')"
                mat-button
                class="months"
                [ngClass]="{ 'selected-month': selectedPeriod === 'Dec' }"
                (click)="calcDate('Dec')"
            >
                DEC
            </button>
        </div>
    </div>
</div>
