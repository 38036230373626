import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4maps from '@amcharts/amcharts4/maps';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets';

import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { MapPinConfig } from './map-pin.model';
import { aus, centers } from './state-models';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectTop3Colors } from 'app/core/whitelabel/store/whitelabel.selector';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'hm-map-pin',
    templateUrl: './map-pin.component.html',
    styleUrls: ['./map-pin.component.scss'],
})
export class MapPinComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() id: string = 'chart-map-pin';
    @Input() config: MapPinConfig;

    private chart: am4maps.MapChart;
    top3Colors: string[];
    private componentDestroyed = new Subject<void>();
    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    ngOnInit() {
        this.store
            .pipe(select(selectTop3Colors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.top3Colors = colors;
            });
    }
    ngAfterViewInit() {
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            // Init map instance
            this.initMap();

            this.createMapSeries();
        });
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            this.chart.series.clear();
            this.createMapSeries();
        }
    }

    initMap() {
        this.chart = am4core.create(this.id, am4maps.MapChart);
        this.chart.geodata = aus;
        this.chart.projection = new am4maps.projections.Miller();

        // Hide the amcharts logo
        this.chart.logo.height = -20000;

        this.chart.homeZoomLevel = 1.1;
        this.chart.minZoomLevel = 0.8;

        this.chart.background.fill = am4core.color(this.top3Colors[2]);
    }

    createMapSeries() {
        var polygonSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        polygonSeries.mapPolygons.template.stroke = am4core.color('#000');

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.fill = am4core.color(this.top3Colors[0]);
        this.chart.homeGeoPoint = {
            latitude: centers[this.config.state].regional.latitude,
            longitude: centers[this.config.state].regional.longitude,
        };

        var color4 = am4core.color(this.top3Colors[3]);
        var imageSeries = this.chart.series.push(new am4maps.MapImageSeries());
        var imageTemplate = imageSeries.mapImages.template;
        imageTemplate.propertyFields.longitude = 'longitude';
        imageTemplate.propertyFields.latitude = 'latitude';
        //    imageTemplate.nonScaling = true;

        var pin = imageTemplate.createChild(am4plugins_bullets.PinBullet);

        // Configuring pin appearance
        pin.background.fill = color4;
        pin.background.pointerBaseWidth = 1;
        // pin.background.pointerLength = 250;
        pin.background.propertyFields.pointerLength = 'length';
        pin.circle.fill = pin.background.fill;
        pin.label = new am4core.Label();
        pin.label.text = '{value}%';
        pin.label.fill = color4.alternative;

        var label = pin.createChild(am4core.Label);
        label.text = '{title}';
        label.fontWeight = 'bold';
        label.propertyFields.dy = 'length';
        label.verticalCenter = 'middle';
        label.fill = color4;
        label.adapter.add('dy', function (dy) {
            return (20 + dy) * -1;
        });

        imageSeries.heatRules.push({
            target: pin.background,
            property: 'radius',
            min: 20,
            max: 30,
            dataField: 'value',
        });

        imageSeries.heatRules.push({
            target: label,
            property: 'dx',
            min: 30,
            max: 40,
            dataField: 'value',
        });

        imageSeries.heatRules.push({
            target: label,
            property: 'paddingBottom',
            min: 0,
            max: 10,
            dataField: 'value',
        });
        // Pin data

        const regionValue = this.config.regional.toFixed();
        const capitalValue = this.config.capital.toFixed();

        imageSeries.data = [
            {
                latitude: centers[this.config.state].regional.latitude,
                longitude: centers[this.config.state].regional.longitude,
                value: regionValue,
                title: centers[this.config.state].regional.title,
                length: centers[this.config.state].regional.length,
            },
            {
                latitude: centers[this.config.state].capital.latitude,
                longitude: centers[this.config.state].capital.longitude,
                value: capitalValue,
                title: centers[this.config.state].capital.title,
                length: centers[this.config.state].capital.length,
            },
        ];
    }
}
