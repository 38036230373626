// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
    ContentAnimateDirective,
    FirstLetterPipe,
    HeaderDirective,
    MenuDirective,
    OffcanvasDirective,
    ScrollTopDirective,
    StickyDirective,
    TabClickEventDirective,
    ToggleDirective,
} from './_base/layout';
import { StoreModule } from '@ngrx/store';
import {
    reportFilterFeatureKey,
    reportFiltersReducer,
} from './report-filters/store/reducers/report-filter.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(reportFilterFeatureKey, reportFiltersReducer)],
    declarations: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        FirstLetterPipe,
    ],
    exports: [
        // directives
        ScrollTopDirective,
        HeaderDirective,
        OffcanvasDirective,
        ToggleDirective,
        MenuDirective,
        TabClickEventDirective,
        ContentAnimateDirective,
        StickyDirective,
        // pipes
        FirstLetterPipe,
    ],
    providers: [],
})
export class CoreModule {}
