import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Dataset } from '../shared/dataset.model';

export interface DatasetState extends EntityState<Dataset> {
    error: boolean;
    loading: boolean;
}

export const datasetAdapter: EntityAdapter<Dataset> = createEntityAdapter<Dataset>({
    selectId: (dataset: Dataset) => dataset.id,
});

export const initialDatasetState: DatasetState = datasetAdapter.getInitialState({
    error: false,
    loading: true,
});
