import { Action, createReducer, on } from '@ngrx/store';
import { ReportFilter } from '../../models/report-filters.model';
import { setReportFilters } from '../actions/report-filter.actions';

// What the state will be known as in the store
export const reportFilterFeatureKey = 'report-filters';

// The Report Filter State is key-value pair object.
// Each value attached to the key is a ReportFilter object.
export interface ReportFilterState {
    [key: string]: ReportFilter;
}

export const initialState: ReportFilterState = {};

/**
 * Example:
 * ReportFilterKey.SurveyFilter : SurveyReportFilter{}
 * ReportFilterKey.TrafficFilter : TrafficReportFilter{}
 *
 * Note: the entire filter object gets replaced,
 *  hence startDate must be included in the dispatched object (or it'll become undefined )
 *  but not necessarily used in the selector
 * See: Profiler.component.ts
 */
export const reportFiltersReducer = createReducer(
    initialState,
    on(setReportFilters, (state, payload) => ({
        ...state,
        [payload.key]: payload.filter,
    }))
);
