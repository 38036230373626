import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnInit,
    PLATFORM_ID,
} from '@angular/core';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { OnChanges } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { XYLineChartConfig } from './xy-line.model';

import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'app/core/reducers';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-xy-line',
    templateUrl: './xy-line.component.html',
    styleUrls: ['./xy-line.component.scss'],
})
export class XyLineComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() id: string = 'chart-div-xy-line';
    @Input() config: XYLineChartConfig;

    private chart: am4charts.XYChart;

    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            // Themes end

            // Create chart instance
            this.chart = am4core.create(this.id, am4charts.XYChart);
            // Hiding AmCharts logo
            this.chart.logo.height = -20000;
            if (this.config.showLegend) {
                this.chart.legend = new am4charts.Legend();
            }

            // const title = this.chart.titles.create();
            // title.text = this.config.title;
            // title.fontSize = 15;
            // title.marginBottom = 30;

            // Add data

            if (this.config.prefix != undefined) {
                this.chart.numberFormatter.numberFormat = `${this.config.prefix}#a`;
            } else {
                this.chart.numberFormatter.numberFormat = '#a';
            }

            this.chart.numberFormatter.bigNumberPrefixes = [
                { number: 1e3, suffix: 'k' },
                { number: 1e6, suffix: 'm' },
                { number: 1e9, suffix: 'b' },
                { number: 1e12, suffix: 't' },
                { number: 1e15, suffix: 'q' },
            ];
            this.chart.fontSize = '1.2rem';
            this.chart.data = this.config.data;

            // Create axes
            const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.minGridDistance = 40;
            this.chart.paddingTop = 10;

            const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

            valueAxis.title.text = this.config.format;
            //    valueAxis.strictMinMax = true;
            valueAxis.calculateTotals = true;
            //      valueAxis.renderer.minWidth = 50;

            valueAxis.extraMax = 0.2;

            // Add cursor
            this.chart.cursor = new am4charts.XYCursor();
            this.chart.cursor.xAxis = dateAxis;

            this.setColours();

            this.draw();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.series.clear();
            this.draw();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setColours() {
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        this.chart.colors.list = colourList;
    }

    draw() {
        this.config.category.forEach((c) => {
            this.createSeries(c.category, c.name);
        });
    }

    createSeries(field, name) {
        // Create series
        var series = this.chart.series.push(new am4charts.LineSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        // series.minBulletDistance = 10;
        //  series.tooltipText =
        //     '[bold]{date.formatDate()}:[/] {value1}\n[bold]{previousDate.formatDate()}:[/] {value2}';
        series.tooltip.pointerOrientation = 'vertical';
        var circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.stroke = am4core.color('#fff');
        circleBullet.circle.strokeWidth = 2;

        var labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{value}';
        labelBullet.label.dy = -20;
    }
}
