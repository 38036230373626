import { Action } from '@ngrx/store';
import { DataPermission } from '../../shared/data-permission.model';

export enum DataPermissionActionType {
    DataPermissionLoadingAll = '[All Data Permission] Loading',
    DataPermissionLoadSuccessAll = '[All Data Permission] LoadSuccess',
    DataPermissionLoadFailureAll = '[All DataPermission] LoadFailure',
}

export class LoadingAllAction implements Action {
    public readonly type = DataPermissionActionType.DataPermissionLoadingAll;
    constructor() {}
}

export class LoadSuccessAllAction implements Action {
    public readonly type = DataPermissionActionType.DataPermissionLoadSuccessAll;
    constructor(public payload: { dataPermissions: DataPermission[] }) {}
}

export class LoadingFailureAllAction implements Action {
    public readonly type = DataPermissionActionType.DataPermissionLoadFailureAll;
    constructor(public error: any) {}
}

export type DataPermissionActions =
    | LoadingAllAction
    | LoadSuccessAllAction
    | LoadingFailureAllAction;
