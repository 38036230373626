import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectedPoint } from '../../../../../pages/hotels/shared/selected-point.model';
@Component({
    selector: 'kt-catchments',
    templateUrl: './catchments.component.html',
    styleUrls: ['./catchments.component.scss'],
})
export class CatchmentsComponent implements OnInit {
    @Input() catchments: SelectedPoint[];
    @Output() removeCatchmentById = new EventEmitter<Number>();
    @Output() zoomToPoint = new EventEmitter<SelectedPoint>();
    constructor() {}

    ngOnInit() {}

    OnChange(catchment: number) {
        this.removeCatchmentById.emit(catchment);
    }
    onZoomToPoint(point: SelectedPoint) {
        this.zoomToPoint.emit(point);
    }
}
