import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Dataset } from './dataset.model';
import { Observable, of, throwError } from 'rxjs';
import { delay, map, concatMap, tap, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class DatasetService {
    apiBase: string = environment.hemisphereApi;
    options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        params: new HttpParams({}),
    };
    constructor(private http: HttpClient) {}

    getDataset(): Observable<Dataset[]> {
        // Call API when ready
        return this.http.get<Dataset[]>(`${this.apiBase}/datasets`);
    }
}
