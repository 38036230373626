import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from 'app/core/reducers';
import { environment } from 'environments/environment';
import { AuthNoticeService, isLoggedIn } from '../../../core/auth';
import { LayoutConfigService, SplashScreenService } from '../../../core/_base/layout';

@Component({
    selector: 'hm-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    // Public properties
    today: number = Date.now();
    headerLogo: string;
    externalUris = environment.externalUris;

    isLoggedIn$: Observable<boolean>;

    /**
     * Component constructor
     *
     * @param el
     * @param render
     * @param layoutConfigService: LayoutConfigService
     * @param authNoticeService: authNoticeService
     * @param splashScreenService: SplashScreenService
     */
    constructor(
        private el: ElementRef,
        private render: Renderer2,
        private layoutConfigService: LayoutConfigService,
        public authNoticeService: AuthNoticeService,
        private splashScreenService: SplashScreenService,
        private store: Store<AppState>
    ) {}

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.headerLogo = this.layoutConfigService.getLogo();

        this.splashScreenService.hide();

        this.isLoggedIn$ = this.store.select(isLoggedIn);
    }

    /**
     * Load CSS for this specific page only, and destroy when navigate away
     * @param styleUrl
     */
    private loadCSS(styleUrl: string) {
        return new Promise((resolve, reject) => {
            const styleElement = document.createElement('link');
            styleElement.href = styleUrl;
            styleElement.type = 'text/css';
            styleElement.rel = 'stylesheet';
            styleElement.onload = resolve;
            this.render.appendChild(this.el.nativeElement, styleElement);
        });
    }
}
