import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { combineLatest, from, map, Observable } from 'rxjs';

import { AppState } from 'app/core/reducers';
import { OffcanvasOptions } from 'app/core/_base/layout';
import { OverlayService } from 'app/views/partials/layout/overlay/overlay.service';
import {
    BookmarkedReport,
    BookmarkType,
    DashboardReportContent,
    SimpleReportContent,
} from './shared/bookmarked-report.model';

import {
    AddBookmarkAction,
    DeleteBookmarkAction,
    LoadBookmarksAction,
} from './store/bookmark.actions';
import {
    selectNonViewBookmarks,
    selectBookmarksBySubtype,
    selectBookmarksByType,
    selectEveryBookmarks,
} from './store/bookmark.selector';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
    selector: 'hm-bookmarks',
    templateUrl: './bookmarks.component.html',
    styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarksComponent implements OnInit, OnChanges {
    @Input() type: BookmarkType;
    @Input() subtype: string;
    @Input() content: Object;

    bookmarkName: string;
    shareWithAllUsers: boolean = false;
    defaultView: boolean = false;
    reportFormat: boolean = false;
    bookmarksSimple$: Observable<BookmarkedReport[]>;
    bookmarksAnalysis$: Observable<BookmarkedReport[]>;
    bookmarksAll$: Observable<BookmarkedReport[]>;
    bookmarksView$: Observable<BookmarkedReport[]>;

    bookmarks$: Observable<BookmarkedReport[]>;

    bookmarksPanelOptions: OffcanvasOptions = {
        overlay: true,
        baseClass: 'kt-bookmarks-panel',
        closeBy: 'kt_bookmarks_panel_close',
        toggleBy: 'kt_bookmarks_panel_toggle',
    };

    isSysAdmin$ = from(this.permissionsService.hasPermission(['SYS_ADMIN']));
    isClientAdmin$ = from(this.permissionsService.hasPermission(['CLIENT_ADMIN']));

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private overlayService: OverlayService
    ) {}

    ngOnInit() {
        this.store.dispatch(new LoadBookmarksAction());
        // this.bookmarksAnalysis$ = this.store.select(selectBookmarksByType(), {
        //     type: 'analysis',
        // });
        // this.bookmarksSimple$ = this.store.select(selectBookmarksByType(), {
        //     type: 'simple',
        // });
        // this.bookmarksDashboard$ = this.store.select(selectBookmarksByType(), {
        //     type: 'dashboard',
        // });
        // this.bookmarksView$ = this.store.select(selectBookmarksByType(), {
        //     type: 'view',
        // });
        this.bookmarks$ = this.store.select(selectBookmarksBySubtype(), {
            type: this.type,
            subtype: this.subtype,
        });
        this.bookmarksAll$ = combineLatest([
            this.store.select(selectNonViewBookmarks(), {}),
            this.bookmarks$,
        ]).pipe(
            map(([nonViewBookmarks, bookmarks]) => {
                // Assuming `BookmarkedReport` has an `id` property for comparison
                const bookmarkedIds = bookmarks.map((b) => b.id);
                return nonViewBookmarks.filter((nonView) => !bookmarkedIds.includes(nonView.id));
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {}

    loadBookmark(bookmark: BookmarkedReport) {
        // route to component based on type/subtype
        let route: string[];
        if (bookmark.type == 'analysis') {
            route = ['/analysis', bookmark.subtype];
        } else if (bookmark.type == 'view') {
            route = [bookmark.link];
        } else if (bookmark.type == 'simple' || bookmark.type == 'dashboard') {
            route = [bookmark.subtype];
        } else {
            console.error('Invalid bookmark type', bookmark.type, bookmark.subtype);
            return;
        }
        this.router.navigate(route, { queryParams: { bookmark: bookmark.id } });
    }

    ownerOfBookmark(bookmark: BookmarkedReport, isSysAdmin: boolean, isClientAdmin: boolean) {
        if (bookmark.shared) {
            if (isSysAdmin) {
                return true;
            } else if (isClientAdmin) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    saveBookmark() {
        const now = new Date();

        this.store.dispatch(
            new AddBookmarkAction({
                bookmark: {
                    type: this.type,
                    subtype: this.subtype,
                    name: this.bookmarkName,
                    link: this.router.url,
                    shared: this.shareWithAllUsers,
                    default_view: this.defaultView,
                    content: _.cloneDeep(this.content),
                },
            })
        );
    }

    showTrial(e) {
        e.preventDefault();
        this.overlayService.overlaySetMessage(
            'This feature is only not available with your current account'
        );
        this.overlayService.overlayOn();
    }

    deleteBookmark(bookmark: BookmarkedReport) {
        this.store.dispatch(new DeleteBookmarkAction({ id: bookmark.id }));
    }

    summary(bookmark: BookmarkedReport) {
        if (bookmark.type == 'simple') {
            return this.simpleSummary(bookmark);
        } else if (bookmark.type == 'dashboard') {
            return this.compareSummary(bookmark);
        } else if (bookmark.type == 'analysis' || bookmark.type == 'view') {
            return this.analysisSummary(bookmark);
        } else {
        }
    }

    simpleSummary(bookmark: BookmarkedReport) {
        const content = bookmark.content as SimpleReportContent;
        const type = bookmark.subtype.split('/').slice(2).join(' ');
        const dates = content.date instanceof Array ? content.date.join(' to ') : content.date;
        const plural = content.boundaries.length > 1 ? '`s' : '';
        const boundaries = `${content.boundaries.length} ${content.boundaryType}${plural}`;
        return `${type}, ${dates}, ${boundaries}`;
    }

    compareSummary(bookmark: BookmarkedReport) {
        const content = bookmark.content as DashboardReportContent;
        if (bookmark.subtype == '/regions/region-compare') {
            return `comparison dashboard - ${content.comparisons.length} cols`;
        } else {
            const dashboard = content.comparisons[0]; // Dashboard uses the first object from the comparisons
            const date = dashboard.endDate;
            const type = bookmark.subtype.split('/').slice(2).join(' ');
            const plural = dashboard.boundaries.length > 1 ? '`s' : '';
            const boundaries = `${dashboard.boundaries.length} ${dashboard.boundaryType}${plural}`;
            return `${type}, ${date}, ${boundaries}`;
        }
    }

    analysisSummary(bookmark: BookmarkedReport) {
        return `${bookmark.subtype.split(':').join(' ')}`;
    }
}
