import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isNumber } from 'lodash';
import { ChartOptions } from '../shared/charts.model';

@Component({
    selector: 'hm-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('exporter', { static: false }) exporter: any;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() options: ChartOptions;
    source: MatTableDataSource<any> = new MatTableDataSource();

    displayedHeaders = ['Date'];
    displayedColumns: string[];
    measures;

    constructor() {}

    ngOnInit(): void {
        this.options.yFieldNames.forEach((axis) => {
            this.displayedHeaders.push(axis.title);
        });

        const cols = this.options.yFieldNames.map((n) => n.name);
        this.displayedColumns = ['date', ...cols];
        this.measures = this.displayedColumns.map((n, i) => {
            return {
                uniqueName: n,
                title: this.displayedHeaders[i],
            };
        });

        this.source.data = this.options.data;
        this.source.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && !changes.options.isFirstChange()) {
            this.source.data = this.options.data;
        }
    }

    ngAfterViewInit() {
        this.source.paginator = this.paginator;
        this.source.sort = this.sort;
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'data-table' });
    }

    isNumber(val) {
        return isNumber(val);
    }
}
