import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'kt-transport-mode',
    templateUrl: './transport-mode.component.html',
    styleUrls: ['./transport-mode.component.scss'],
})
export class TransportModeComponent implements OnInit {
    @Output() travelModeEvent = new EventEmitter<string>();
    @Input() travelModes: string[];

    constructor() {}

    ngOnInit() {}

    // When travel mode changes, emit the changes to travelModeEvent and Output it to HotelsComponent
    updateTravelOption(travelOption): void {
        this.travelModeEvent.emit(travelOption);
    }
    travelModeOn(mode: string): boolean {
        if (this.travelModes.includes(mode)) {
            return true;
        } else return false;
    }
}
