<div class="report">
    <div class="row justify-content-end">
        <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
            <mat-icon>file_download</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportAs()">CSV</button>
        </mat-menu>
    </div>

    <table
        class="numerical-table"
        *ngIf="dataAvailable"
        mat-table
        [dataSource]="data"
        matTableExporter
        matSort
        [matSortActive]="selectedMeasure.uniqueName"
        matSortDirection="desc"
        matSortDisableClear
        #exporter="matTableExporter"
    >
        <ng-container matColumnDef="category" sticky>
            <mat-header-cell *matHeaderCellDef mat-sort-header>Sentiment Topics</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.category }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="avg_sentiment">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Average Sentiment</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.avg_sentiment | number: '1.1-1' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="median_sentiment">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Median Sentiment</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.median_sentiment | number: '1.1-1' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating_1_pct">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rating 1</mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let report">{{ report.rating_1_pct | shortNumber: 'p' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating_2_pct">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rating 2</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let report">{{ report.rating_2_pct | shortNumber: 'p' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating_3_pct">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rating 3</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let report">{{ report.rating_3_pct | shortNumber: 'p' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating_4_pct">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rating 4</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let report">{{ report.rating_4_pct | shortNumber: 'p' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rating_5_pct">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rating 5</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let report">{{ report.rating_5_pct | shortNumber: 'p' }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButton></mat-paginator>
</div>
