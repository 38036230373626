export class Organisation {
    public id: number;
    public auth0ID: string;
    public email: string;
    public name: string;
    public description: string;
    public phone_number: string;
    public address: string;
    public suburb: string;
    public state: string;
    public postcode: string;
    public parent_id?: number;
    public level: number;
    public active: boolean;
    public selectable: boolean;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

export interface OrganisationTreeNode extends Organisation {
    children?: OrganisationTreeNode[];
}

export interface CreateResponse {
    id: number;
}
