<div class="heat-map-container">
    <mgl-map
        [style]="style | async"
        [zoom]="3"
        [center]="[133.7751, -25.2744]"
        (mapLoad)="onLoad($event)"
        [cursorStyle]="cursorStyle"
        preserveDrawingBuffer="true"
        [projection]="'mercator'"
    >
        <mgl-control mglFullscreen></mgl-control>
        <mgl-control mglNavigation position="top-right"></mgl-control>
        <mgl-control mglScale position="top-right"></mgl-control>
    </mgl-map>
    <hm-report-controls
        [measures]="config.measures"
        [scale]="scale"
        (selectedMeasureChange)="onSelectedMeasureChange($event)"
        [selectedMeasure]="currMeasure"
    ></hm-report-controls>
</div>
