<mgl-map
    *ngIf="style"
    [style]="style | async"
    [zoom]="zoom$ | async"
    [center]="lnglat$ | async"
    [cursorStyle]="cursorStyle"
    preserveDrawingBuffer="true"
    (mapLoad)="onMapLoad($event)"
    [projection]="'mercator'"
>
    <mgl-control mglFullscreen></mgl-control>
    <mgl-control mglNavigation position="top-right"></mgl-control>
    <mgl-control mglScale position="top-right"></mgl-control>

    <mgl-vector-source id="boundaries" [url]="boundaryMap$ | async" [promoteId]="'code'"></mgl-vector-source>
    <mgl-vector-source *ngIf="showListings" id="listings" [url]="listingsUrl"></mgl-vector-source>

    <mgl-layer
        *ngIf="showListings"
        id="hotels-unclustered"
        source="listings"
        type="circle"
        sourceLayer="listings-points"
        [paint]="listingsPaintConfig"
        [filter]="filterListingsCategories"
    >
    </mgl-layer>

    <mgl-layer
        *ngIf="ownBoundaries"
        id="boundaries-no-permissions"
        source="boundaries"
        sourceLayer="boundary"
        [filter]="filterBoundariesDisallowed"
        type="fill"
        [paint]="{
            'fill-color': mapNoRightsColor,
            'fill-opacity': 0.5
        }"
    >
    </mgl-layer>

    <mgl-layer
        id="boundaries-fills"
        source="boundaries"
        sourceLayer="boundary"
        [filter]="filterBoundariesAllowed"
        type="fill"
        [paint]="{
            'fill-color': mapSelectedColor,
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.88,
                ['boolean', ['feature-state', 'click'], false],
                0.7,
                0
            ]
        }"
        (layerMouseMove)="onHoverOver($event)"
        (layerMouseLeave)="onHoverOff()"
        (layerClick)="onClick($event)"
    >
    </mgl-layer>

    <mgl-layer
        id="boundaries-borders"
        type="line"
        source="boundaries"
        sourceLayer="boundary"
        [paint]="{
            'line-color': mapLineColor,
            'line-width': 1.1
        }"
    >
    </mgl-layer>

    <mgl-popup *ngIf="popupLngLat" [lngLat]="popupLngLat">
        <h4>{{ popupProps.title }}</h4>
        <h5>{{ popupProps.state }}</h5>
    </mgl-popup>
</mgl-map>
