import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectTileServerUrl } from 'app/core/session-info/store/selectors/session-info.selector';
import { MapboxStyles } from 'app/core/whitelabel/shared/whitelabel.model';
import { environment } from 'environments/environment';
import { LngLat, MapLayerMouseEvent, Map as MapboxMap } from 'mapbox-gl';
import { BehaviorSubject, Observable, Subject, combineLatest, map, of, takeUntil } from 'rxjs';

@Component({
    selector: 'hm-choropleth',
    templateUrl: './choropleth.component.html',
    styleUrls: ['./choropleth.component.scss'],
})
export class ChoroplethComponent implements OnInit {
    @Input() boundaryType: string;
    @Input() data: Map<string, any[]> = new Map<string, any[]>();
    @Input() keys: string[];
    @Input() selectedKey: string;
    @Input() colours: string[][];
    @Input() dirty: boolean;

    // data: Map<string, any[]>;

    map: MapboxMap;
    zoom: number = 3;
    center: LngLat = new LngLat(133.7751, -25.2744);
    cursorStyle = '';
    // style = mapboxStyle;
    style: Observable<string>;

    // Boundary Outlines
    boundaryMap$ = new BehaviorSubject<string>(
        `${environment.tileserver.baseUrl}/capabilities/au-state.json`
    );

    // Pop-up
    hoveredBoundary;
    popupLngLat = null;
    popupContent = null;

    // Slider
    sliderMax: number;
    tileserverUrl: string;

    dirtyLngLat = [133.7751, -25.2744];
    private componentDestroyed = new Subject<void>();
    constructor(private store: Store<AppState>) {}

    ngOnInit(): void {
        this.style = of(MapboxStyles.DNSW);
        this.store
            .select(selectTileServerUrl)
            .pipe(
                takeUntil(this.componentDestroyed),
                map((tileServerUrl) => {
                    this.tileserverUrl = tileServerUrl;
                    this.boundaryMap$.next(
                        `${this.tileserverUrl}${this.boundaryType.toLowerCase()}.json`
                    );
                })
            )
            .subscribe();
        this.sliderMax = this.data.size - 1;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['boundaryType'] && !changes['boundaryType'].isFirstChange()) {
            this.boundaryMap$.next(`${this.tileserverUrl}${this.boundaryType.toLowerCase()}.json`);
        }

        if (changes['data'] && !changes['data'].isFirstChange()) {
            this.setPaintConfig();
            this.sliderMax = this.data.size - 1;
        }

        if (changes['keys'] && !changes['keys'].isFirstChange()) {
        }

        if (changes['selectedKey'] && !changes['selectedKey'].isFirstChange()) {
            this.setPaintConfig();
        }

        if (changes['dirty'] && !changes['dirty'].isFirstChange()) {
            this.setPaintConfig();
        }
    }

    setPaintConfig() {
        let colours;

        if (!this.dirty) {
            colours = ['match', ['get', 'code']];
            this.data.get(this.selectedKey).map((d) => {
                colours.push(d.merchant_bdy_id.split('/')[1]);
                colours.push(d['colour']);
            });
            colours.push('rgba(0, 0, 0, 0.01)');
        } else {
            colours = 'rgba(0, 0, 0, 1.0)';
        }

        return {
            'fill-color': colours,
            'fill-opacity': 0.8,
        };
    }

    onHover(event: MapLayerMouseEvent) {
        this.cursorStyle = 'pointer';

        if (event.features && event.features.length > 0) {
            const found = this.data
                .get(this.selectedKey)
                .find((d) => d.merchant_bdy_id.split('/')[1] === event.features[0].properties.code);
            if (found) {
                this.hoveredBoundary = found;
                this.popupLngLat = event.lngLat;
                this.popupContent = event.features[0].properties;
            } else {
                this.resetPopup();
            }
        }
    }

    offHover() {
        this.cursorStyle = '';
        this.resetPopup();
    }

    resetPopup() {
        this.popupLngLat = null;
        this.popupContent = null;
        this.hoveredBoundary = null;
    }
}
