import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { calculateColorBand } from 'app/core/utilities/colors';
import { MapboxStyles } from 'app/core/whitelabel/shared/whitelabel.model';
import { selectWhitelabelState } from 'app/core/whitelabel/store/whitelabel.selector';
import { setScaleBuckets } from 'app/core/utilities/utilities';
import { environment } from 'environments/environment';
import { AnyLayer, FillPaint, Map, Popup } from 'mapbox-gl';
import { BehaviorSubject, Observable, Subject, of, takeUntil } from 'rxjs';
import { Measure } from '../../measures/shared/measure.model';
import { HeatMapConfig } from './shared/heat-map.model';

// import { colors, mapboxStyle, mapLineColor } from 'app/whitelabel/hemisphere/mapbox-colors';

@Component({
    selector: 'hm-heat-map',
    templateUrl: './heat-map.component.html',
    styleUrls: ['./heat-map.component.scss'],
})
export class HeatMapComponent implements OnInit, OnDestroy {
    @Input() config: HeatMapConfig;

    map: Map;
    cursorStyle = '';
    style: Observable<string>;
    boundaryMap$ = new BehaviorSubject<string>(
        `${environment.tileserver.baseUrl}/capabilities/au-state.json`
    );

    popUp = new Popup({
        maxWidth: '600px',
        closeButton: false,
        closeOnClick: false,
    });

    currMeasure: Measure;
    scale = [];
    currencyPipe: CurrencyPipe;
    private componentDestroyed = new Subject<void>();
    mapLineColor: string;
    colors: string[];

    constructor(private store: Store<AppState>) {
        this.currencyPipe = new CurrencyPipe('en-AU');
    }

    ngOnInit(): void {
        this.store
            .pipe(select(selectWhitelabelState))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((wl) => {
                this.mapLineColor = wl.whitelabel.mapLineColor;
                this.colors = wl.whitelabel.mapboxColors;
            });
        this.style = of(MapboxStyles.DNSW);
        this.boundaryMap$.next(
            `${
                environment.tileserver.baseUrl
            }/capabilities/au-${this.config.boundaryType.toLowerCase()}.json`
        );

        // Default metric to display on the map
        this.currMeasure = this.config.measures[0];

        // Scale can be calculated without waiting for map to load
        this.setScale(this.currMeasure);
    }
    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    onLoad(map: Map) {
        this.map = map;
        this.setupMap(map);
    }

    setupMap(map: Map) {
        map.addSource('boundaries', {
            type: 'vector',
            url: `${this.boundaryMap$.getValue()}`,
            promoteId: 'code',
        });

        map.addLayer({
            id: 'state-borders',
            type: 'line',
            source: 'boundaries',
            'source-layer': `boundary`,
            paint: {
                'line-color': this.mapLineColor,
                'line-width': 1.1,
            },
        });

        const layerID = this.currMeasure.uniqueName;
        this.removeLayer(layerID);

        this.addLayer(layerID);
    }

    onSelectedMeasureChange(newMeasure: Measure) {
        // Remove prev layer
        this.removeLayer(this.currMeasure.uniqueName);

        this.currMeasure = newMeasure;

        // Set Scale
        this.setScale(this.currMeasure);

        this.addLayer(this.currMeasure.uniqueName);
    }

    addLayer(id: string) {
        // Add new layer
        const newLayer: AnyLayer = {
            id,
            source: 'boundaries',
            'source-layer': `boundary`,
            type: 'fill',
            paint: this.getPaintConfig(),
            interactive: true,
        };
        this.map.addLayer(newLayer, 'state-borders');

        this.hoverOn(id);

        this.hoverOff(id);
    }

    removeLayer(id: string): void {
        if (this.map.getLayer(id)) {
            this.map.removeLayer(id);
        }
    }

    setScale(measure: Measure) {
        const selectedMeasureValue = this.config.data.map((d) => d[measure.uniqueName]);

        const min = Math.min(...selectedMeasureValue);
        const max = Math.max(...selectedMeasureValue);

        const buckets = setScaleBuckets(min, max, selectedMeasureValue);

        this.scale = buckets;
    }

    getPaintConfig(): FillPaint {
        const colours: any = ['match', ['get', 'code']];

        this.config.data.map((d) => {
            if (this.config.boundaryType.startsWith('sa')) {
                colours.push(Number(d.boundary_code));
            } else {
                colours.push(d.boundary_code);
            }
            colours.push(
                calculateColorBand(d[this.currMeasure.uniqueName], this.scale, this.colors)
            );
        });
        colours.push('rgba(0, 0, 0, 0.01)');

        const newPaintConfig = {
            'fill-color': colours,
            'fill-opacity': 0.75,
        };

        return newPaintConfig;
    }

    hoverOn(layerID): void {
        this.map.on('mousemove', layerID, (event) => {
            this.cursorStyle = 'pointer';
            const popupProps = event.features[0].properties;

            const popupLngLat = event.lngLat;

            const popupMetrics = this.config.data.filter(
                (row) => row.boundary_code === popupProps.code
            )[0];
            if (popupMetrics) {
                const spendLocal = this.currencyPipe.transform(
                    popupMetrics.spend_per_cardholder_local,
                    'AUD',
                    'symbol-narrow',
                    '1.2-2'
                );

                const spendVisitor = this.currencyPipe.transform(
                    popupMetrics.spend_per_cardholder_visitor,
                    'AUD',
                    'symbol-narrow',
                    '1.2-2'
                );

                const spendLocal12Months = this.currencyPipe.transform(
                    popupMetrics.spend_per_cardholder_local_12months,
                    'AUD',
                    'symbol-narrow',
                    '1.2-2'
                );

                const spendVisitor12Months = this.currencyPipe.transform(
                    popupMetrics.spend_per_cardholder_visitor_12months,
                    'AUD',
                    'symbol-narrow',
                    '1.2-2'
                );

                const popupContent = `
                    <h4>${popupProps.title}</h4>
                    Unique Visits (Local): ${popupMetrics.unique_visits_local}</br>
                    Unique Visits  (Visitor): ${popupMetrics.unique_visits_visitor}</br>
                    Spend per Cardholder (Local): ${spendLocal}</br>
                    Spend per Cardholder (Visitor): ${spendVisitor}</br>
                    Spend per Cardholder (Local) 12 Months Avg: ${spendLocal12Months}</br>
                    Spend per Cardholder (Visitor) 12 Months Avg: ${spendVisitor12Months}</br>
                `;

                this.popUp.setLngLat(popupLngLat).setHTML(popupContent).addTo(this.map);
            }
        });
    }

    hoverOff(layerID): void {
        this.map.on('mouseleave', layerID, () => {
            this.popUp.remove();
        });
    }
}
