<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
    <div ngbDropdownToggle class="kt-header__topbar-wrapper">
        <span
            class="kt-header__topbar-icon"
            [ngClass]="{
                'kt-pulse kt-pulse--brand': pulse,
                'kt-pulse--light': pulseLight,
                'kt-header__topbar-icon--success': iconType === 'success'
            }"
        >
            <i *ngIf="!useSVG" [ngClass]="icon"></i>
            <span
                *ngIf="useSVG"
                class="kt-svg-icon"
                [ngClass]="{ 'kt-svg-icon--success': iconType === 'success' }"
                [inlineSVG]="icon"
            ></span>
            <span class="kt-pulse__ring" [hidden]="!pulse"></span>
        </span>
        <span class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand" [hidden]="!dot"></span>
    </div>
</div>
