import { Action } from '@ngrx/store';
import { Organisation } from 'app/views/pages/organisations/shared/organisation.model';
import { User } from 'app/views/pages/users/shared/user.model';
import { OrganisationDataset } from '../../shared/organisation-dataset.model';
import { Setting } from '../../shared/setting.model';

export enum SessionInfoActionType {
    SessionInfoGet = '[SessionInfo] Get GetSessionInfo',
    SessionInfoLoaded = '[Session Info] SessionInfo Loaded',
    SessionInfoExtras = '[Session Info ] LoadExtras',
}

export class SessionInfoGet implements Action {
    public readonly type = SessionInfoActionType.SessionInfoGet;
    constructor() {}
}

export class SessionInfoLoaded implements Action {
    public readonly type = SessionInfoActionType.SessionInfoLoaded;
    constructor(
        public payload: {
            user: User;
            organisation: Organisation;
            setting: Setting[];
            dataset: OrganisationDataset[];
        }
    ) {}
}

export class SessionInfoExtras implements Action {
    public readonly type = SessionInfoActionType.SessionInfoExtras;
    constructor() {}
}

export type SessionInfoActions = SessionInfoGet | SessionInfoLoaded | SessionInfoExtras;
