import { BookmarkAction, BookmarkActions } from './bookmark.actions';
import { initialBookmarkState, BookmarkState, bookmarkAdapter, ADDING_ID } from './bookmark.state';

export function bookmarkReducer(
    state = initialBookmarkState,
    action: BookmarkActions
): BookmarkState {
    switch (action.type) {
        case BookmarkAction.LoadBookmarks:
            return { ...state, loading: true };

        case BookmarkAction.LoadBookmarksSuccess:
            return bookmarkAdapter.setAll(action.payload.bookmarks, {
                ...state,
                error: null,
                loading: false,
            });

        case BookmarkAction.LoadBookmarksFailure:
            return bookmarkAdapter.removeAll({
                ...state,
                error: action.payload.error,
                loading: false,
            });

        case BookmarkAction.AddBookmark:
            return bookmarkAdapter.addOne(
                {
                    ...action.payload.bookmark,
                    id: ADDING_ID,
                },
                state
            );

        case BookmarkAction.AddBookmarkSuccess:
            const added = state.entities[ADDING_ID];
            const removedState = bookmarkAdapter.removeOne(ADDING_ID, state);
            return bookmarkAdapter.addOne({ ...added, id: action.payload.id }, removedState);

        case BookmarkAction.AddBookmarkFailure:
            return bookmarkAdapter.removeOne(ADDING_ID, {
                ...state,
                error: action.payload.error,
            });

        case BookmarkAction.EditBookmark:
            return {
                ...state,
                error: null,
                loading: false,
            };

        case BookmarkAction.EditBookmarkSuccess:
            return bookmarkAdapter.upsertOne(action.payload.bookmark, { ...state });

        case BookmarkAction.EditBookmarkFailure:
            return {
                ...state,
                error: action.payload.error,
            };

        case BookmarkAction.DeleteBookmark:
            const removed = state.entities[action.payload.id];
            return bookmarkAdapter.removeOne(action.payload.id, {
                ...state,
                deleting: removed,
            });

        case BookmarkAction.DeleteBookmarkSuccess:
            return {
                ...state,
                deleting: null,
            };

        case BookmarkAction.DeleteBookmarkFailure:
            return bookmarkAdapter.addOne(state.deleting, {
                ...state,
                deleting: null,
            });

        default:
            return state;
    }
}
