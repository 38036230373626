import { NgxPermissionsModule } from 'ngx-permissions';
// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Angular Material
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
// NgBootstrap
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
// Translation
import { TranslateModule } from '@ngx-translate/core';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
// NGRX
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
// Ngx DatePicker
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2';
// Core Module
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { filtersReducer, searchReducer } from 'app/core/filters/store/filters.reducer';
import { SessionInfoEffects, sessionInfoReducer } from 'app/core/session-info/store';
import {
    UserDataPermissionEffects,
    userDataPermissionReducer,
} from 'app/core/user-data-permissions/store';
import { CoreModule } from '../../core/core.module';
import { DataPermissionEffects, dataPermissionReducer } from '../../core/data-permissions/store';
import { DataSetEffects, dataSetReducer } from '../../core/dataset/store';
import { BookmarkEffects } from '../partials/content/bookmarks/store/bookmark.effects';
import { bookmarkReducer } from '../partials/content/bookmarks/store/bookmark.reducer';
import { CategoryEffects, categoryReducer } from '../partials/content/category/store';
import { MeasureEffects, measureReducer } from '../partials/content/measures/store';
import { PrintComponent } from '../partials/layout/print/print.component';
import { PartialsModule } from '../partials/partials.module';
import { AsideLeftComponent } from './aside/aside-left.component';
import { BaseComponent } from './base/base.component';
import { BrandComponent } from './brand/brand.component';
import { ErrorPageComponent } from './content/error-page/error-page.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderMobileComponent } from './header/header-mobile/header-mobile.component';
import { HeaderComponent } from './header/header.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { HtmlClassService } from './html-class.service';
import { SubheaderComponent } from './subheader/subheader.component';
import { PipeModule } from '../../pipes/pipe.module';
import { whitelabelReducer } from 'app/core/whitelabel/store/whitelabel.reducer';
import { WhitelabelEffects } from 'app/core/whitelabel/store/whitelabel.effects';

@NgModule({
    declarations: [
        BaseComponent,
        FooterComponent,
        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        // subheader
        SubheaderComponent,
        // topbar components
        TopbarComponent,
        // aside left menu components
        AsideLeftComponent,
        // horizontal menu components
        MenuHorizontalComponent,
        ErrorPageComponent,
        PrintComponent,
    ],
    exports: [
        BaseComponent,
        FooterComponent,
        PrintComponent,
        // headers
        HeaderComponent,
        BrandComponent,
        HeaderMobileComponent,
        // subheader
        SubheaderComponent,
        // topbar components
        TopbarComponent,
        // aside left menu components
        AsideLeftComponent,
        // horizontal menu components
        MenuHorizontalComponent,
        ErrorPageComponent,
        NgxPermissionsModule,
    ],
    providers: [
        HtmlClassService,
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxPermissionsModule.forChild(),
        // TODO: see about putting Store and Effects declarations into feature modules
        // rather than this general module
        StoreModule.forFeature('data-permissions', dataPermissionReducer),
        StoreModule.forFeature('categories', categoryReducer),
        StoreModule.forFeature('measures', measureReducer),
        StoreModule.forFeature('user-data-permissions', userDataPermissionReducer),
        StoreModule.forFeature('sessioninfo', sessionInfoReducer),
        StoreModule.forFeature('dataset', dataSetReducer),
        StoreModule.forFeature('bookmarks', bookmarkReducer),
        StoreModule.forFeature('filters', filtersReducer),
        StoreModule.forFeature('filters-search', searchReducer),
        StoreModule.forFeature('whitelabel', whitelabelReducer),
        EffectsModule.forFeature([
            CategoryEffects,
            MeasureEffects,
            DataPermissionEffects,
            UserDataPermissionEffects,
            SessionInfoEffects,
            DataSetEffects,
            BookmarkEffects,
            WhitelabelEffects,
        ]),
        PartialsModule,
        CoreModule,
        PerfectScrollbarModule,
        FormsModule,
        MatProgressBarModule,
        MatTabsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        TranslateModule.forChild(),
        LoadingBarModule,
        InlineSVGModule,
        // ng-bootstrap modules
        NgbProgressbarModule,
        NgbTooltipModule,
        PipeModule,
    ],
})
export class ThemeModule {}
