<div class="choropleth-container">
    <div class="map item">
        <mgl-map
            [style]="style | async"
            [zoom]="zoom"
            [center]="center"
            (mapLoad)="map = $event"
            [cursorStyle]="cursorStyle"
            preserveDrawingBuffer="true"
            [projection]="'mercator'"
        >
            <mgl-control mglNavigation [showCompass]="true" [showZoom]="true"></mgl-control>
            <mgl-control mglFullscreen></mgl-control>
            <mgl-control mglScale unit="metric" position="bottom-right"></mgl-control>

            <mgl-vector-source id="boundaries" [url]="boundaryMap$ | async" [promoteId]="'code'"></mgl-vector-source>
            <mgl-layer
                id="boundary-lines"
                type="line"
                source="boundaries"
                [sourceLayer]="'boundary'"
                [paint]="{
                    'line-color': '#000000',
                    'line-width': 1.1
                }"
            >
            </mgl-layer>

            <mgl-layer
                id="choropleth"
                source="boundaries"
                [sourceLayer]="'boundary'"
                type="fill"
                [paint]="setPaintConfig()"
                (layerMouseMove)="onHover($event)"
                (layerMouseLeave)="offHover()"
                before="boundary-lines"
            >
            </mgl-layer>

            <mgl-popup *ngIf="popupLngLat && hoveredBoundary" [lngLat]="popupLngLat">
                <h4>{{ popupContent.title }}</h4>
                <table class="pop-up">
                    <tr>
                        <td>Spend</td>
                        <td>Rainfall</td>
                    </tr>
                    <tr>
                        <td>{{ hoveredBoundary['val_tran_ix'] | shortNumber : 'n' }}</td>
                        <td>{{ hoveredBoundary['rainfall_ix'] | shortNumber : 'n' }}</td>
                    </tr>
                </table>
                <b> Colour: {{ hoveredBoundary['colour'] }} </b>
            </mgl-popup>

            <mgl-popup *ngIf="dirty" [lngLat]="dirtyLngLat" [closeButton]="false">
                <b>Options Changed</b>
                <p>Please click Update.</p>
            </mgl-popup>
        </mgl-map>
    </div>
    <div class="legend item">
        <hm-choropleth-legend [colours]="colours"></hm-choropleth-legend>
    </div>
</div>
