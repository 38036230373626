import { Colours } from './colour-palette';

export const ACCOMMODATION = 'Accommodation';
export const ATDW = 'ATDW';
export class Category {
    public id: number;
    public name: string;
    public color: string;
    public icon: string;
    public selected = true;
    public category_type: string;

    constructor(obj: any) {
        Object.assign(this, obj);
        const themeColor = Colours[obj.id - 1];
        this.color = themeColor.colour;
    }
}
