import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
// NGRX
import { Store } from '@ngrx/store';
import { filter, Observable, switchMap, take } from 'rxjs';
import { tap } from 'rxjs/operators';
// // Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isUserLoaded } from '../_selectors/auth.selectors';
import { Auth0Service } from '../_services/auth0.service';

@Injectable({
    providedIn: 'root',
})
export class Auth0Guard implements CanActivate {
    constructor(
        private auth: Auth0Service,
        private store: Store<AppState>,
        private router: Router
    ) {}

    /**
     * canActivate() handles user authentication with Auth0 and reloading the Ngrx store upon browser refresh
     * @returns an Observable boolean
     */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
        return this.waitForHandleAuthCallbackToComplete().pipe(
            // switchMap interrupts the source observable by subscribing to and completing the inner observables first
            switchMap(() =>
                // Handles user authentication with Auth0
                this.auth.isAuthenticated$.pipe(
                    tap((loggedIn) => {
                        if (!loggedIn) {
                            this.router.navigate(['/auth/login'], {
                                queryParams: { target: state.url },
                            });
                        }
                    })
                )
            ),
            switchMap(() =>
                // Handles Ngrx store reload upon browser refresh

                this.store.select(isUserLoaded).pipe(
                    tap((loaded) => {
                        if (!loaded) {
                            this.auth.handleAuthReload(state.url);
                        }
                    })
                )
            )
        );
    }

    waitForHandleAuthCallbackToComplete(): Observable<boolean> {
        return this.auth.handleAuthCallbackComplete$.pipe(
            filter((complete) => complete),
            // Results from Behavior Subjects are passed through next(),
            // hence never resorting to complete()
            // As such, we need to take the first "completed" value
            take(1)
        );
    }
}
