import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APIError } from 'app/core/utilities/api-error';
import { MarketplaceService } from 'app/views/pages/marketplace/shared/marketplace.service';
import { EnquireForm } from './shared/enquire.model';

@Component({
    selector: 'hm-enquire',
    templateUrl: './enquire.component.html',
    styleUrls: ['./enquire.component.scss'],
})
export class EnquireComponent implements OnInit {
    form: UntypedFormGroup;

    message: string;

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<EnquireComponent>,
        private marketplaceService: MarketplaceService,
        private snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.data.name, Validators.required],
            contactEmail: [
                this.data.email,
                Validators.compose([Validators.email, Validators.required]),
            ],
            contactPhone: [''],
            description: [''],
        });
    }

    save() {
        const formVals = this.form.value;

        const newEnquiry: EnquireForm = {
            email: formVals.contactEmail,
            name: formVals.name,
            phone_number: formVals.contactPhone,
            message: formVals.description,
        };
        // call api to send values
        this.marketplaceService.enquire(newEnquiry).subscribe({
            next: (resp: HttpResponse<null>) => {
                if (resp.status === 201) {
                    this.message =
                        'Thanks for your enquiry. A member of our team will be in touch shortly.';
                    // if api sends ok, set good message, else set failure message
                    this.dialogRef.close(this.message);
                }
            },
            error: (err: HttpResponse<APIError>) => {
                if (!err) {
                    this.message = 'An error occured. Please try again.';
                    this.dialogRef.close(this.message);
                }
            },
        });
    }

    close() {
        this.dialogRef.close();
    }
}
