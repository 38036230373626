import {
    Component,
    Directive,
    EventEmitter,
    Inject,
    Injectable,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
    DateRange,
    MatDateRangeSelectionStrategy,
    MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { defaultDateArray } from 'app/core/utilities/date';

/**
 * A custom selection strategy for implementing the maximum number of selectable dates.
 */
@Injectable()
export class MaxRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
    start: any;
    public delta: number;
    constructor(private _dateAdapter: DateAdapter<D>) {}

    // selectionFinished() sets the start and end date in relation to the maximum number of days allowed.
    // If the range exceeds the maxDateRange, end date gets assigned to (startDate + maxDateRange)
    // selectionFinished() gets triggered twice, once when start date is selected and another when end date is selected.
    selectionFinished(date: D, currentRange: DateRange<D>) {
        let { start, end } = currentRange;

        // Check whether to assign selected date to start or end, depending on whether start has been assigned.
        if (start == null || (start && end)) {
            // Assign start date
            start = date;
            end = null;
        } else if (end == null) {
            // Validity check on start date being earlier than end date
            // Resets start date to selected date and end date to null if above.
            if (date <= start) {
                start = date;
                end = null;
            } else {
                // Assign selected date to end date or
                // if selectedDate > (startDate + maxDateRange), assign (startDate + maxDateRange) to end date
                const maxDate = this._dateAdapter.addCalendarDays(start, this.delta);
                end = date ? (date > maxDate ? maxDate : date) : null;
            }
        }

        return new DateRange<D>(start, end);
    }

    // createPreview() generates the on:hover effect that displays the maximum number of days the range can go before reaching maxDate
    createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
        if (currentRange.start && !currentRange.end) {
            const maxDate = this._dateAdapter.addCalendarDays(currentRange.start, this.delta);
            const rangeEnd = activeDate ? (activeDate > maxDate ? maxDate : activeDate) : null;

            return new DateRange(currentRange.start, rangeEnd);
        }

        return new DateRange<D>(null, null);
    }
}

/**
 * The custom selection strategy is then converted into a directive so that it can be attached to mat-datepicker
 */
@Directive({
    selector: '[maxRange]',
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: MaxRangeSelectionStrategy,
        },
    ],
})
export class MaxRangeDirective {
    constructor(
        @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
        private maxRangeStrategy: MaxRangeSelectionStrategy<any>
    ) {}
    @Input() set maxRange(value: number) {
        if (value) {
            this.maxRangeStrategy.delta = +value;
        }
    }
}

@Component({
    selector: 'hm-multi-date-selector',
    templateUrl: './multi-date-selector.component.html',
    styleUrls: ['./multi-date-selector.component.scss'],
})
export class MultiDateSelectorComponent implements OnInit {
    @Input() selectedDateRange: Date[];
    @Output() selectedDateRangeChange = new EventEmitter<Date[]>();

    dateSelectorGroup: UntypedFormGroup;
    defaultDates: Date[];

    //YYYY-MMM
    @Input() minDate: string;
    @Input() maxDate: string;
    @Input() maxDateRange: number;

    min: Date;
    max: Date;

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.defaultDates = defaultDateArray();
        if (this.minDate) {
            this.min = new Date(this.minDate);
        }
        if (this.maxDate) {
            this.max = new Date(this.maxDate);
        }
        this.initializeForm();
    }

    initializeForm() {
        if (this.selectedDateRange != null) {
            this.dateSelectorGroup = this.fb.group({
                startDate: [new Date(this.selectedDateRange[0])],
                endDate: [new Date(this.selectedDateRange[1])],
            });
        } else {
            this.dateSelectorGroup = this.fb.group({
                startDate: [new Date(this.defaultDates[0])],
                endDate: [new Date(this.defaultDates[1])],
            });
        }
    }

    dateChange() {
        const start = this.dateSelectorGroup.controls['startDate'].value;
        const end = this.dateSelectorGroup.controls['endDate'].value;

        if (start && end) {
            this.selectedDateRangeChange.emit([start, end]);
        }
    }
}
