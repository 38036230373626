<div class="row justify-content-end">
    <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
        <mat-icon>file_download</mat-icon>
    </button>
    <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="exportAs()">CSV</button>
    </mat-menu>
</div>
<div class="data-table-container watermark-container">
    <div class="watermark" *ngIf="config.watermark">{{ config.watermark }}</div>
    <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" matSort>
        <ng-container *ngFor="let measure of config.measures" matColumnDef="{{ measure.uniqueName }}">
            <mat-header-cell class="header" *matHeaderCellDef mat-sort-header>
                {{ measure.title }}
            </mat-header-cell>
            <!-- If the column value is anything within ignoreFormatter, don't calculate total and don't format the number -->
            <ng-container *ngIf="measure.uniqueName === 'date'">
                <mat-cell class="description-cell" *matCellDef="let row">
                    {{ row[measure.uniqueName] | date: 'dd/MM/yyyy' }}
                </mat-cell>
            </ng-container>
            <ng-container *ngIf="ignoreFormatter.includes(measure.uniqueName); formatNumber">
                <mat-cell class="description-cell" *matCellDef="let row">
                    {{ row[measure.uniqueName] }}
                </mat-cell>
            </ng-container>
            <!-- Calculate the total and format the number -->
            <ng-container #formatNumber>
                <mat-cell class="description-cell" *matCellDef="let row">
                    {{ row[measure.uniqueName] | number: '1.0-2' }}
                </mat-cell>
                <!-- <mat-footer-cell *matFooterCellDef>{{ getTotalCost(measure.uniqueName) | number: '1.0-2' }}</mat-footer-cell> -->
            </ng-container>
        </ng-container>

        <div *ngIf="config.footer">
            <ng-container matColumnDef="grand_total">
                <mat-footer-cell *matFooterCellDef>Grand Total</mat-footer-cell>
            </ng-container>
            <!-- To occupy empty space in footer due to Month Column -->
            <ng-container matColumnDef="month_filler">
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>
            <!-- To occupy empty space in footer due to Day Column -->
            <ng-container matColumnDef="day_filler">
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <mat-footer-row *matFooterRowDef="config.footerDisplayedColumns"></mat-footer-row>
        </div>

        <mat-header-row *matHeaderRowDef="config.displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: config.displayedColumns"></mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="20" showFirstLastButton></mat-paginator>
</div>
