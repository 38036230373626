import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { Colours } from '../../category/shared/colour-palette';
import { SurveyBarChartConfig } from './survey-bar-chart.model';
import { Subject, takeUntil } from 'rxjs';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-survey-bar-chart',
    template: `<div id="{{ id }}" class="survey-bar-chart"></div>`,
    styleUrls: ['./survey-bar-chart.component.scss'],
})
export class SurveyBarChartComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() id = 'survey-bar-chart';
    @Input() config: SurveyBarChartConfig;

    private chart: am4charts.XYChart;
    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        public store: Store<AppState>,
        private zone: NgZone
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }

    ngAfterViewInit() {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });

        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            const chart = am4core.create(this.id, am4charts.XYChart);

            // Data
            chart.data = this.config.data;

            // Hiding AmCharts logo
            chart.logo.height = -20000;

            // Create axes
            this.setCategoryAxis(chart);
            this.setValueAxis(chart);

            this.chart = chart;

            this.setColours();

            this.createSeries();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.chart.data = this.config.data;
            // Reset colours
            this.setColours();
            // Reset series
            this.chart.series.clear();
            this.createSeries();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    setValueAxis(chart: am4charts.XYChart) {
        let valueAxis: am4charts.ValueAxis<am4charts.AxisRenderer>;
        if (this.config.orientation === 'vertical') {
            valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        } else {
            // horizontal
            valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        }

        valueAxis.renderer.labels.template.adapter.add('text', (text, label) => {
            return label.dataItem['value'] + '%';
        });
        chart.events.on('ready', () => {
            valueAxis.min = valueAxis.minZoomed;

            valueAxis.max = Math.min(100, valueAxis.maxZoomed);
        });
    }

    setCategoryAxis(chart: am4charts.XYChart) {
        let categoryAxis: am4charts.CategoryAxis<am4charts.AxisRenderer>;
        if (this.config.orientation === 'vertical') {
            categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        } else {
            // horizontal
            categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.inversed = true;
        }
        categoryAxis.dataFields.category = this.config.category;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        const label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 180;
        // Auto-wrap tooltip
        categoryAxis.tooltip.label.maxWidth = 150;
        categoryAxis.tooltip.label.wrap = true;
    }

    setColours() {
        const chart = this.chart;
        const colourList = [];
        this.amColors.map((colour) => {
            colourList.push(am4core.color(colour));
        });
        chart.colors.list = colourList;
        this.chart = chart;
    }

    createSeries() {
        const chart = this.chart;

        // Create Series
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.name = this.config.title;
        series.strokeWidth = 0;

        const bullet = series.bullets.push(new am4charts.LabelBullet());
        bullet.interactionsEnabled = true;
        bullet.label.fill = am4core.color('#FFFFFF');

        if (this.config.orientation === 'vertical') {
            series.dataFields.valueY = this.config.value;
            series.dataFields.categoryX = this.config.category;
            series.columns.template.tooltipText = "[bold]{valueY.formatNumber('#.')}%";
        } else {
            series.dataFields.categoryY = this.config.category;
            series.dataFields.valueX = this.config.value;
            series.columns.template.tooltipText = " [bold]{valueX.formatNumber('#.')}%";

            // Center-align bullets
            bullet.locationX = 0.99;
        }

        series.columns.template.adapter.add('fill', (fill, target) => {
            return chart.colors.getIndex(target.dataItem.index);
        });

        series.columns.template.fillOpacity = 0.8;

        series.columns.template.showTooltipOn = 'always';

        series.tooltip.pointerOrientation = 'left';

        this.chart = chart;
    }
}
