import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberDate',
})
export class NumberDatePipe implements PipeTransform {
    year: string;
    quarter: string;
    month: string;
    rest: string; // not used... day or week to be implemented on use case
    transform(dateValue?: number, ...args: any[]): string {
        if (dateValue == null) {
            return '';
        }
        const stringDate = dateValue.toString();
        this.year = stringDate.slice(0, 4);
        this.quarter = stringDate[4];
        this.month = stringDate.slice(5, 7);
        if (this.month == '00') {
            return `${this.year} - Q${this.quarter}`;
        } else {
            return `${this.year} - ${this.convertMonthWord(this.month)}`;
        }
    }

    convertMonthWord(month: string) {
        switch (month) {
            case '01':
                return 'Jan';
            case '02':
                return 'Feb';
            case '03':
                return 'Mar';
            case '04':
                return 'Apr';
            case '05':
                return 'May';
            case '06':
                return 'Jun';
            case '07':
                return 'Jul';
            case '08':
                return 'Aug';
            case '09':
                return 'Sep';
            case '10':
                return 'Oct';
            case '11':
                return 'Nov';
            case '12':
                return 'Dec';
        }
        return '';
    }
}
