import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
// services
import { environment } from 'environments/environment';
import { LngLat, Map, MapLayerMouseEvent } from 'mapbox-gl';
import { BehaviorSubject, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';

import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { calculateColorBand } from 'app/core/utilities/colors';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { DigitalIntegrationService } from '../shared/digital-integration.service';
import { DigitalIntegrationFromWhere } from '../shared/from-where.model';

import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { checkDates } from 'app/core/utilities/permission';

import { selectDigitalIntegration } from 'app/core/session-info/store/selectors/session-info.selector';
import { dateToDateString, YEAR_MONTH_FMT } from 'app/core/utilities/date';
import { MapboxStyles } from 'app/core/whitelabel/shared/whitelabel.model';
import { selectWhitelabelState } from 'app/core/whitelabel/store/whitelabel.selector';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { Category } from '../../category/shared/category.model';
import { Measure } from '../../measures/shared/measure.model';

@Component({
    selector: 'hm-digitalintegration-fromwhere',
    templateUrl: './fromwhere.component.html',
    styleUrls: ['./fromwhere.component.scss'],
})
export class FromwhereComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() categories: Category[];
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];
    @Input() expanded: string;
    @Input() showTable: boolean = true;
    @Input() showGraph: boolean = true;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('exporter') exporter: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() reportDates: Date[];
    mpCursorStyle: string = '';

    format: string;

    tileserverUrl: string;
    mpLayerLabelID: string;
    map_report: Map;

    selectedLngLat: LngLat;
    cursorStyle: string;
    popupHTML: any;
    hoveredStateId: any = null;

    scale = [];
    startup = false;

    style: Observable<string>;
    mapSelectedColor;
    mapNoRightsColor;
    mapLineColor;
    colors: string[];

    listingCount: number;
    views: number;
    visits: number;
    leads: number;
    uniqueVisitsLocal: number;
    totalVisitsLocal: number;
    uniqueVisitsVisitor: number;
    totalVisitsVisitor: number;

    sourceLayer$ = new BehaviorSubject<string>('state-boundaries');

    listingCountPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    viewsPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    visitsPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    leadsPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    uniqueVisitsPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    timePerVisitPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    timeOnSitePaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    uniqueVisitsLocalPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    totalVisitsLocalPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    uniqueVisitsVisitorPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    totalVisitsVisitorPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    monthlyExpenditurePrevMonthPaint = { 'fill-color': [], 'fill-opacity': 0.75 };
    monthlyExpenditurePrev12MonthsPaint = { 'fill-color': [], 'fill-opacity': 0.75 };

    listingCountScale = [];
    viewsScale = [];
    visitsScale = [];
    leadsScale = [];
    uniqueVisitsScale = [];
    timePerVisitScale = [];
    timeOnSiteScale = [];
    uniqueVisitsLocalScale = [];
    totalVisitsLocalScale = [];
    uniqueVisitsVisitorScale = [];
    totalVisitsVisitorScale = [];
    monthlyExpenditurePrevMonthScale = [];
    monthlyExpenditurePrev12MonthsScale = [];

    dataPresent: boolean = false;

    selectedData: DigitalIntegrationFromWhere;
    aggregateData: DigitalIntegrationFromWhere[] = [];
    displayedColumns = [
        'boundary_name',
        'listing_count',
        'views',
        'visits',
        'leads',
        'unique_visits',
        'time_on_site',
        'time_per_visit',
        'unique_visits_local',
        'total_visits_local',
        'unique_visits_visitor',
        'total_visits_visitor',
        'monthly_expenditure_prev_month',
        'monthly_expenditure_prev_12months',
    ];
    dataSource: MatTableDataSource<DigitalIntegrationFromWhere> = new MatTableDataSource();
    // Value displayed on unique_count is dependant on this
    multipleBoundaries = false;
    dataAvailable: boolean = true;
    permissionsAvailable = false;
    private subscriptions: Subscription[] = [];

    /** GRAPH METRIC SELECTOR DROPDOWN MENU */
    // There is a serialization problem between JSON objects and Typescript model due to camelCase and snakeCase discrepancies. TBF
    reportMeasures: Measure[] = [
        {
            uniqueName: 'listing_count',
            title: 'Listings Count',
        },
        {
            uniqueName: 'views',
            title: 'Total Views',
        },
        {
            uniqueName: 'visits',
            title: 'Visits',
        },
        {
            uniqueName: 'leads',
            title: 'Leads',
        },
        {
            uniqueName: 'unique_visits',
            title: 'Sum of Unique Visits',
        },
        {
            uniqueName: 'time_on_site',
            title: 'Average Time on Site',
        },
        {
            uniqueName: 'time_per_visit',
            title: 'Average Time per Visit',
        },
        {
            uniqueName: 'unique_visits_local',
            title: 'Sum of Unique Visits Local',
        },
        {
            uniqueName: 'unique_visits_visitor',
            title: 'Sum of Total Visits Visitor',
        },
        {
            uniqueName: 'total_visits_local',
            title: 'Sum of Total Visits Local',
        },
        {
            uniqueName: 'total_visits_visitor',
            title: 'Sum of Total Visits Visitor',
        },
        {
            uniqueName: 'monthly_expenditure_prev_month',
            title: 'Average Monthly Expenditure Prev Month',
        },
        {
            uniqueName: 'monthly_expenditure_prev_12months',
            title: 'Average Monthly Expenditure 12 Months',
        },
    ];

    private componentDestroyed = new Subject<void>();
    /** LISTINGS GRAPH */
    selectedReportMeasure = this.reportMeasures[0];

    constructor(
        private digitalIntegrationService: DigitalIntegrationService,
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef
    ) {}
    ngOnInit() {
        this.store
            .pipe(select(selectWhitelabelState))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((wl) => {
                this.mapSelectedColor = wl.whitelabel.mapboxSelectedColor;
                this.mapNoRightsColor = wl.whitelabel.mapboxNoRightsColor;
                this.mapLineColor = wl.whitelabel.mapLineColor;
            });

        this.style = of(MapboxStyles.DNSW);

        this.sourceLayer$.next(`au-${this.boundaryType.toLowerCase()}`);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
            this.refreshData();
        } else if (changes.reportDates) {
            this.checkPermissions();
            if (this.permissionsAvailable == true) {
                this.dataAvailable = true;
                this.refreshData();
            }
        } else if (changes.expanded) {
            window.setTimeout(() => this.map_report.resize(), 20);
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'digital-integration' }); // File name: name of tab + timestamp
    }

    onSelectReportMeasure() {}

    selectedMeasure(selectedMeasure: Measure) {
        this.selectedReportMeasure = selectedMeasure;
        this.ref.detectChanges();
        this.dataSource.sort = this.sort;
        switch (selectedMeasure.uniqueName) {
            case 'listing_count':
                this.scale = this.listingCountScale;
                this.format = 'n';
                break;
            case 'views':
                this.scale = this.viewsScale;
                this.format = 'n';
                break;
            case 'visits':
                this.scale = this.visitsScale;
                this.format = 'n';
                break;
            case 'leads':
                this.scale = this.leadsScale;
                this.format = 'n';
                break;
            case 'unique_visits':
                this.scale = this.uniqueVisitsScale;
                this.format = 'n';
                break;
            case 'time_per_visit':
                this.scale = this.timePerVisitScale;
                this.format = 'n';
                break;
            case 'time_on_site':
                this.scale = this.timeOnSiteScale;
                this.format = 'n';
                break;
            case 'unique_visits_local':
                this.scale = this.uniqueVisitsLocalScale;
                this.format = 'n';
                break;
            case 'total_visits_local':
                this.scale = this.totalVisitsLocalScale;
                this.format = 'n';
                break;

            case 'unique_visits_visitor':
                this.scale = this.uniqueVisitsVisitorScale;
                this.format = 'n';
                break;
            case 'total_visits_visitor':
                this.scale = this.totalVisitsVisitorScale;
                this.format = 'n';
                break;
            case 'monthly_expenditure_prev_month':
                this.scale = this.monthlyExpenditurePrevMonthScale;
                this.format = 'n';
                break;
            case 'monthly_expenditure_prev_12months':
                this.scale = this.monthlyExpenditurePrev12MonthsScale;
                this.format = 'n';
                break;
            default:
                this.scale = this.listingCountScale;
                this.format = 'n';
                break;
        }
    }

    ngAfterViewInit() {
        this.tileserverUrl = `${
            environment.tileserver.baseUrl
        }/capabilities/au-${this.boundaryType.toLowerCase()}.json`;
        this.dataSource.paginator = this.paginator;
        this.checkPermissions();
        this.refreshData();
    }
    checkPermissions() {
        const rights = this.store.pipe(select(selectDigitalIntegration));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = true;
                this.permissionsAvailable = checkDates(
                    r[0],
                    this.reportDates[0],
                    this.reportDates[1]
                );
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    onLoad(map: Map) {
        this.map_report = map;
    }
    activateHoverOn(evt: any) {
        this.dataPresent = false;
        this.mpCursorStyle = 'pointer';
        this.selectedData = this.aggregateData.filter(
            (row) => row.boundary_code == evt.features[0].properties.code
        )[0];

        if (typeof this.selectedData !== 'undefined') {
            this.dataPresent = true;
            this.popupHTML = evt.features[0].properties;
            this.selectedLngLat = evt.lngLat;
            if (evt.features.length > 0) {
                if (this.hoveredStateId) {
                    this.map_report.setFeatureState(
                        {
                            source: 'tileserver-source',
                            sourceLayer: this.sourceLayer$.getValue(),
                            id: this.hoveredStateId,
                        },
                        { hover: false }
                    );
                }

                this.hoveredStateId = evt.features[0].id;
                this.map_report.setFeatureState(
                    {
                        source: 'tileserver-source',
                        sourceLayer: this.sourceLayer$.getValue(),
                        id: this.hoveredStateId,
                    },
                    { hover: true }
                );
            }
        }
    }

    disableHover() {
        this.mpCursorStyle = '';
        if (this.hoveredStateId) {
            this.map_report.setFeatureState(
                {
                    source: 'tileserver-source',
                    sourceLayer: this.sourceLayer$.getValue(),
                    id: this.hoveredStateId,
                },
                { hover: false }
            );
        }
        this.hoveredStateId = null;
        this.mpCursorStyle = '';
        this.selectedLngLat = null;
    }

    onClick(evt: MapLayerMouseEvent) {}

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            const month = dateToDateString(this.reportDates[1], YEAR_MONTH_FMT);
            this.subscriptions.push(
                this.digitalIntegrationService
                    .getFromWhere(
                        this.boundaryType as Level,
                        this.boundaries.map((boundary) => boundary.code),
                        month
                    )
                    .subscribe((res: DigitalIntegrationFromWhere[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.aggregateData = res;
                            this.dataSource.data = res;
                            this.dataSource.sort = this.sort;
                            this.calculatePaint();
                            this.selectedMeasure(this.selectedReportMeasure);
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    calculatePaint() {
        let currentScale;
        const recordWithData = [];
        let fillcolor = [];

        let themax;
        this.reportMeasures.map((measure) => {
            fillcolor = [];
            fillcolor.push('match');
            const expression = ['get', 'code'];
            fillcolor.push(expression);
            currentScale = this.aggregateData.map((column) => column[measure.uniqueName]);
            themax = currentScale.reduce((a, b) => {
                return Math.max(a, b);
            });
            const newScale = [
                0,
                themax * 0.166,
                themax * 0.333,
                themax * 0.5,
                themax * 0.666,
                themax * 0.831,
                themax,
            ];

            this.aggregateData.map((record, index) => {
                if (this.boundaryType.startsWith('sa')) {
                    fillcolor.push(Number(record.boundary_code));
                } else {
                    fillcolor.push(record.boundary_code);
                }
                fillcolor.push(
                    calculateColorBand(record[measure.uniqueName], newScale, this.colors)
                );
            });
            fillcolor.push('rgba(0, 0, 0, 0.01)');
            // refactor to be based on measures and to avoid repetition
            if (measure.uniqueName === 'listing_count') {
                this.listingCountPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.listingCountScale = newScale;
            } else if (measure.uniqueName === 'views') {
                this.viewsPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.viewsScale = newScale;
            } else if (measure.uniqueName == 'visits') {
                this.visitsPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.visitsScale = newScale;
                this.startup = true;
            } else if (measure.uniqueName === 'leads') {
                this.leadsPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.leadsScale = newScale;
            } else if (measure.uniqueName === 'unique_visits') {
                this.uniqueVisitsPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.uniqueVisitsScale = newScale;
            } else if (measure.uniqueName === 'time_per_visit') {
                this.timePerVisitPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.timePerVisitScale = newScale;
            } else if (measure.uniqueName === 'time_on_site') {
                this.timeOnSitePaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.timeOnSiteScale = newScale;
            } else if (measure.uniqueName === 'unique_visits_local') {
                this.uniqueVisitsLocalPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.uniqueVisitsLocalScale = newScale;
            } else if (measure.uniqueName === 'total_visits_local') {
                this.totalVisitsLocalPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.totalVisitsLocalScale = newScale;
            } else if (measure.uniqueName === 'unique_visits_visitor') {
                this.uniqueVisitsVisitorPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.uniqueVisitsVisitorScale = newScale;
            } else if (measure.uniqueName === 'total_visits_visitor') {
                this.totalVisitsVisitorPaint = { 'fill-color': fillcolor, 'fill-opacity': 0.75 };
                this.totalVisitsVisitorScale = newScale;
            } else if (measure.uniqueName === 'monthly_expenditure_prev_month') {
                this.monthlyExpenditurePrevMonthPaint = {
                    'fill-color': fillcolor,
                    'fill-opacity': 0.75,
                };
                this.monthlyExpenditurePrevMonthScale = newScale;
            } else if (measure.uniqueName === 'monthly_expenditure_prev_12months') {
                this.monthlyExpenditurePrev12MonthsPaint = {
                    'fill-color': fillcolor,
                    'fill-opacity': 0.75,
                };
                this.monthlyExpenditurePrev12MonthsScale = newScale;
            }
        });
    }
}
