import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { Measure } from 'app/views/partials/content/measures/shared/measure.model';

import { GraphModel } from '../../shared/graph.model';
import { SentimentDG } from '../shared/sentiment-demographics.model';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { Subject, takeUntil } from 'rxjs';
import { selectTop3Colors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-sentiment-demographics-graph',
    template: `<div id="sentiment-demographics-chart"></div>`,
    styleUrls: ['./sentiment-demographics-graph.component.scss'],
})
export class SentimentDemographicsGraphComponent
    implements OnChanges, AfterViewInit, OnDestroy, OnInit
{
    @Input() data: SentimentDG[];
    @Input() selectedMeasure: Measure;

    private mChart: am4charts.XYChart;
    private fChart: am4charts.XYChart;

    emptyState = false;
    top3Colors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    ngOnInit() {
        this.store
            .pipe(select(selectTop3Colors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.top3Colors = colors;
            });
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            am4core.useTheme(am4themes_animated);
            am4core.options.autoDispose = true;
            const mainContainer = am4core.create('sentiment-demographics-chart', am4core.Container);
            mainContainer.width = am4core.percent(100);
            mainContainer.height = am4core.percent(100);
            mainContainer.layout = 'horizontal';

            // Hiding AmCharts logo
            mainContainer.logo.height = -20000;

            // Watermarks
            let watermark = new am4core.Label();
            watermark.text = 'Partial Dataset';

            let pattern = new am4core.Pattern();
            pattern.addElement(watermark.group);
            pattern.width = 200;
            pattern.height = 100;
            pattern.x = 20;

            // Male chart
            const mChart = mainContainer.createChild(am4charts.XYChart);
            mChart.paddingRight = 0;
            mChart.paddingLeft = 0;
            mChart.colors.list = [am4core.color(this.top3Colors[0])];

            mChart.plotContainer.background.fill = pattern;
            mChart.plotContainer.background.fillOpacity = 0.1;

            mChart.data = [];

            const maleCategoryAxis = mChart.yAxes.push(new am4charts.CategoryAxis());
            maleCategoryAxis.dataFields.category = 'category';
            maleCategoryAxis.renderer.grid.template.location = 0;
            maleCategoryAxis.renderer.minGridDistance = 15;

            const maleValueAxis = mChart.xAxes.push(new am4charts.ValueAxis());
            maleValueAxis.renderer.inversed = true;
            maleValueAxis.min = 0;
            maleValueAxis.max = 5;
            maleValueAxis.strictMinMax = true;
            maleValueAxis.numberFormatter = new am4core.NumberFormatter();
            maleValueAxis.numberFormatter.numberFormat = this.selectedMeasure.format;

            // Female Chart
            const fChart = mainContainer.createChild(am4charts.XYChart);
            fChart.paddingRight = 0;
            fChart.paddingLeft = 0;
            fChart.colors.list = [am4core.color(this.top3Colors[1])];
            fChart.data = [];

            fChart.plotContainer.background.fill = pattern;
            fChart.plotContainer.background.fillOpacity = 0.1;

            const femaleCategoryAxis = fChart.yAxes.push(new am4charts.CategoryAxis());
            femaleCategoryAxis.renderer.opposite = true;
            femaleCategoryAxis.dataFields.category = 'category';
            femaleCategoryAxis.renderer.grid.template.location = 0;
            femaleCategoryAxis.renderer.minGridDistance = 15;

            const femaleValueAxis = fChart.xAxes.push(new am4charts.ValueAxis());
            femaleValueAxis.min = 0;
            femaleValueAxis.max = 5;
            femaleValueAxis.strictMinMax = true;
            femaleValueAxis.numberFormatter = new am4core.NumberFormatter();
            femaleValueAxis.numberFormatter.numberFormat = this.selectedMeasure.format;
            femaleValueAxis.renderer.minLabelPosition = 0.01;

            this.mChart = mChart;
            this.fChart = fChart;
        });
    }
    ngOnDestroy() {
        this.browserOnly(() => {
            this.mChart.dispose();
            this.fChart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.data && !changes.data.isFirstChange()) ||
            (changes.selectedMeasure && !changes.selectedMeasure.isFirstChange())
        ) {
            this.mChart.series.clear();
            this.fChart.series.clear();
            this.setData();
            this.createSeries();
        }
    }

    setData() {
        this.removeData();
        const mChart = this.mChart;
        const fChart = this.fChart;

        const mData = [];
        const fData = [];

        const newSelectedMeasure = this.selectedMeasure;
        const newData = this.data;
        const valueName = newSelectedMeasure.uniqueName;

        newData.forEach((element) => {
            const newItem: GraphModel = new GraphModel();
            if (element[valueName] !== -1) {
                let reviewer_gender = element['reviewer_gender'];
                this.emptyState = false;
                if (reviewer_gender === 'M') {
                    newItem['category'] = element['category'];
                    newItem['M'] = element[valueName];
                    newItem['F'] = 0;
                    mData.push(newItem);
                } else {
                    newItem['category'] = element['category'];
                    newItem['F'] = element[valueName];
                    newItem['M'] = 0;
                    fData.push(newItem);
                }
            } else {
                this.emptyState = true;
            }
        });
        mChart.invalidateRawData();
        fChart.invalidateRawData();

        mChart.numberFormatter.numberFormat = this.selectedMeasure.format;
        fChart.numberFormatter.numberFormat = this.selectedMeasure.format;

        mChart.data = mData;
        fChart.data = fData;

        this.mChart = mChart;
        this.fChart = fChart;
    }

    removeData() {
        this.mChart.data = [];
        this.fChart.data = [];
        this.mChart.validateData();
        this.fChart.validateData();
    }

    createSeries() {
        const mChart = this.mChart;
        const fChart = this.fChart;

        const maleSeries = mChart.series.push(new am4charts.ColumnSeries());
        maleSeries.dataFields.valueX = 'M';
        maleSeries.dataFields.valueXShow = 'value';
        maleSeries.calculatePercent = false;
        maleSeries.fill = mChart.colors.getIndex(0);
        maleSeries.stroke = maleSeries.fill;
        maleSeries.dataFields.categoryY = 'category';
        maleSeries.interpolationDuration = 1000;
        maleSeries.columns.template.tooltipText = 'Males, age{categoryY}: {valueX} ';

        const femaleSeries = this.fChart.series.push(new am4charts.ColumnSeries());
        femaleSeries.dataFields.valueX = 'F';
        femaleSeries.dataFields.valueXShow = 'value';
        femaleSeries.calculatePercent = false;
        femaleSeries.fill = this.fChart.colors.getIndex(0);
        femaleSeries.stroke = femaleSeries.fill;
        femaleSeries.columns.template.tooltipText = 'Females, age{categoryY}: {valueX} ';
        femaleSeries.dataFields.categoryY = 'category';
        femaleSeries.interpolationDuration = 1000;

        this.mChart = mChart;
        this.fChart = fChart;
    }
}
