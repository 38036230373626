import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const format = args[0];

        // Determines how many DP to show. 1 is none, 10 is one and so on
        let minFraction = 10;
        if (args.length > 1 && args[1] !== undefined) {
            minFraction = args[1];
        }

        // Check value validity
        if (value === null || value == undefined || value === -1 || Number.isNaN(value)) {
            return '–';
        }
        if (value === ' ') {
            return null;
        }
        if (value < 10 && value > 0 && format !== 'p') {
            if (format === 'd') {
                return '<$10';
            } else if (format === 'n') {
                return Math.round((value + Number.EPSILON) * 100) / 100;
            } else if (format === 'n-1') {
                return Math.round((value + Number.EPSILON) * 10) / 10;
            } else {
                return '<10';
            }
        }

        // Shortening number and appending suffix
        const fractionSize = 1;
        let abs = Math.abs(value);

        const rounder = Math.pow(minFraction, fractionSize);
        const isNegative = value < 0;
        let key = '';
        const powers = [
            { key: 'Q', value: Math.pow(10, 15) },
            { key: 'T', value: Math.pow(10, 12) },
            { key: 'B', value: Math.pow(10, 9) },
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'k', value: 1000 },
        ];
        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            } else {
                if (format === 'n-1') {
                    abs = Math.round((abs + Number.EPSILON) * 10) / 10;
                } else {
                    abs = Math.round((abs + Number.EPSILON) * 100) / 100;
                }
            }
        }

        // Appending unit if any
        if (format === 'p') {
            return (isNegative ? '-' : '') + abs + key + '%';
        }
        if (format === 'd') {
            return (isNegative ? '-' : '') + '$' + abs + key;
        } else {
            return (isNegative ? '-' : '') + abs + key;
        }
    }
}
