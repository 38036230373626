import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { selectSentiment } from 'app/core/session-info/store/selectors/session-info.selector';
import { isDateValidForDataset } from 'app/core/utilities/permission';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { LoadingService } from 'app/views/partials/layout/loading/loading.service';
import { Subscription } from 'rxjs';
import { Measure } from '../../../measures/shared/measure.model';
import { ProfilerService } from '../profiler.service';
import { Sentiment } from '../sentiment/sentiment.model';

@Component({
    selector: 'hm-sentimentreport',
    templateUrl: './sentiment.component.html',
    styleUrls: ['./sentiment.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SentimentComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() boundaryType: string;
    @Input() boundaries: Boundary[];
    @Input() showTable: boolean = true;
    @Input() showGraph: boolean = true;
    @Input() reportDate: string;

    dataAvailable: boolean = true;
    permissionsAvailable = false;

    multipleBoundaries = false;

    private subscriptions: Subscription[] = [];

    /** MEASURES CONFIGS */
    reportMeasures: Measure[] = [
        { uniqueName: 'avg_sentiment', title: 'Average Sentiment', format: '#,###.##' },
        { uniqueName: 'median_sentiment', title: 'Median Sentiment', format: '#,###.##' },
        { uniqueName: 'sentiment_by_topic_pct', title: 'Sentiment by Topic (%)', format: '#,###' },
        {
            uniqueName: 'sentiment_by_topic_count',
            title: 'Sentiment By Topic (Count)',
            format: '#,###',
        },
    ];
    selectedReportMeasure = this.reportMeasures[0];

    /** CHART CONFIGS */
    chartName = 'profiler-sentiment-chart' + Math.random().toString();
    chartData: Sentiment[] = [];

    /** TABLE CONFIGS */
    tableData: MatTableDataSource<Sentiment> = new MatTableDataSource();

    constructor(
        private profilerService: ProfilerService,
        private loadingService: LoadingService,
        private store: Store<AppState>,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.checkPermissions();
        this.refreshData();
    }

    checkPermissions() {
        const rights = this.store.pipe(select(selectSentiment));
        this.subscriptions.push(
            rights.subscribe((r) => {
                this.permissionsAvailable = isDateValidForDataset(r[0], this.reportDate);
                this.dataAvailable = this.permissionsAvailable ? true : false;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.boundaries) {
            if (this.boundaries.length === 1) {
                this.multipleBoundaries = false;
            } else {
                this.multipleBoundaries = true;
            }
            this.boundaries = changes.boundaries.currentValue;
            this.refreshData();
        } else if (changes.reportDate) {
            this.checkPermissions();
            if (this.permissionsAvailable === true) {
                this.dataAvailable = true;
                this.refreshData();
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    }

    selectedMeasure(selectedMeasure: Measure) {
        this.selectedReportMeasure = selectedMeasure;
        this.ref.detectChanges();
    }

    refreshData() {
        if (this.permissionsAvailable) {
            this.loadingService.loadingOn();
            this.subscriptions.push(
                this.profilerService
                    .getSentiment(
                        this.boundaryType as Level,
                        this.reportDate,
                        this.boundaries.map((boundary) => boundary.code)
                    )
                    .subscribe((res: Sentiment[]) => {
                        if (res.length > 0) {
                            this.dataAvailable = true;
                            this.tableData.data = res;
                            this.chartData = res;
                        } else {
                            this.dataAvailable = false;
                        }
                        this.loadingService.loadingOff();
                    })
            );
        }
    }
}
