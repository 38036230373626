<div class="sizer-wrap">
    <div class="strip"></div>

    <div class="sizer" (click)="chevronLine()">
        <div class="line"></div>
    </div>

    <div class="slider">
        <div class="middle" *ngIf="expandedState === '010'">
            <button class="btn btn_half_left" (click)="chevronLeft()">
                <span class="chevron_middle">
                    <svg width="24" height="24">
                        <path
                            d="M13.706 9.698a.988.988 0 000-1.407 1.01 1.01 0 00-1.419 0l-2.965 2.94a1.09 1.09 0 000 1.548l2.955 2.93a1.01 1.01 0 001.42 0 .988.988 0 000-1.407l-2.318-2.297 2.327-2.307z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        />
                    </svg>
                </span>
            </button>
            <button class="btn btn_half_right both" (click)="chevronRight()">
                <span class="chevron_right">
                    <svg width="24" height="24">
                        <path
                            d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        />
                    </svg>
                </span>
            </button>
        </div>

        <div class="left" *ngIf="expandedState === '001'">
            <button class="btn" (click)="chevron()">
                <span class="chevron_left">
                    <svg width="24" height="24">
                        <path
                            d="M13.706 9.698a.988.988 0 000-1.407 1.01 1.01 0 00-1.419 0l-2.965 2.94a1.09 1.09 0 000 1.548l2.955 2.93a1.01 1.01 0 001.42 0 .988.988 0 000-1.407l-2.318-2.297 2.327-2.307z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        />
                    </svg>
                </span>
            </button>
        </div>
        <div *ngIf="expandedState === '100'">
            <button class="btn" (click)="chevron()">
                <span class="chevron">
                    <svg width="24" height="24">
                        <path
                            d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z"
                            fill="currentColor"
                            fill-rule="evenodd"
                        />
                    </svg>
                </span>
            </button>
        </div>
    </div>
</div>
