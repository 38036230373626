import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Category } from '../../shared/category.model';

export interface CategoryState extends EntityState<Category> {
    error: boolean;
    loading: boolean;
}

export const categoryAdapter: EntityAdapter<Category> = createEntityAdapter<Category>({
    selectId: (category: Category) => category.id,
});

export const initialCategoryState: CategoryState = categoryAdapter.getInitialState({
    error: false,
    loading: true,
});
