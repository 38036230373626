<h2 mat-dialog-title>Enquiry Form</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput placeholder="Name" formControlName="name" type="name" />
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Preferred Email" formControlName="contactEmail" type="email" />
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Preferred Phone" formControlName="contactPhone" type="tel" />
    </mat-form-field>

    <mat-form-field>
        <textarea matInput placeholder="Description" formControlName="description"> </textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button class="mat-raised-button" (click)="close()">Close</button>
    &nbsp; &nbsp;

    <button class="mat-raised-button mat-primary" (click)="save()">Submit</button>
</mat-dialog-actions>
