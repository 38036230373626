import { Dictionary } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { BookmarkedReport, BookmarkType } from '../shared/bookmarked-report.model';
import { bookmarkAdapter, BookmarkState } from './bookmark.state';

const { selectAll, selectEntities } = bookmarkAdapter.getSelectors();

export const selectBookmarkState = createFeatureSelector<BookmarkState>('bookmarks');
export const selectAllBookmarks = createSelector(selectBookmarkState, selectAll);
const selectBookmarkEntities = createSelector(selectBookmarkState, selectEntities);

export const selectBookmarksByType = () =>
    createSelector(selectAllBookmarks, (list: BookmarkedReport[], props: { type: BookmarkType }) =>
        list.filter((b) => b.type == props.type)
    );

export const selectBookmarksBySubtype = () =>
    createSelector(
        selectAllBookmarks,
        (list: BookmarkedReport[], props: { type: BookmarkType; subtype: string }) =>
            list.filter((b) => b.type == props.type && b.subtype == props.subtype)
    );

export const selectBookmark = () =>
    createSelector(
        selectBookmarkEntities,
        (entities: Dictionary<BookmarkedReport>, props: { id: string }) => entities[props.id]
    );

export const selectBookmarkLoading = createSelector(
    selectBookmarkState,
    (state: BookmarkState): boolean => state.loading
);

export const selectBookmarkError = createSelector(
    selectBookmarkState,
    (state: BookmarkState): Object => state.error
);
