export class PageConfig {
    public defaults: any = {
        dashboard: {
            page: {
                title: 'Dashboard',
                desc: 'Chart View',
                subheaderDisplay: false,
            },
        },
        organisation: {
            page: { title: 'Organisation', desc: 'Manage your organisation' },
        },

        error: {
            404: {
                page: { title: '404 Not Found', desc: '', subheader: false },
            },
            403: {
                page: { title: '403 Access Forbidden', desc: '', subheader: false },
            },
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
