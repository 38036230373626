const defaultNotice = `Commercial in Confidence. Not for distribution. Hemisphere Digital™ ©${new Date().getFullYear()} Hemisphere Digital Pty. Ltd. - All rights Reserved. `;
const noticeExtras = {
    IFI_DOMESTIC:
        'Enabled by Visa. Anonymised data provided by Visa. Data weighted to the Australian population. Values are rounded for reporting purposes',
};

/**
 * Return the notice that accompanies any data exports for data by default as well
 * as data that is sourced from the given datasets.
 * @param datasets array of dataset keys
 * @returns the notice that should display at the footer of the data export.
 */
export function dataNotice(datasets: string[] = []): string {
    let notice = defaultNotice;
    for (let dataset of datasets || []) {
        notice += noticeExtras[dataset] ? `\n${noticeExtras[dataset]}` : '';
    }
    return notice;
}
