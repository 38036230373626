import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DataPermissionType } from '../../shared/data-permission.model';
import { dataPermissionAdapter, DataPermissionState } from '../states/data-permissions.state';

export const {
    selectIds: _selectDataPermissionDataIds,
    selectEntities: _selectDataPermissionEntities,
    selectAll: _selectAllDataPermissions,
} = dataPermissionAdapter.getSelectors();

export const selectDataPermissionState =
    createFeatureSelector<DataPermissionState>('data-permissions');
export const selectDataPermissionIds = createSelector(
    selectDataPermissionState,
    _selectDataPermissionDataIds
);
export const selectDataPermissionEntities = createSelector(
    selectDataPermissionState,
    _selectDataPermissionEntities
);
export const selectAllDataPermission = createSelector(
    selectDataPermissionState,
    _selectAllDataPermissions
);
export const selectDataPermissionError = createSelector(
    selectDataPermissionState,
    (state: DataPermissionState): boolean => state.error
);
export const selectDataPermissionLoading = createSelector(
    selectDataPermissionState,
    (state: DataPermissionState): boolean => state.loading
);

export const areDataPermissionsLoaded = createSelector(
    selectDataPermissionState,
    (state) => state.entities
);

export const dataPermissionsSettings = createSelector(selectAllDataPermission, (state) =>
    state.filter((x) => x.type == DataPermissionType.SETTING)
);

export const dataPermissionsBoundaries = createSelector(selectAllDataPermission, (state) =>
    state.filter((x) => x.type != DataPermissionType.SETTING)
);

export const selectAllPermittedBoundaryTypes = createSelector(selectDataPermissionState, (state) =>
    Array.from(
        new Set(
            _selectAllDataPermissions(state)
                .map((perm) => perm.type)
                .filter((t) => t != DataPermissionType.SETTING)
        )
    )
);
