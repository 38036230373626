<hm-bookmarks [type]="'simple'" [subtype]="bookmarkSubtype()" [content]="bookmarkContent()"></hm-bookmarks>

<div class="dashboard-container__wrapper" id="dashboard">
    <div class="dashboard-header">
        <div class="dashboard-subheader">
            <div class="row title-up">
                <div class="col">
                    <h3 class="kt-subheader__title ng-star-inserted title">{{ title }}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col select-box">
            <mat-card>
                <mat-card-content>
                    <div class="selectors">
                        <hm-boundary-type-select
                            #typeSelector
                            [(boundaryType)]="boundaryType"
                            [boundaryTypes]="boundaryTypes$ | async"
                            (boundaryTypeChange)="boundaryTypeChange()"
                        ></hm-boundary-type-select>
                        <div class="type-ahead-box">
                            <div class="hero-selectors">
                                <div class="regions">
                                    <hm-boundary-autocomplete-selector
                                        [typeSelector]="typeSelector"
                                        [(boundaries)]="boundaries"
                                        (boundariesChange)="boundariesChange()"
                                    ></hm-boundary-autocomplete-selector>
                                </div>
                                <div class="filters">
                                    <div class="spacer">
                                        <span (click)="onMapClick()" class="filter-button"
                                            ><svg class="domain-icon map-button-icon" viewBox="0 0 24 24" aria-hidden="true">
                                                <path
                                                    d="M9 20.9l6.5-2.9 3.9 2.8A1 1 0 0 0 21 20V6l-5.1-2.9a1 1 0 0 0-.9 0L8.5 6 4.6 3.2A1 1 0 0 0 3 4v13.4a1 1 0 0 0 .5.9l4.6 2.6a1 1 0 0 0 .9 0z"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                ></path>
                                                <path fill="none" stroke="currentColor" d="M15.5 3.5v15m-7-12v14"></path>
                                            </svg>
                                            Map</span
                                        >
                                    </div>
                                    <div class="spacer">
                                        <div class="datepicker-button">
                                            <hm-datepicker
                                                [enableQuarters]="false"
                                                [minDate]="minDate"
                                                [maxDate]="maxDate"
                                                [(selectedDate)]="endDate"
                                                (selectedDateChange)="onDateChange($event)"
                                            ></hm-datepicker>
                                        </div>
                                    </div>
                                    <div class="spacer" *ngIf="showFilterButton">
                                        <span class="filter-button" (click)="onFiltersClick()"
                                            ><svg viewBox="0 0 24 24" aria-hidden="true" class="filter-button-icon">
                                                <path fill="none" stroke="currentColor" stroke-width="2" d="M3 19h2m6 0h10"></path>
                                                <circle cx="8" cy="19" r="2.5" fill="none" stroke="currentColor"></circle>
                                                <path fill="none" stroke="currentColor" stroke-width="2" d="M3 5h2m6 0h10"></path>
                                                <circle cx="8" cy="5" r="2.5" fill="none" stroke="currentColor"></circle>
                                                <path fill="none" stroke="currentColor" stroke-width="2" d="M21 12h-2m-6 0H3"></path>
                                                <circle cx="16" cy="12" r="2.5" fill="none" stroke="currentColor"></circle>
                                            </svg>
                                            Filters</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="search">
                                <button (click)="runReport()" mat-raised-button color="primary" class="search-button">Run</button>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="h4" *ngIf="toggleFilters">
                            <hm-categories [showCategories]="true" [categories]="categories" (toggleCategory)="toggleCategory()">
                            </hm-categories>
                        </div>

                        <div class="map-filter" *ngIf="toggleMap">
                            <hm-boundary-selection-map
                                #map
                                [showListings]="false"
                                [categories]="categories"
                                [boundaryType]="boundaryType"
                                [(selectedBoundaries)]="boundaries"
                                (selectedBoundariesChange)="boundariesChange()"
                                [mapZoom]="zoom$ | async"
                                [mapCenter]="lnglat$ | async"
                                [singleSelection]="true"
                            ></hm-boundary-selection-map>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="results" *ngIf="reportReady">
        <router-outlet></router-outlet>
    </div>
</div>
<hm-data-notice [datasets]="['DEVICE_GPS', 'VISITATION_CELLULAR', 'IFI_DOMESTIC', 'IFI_INTERNATIONAL']"></hm-data-notice>
