<mat-list class="hem-boundary-controls mt-0">
    <div mat-subheader>Boundary type</div>
    <mat-list-item>
        <mat-icon mat-list-icon><i class="flaticon-placeholder-3"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <!-- <mat-label>Boundary type</mat-label> -->
                    <select matNativeControl [ngModel]="boundaryType" (ngModelChange)="onSelectBoundaryType($event)">
                        <option *ngFor="let title of availableBoundaryTypes" [ngValue]="title">
                            {{ title.toLocaleUpperCase() }}
                        </option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <div mat-subheader>Map areas selected</div>
    <mat-list-item>
        <mat-icon mat-list-icon><i class="flaticon-map-location"></i> </mat-icon>
        <div class="row">
            <div class="col-sm-12 mt-3 mb-3" *ngIf="areas.length > 0">
                <div *ngFor="let area of areas; let i = index">
                    <mat-checkbox id="area.id" (click)="onAreaChange(area)" checked="true" class="catchments">{{ area.name }}</mat-checkbox
                    >&nbsp;
                    <span class="hem-btn-zoom" (click)="onZoomToPoint(area)">
                        &nbsp; <mat-icon class="zoomIcon">zoom_in</mat-icon>Zoom&nbsp;&nbsp;
                    </span>
                </div>
            </div>
            <div class="col-sm-12 mt-3 mb-3" *ngIf="areas.length == 0">
                - No map area selected -
            </div>
        </div>
    </mat-list-item>
    <div *ngIf="showCategories">
        <mat-divider></mat-divider>

        <div mat-subheader>Categories</div>
        <mat-list-item *ngIf="categories as cat">
            <mat-icon mat-list-icon><i class="flaticon2-architecture-and-city"></i> </mat-icon>
            <div class="row">
                <div class="col-sm-12">
                    <div class="hem-category">
                        <div *ngFor="let category of cat; let i = index">
                            <mat-checkbox
                                [checked]="category.selected"
                                (change)="category.selected = !category.selected; onCategoryChange(category)"
                            >
                                <mat-icon [ngStyle]="{ color: category.color }">brightness_1</mat-icon>
                                {{ category.name }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </mat-list-item>
    </div>
</mat-list>
