import { SessionInfoActions, SessionInfoActionType } from '../actions/session-info.actions';
import { initialSessionInfoState, SessionInfoState } from '../states/sessionInfo.state';

export function sessionInfoReducer(
    state = initialSessionInfoState,
    action: SessionInfoActions
): SessionInfoState {
    switch (action.type) {
        case SessionInfoActionType.SessionInfoGet:
            return { ...state };

        case SessionInfoActionType.SessionInfoLoaded: {
            return {
                organisation: action.payload.organisation,
                user: action.payload.user,
                setting: action.payload.setting,
                dataset: action.payload.dataset,
                isLoaded: true,
            };
        }

        default:
            return state;
    }
}
