import { Organisation } from 'app/views/pages/organisations/shared/organisation.model';
import { User } from 'app/views/pages/users/shared/user.model';
import { Setting } from '../../shared/setting.model';
import { OrganisationDataset } from '../../shared/organisation-dataset.model';

export interface SessionInfoState {
    organisation: Organisation;
    user: User;
    setting: Setting[];
    dataset: OrganisationDataset[];
    isLoaded: boolean;
}

export const initialSessionInfoState: SessionInfoState = {
    organisation: undefined,
    user: undefined,
    setting: [],
    dataset: [],
    isLoaded: false,
};
