import { DatasetActions, DatasetActionType } from './dataset.actions';
import { initialDatasetState, DatasetState, datasetAdapter } from './dataset.state';

export function datasetReducer(state = initialDatasetState, action: DatasetActions): DatasetState {
    switch (action.type) {
        case DatasetActionType.DatasetLoadingAll: {
            return { ...state, loading: true };
        }
        case DatasetActionType.DatasetLoadSuccessAll: {
            return datasetAdapter.setAll(action.payload.dataset, {
                ...state,
                error: false,
                loading: false,
            });
        }
        case DatasetActionType.DatasetLoadFailureAll: {
            return datasetAdapter.removeAll({
                ...state,
                error: true,
                loading: false,
            });
        }
        default:
            return state;
    }
}
