import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Measure } from '../../../measures/shared/measure.model';
import { SentimentListing } from '../shared/sentiment-listing.model';

@Component({
    selector: 'hm-sentiment-listings-table',
    templateUrl: './sentiment-listings-table.component.html',
    styleUrls: ['./sentiment-listings-table.component.scss'],
})
export class SentimentListingsTableComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild('exporter', { static: false }) exporter: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @Input() data: MatTableDataSource<SentimentListing> = new MatTableDataSource();
    @Input() dataAvailable;
    @Input() selectedMeasure: Measure;

    displayedColumns = [
        'category',
        'rating_avg',
        'rating_1_pct',
        'rating_2_pct',
        'rating_3_pct',
        'rating_4_pct',
        'rating_5_pct',
    ];

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.data.paginator = this.paginator;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data && !changes.data.isFirstChange()) {
            this.data.sort = this.sort;
        }
    }

    exportAs() {
        this.exporter.exportTable('csv', { fileName: 'sentiment-listings' }); // File name: name of tab + timestamp
    }
}
