import { Action, props, createAction } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Measure } from '../../shared/measure.model';

export enum MeasureActionType {
    Loading = '[Profiler/Measures] Loading',
    LoadSuccess = '[Profiler/Measures] LoadSuccess',
    LoadFailure = '[Profiler/Measures] LoadFailure',
    ToggleMeasure = '[Profiler/Measures] Toggle Measure',
}

export class MeasureLoadAction implements Action {
    public readonly type = MeasureActionType.Loading;
    constructor() {}
}

export class MeasureLoadSuccessAction implements Action {
    public readonly type = MeasureActionType.LoadSuccess;
    constructor(public payload: { measures: Measure[] }) {}
}

export class MeasureLoadFailAction implements Action {
    public readonly type = MeasureActionType.LoadFailure;
    constructor(public error: any) {}
}

export type MeasureActions = MeasureLoadAction | MeasureLoadSuccessAction | MeasureLoadFailAction;
