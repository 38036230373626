import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { currentUser, User } from 'app/core/auth';
import { EnquireComponent } from '../../content/enquire/enquire.component';
import { OverlayService } from './overlay.service';

@Component({
    selector: 'overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.css'],
})
export class OverlayComponent implements OnInit {
    @Input() message: string = '';
    user$: Observable<User>;
    name: string = '';
    email: string = '';
    constructor(
        public overlayService: OverlayService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private store: Store
    ) {}

    ngOnInit() {
        // this.message= this.overlayService.overlayGetMessage();
        this.user$ = this.store.pipe(select(currentUser));
        this.user$
            .subscribe((user) => {
                this.name = user.given_name + ' ' + user.family_name;
                this.email = user.email;
            })
            .unsubscribe();
    }
    close() {
        this.overlayService.overlayOff();
    }
    enquire() {
        this.overlayService.overlayOff();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '600px';

        // get details of logged in name and email
        dialogConfig.data = {
            name: this.name,
            email: this.email,
        };
        const dialogRef = this.dialog.open(EnquireComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((message) => {
            if (message != undefined) {
                this.snackbar.open(message, 'Dismiss');
            }
        });
    }
}
