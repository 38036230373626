import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DataPermissionType } from 'app/core/data-permissions/shared/data-permission.model';
import { AppState } from 'app/core/reducers';
import { ReportFilterKey } from 'app/core/report-filters/models/report-filters.model';
import { setReportFilters } from 'app/core/report-filters/store/actions/report-filter.actions';
import { selectReportFilterByKey } from 'app/core/report-filters/store/selectors/report-filter.selectors';
import {
    selectDefaultBoundary,
    selectSettingLngLat,
    selectSettingZoom,
} from 'app/core/session-info/store/selectors/session-info.selector';
import { selectUserPermittedBoundaryTypes } from 'app/core/user-data-permissions/store/selectors/user-data-permissions.selectors';
import { getPrevDateString, YEAR_MONTH_FMT } from 'app/core/utilities/date';
import { SubheaderService } from 'app/core/_base/layout';
import { Boundary } from 'app/views/pages/hotels/shared/area-selected-point.model';
import { ACCOMMODATION, Category } from 'app/views/partials/content/category/shared/category.model';
import { selectAllCategory } from 'app/views/partials/content/category/store/selectors/category.selector';
import { filter, map, Observable, Subscription, switchMap, tap } from 'rxjs';
import { BookmarkedReport } from '../../content/bookmarks/shared/bookmarked-report.model';
import { selectBookmark } from '../../content/bookmarks/store/bookmark.selector';

@Component({
    selector: 'hm-searchheader',
    templateUrl: './searchheader.component.html',
    styleUrls: ['./searchheader.component.scss'],
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
    title: string;

    // Date Picker
    startDate: string;
    endDate: string;
    minDate = '2019-Jan';
    maxDate = getPrevDateString(YEAR_MONTH_FMT, 'month', 1);

    // Router
    reportReady = false;

    // ?
    firstLoad = true;

    // Store
    reportFilterKey: ReportFilterKey;

    // Searchbar
    boundaryTypes$: Observable<DataPermissionType[]> = this.store.select(
        selectUserPermittedBoundaryTypes
    );
    boundaryType: DataPermissionType = DataPermissionType.STATE;
    toggleMap = false;
    toggleFilters = false;
    showFilterButton = true;
    boundaries: Boundary[] = [];
    categories: Category[] = [];

    // Map selector
    lnglat$: Observable<number[]>;
    zoom$: Observable<number>;
    defaultBoundary$: Observable<any>;

    // Bookmarks
    bookmarkLoading = false;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private store: Store<AppState>,
        private subheaderService: SubheaderService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.subheaderService.setTitle('');

        this.activatedRoute.data.subscribe((data) => {
            this.title = data.title;
            if (data.showFilterButton !== undefined) {
                this.showFilterButton = data.showFilterButton;
            }
            if (data.allowedBoundaryTypes !== undefined) {
                this.boundaryTypes$ = this.boundaryTypes$.pipe(
                    map((boundaryTypes) => {
                        return boundaryTypes.filter((boundaryType) =>
                            data.allowedBoundaryTypes.includes(boundaryType.toString())
                        );
                    })
                );
            }
            if (data.reportFilterKey !== undefined) {
                this.reportFilterKey = data.reportFilterKey;
            }
        });
        this.subscriptions.add(
            this.store.select(selectDefaultBoundary).subscribe((b) => {
                if (b != null) {
                    this.boundaryType = b.boundaryType;
                    this.boundaries = b.boundaries;
                }
            })
        );

        this.lnglat$ = this.store.select(selectSettingLngLat);
        this.zoom$ = this.store.select(selectSettingZoom);

        this.startDate = this.minDate;
        this.endDate = getPrevDateString(YEAR_MONTH_FMT, 'month', 2);

        const categoriesSub = this.store
            .select(selectAllCategory)
            .pipe(
                map((categories) => {
                    return categories.filter((c) => c.category_type === ACCOMMODATION);
                })
            )
            .subscribe((aCats) => (this.categories = aCats));
        this.subscriptions.add(categoriesSub);

        const bookmarksSub = this.activatedRoute.queryParams
            .pipe(
                map(({ bookmark }) => bookmark as string),
                filter((bookmarkID) => !!bookmarkID),
                // clear the bookmarkID from the url
                tap((id) =>
                    this.router.navigate([], { relativeTo: this.activatedRoute, replaceUrl: true })
                ),
                switchMap((id) => this.store.select(selectBookmark(), { id }))
            )
            .subscribe((bookmark) => this.loadBookmark(bookmark));
        this.subscriptions.add(bookmarksSub);

        this.checkFilters();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onDateChange(end) {
        this.endDate = end;

        if (this.boundaries && this.boundaries.length > 0) {
            this.saveFilters();
        }
    }

    boundariesChange() {
        if (this.boundaries.length == 0) {
            this.reportReady = false;
        } else if (this.boundaries.length > 0 && this.reportReady) {
            this.saveFilters();
        }
    }

    boundaryTypeChange() {
        if (this.firstLoad == true) {
            this.reportReady = true;
            this.firstLoad = false;
        } else if (!this.bookmarkLoading) {
            this.boundaries = [];
            this.reportReady = false;
        } else {
            this.bookmarkLoading = false;
            this.reportReady = true;
        }
    }

    runReport() {
        if (this.boundaries.length > 0) {
            this.saveFilters();
            this.reportReady = true;
        } else {
            this.reportReady = false;
        }
    }

    onMapClick() {
        this.toggleMap = !this.toggleMap;
    }

    onFiltersClick() {
        this.toggleFilters = !this.toggleFilters;
        this.saveFilters();
    }

    toggleCategory() {
        this.categories = this.categories.slice();
    }

    saveFilters() {
        // Could be of type TourismRecoveryFilter or RegionFilter
        const newReportFilter = {
            startDate: this.startDate,
            endDate: this.endDate,
            boundaries: this.boundaries,
            boundaryType: this.boundaryType,
            // categories: this.categories,
            extendMap: this.toggleMap,
        };
        this.store.dispatch(
            setReportFilters({
                key: this.reportFilterKey,
                filter: newReportFilter,
            })
        );
    }

    checkFilters() {
        const filtersSub = this.store
            .select(selectReportFilterByKey(this.reportFilterKey))
            .subscribe((reportFilters) => {
                if (reportFilters) {
                    this.endDate = reportFilters.endDate;
                    this.startDate = reportFilters.startDate;
                    this.boundaryType = reportFilters.boundaryType;
                    this.boundaries = reportFilters.boundaries;
                    this.toggleMap = reportFilters.extendMap;

                    // this.categories = reportFilters.categories;

                    this.reportReady = true;
                }
            });
        this.subscriptions.add(filtersSub);
    }

    loadBookmark(report: BookmarkedReport) {
        const { boundaryType, boundaries, categories, date } = report.content as {
            boundaryType: DataPermissionType;
            boundaries: Boundary[];
            categories: number[];
            date: string;
        };
        this.bookmarkLoading = true;

        const newReportFilter = {
            startDate: date,
            endDate: date,
            boundaries: boundaries,
            boundaryType: boundaryType,
            // categories: this.categories,
            extendMap: this.toggleMap,
        };
        this.store.dispatch(
            setReportFilters({
                key: this.reportFilterKey,
                filter: newReportFilter,
            })
        );
    }

    bookmarkContent(): Object {
        return {
            boundaryType: this.boundaryType,
            boundaries: this.boundaries,
            categories: this.categories.filter((c) => c.selected).map((c) => c.id),
            date: this.endDate,
        };
    }

    bookmarkSubtype(): string {
        return this.router.url.split('?')[0];
    }
}
