export interface Product {
    id: number;
    name: string;
    title: string;
    description: string;
    // The property is named with snake case to match the definition of the role on Auth0
    sys_admin?: string[];
    client_admin?: string[];
    contributor?: string[];
    analyst?: string[];
    reader?: string[];
    subscriber?: string[];
    userAccess?: boolean;
}

export class RolesTable {
    public static products: Product[] = [
        {
            id: 1,
            name: 'REGION_PROFILER',
            title: 'Region Profiler',
            description:
                'Access multiple data points across a specific region, including visitation & expenditure',
            sys_admin: [
                'REGION_PROFILER',
                'RR_ADD',
                'RR_TA_REGION',
                'RR_TA_CATEGORY',
                'RR_DASHBOARD',
                'RR_DASHBOARD_COMPARISON',
                'RR_PROFILER',
                'RR_FROMWHERE',
                'RR_WHERETO',
                'RA_PROFILER_GPS',
                'RA_PROFILER_IFID',
                'RA_PROFILER_IFII',
                'RA_TREND_IFID_INDEX',
                'RA_TREND_IFII_INDEX',
                'TIER1',
                'TIER2',
                'RP_DOWNLOADS',
                'RP_HELP',
            ],
            client_admin: [
                'REGION_PROFILER',
                'RR_ADD',
                'RR_TA_REGION',
                'RR_TA_CATEGORY',
                'RR_DASHBOARD',
                'RR_DASHBOARD_COMPARISON',
                'RR_PROFILER',
                'RR_FROMWHERE',
                'RR_WHERETO',
                'RA_PROFILER_GPS',
                'RA_PROFILER_IFID',
                'RA_PROFILER_IFII',
                'RA_TREND_IFID_INDEX',
                'RA_TREND_IFII_INDEX',
                'TIER1',
                'TIER2',
                'RP_DOWNLOADS',
                'RP_HELP',
            ],
            contributor: [
                'REGION_PROFILER',
                'RR_ADD',
                'RR_TA_REGION',
                'RR_TA_CATEGORY',
                'RR_DASHBOARD',
                'RR_DASHBOARD_COMPARISON',
                'RR_PROFILER',
                'RR_FROMWHERE',
                'RR_WHERETO',
                'RA_PROFILER_GPS',
                'RA_PROFILER_IFID',
                'RA_PROFILER_IFII',
                'RA_TREND_IFID_INDEX',
                'RA_TREND_IFII_INDEX',
                'TIER1',
                'TIER2',
                'RP_DOWNLOADS',
                'RP_HELP',
            ],
            analyst: [
                'REGION_PROFILER',
                'RR_TA_REGION',
                'RR_TA_CATEGORY',
                'RR_DASHBOARD',
                'RR_DASHBOARD_COMPARISON',
                'RR_PROFILER',
                'RR_FROMWHERE',
                'RR_WHERETO',
                'RA_PROFILER_GPS',
                'RA_PROFILER_IFID',
                'RA_PROFILER_IFII',
                'RA_TREND_IFID_INDEX',
                'RA_TREND_IFII_INDEX',
                'TIER1',
                'TIER2',
                'RP_DOWNLOADS',
                'RP_HELP',
            ],
            reader: [
                'REGION_PROFILER',
                'RR_TA_REGION',
                'RR_TA_CATEGORY',
                'RR_DASHBOARD',
                'RR_PROFILER',
                'RR_FROMWHERE',
                'RR_WHERETO',
                'TIER1',
                'TIER2',
                'RP_DOWNLOADS',
                'RP_HELP',
            ],
            subscriber: ['REGION_PROFILER', 'TIER1', 'TIER2', 'RP_DOWNLOADS', 'RP_HELP'],
        },
        {
            id: 2,
            name: 'DIGITAL_MARKETING',
            title: 'Digital Marketing',
            description:
                'Integrate digital performance metrics from web analytics tools such as Adobe or Google.',
            sys_admin: [
                'DIGITAL_MARKETING',
                'DI_PROFILING',
                'DI_TREND',
                'DI_PROFILING_A',
                'DI_TREND_A',
                'DI_ADD',
                'DI_DOWNLOADS',
                'DI_HELP',
            ],
            client_admin: [
                'DIGITAL_MARKETING',
                'DI_PROFILING',
                'DI_TREND',
                'DI_PROFILING_A',
                'DI_TREND_A',
                'DI_ADD',
                'DI_DOWNLOADS',
                'DI_HELP',
            ],
            contributor: [
                'DIGITAL_MARKETING',
                'DI_PROFILING',
                'DI_TREND',
                'DI_PROFILING_A',
                'DI_TREND_A',
                'DI_ADD',
                'DI_DOWNLOADS',
                'DI_HELP',
            ],
            analyst: [
                'DIGITAL_MARKETING',
                'DI_PROFILING',
                'DI_TREND',
                'DI_PROFILING_A',
                'DI_TREND_A',
                'DI_DOWNLOADS',
                'DI_HELP',
            ],
            reader: ['DIGITAL_MARKETING', 'DI_PROFILING', 'DI_TREND', 'DI_DOWNLOADS', 'DI_HELP'],
            subscriber: ['DIGITAL_MARKETING', 'DI_DOWNLOADS', 'DI_HELP'],
        },
        {
            id: 3,
            name: 'TOURISM_SURVEY',
            title: 'Tourism survey data',
            description: 'Access visitation and expenditure metrics across regions',
            sys_admin: [
                'TOURISM_SURVEY',
                'TS_ADD',
                'SURVEY_DO',
                'SURVEY_DD',
                'SURVEY_INT',
                'TRA_VISITORPROFILE',
                'TRA_TIMESERIES',
                'TS_DOWNLOADS',
                'TS_HELP',
            ],
            client_admin: [
                'TOURISM_SURVEY',
                'TS_ADD',
                'SURVEY_DO',
                'SURVEY_DD',
                'SURVEY_INT',
                'TRA_VISITORPROFILE',
                'TRA_TIMESERIES',
                'TS_DOWNLOADS',
                'TS_HELP',
            ],
            contributor: [
                'TOURISM_SURVEY',
                'TS_ADD',
                'SURVEY_DO',
                'SURVEY_DD',
                'SURVEY_INT',
                'TRA_VISITORPROFILE',
                'TRA_TIMESERIES',
                'TS_DOWNLOADS',
                'TS_HELP',
            ],
            analyst: [
                'TOURISM_SURVEY',
                'SURVEY_DO',
                'SURVEY_DD',
                'SURVEY_INT',
                'TRA_VISITORPROFILE',
                'TRA_TIMESERIES',
                'TS_DOWNLOADS',
                'TS_HELP',
            ],
            reader: [
                'TOURISM_SURVEY',
                'TRA_VISITORPROFILE',
                'TRA_TIMESERIES',
                'TS_DOWNLOADS',
                'TS_HELP',
            ],
            subscriber: ['TS_DOWNLOADS', 'TS_HELP'],
        },
        {
            id: 4,
            name: 'TOURISM_PERFORMANCE',
            title: 'Tourism performance',
            description: 'Anonymised insights by geo-region',
            sys_admin: ['TOURISM_PERFORMANCE', 'TP_ADD', 'LGA_TRIAL', 'TP_DOWNLOADS', 'TP_HELP'],
            client_admin: ['TOURISM_PERFORMANCE', 'TP_ADD', 'LGA_TRIAL', 'TP_DOWNLOADS', 'TP_HELP'],
            contributor: ['TOURISM_PERFORMANCE', 'TP_ADD', 'LGA_TRIAL', 'TP_DOWNLOADS', 'TP_HELP'],
            analyst: ['TOURISM_PERFORMANCE', 'LGA_TRIAL', 'TP_DOWNLOADS', 'TP_HELP'],
            reader: ['TOURISM_PERFORMANCE', 'LGA_TRIAL', 'TP_DOWNLOADS', 'TP_HELP'],
            subscriber: ['TP_DOWNLOADS', 'TP_HELP'],
        },
        {
            id: 8,
            name: 'TOURISM_INDICATORS',
            title: 'Performance indicators',
            description: 'Performance indicators and metrics',
            sys_admin: [
                'TOURISM_INDICATORS',
                'TI_ADD',
                'METRICS_DASHBOARD',
                'METRICS_ANALYSIS',
                'TI_DOWNLOADS',
                'TI_HELP',
            ],
            client_admin: [
                'TOURISM_INDICATORS',
                'TI_ADD',
                'METRICS_DASHBOARD',
                'METRICS_ANALYSIS',
                'TI_DOWNLOADS',
                'TI_HELP',
            ],
            contributor: [
                'TOURISM_INDICATORS',
                'TI_ADD',
                'METRICS_DASHBOARD',
                'METRICS_ANALYSIS',
                'TI_DOWNLOADS',
                'TI_HELP',
            ],
            analyst: [
                'TOURISM_INDICATORS',
                'METRICS_DASHBOARD',
                'METRICS_ANALYSIS',
                'TI_DOWNLOADS',
                'TI_HELP',
            ],
            reader: ['TOURISM_INDICATORS', 'METRICS_DASHBOARD', 'TI_DOWNLOADS', 'TI_HELP'],
            subscriber: ['TI_DOWNLOADS', 'TI_HELP'],
        },
        {
            id: 5,
            name: 'AUDIENCE_PROFILER',
            title: 'Audience profiler',
            description:
                'Visitation, expenditure and sentiment metrics for the time and location you need.',
            sys_admin: [
                'AUDIENCE_PROFILER',
                'AP_ADD',
                'AP_CATEGORY_SPEND',
                'AP_CATEGORY_SPEND_INDEX',
                'AP_CATEGORY_SPEND_GLOBAL_INDEX',
                'AP_ABS_PROFILE_INDEX',
                'AP_ABS_PROFILE',
                'AP_ABS_PROFILE_INDEX_INDIVIDUAL',
                'AP_ABS_PROFILE_INDIVIDUAL',
                'AP_BRAND_SPEND',
                'AP_BRAND_SPEND_INDEX',
                'AP_DOWNLOADS',
                'AP_HELP',
            ],
            client_admin: [
                'AUDIENCE_PROFILER',
                'AP_ADD',
                'AP_CATEGORY_SPEND',
                'AP_CATEGORY_SPEND_INDEX',
                'AP_CATEGORY_SPEND_GLOBAL_INDEX',
                'AP_ABS_PROFILE_INDEX',
                'AP_ABS_PROFILE',
                'AP_ABS_PROFILE_INDEX_INDIVIDUAL',
                'AP_ABS_PROFILE_INDIVIDUAL',
                'AP_BRAND_SPEND',
                'AP_BRAND_SPEND_INDEX',
                'AP_DOWNLOADS',
                'AP_HELP',
            ],
            contributor: [
                'AUDIENCE_PROFILER',
                'AP_ADD',
                'AP_CATEGORY_SPEND',
                'AP_CATEGORY_SPEND_INDEX',
                'AP_CATEGORY_SPEND_GLOBAL_INDEX',
                'AP_ABS_PROFILE_INDEX',
                'AP_ABS_PROFILE',
                'AP_ABS_PROFILE_INDEX_INDIVIDUAL',
                'AP_ABS_PROFILE_INDIVIDUAL',
                'AP_BRAND_SPEND',
                'AP_BRAND_SPEND_INDEX',
                'AP_DOWNLOADS',
                'AP_HELP',
            ],
            analyst: [
                'AUDIENCE_PROFILER',
                'AP_CATEGORY_SPEND',
                'AP_CATEGORY_SPEND_INDEX',
                'AP_CATEGORY_SPEND_GLOBAL_INDEX',
                'AP_ABS_PROFILE_INDEX',
                'AP_ABS_PROFILE',
                'AP_ABS_PROFILE_INDEX_INDIVIDUAL',
                'AP_ABS_PROFILE_INDIVIDUAL',
                'AP_BRAND_SPEND',
                'AP_BRAND_SPEND_INDEX',
                'AP_DOWNLOADS',
                'AP_HELP',
            ],
            reader: ['AUDIENCE_PROFILER', 'AP_DOWNLOADS', 'AP_HELP'],
            subscriber: ['AUDIENCE_PROFILER', 'AP_DOWNLOADS', 'AP_HELP'],
        },

        {
            id: 6,
            name: 'ASSET_MANAGER',
            title: 'Asset manager',
            description: 'Upload sites, manage sites and packs',
            sys_admin: [
                'ASSET_MANAGER',
                'TR_UPLOAD',
                'ASSETS_UPLOAD',
                'TR_SITES',
                'TR_PACKS',
                'AM_ADD',
                'AM_HELP',
            ],
            client_admin: [
                'ASSET_MANAGER',
                'TR_UPLOAD',
                'TR_SITES',
                'ASSETS_UPLOAD',
                'TR_PACKS',
                'AM_ADD',
                'AM_HELP',
            ],
            contributor: [
                'ASSET_MANAGER',
                'TR_UPLOAD',
                'TR_SITES',
                'ASSETS_UPLOAD',
                'TR_PACKS',
                'AM_ADD',
                'AM_HELP',
            ],
            analyst: [''],
            reader: [''],
            subscriber: [''],
        },
        {
            id: 7,
            name: 'DATACENTRE',
            title: 'Data centre',
            description: 'Data centre module',
            sys_admin: [
                'DATACENTRE',
                'DATACENTRE_UPLOAD',
                'DATACENTRE_DASHBOARD',
                'DATACENTRE_LIST',
                'DC_ADD',
                'DC_HELP',
            ],
            client_admin: [
                'DATACENTRE',
                'DATACENTRE_UPLOAD',
                'DATACENTRE_LIST',
                'DC_ADD',
                'DC_HELP',
            ],
            contributor: [
                'DATACENTRE',
                'DATACENTRE_UPLOAD',
                'DATACENTRE_LIST',
                'DC_ADD',
                'DC_HELP',
            ],
            analyst: [],
            reader: [],
            subscriber: [],
        },
        {
            id: 8,
            name: 'POST_ANALYSIS',
            title: 'Campaign analysis',
            description: 'Campaign analysis module',
            sys_admin: [
                'POST_ANALYSIS',
                'PA_ANALYSIS',
                'PA_PRE_ANALYSIS',
                'PA_VIEWER',
                'PA_REPORT',
                'PA_ADD',
                'PA_DOWNLOADS',
                'PA_HELP',
            ],
            client_admin: [
                'POST_ANALYSIS',
                'PA_ANALYSIS',
                'PA_PRE_ANALYSIS',
                'PA_VIEWER',
                'PA_REPORT',
                'PA_ADD',
                'PA_DOWNLOADS',
                'PA_HELP',
            ],
            contributor: [
                'POST_ANALYSIS',
                'PA_PRE_ANALYSIS',
                'PA_VIEWER',
                'PA_DOWNLOADS',
                'PA_HELP',
            ],
            analyst: ['POST_ANALYSIS', 'PA_PRE_ANALYSIS', 'PA_VIEWER', 'PA_DOWNLOADS', 'PA_HELP'],
            reader: ['POST_ANALYSIS', 'PA_DOWNLOADS', 'PA_HELP'],
            subscriber: ['POST_ANALYSIS', 'PA_DOWNLOADS', 'PA_HELP'],
        },
        {
            id: 9,
            name: 'STORE_PROFILER',
            title: 'Asset profiler',
            description: 'Asset profiler module',
            sys_admin: [
                'STORE_PROFILER',
                'SP_ANALYSIS',
                'PA_REPORT',
                'SP_ADD',
                'SP_DOWNLOADS',
                'SP_HELP',
                'SP_GEOVIZ',
                'ASSETS_VISITATION',
            ],
            client_admin: [
                'STORE_PROFILER',
                'SP_ANALYSIS',
                'PA_REPORT',
                'SP_ADD',
                'SP_DOWNLOADS',
                'SP_HELP',
                'SP_GEOVIZ',
                'ASSETS_VISITATION',
            ],
            contributor: [
                'STORE_PROFILER',
                'SP_ANALYSIS',
                'PA_REPORT',
                'SP_ADD',
                'SP_DOWNLOADS',
                'SP_HELP',
                'SP_GEOVIZ',
                'ASSETS_VISITATION',
            ],
            analyst: ['STORE_PROFILER', 'SP_ANALYSIS', 'PA_REPORT', 'SP_DOWNLOADS', 'SP_HELP'],
            reader: ['STORE_PROFILER', 'ASSETS_VISITATION', 'SP_DOWNLOADS', 'SP_HELP'],
            subscriber: ['STORE_PROFILER', 'SP_DOWNLOADS', 'SP_HELP'],
        },
        {
            id: 10,
            name: 'KEY_ACCOUNT_DATA_MODULE',
            title: 'Key account data',
            description: 'Key account data module',
            sys_admin: [
                'KEY_ACCOUNT_DATA_MODULE',
                'KAD_ANALYSIS_PERFORMANCE',
                'KAD_ANALYSIS_INVENTORY',
                'KAD_ANALYSIS_OPPORTUNITIES',
                'KAD_ANALYSIS_VENDOR',
                'KAD_ANALYSIS_STANDARD_DNLD',
                'KAD_ANALYSIS_BASIC_DNLD',
                'KAD_DOWNLOADS',
                'KAD_HELP',
            ],
            client_admin: [
                'KEY_ACCOUNT_DATA_MODULE',
                'KAD_ANALYSIS_PERFORMANCE',
                'KAD_ANALYSIS_INVENTORY',
                'KAD_ANALYSIS_OPPORTUNITIES',
                'KAD_ANALYSIS_VENDOR',
                'KAD_ANALYSIS_STANDARD_DNLD',
                'KAD_ANALYSIS_BASIC_DNLD',
                'KAD_DOWNLOADS',
                'KAD_HELP',
            ],
            contributor: [
                'KEY_ACCOUNT_DATA_MODULE',
                'KAD_ANALYSIS_PERFORMANCE',
                'KAD_ANALYSIS_INVENTORY',
                'KAD_ANALYSIS_OPPORTUNITIES',
                'KAD_ANALYSIS_VENDOR',
                'KAD_ANALYSIS_STANDARD_DNLD',
                'KAD_ANALYSIS_BASIC_DNLD',
                'KAD_DOWNLOADS',
                'KAD_HELP',
            ],
            analyst: [
                'KEY_ACCOUNT_DATA_MODULE',
                'KAD_ANALYSIS_PERFORMANCE',
                'KAD_ANALYSIS_INVENTORY',
                'KAD_ANALYSIS_OPPORTUNITIES',
                'KAD_ANALYSIS_VENDOR',
                'KAD_ANALYSIS_STANDARD_DNLD',
                'KAD_ANALYSIS_BASIC_DNLD',
                'KAD_DOWNLOADS',
                'KAD_HELP',
            ],
            reader: ['KEY_ACCOUNT_DATA_MODULE', 'KAD_DOWNLOADS', 'KAD_HELP'],
            subscriber: ['KEY_ACCOUNT_DATA_MODULE', 'KAD_DOWNLOADS', 'KAD_HELP'],
        },
        {
            id: 11,
            name: 'ASGA_MODULE',
            title: 'Syndicated data',
            description: 'Syndicated data',
            sys_admin: ['ASGA_MODULE', 'ASGA_CUBES', 'SD_DOWNLOADS', 'SD_HELP'],
            client_admin: ['ASGA_MODULE', 'ASGA_CUBES', 'SD_DOWNLOADS', 'SD_HELP'],
            contributor: ['ASGA_MODULE', 'ASGA_CUBES', 'SD_DOWNLOADS', 'SD_HELP'],
            analyst: ['ASGA_MODULE', 'ASGA_CUBES', 'SD_DOWNLOADS', 'SD_HELP'],
            reader: ['ASGA_MODULE', 'SD_DOWNLOADS', 'SD_HELP'],
            subscriber: ['ASGA_MODULE', 'SD_DOWNLOADS', 'SD_HELP'],
        },
        {
            id: 12,
            name: 'AUDIENCE_REACH',
            title: 'Audience reach',
            description: 'Audience reach module',
            sys_admin: [
                'AUDIENCE_REACH',
                'AR_HOUR',
                'AR_DAYPART',
                'AR_DAILY',
                'AR_WEEKDAY',
                'AR_WEEK',
                'AR_STATUS',
                'AR_DOWNLOADS',
                'AR_HELP',
            ],
            client_admin: [
                'AUDIENCE_REACH',
                'AR_HOUR',
                'AR_DAYPART',
                'AR_DAILY',
                'AR_WEEKDAY',
                'AR_WEEK',
                'AR_STATUS',
                'AR_DOWNLOADS',
                'AR_HELP',
            ],
            contributor: [
                'AUDIENCE_REACH',
                'AR_HOUR',
                'AR_DAYPART',
                'AR_DAILY',
                'AR_WEEKDAY',
                'AR_WEEK',
                'AR_STATUS',
                'AR_DOWNLOADS',
                'AR_HELP',
            ],
            analyst: [
                'AUDIENCE_REACH',
                'AR_HOUR',
                'AR_DAYPART',
                'AR_DAILY',
                'AR_WEEKDAY',
                'AR_WEEK',
                'AR_STATUS',
                'AR_DOWNLOADS',
                'AR_HELP',
            ],
            reader: ['AUDIENCE_REACH', 'AR_DOWNLOADS', 'AR_HELP'],
            subscriber: ['AUDIENCE_REACH', 'AR_DOWNLOADS', 'AR_HELP'],
        },
        {
            id: 13,
            name: 'REGION_ANALYSIS',
            title: 'Region analysis',
            description: 'Region analysis module',
            sys_admin: ['REGION_ANALYSIS', 'RA_REGION_CATEGORY_SPEND', 'RA_DOWNLOADS', 'RA_HELP'],
            client_admin: [
                'REGION_ANALYSIS',
                'RA_REGION_CATEGORY_SPEND',
                'RA_DOWNLOADS',
                'RA_HELP',
            ],
            contributor: ['REGION_ANALYSIS', 'RA_REGION_CATEGORY_SPEND', 'RA_DOWNLOADS', 'RA_HELP'],
            analyst: ['REGION_ANALYSIS', 'RA_REGION_CATEGORY_SPEND', 'RA_DOWNLOADS', 'RA_HELP'],
            reader: ['REGION_ANALYSIS', 'RA_DOWNLOADS', 'RA_HELP'],
            subscriber: ['REGION_ANALYSIS', 'RA_DOWNLOADS', 'RA_HELP'],
        },
    ];
}
