import { createAction, props } from '@ngrx/store';
import { ReportFilter, ReportFilterKey } from '../../models/report-filters.model';

/**
 * Example dispatch:
 * store.dispatch(setNewFilters({key: ReportFilterKey.SurveyFilter, filter: {} }))
 */
export const setReportFilters = createAction(
    '[ReportFilter] Set ReportFilters',
    props<{
        key: ReportFilterKey;
        filter: ReportFilter;
    }>()
);
