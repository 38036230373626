import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { BookmarkedReportsService } from '../shared/bookmarked-reports.service';
import {
    AddBookmarkAction,
    AddBookmarkFailureAction,
    AddBookmarkSuccessAction,
    BookmarkAction,
    DeleteBookmarkAction,
    DeleteBookmarkFailureAction,
    DeleteBookmarkSuccessAction,
    LoadBookmarksAction,
    LoadBookmarksFailureAction,
    LoadBookmarksSuccessAction,
    EditBookmarksAction,
    EditBookmarksFailureAction,
    EditBookmarksSuccessAction,
} from './bookmark.actions';

@Injectable()
export class BookmarkEffects {
    constructor(private service: BookmarkedReportsService, private actions$: Actions) {}

    public loadBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadBookmarksAction>(BookmarkAction.LoadBookmarks),
            switchMap(() =>
                this.service.list().pipe(
                    map((bookmarks) => new LoadBookmarksSuccessAction({ bookmarks })),
                    catchError((res) => of(new LoadBookmarksFailureAction({ error: res.error })))
                )
            )
        )
    );

    public addBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AddBookmarkAction>(BookmarkAction.AddBookmark),
            switchMap(({ payload }) =>
                this.service.create(payload.bookmark).pipe(
                    map(({ id }) => new AddBookmarkSuccessAction({ id })),
                    catchError((res) => of(new AddBookmarkFailureAction({ error: res.error })))
                )
            )
        )
    );

    public deleteBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteBookmarkAction>(BookmarkAction.DeleteBookmark),
            switchMap(({ payload }) =>
                this.service.delete(payload.id).pipe(
                    map((_) => new DeleteBookmarkSuccessAction({})),
                    catchError((res) => of(new DeleteBookmarkFailureAction({ error: res.error })))
                )
            )
        )
    );

    public editBookmark$ = createEffect(() =>
        this.actions$.pipe(
            ofType<EditBookmarksAction>(BookmarkAction.EditBookmark),
            switchMap(({ payload }) =>
                this.service.edit(payload.bookmark).pipe(
                    map((bookmark) => new EditBookmarksSuccessAction({ bookmark })),
                    catchError((res) => of(new EditBookmarksFailureAction({ error: res.error })))
                )
            )
        )
    );
}
