import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { isPlatformBrowser } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';
import { hideSmall } from 'app/core/utilities/utilities';
import { SurveyStackedBarChartConfig } from './survey-stacked-bar-chart.model';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'app/core/reducers';
import { Store, select } from '@ngrx/store';
import { selectAMColors } from 'app/core/whitelabel/store/whitelabel.selector';

@Component({
    selector: 'hm-survey-stacked-bar-chart',
    template: `<div id="{{ id }}" class="survey-stacked-bar-chart chart-stats"></div>`,
    styleUrls: ['./survey-stacked-bar-chart.component.scss'],
})

// This is now a half donut chart.  Should be refactored into its own chart if this is approved.
export class SurveyStackedBarChartComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
    @Input() id = 'survey-stacked-bar-chart';
    @Input() config: SurveyStackedBarChartConfig;

    private chart: am4charts.PieChart;

    amColors: string[];
    private componentDestroyed = new Subject<void>();

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        private zone: NgZone,
        private store: Store<AppState>
    ) {}

    // Run the function only in the browser
    browserOnly(f: () => void) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                f();
            });
        }
    }
    createPieData(data: any[]) {
        return data;
    }
    ngOnInit(): void {
        this.store
            .pipe(select(selectAMColors))
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((colors) => {
                this.amColors = colors;
            });
    }

    am4themes_myTheme(target) {
        if (target instanceof am4core.ColorSet) {
            target.list = [];
            this.amColors.map((colour) => {
                target.list.push(am4core.color(colour));
            });
        }
    }

    ngAfterViewInit() {
        this.browserOnly(() => {
            am4core.useTheme(this.am4themes_myTheme);

            const chart = am4core.create(this.id, am4charts.PieChart);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

            // Add data
            // Legend
            if (this.config.showLegend) {
                chart.legend = new am4charts.Legend();
                // chart.legend.labels.template.maxWidth = 150;
                chart.legend.labels.template.truncate = true;
                chart.legend.position = 'right';
                chart.legend.scrollable = true;
                chart.legend.valueLabels.template.disabled = true;
            }
            chart.fontSize = '1.1rem';

            chart.radius = am4core.percent(70);
            chart.innerRadius = am4core.percent(40);
            chart.startAngle = 180;
            chart.endAngle = 360;

            chart.data = this.createPieData(this.config.data);

            // Hiding AmCharts logo
            chart.logo.height = -20000;
            this.chart = chart;
            this.draw();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config && !changes.config.isFirstChange()) {
            // Reset data
            this.chart.data = this.createPieData(this.config.data);
            this.chart.series.clear();
            this.draw();
        }
    }

    ngOnDestroy() {
        this.browserOnly(() => {
            this.chart.dispose();
        });
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }

    draw() {
        const chart = this.chart;
        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = 'value';
        series.dataFields.category = 'category';

        series.slices.template.cornerRadius = 10;
        series.slices.template.innerCornerRadius = 7;
        series.slices.template.draggable = true;
        series.slices.template.inert = true;

        series.hiddenState.properties.startAngle = 90;
        series.hiddenState.properties.endAngle = 90;
        // series.labels.template.text = "{value.value}";
        //series.calculatePercent = true;

        series.labels.template.text = "{value.percent.formatNumber('#.')}%";
        series.ticks.template.events.on('ready', hideSmall);
        series.ticks.template.events.on('visibilitychanged', hideSmall);
        series.labels.template.events.on('ready', hideSmall);
        series.labels.template.events.on('visibilitychanged', hideSmall);

        this.chart = chart;
    }
}
