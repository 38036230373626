import {
    Component,
    Directive,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This is a simple directive to add a simple confirmation to a button, e.g.:
 *    <button (confirm)="doThing()" [message]="Are you sure you want to do a thing?">
 *      Do a thing
 *    </button>
 */
@Directive({
    selector: '[confirm]',
})
export class ConfirmDirective implements OnInit {
    @Input() message: string = 'Confirm';
    @Output() confirm = new EventEmitter();

    constructor(private dialog: MatDialog) {}

    ngOnInit() {}

    @HostListener('click')
    open() {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '250px',
            data: {
                title: this.message,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.confirm.emit();
            }
        });
    }
}

@Component({
    selector: 'hm-confirm-dialog',
    template: `
        <h2 mat-dialog-title>{{ title }}</h2>
        <mat-dialog-actions align="end" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="true">
                Confirm
            </button>
        </mat-dialog-actions>
    `,
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    title: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) { title }
    ) {
        this.title = title;
    }
}
