import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'hm-choropleth-legend',
    templateUrl: './choropleth-legend.component.html',
    styleUrls: ['./choropleth-legend.component.scss'],
})
export class ChoroplethLegendComponent implements OnInit {
    @Input() colours: string[][];

    coloursToDisplay: string[] = [];

    constructor() {}

    ngOnInit(): void {
        this.colours.reverse().map((c) => {
            this.coloursToDisplay = this.coloursToDisplay.concat(c);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['colours'] && !changes['colours'].isFirstChange()) {
            this.coloursToDisplay = [];
            this.colours.reverse().map((c) => {
                this.coloursToDisplay = this.coloursToDisplay.concat(c);
            });
        }
    }
}
