<mat-button-toggle-group
    #group="matButtonToggleGroup"
    (click)="updateTravelOption(group.value)"
    value="car"
    color="primary"
>
    <!-- https://material.io/resources/icons/?search=walk&icon=directions_walk&style=baseline -->
    <mat-button-toggle value="walk">
        <mat-icon aria-hidden="false" aria-label="Walk icon">directions_walk</mat-icon>
        <p>Walk</p>
    </mat-button-toggle>
    <mat-button-toggle value="bike">
        <mat-icon aria-hidden="false" aria-label="Bike icon ">directions_bike</mat-icon>
        <p>Bike</p>
    </mat-button-toggle>

    <mat-button-toggle value="transit" *ngIf="travelModeOn('transit')">
        <mat-icon aria-hidden="false" aria-label="Bike icon ">directions_transit</mat-icon>
        <p>Transit</p>
    </mat-button-toggle>
    <mat-button-toggle value="car" *ngIf="travelModeOn('car')">
        <mat-icon aria-hidden="false" aria-label="Car icon">directions_car</mat-icon>
        <p>Car</p>
    </mat-button-toggle>
</mat-button-toggle-group>
