import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { ReportFilterKey } from '../../models/report-filters.model';
import { reportFilterFeatureKey } from '../reducers/report-filter.reducer';

// FeatureSelector is essentially the top-level parent state object in the store
export const selectNewReportFilterFeature = createFeatureSelector<AppState>(reportFilterFeatureKey);

export const selectReportFilterByKey = (key: ReportFilterKey) =>
    createSelector(selectNewReportFilterFeature, (newReportFilterState) => {
        return newReportFilterState[key];
    });
