import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Measure } from '../../measures/shared/measure.model';

@Component({
    selector: 'hm-report-controls',
    templateUrl: './report-controls.component.html',
    styleUrls: ['./report-controls.component.scss'],
})
export class ReportControlsComponent implements OnInit, OnChanges {
    @Input() scale: number[];
    @Input() measures: Measure[];
    @Input() selectedMeasure: Measure;
    @Output() selectedMeasureChange = new EventEmitter<Measure>();

    currentMeasure: Measure;

    format = 'n';

    constructor() {}

    ngOnInit() {
        // Certain schemas are segmented like device_gps_new are segmented;
        const key = this.selectedMeasure.segment ? 'segment' : 'uniqueName';
        this.currentMeasure = this.measures.find((m) => m[key] === this.selectedMeasure[key]);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedMeasure'] && !changes['selectedMeasure'].isFirstChange()) {
            this.format = this.selectedMeasure.uniqueName.includes('ratio') ? 'p' : 'n';
        }
    }

    onSelectedMeasureChange(newSelectedMeasure: Measure) {
        this.format = this.selectedMeasure.uniqueName.includes('ratio') ? 'p' : 'n';

        this.selectedMeasure = newSelectedMeasure;
        this.selectedMeasureChange.emit(newSelectedMeasure);
    }
}
