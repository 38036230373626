import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PrintService } from './print.service';

@Component({
    selector: 'hm-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class PrintComponent {
    constructor(public printService: PrintService) {}
}
