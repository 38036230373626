import { Injectable } from '@angular/core';
import { of, Observable, defer, combineLatest } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Store, select, Action } from '@ngrx/store';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';

import { DataPermissionsService } from '../../shared/data-permissions.service';
import { DataPermission, DataPermissionType } from '../../shared/data-permission.model';
import {
    LoadingAllAction,
    DataPermissionActionType,
    LoadSuccessAllAction,
    LoadingFailureAllAction,
} from '../actions/data-permissions.actions';
import { AppState } from 'app/core/reducers';
import { selectAllUserDataPermission } from 'app/core/user-data-permissions/store/selectors/user-data-permissions.selectors';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { selectCountry } from 'app/core/session-info/store/selectors/session-info.selector';

@Injectable()
export class DataPermissionEffects {
    country$: Observable<string> = this.store.pipe(select(selectCountry));
    // API
    constructor(
        private service: DataPermissionsService,
        private actions$: Actions,
        private store: Store
    ) {}

    public loadDataPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadingAllAction>(DataPermissionActionType.DataPermissionLoadingAll),

            switchMap((action) =>
                this.service.getDataPermissions(this.country$).pipe(
                    map(
                        (response: DataPermission[]) =>
                            new LoadSuccessAllAction({
                                dataPermissions: response,
                            }),
                        catchError((error) => of(new LoadingFailureAllAction(error)))
                    )
                )
            )
        )
    );
}
