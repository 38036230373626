<hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

<div [hidden]="!permissionsAvailable || !dataAvailable">
    <hm-transaction-ifi-int-graph [data]="aggregateData" [selectedMeasure]="selectedReportMeasure"></hm-transaction-ifi-int-graph>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>
