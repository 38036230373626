import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Product, RolesTable } from 'app/core/auth/_services/roles';
import {
    DataPermission,
    DataPermissionType,
} from 'app/core/data-permissions/shared/data-permission.model';
import { DataPermissionsService } from 'app/core/data-permissions/store';
import { NgxPermissionsService, NgxRolesObject, NgxRolesService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import {
    selectCountry,
    selectOrganisationId,
} from '../../../session-info/store/selectors/session-info.selector';
import {
    LoadUserDataPermissionsAction,
    LoadUserDataPermissionsFailureAction,
    LoadUserDataPermissionsSuccessAction,
    UserDataPermissionActionType,
} from '../actions/user-data-permissions.action';

@Injectable()
export class UserDataPermissionEffects {
    // API
    organisationId$: Observable<number> = this.store.pipe(select(selectOrganisationId));
    country$: Observable<string> = this.store.pipe(select(selectCountry));
    validRoles = ['SYS_ADMIN', 'CLIENT_ADMIN', 'CONTRIBUTOR', 'ANALYST', 'READER', 'SUBSCRIBER'];
    tempRoles: string[] = [];
    constructor(
        private service: DataPermissionsService,
        private actions$: Actions,
        private store: Store<AppState>,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService
    ) {}

    public loadUserDataPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadUserDataPermissionsAction>(
                UserDataPermissionActionType.LoadUserDataPermissions
            ),
            concatMap(() =>
                this.service
                    .getOrganisationDataPermissions(this.organisationId$, this.country$)
                    .pipe(
                        map(
                            (response: DataPermission[]) =>
                                new LoadUserDataPermissionsSuccessAction({
                                    userDataPermission: response,
                                }),
                            catchError((error) =>
                                of(new LoadUserDataPermissionsFailureAction(error))
                            )
                        )
                    )
            )
        )
    );

    public addDataPermissionsToSession$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType<LoadUserDataPermissionsSuccessAction>(
                    UserDataPermissionActionType.LoadUserDataPermissionsSuccess
                ),
                tap((action) => {
                    const orgPagePermissionsSettings = action.payload.userDataPermission.filter(
                        (pg) => pg.type === DataPermissionType.SETTING
                    );
                    const orgPagePermissions = orgPagePermissionsSettings.map((pg) => pg.keyText);

                    let roles;

                    this.rolesService.roles$.subscribe((allRoles: NgxRolesObject) => {
                        roles = Object.keys(allRoles);
                    });
                    // This determines if the modules are subscribed to and gives top level menu access
                    roles.map((role) => {
                        if (orgPagePermissions.includes(role)) {
                            this.tempRoles.push(role);
                        }
                    });
                    const orgUserProductMatch = this.intersectOrganisationRolePermissions(
                        this.tempRoles,
                        orgPagePermissions
                    );
                    roles.map((r) => {
                        if (this.validRoles.includes(r)) {
                            const rolePermissions = this.getPermissions(r, orgUserProductMatch);
                            rolePermissions.map((p) => {
                                if (orgPagePermissions.includes(p) || p.includes('_ADD')) {
                                    this.permissionsService.addPermission(p);
                                }
                            });
                        }
                    });
                })
            ),
        { dispatch: false }
    );

    private getPermissions(roleName: string, products: string[]): string[] {
        const p = roleName.toLowerCase();

        return RolesTable.products
            .filter((m) => products.includes(m.name))
            .flatMap((f) => f[p as keyof Product] || []) as string[];
    }

    private intersectOrganisationRolePermissions(arr1, arr2) {
        const intersectionResult = arr1.filter((x) => arr2.indexOf(x) !== -1);
        return intersectionResult;
    }
}
