import { Pipe, PipeTransform } from '@angular/core';
import { Metric, MetricValue } from 'app/views/pages/metrics/metrics.model';

@Pipe({
    name: 'filterIndicator',
})
export class FilterIndicatorPipe implements PipeTransform {
    transform(vals: (Metric | MetricValue)[], indicator_id: number): (Metric | MetricValue)[] {
        if (!vals || !indicator_id) {
            return vals;
        }
        return vals.filter((v) => v.indicator_id == indicator_id);
    }
}
