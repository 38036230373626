<!--begin::Body-->

<div class="kt-login__body">
    <!--begin::Signin-->
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>Welcome to Hemisphere</h3>
            <p>Congratulations, you have now successfully activated your Tourism Data Hub account on Hemisphere.</p>
            <p>Click Sign In to get started</p>

            <div>
                <img src="/assets/media/logos/auth0.png" width="200px" />
            </div>
            <div>
                <button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary">
                    Sign In
                </button>
            </div>
            <p></p>
            <div>
                <p></p>
                <p>
                    By continuing, you agree to Hemisphere digital's
                    <a [href]="externalUris.terms">Terms of Service</a>, <a [href]="externalUris.eula">Licence Agreement</a>,
                    <a [href]="externalUris.privacy">Privacy Policy</a> and use of Cookies.
                </p>
            </div>
        </div>
    </div>
    <!--end::Signin-->
</div>
<!--end::Body-->
