<div class="hem-empty-state" *ngIf="!dataAvailable && permissionsAvailable">
    <i class="flaticon2-calendar-9"></i>
    <h4>No data returned for the date selected</h4>
    <p>Please select a new date range...</p>
</div>

<div class="hem-empty-state" *ngIf="!permissionsAvailable && !dataAvailable">
    <i class="flaticon2-calendar-9"></i>
    <h4>No permissions for the selected date range</h4>
    <p>Please confirm your dataset subscription...</p>
</div>
