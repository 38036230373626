<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <!--begin::Aside-->
        <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
            <div class="kt-grid__item">
                <a href="/" class="kt-login__logo">
                    <img alt="logo" [src]="'logo-large.png' | assetsSource" alt="" height="130px;" />
                </a>
            </div>
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div class="kt-grid__item kt-grid__item--middle">
                    <h3 class="kt-login__title"><span class="loginPageHeader"></span></h3>
                    <h4 class="kt-login__subtitle">
                        <span class="loginPageParagraph"></span>
                    </h4>
                    <p></p>
                </div>
            </div>

            <div class="kt-grid__item">
                <div class="kt-login__info">
                    <div class="kt-login__copyright">©{{ today | date : 'yyyy' }} Hemisphere Digital Pty Ltd. All Rights Reserved.</div>
                    <div class="kt-login__menu">
                        <a target="_blank" rel="noopener noreferrer" [href]="externalUris.support" class="kt-link">Customer Support</a>
                        <a target="_blank" rel="noopener noreferrer" [href]="externalUris.terms" class="kt-link">Terms of Service</a>
                        <a target="_blank" rel="noopener noreferrer" [href]="externalUris.privacy" class="kt-link">Privacy Policy</a>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::Aside-->

        <!--begin::Content-->
        <div class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
            <div *ngIf="isLoggedIn$ | async" class="meow">
                <h4>Success!</h4>
                <span>You're being redirected now...</span>
                <span>It may take up to 10 seconds</span>
            </div>
            <router-outlet></router-outlet>
        </div>
        <!--end::Content-->
    </div>
</div>
