import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/reducers';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, switchMap, tap } from 'rxjs/operators';
import { Whitelabel } from '../shared/whitelabel.model';
import { WhitelabelService } from '../shared/whitelabel.service';
import { WhitelabelActionType, WhitelabelGet, WhitelabelLoaded } from './whitelabel.actions';

@Injectable()
export class WhitelabelEffects {
    // API
    constructor(
        private service: WhitelabelService,
        private actions$: Actions,
        private store: Store<AppState>
    ) {}

    public loadWhitelabel$ = createEffect(() =>
        this.actions$.pipe(
            ofType<WhitelabelGet>(WhitelabelActionType.WhitelabelGet),
            switchMap(() =>
                this.service.getWhitelabel().pipe(
                    map(
                        (response: Whitelabel) =>
                            new WhitelabelLoaded({
                                whitelabel: response,
                            })
                    )
                )
            )
        )
    );
}
