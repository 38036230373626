import { ModuleWithProviders, NgModule } from '@angular/core';
import { AssetsSourcePipe } from './asset.pipe';
import { FilterIndicatorPipe } from './filter-indicator.pipe';
import { FilterMetricValuePipe } from './filter-metric-value.pipe';
import { FilterMetricPipe } from './filter-metric.pipe';
import { LicencePipe } from './licence-type.pipe';
import { NumberDatePipe } from './number-date.pipe';
import { ShortNumberPipe } from './short-number.pipe';

@NgModule({
    imports: [],
    declarations: [
        ShortNumberPipe,
        FilterMetricPipe,
        FilterIndicatorPipe,
        FilterMetricValuePipe,
        AssetsSourcePipe,
        NumberDatePipe,
        LicencePipe,
    ],
    exports: [
        ShortNumberPipe,
        FilterMetricPipe,
        FilterIndicatorPipe,
        FilterMetricValuePipe,
        AssetsSourcePipe,
        NumberDatePipe,
        LicencePipe,
    ],
})
export class PipeModule {
    static forRoot(): ModuleWithProviders<PipeModule> {
        return {
            ngModule: PipeModule,
            providers: [],
        };
    }
}
