import { Injectable } from '@angular/core';
import { Measure } from '../../../measures/shared/measure.model';
import { DataTableConfig } from '../../data-table/data-table.model';
import {
    MultipleAxesLineChartConfig,
    MultipleAxesLineChartData,
} from '../../multiple-axes-line-chart/multiple-axes-line-chart.model';
import { DigitalIntegrationTrend } from '../../shared/digital-integration.model';

@Injectable({
    providedIn: 'root',
})
export class TrendDataService {
    // Chart Configurations
    // Measures are split into two because they each require their own chart axis
    // Adobe data
    chartMeasures1: Measure[] = [
        {
            title: 'Product Views',
            uniqueName: 'views',
        },
        {
            title: 'Website Visits',
            uniqueName: 'visits',
        },
        {
            title: 'Total ATDW Leads',
            uniqueName: 'leads',
        },
        // {
        //     title: 'Sum of Unique Visits',
        //     uniqueName: 'unique_visits',
        // },
        // {
        //     title: 'Average Time on Site',
        //     uniqueName: 'time_on_site',
        // },
        // {
        //     title: 'Average Time per Visit',
        //     uniqueName: 'time_per_visit',
        // },
    ];

    // Visitation Data
    chartMeasures2: Measure[] = [
        {
            title: 'Unique Visits (Local)',
            uniqueName: 'unique_visits_local',
        },
        {
            title: 'Unique Visits (Visitor)',
            uniqueName: 'unique_visits_visitor',
        },
        {
            title: 'Total Visits (Local)',
            uniqueName: 'total_visits_local',
        },
        {
            title: 'Total Visits (Visitor)',
            uniqueName: 'total_visits_visitor',
        },
    ];

    // Table Configurations
    tableMeasures: Measure[] = [
        // {
        //     title: 'Year',
        //     uniqueName: 'year',
        // },
        // {
        //     title: 'Month',
        //     uniqueName: 'month_number',
        // },
        // {
        //     title: 'Day',
        //     uniqueName: 'day_in_month',
        // },
        {
            title: 'Date',
            uniqueName: 'date',
        },
        {
            title: 'Number of Products',
            uniqueName: 'listing_count',
        },
        {
            title: 'Product Views',
            uniqueName: 'views',
        },
        {
            title: 'Website Visits',
            uniqueName: 'visits',
        },
        {
            title: 'Total ATDW Leads',
            uniqueName: 'leads',
        },
        // {
        //     title: 'Sum of Unique Visits',
        //     uniqueName: 'unique_visits',
        // },
        // {
        //     title: 'Average Time on Site',
        //     uniqueName: 'time_on_site',
        // },
        // {
        //     title: 'Average Time per Visit',
        //     uniqueName: 'time_per_visit',
        // },
        {
            title: 'Unique Visits (Local)',
            uniqueName: 'unique_visits_local',
        },
        {
            title: 'Unique Visits (Visitor)',
            uniqueName: 'unique_visits_visitor',
        },
        {
            title: 'Total Visits (Local)',
            uniqueName: 'total_visits_local',
        },
        {
            title: 'Total Visits (Visitor)',
            uniqueName: 'total_visits_visitor',
        },
    ];

    tableDisplayedColumns: string[] = [
        // 'year',
        // 'month_number',
        // 'day_in_month',
        'date',
        'listing_count',
        'views',
        'visits',
        'leads',
        // 'unique_visits',
        // 'time_on_site',
        // 'time_per_visit',
        'total_visits_local',
        'total_visits_visitor',
        'unique_visits_local',
        'unique_visits_visitor',
    ];

    tableFooterDisplayedColumns: string[] = [
        'grand_total',
        'month_filler',
        'day_filler',
        'listing_count',
        'views',
        'visits',
        'leads',
        'unique_visits',
        'time_on_site',
        'time_per_visit',
        'unique_visits_local',
        'unique_visits_visitor',
        'total_visits_visitor',
        'total_visits_local',
    ];

    constructor() {}

    getDataTableConfig(privacyMask: number, data: DigitalIntegrationTrend[]): DataTableConfig {
        const config: DataTableConfig = {
            displayedColumns: this.tableDisplayedColumns,
            measures: this.tableMeasures,
            data,
            privacyMask,
            footer: false,
            // footerDisplayedColumns: this.tableFooterDisplayedColumns,
        };

        return config;
    }

    getChartConfig(
        data: DigitalIntegrationTrend[],
        title: string,
        showLegend: boolean,
        format: string,
        active?: string[]
    ) {
        const config: MultipleAxesLineChartConfig = {
            data: this.setChartData(data),
            format,
            showLegend,
            title,
            measures: [this.chartMeasures1, this.chartMeasures2],
        };

        if (active) {
            config.active = active;
        }

        return config;
    }

    private setChartData(data: DigitalIntegrationTrend[]): any[] {
        const res: MultipleAxesLineChartData[] = [];

        for (const d of data) {
            const newData: MultipleAxesLineChartData = {
                date: `${d.year}-${d.month_number}-${d.day_in_month}`,
                views: d.views,
                visits: d.visits,
                leads: d.leads,
                unique_visits_local: d.unique_visits_local,
                unique_visits_visitor: d.unique_visits_visitor,
                total_visits_visitor: d.total_visits_visitor,
                total_visits_local: d.total_visits_local,
            };
            res.push(newData);
        }
        return res;
    }
}
