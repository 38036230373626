<div *ngIf="showGraph">
    <hm-mobile-graph-demographics *ngIf="dataAvailable" [selectedMeasure]="selectedReportMeasure" [data]="mobile_GPD_Data">
    </hm-mobile-graph-demographics>

    <hm-report-controls
        [measures]="reportMeasures"
        (selectedMeasureChange)="selectedMeasure($event)"
        [selectedMeasure]="selectedReportMeasure"
    ></hm-report-controls>
</div>
<loading></loading>
<div class="report" *ngIf="showTable">
    <div class="row justify-content-end">
        <button class="btn-dropdown" mat-icon-button [matMenuTriggerFor]="beforeMenu">
            <mat-icon>file_download</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportAs()">CSV</button>
        </mat-menu>
    </div>

    <hm-permission-check [permissionsAvailable]="permissionsAvailable" [dataAvailable]="dataAvailable"></hm-permission-check>

    <table
        class="numerical-table"
        *ngIf="dataAvailable"
        mat-table
        [dataSource]="dataSource"
        matTableExporter
        matSort
        [matSortActive]="selectedReportMeasure.uniqueName"
        matSortDirection="desc"
        matSortDisableClear
        #exporter="matTableExporter"
    >
        <ng-container matColumnDef="age_band" sticky>
            <mat-header-cell *matHeaderCellDef mat-sort-header>Age Bands</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let report">{{ report.age_band }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="gender">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Gender</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.gender }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="unique_visits_local">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Unique Visits (Local)</mat-header-cell>
            <mat-cell
                class="description-cell"
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.unique_visits_local < 10 && report.unique_visits_local > 0,
                    neutral: report.unique_visits_local >= 10 && report.unique_visits_local <= 80
                }"
            >
                <ng-container *ngIf="report.unique_visits_local === -1; else available"> Coming Soon </ng-container>
                <ng-template #available>
                    {{ report.unique_visits_local | shortNumber }}
                </ng-template>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="unique_visits_visitor">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Unique Visits (Visitor)</mat-header-cell>
            <mat-cell
                class="description-cell"
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.unique_visits_visitor < 10 && report.unique_visits_visitor > 0,
                    neutral: report.unique_visits_visitor >= 10 && report.unique_visits_visitor <= 80
                }"
            >
                <ng-container *ngIf="report.unique_visits_visitor === -1; else available"> Coming Soon </ng-container>
                <ng-template #available>
                    {{ report.unique_visits_visitor | shortNumber }}
                </ng-template>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="total_visits_local">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Total Visits (Local)</mat-header-cell>
            <mat-cell
                class="description-cell"
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.total_visits_local < 10 && report.total_visits_local > 0,
                    neutral: report.total_visits_local >= 10 && report.total_visits_local <= 80
                }"
            >
                {{ report.total_visits_local | shortNumber }}</mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="total_visits_visitor">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Total Visits (Visitor)</mat-header-cell>
            <mat-cell
                class="description-cell"
                *matCellDef="let report"
                [ngClass]="{
                    negative: report.total_visits_visitor < 10 && report.total_visits_visitor > 0,
                    neutral: report.total_visits_visitor >= 10 && report.total_visits_visitor <= 80
                }"
                >{{ report.total_visits_visitor | shortNumber }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="local_ratio">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Local Ratio</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.local_ratio | percent : '1.0-0' }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="visitor_ratio">
            <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Visitor Ratio</mat-header-cell>
            <mat-cell *matCellDef="let report">{{ report.visitor_ratio | percent : '1.0-0' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" showFirstLastButton></mat-paginator>
</div>
