/**
 * A query object.
 */
export class Query {
    /**
     * Array of fields (columns) to include in the flat table response.
     */
    fields?: Field[];
    /**
     * Query aggregations, the part of the query that specifies which data should be
     * aggregated and how.
     */
    aggs?: Aggs;
    /**
     * Query filters. The part of a query that specifies which filters should be applied.
     */
    filter?: FilterGroup;
    /**
     * The maximum number of records that should be included in the flat-table response.
     * Configurable on the client. Default value `1000`.
     */
    limit?: number;
}

/**
 * A cube's field.
 */
export class Field {
    constructor(public uniqueName: string, public interval?: string) {}
}

export class Aggs {
    /**
     * Values to aggregate. Fields that have at least one supported aggregation
     * defined in the schema can be selected for the query as values.
     */
    values: AggField[] = [];
    /**
     * Fields to aggregate by, in rows and/or columns.
     */
    by?: {
        rows: Field[];
        cols: Field[];
    };
    /**
     * Return totals and subtotals over `by` fields in query. On by default.
     */
    totals?: boolean = true;
}

export class AggField {
    constructor(public field: Field, public func: AggFunc) {}
}

export type AggFunc =
    | 'sum'
    | 'count'
    | 'distinctcount'
    | 'average'
    | 'median'
    | 'product'
    | 'min'
    | 'max'
    | 'stdevp'
    | 'stdevs'
    | 'none';

/**
 * A query filter.
 */
export class Filter {
    constructor(
        public field: Field,
        public include?: FilterMember[],
        public exclude?: FilterMember[],
        public query?: FilterQuery
    ) {}
}

export enum FilterQueryType {
    Between = 'between',
    NotBetween = 'not_between',
    Equal = 'equal',
    NotEqual = 'not_equal',
    Begin = 'begin',
    NotBegin = 'not_begin',
    End = 'end',
    NotEnd = 'not_end',
    Contain = 'contain',
    NotContain = 'not_contain',
    Greater = 'greater',
    GreaterEqual = 'greater_equal',
    Less = 'less',
    LessEqual = 'less_equal',
    Before = 'before',
    BeforeEqual = 'before_equal',
    After = 'after',
    AfterEqual = 'after_equal',
    Last = 'last',
    Current = 'current',
    Next = 'next',
}

export type FilterQuery = {
    [key in FilterQueryType]?: string | number | string[] | number[];
};

/**
 * A field member value to filter by.
 * May contain a recursive filter to enable working with multi-level hierarchies.
 */
export class FilterMember {
    constructor(public member: Value, public filter?: Filter) {}
}

export type Value = string | number;

export type FilterGroupType = 'and' | 'or';

/**
 * A group of filters
 */
export class FilterGroup {
    constructor(public type: FilterGroupType, public value: Array<FilterGroup | Filter>) {}
}

/**
 * Aggregated values for a given member tuple
 */
export interface AggValues {
    /**
     * Fields’ values that describe a tuple, for which `values` are the corresponding
     * aggregate values. In case it is not defined/empty, `values` are grand totals.
     */
    keys: {
        [uniqueName: string]: Value;
    };
    /**
     * Numeric values aggregated for a specific tuple described by `keys`.
     * Map of field `uniqueName` to map of aggregate function name to aggregate value.
     */
    values: {
        [uniqueName: string]: {
            [func in AggFunc]: number;
        };
    };
}
