<div class="kt-mat-alert kt-mat-alert--{{ type }}" role="alert">
    <div class="kt-mat-alert__icon">
        <i class="la la-warning"></i>
    </div>
    <div class="kt-mat-alert__text">
        <ng-content></ng-content>
    </div>
    <div class="kt-mat-alert__close" *ngIf="showCloseButton">
        <button type="button" mat-icon-button color="warn" (click)="closeAlert()">
            <mat-icon class="material-icons">clear</mat-icon>
        </button>
    </div>
</div>
