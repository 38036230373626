import { P } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Level } from 'app/views/pages/hotels/shared/listings.service';
import { Measure } from '../../../measures/shared/measure.model';
import { DataTableConfig } from '../../data-table/data-table.model';
import { HeatMapConfig } from '../../heat-map/shared/heat-map.model';
import { DigitalIntegrationFromWhere, DIFWCalculated } from '../../shared/from-where.model';

@Injectable({
    providedIn: 'root',
})
export class ProfileDataService {
    // HeatMap Configurations
    heatMapMeasures: Measure[] = [
        {
            title: 'Unique Visits (Local)',
            uniqueName: 'unique_visits_local',
        },
        {
            title: 'Unique Visits (Visitor)',
            uniqueName: 'unique_visits_visitor',
        },
        {
            title: 'Spend per Cardholder (Local)',
            uniqueName: 'spend_per_cardholder_local',
        },
        {
            title: 'Spend per Cardholder (Visitor)',
            uniqueName: 'spend_per_cardholder_visitor',
        },
        {
            title: 'Spend per Cardholder (Local) 12 Months Avg',
            uniqueName: 'spend_per_cardholder_local_12months',
        },
        {
            title: 'Spend per Cardholder (Visitor) 12 Months Avg',
            uniqueName: 'spend_per_cardholder_visitor_12months',
        },
    ];

    // Table Configurations
    tableMeasures: Measure[] = [
        {
            title: 'Boundary Name',
            uniqueName: 'boundary_name',
        },
        {
            title: 'Unique Visits (Local)',
            uniqueName: 'unique_visits_local',
        },
        {
            title: 'Unique Visits (Visitor)',
            uniqueName: 'unique_visits_visitor',
        },
        {
            title: 'Spend per Cardholder (Local)',
            uniqueName: 'spend_per_cardholder_local',
        },
        {
            title: 'Spend per Cardholder (Visitor)',
            uniqueName: 'spend_per_cardholder_visitor',
        },
        {
            title: 'Spend per Cardholder (Local) 12 Months Avg',
            uniqueName: 'spend_per_cardholder_local_12months',
        },
        {
            title: 'Spend per Cardholder (Visitor) 12 Months Avg',
            uniqueName: 'spend_per_cardholder_visitor_12months',
        },
    ];

    tableDisplayedColumns: string[] = [
        'boundary_name',
        'unique_visits_local',
        'unique_visits_visitor',
        'spend_per_cardholder_local',
        'spend_per_cardholder_visitor',
        'spend_per_cardholder_local_12months',
        'spend_per_cardholder_visitor_12months',
    ];

    tableFooterDisplayedColumns: string[] = [
        'grand_total',
        'unique_visits_local',
        'unique_visits_visitor',
        'spend_per_cardholder_local',
        'spend_per_cardholder_visitor',
        'spend_per_cardholder_local_12months',
        'spend_per_cardholder_visitor_12months',
    ];

    constructor() {}

    getHeatMapConfig(data: DIFWCalculated[], boundaryType: Level): HeatMapConfig {
        const config: HeatMapConfig = {
            data,
            measures: this.heatMapMeasures,
            boundaryType,
        };

        return config;
    }

    getDataTableConfig(privacyMask: number, data: DIFWCalculated[]): DataTableConfig {
        const config: DataTableConfig = {
            displayedColumns: this.tableDisplayedColumns,
            measures: this.tableMeasures,
            data,
            privacyMask,
            footer: false,

            // footerDisplayedColumns: this.tableFooterDisplayedColumns,
        };

        return config;
    }

    getCalculatedFields(data: DigitalIntegrationFromWhere[]): DIFWCalculated[] {
        const result: DIFWCalculated[] = [];

        data.forEach((d) => {
            let spendLocal: string | number = '—'; // em dash to match flexmonster report
            if (d.vol_tran_local && d.val_tran_local) {
                // To prevent divison by zero;
                if (d.vol_tran_local !== 0) {
                    spendLocal = d.val_tran_local / d.vol_tran_local;
                } else {
                    spendLocal = 0;
                }
            }

            let spendVisitor: string | number = '—';
            if (d.val_tran_visitor && d.vol_tran_visitor) {
                if (d.vol_tran_visitor !== 0) {
                    spendVisitor = d.val_tran_visitor / d.vol_tran_visitor;
                } else {
                    spendVisitor = 0;
                }
            }

            let spend12MonthsLocal: number | string = '—';
            if (d.vol_tran_local_12months && d.val_tran_local_12months) {
                if (d.vol_tran_local_12months !== 0) {
                    spend12MonthsLocal = d.val_tran_local_12months / d.vol_tran_local_12months;
                } else {
                    spend12MonthsLocal = 0;
                }
            }

            let spend12MonthsVisitor: number | string = '—';
            if (d.vol_tran_visitor_12months && d.val_tran_visitor_12months) {
                if (d.vol_tran_visitor_12months !== 0) {
                    spend12MonthsVisitor =
                        d.val_tran_visitor_12months / d.vol_tran_visitor_12months;
                } else {
                    spend12MonthsVisitor = 0;
                }
            }
            const newDIFWCalculated: DIFWCalculated = {
                boundary_code: d.boundary_code,
                boundary_name: d.boundary_name,
                boundary_state: d.boundary_state,
                boundary_type: d.boundary_type,
                unique_visits_local: d.unique_visits_local,
                unique_visits_visitor: d.unique_visits_visitor,
                spend_per_cardholder_local: spendLocal,
                spend_per_cardholder_visitor: spendVisitor,
                spend_per_cardholder_local_12months: spend12MonthsLocal,
                spend_per_cardholder_visitor_12months: spend12MonthsVisitor,
            };

            result.push(newDIFWCalculated);
        });
        return result;
    }
}
