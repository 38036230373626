import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DataPermission } from 'app/core/data-permissions/shared/data-permission.model';

export enum UserDataPermissionActionType {
    LoadUserDataPermissions = '[User DataPermission] Load',
    LoadUserDataPermissionsSuccess = '[User DataPermission] Load Success',
    LoadUserDataPermissionsFailure = '[User DataPermission] Load Failure',
}

export class LoadUserDataPermissionsAction implements Action {
    public readonly type = UserDataPermissionActionType.LoadUserDataPermissions;
    constructor() {}
}

export class LoadUserDataPermissionsSuccessAction implements Action {
    public readonly type = UserDataPermissionActionType.LoadUserDataPermissionsSuccess;
    constructor(public payload: { userDataPermission: DataPermission[] }) {}
}

export class LoadUserDataPermissionsFailureAction implements Action {
    public readonly type = UserDataPermissionActionType.LoadUserDataPermissionsFailure;
    constructor(public error: any) {}
}

export type UserDataPermissionActions =
    | LoadUserDataPermissionsAction
    | LoadUserDataPermissionsSuccessAction
    | LoadUserDataPermissionsFailureAction;
