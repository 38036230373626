<div class="slider-container">
    <div class="slides" #slides>
        <ng-content select="[appSliderItem]" #sliderItem></ng-content>
    </div>
</div>
<div class="controls" *ngIf="getTotal() > 1">
    <button color="primary" mat-icon-button aria-label="left" (click)="onClickLeft()">
        <mat-icon class="icon-size">navigate_before</mat-icon>
    </button>
    <button mat-icon-button aria-label="right" (click)="onClickRight()">
        <mat-icon class="icon-size" color="primary">navigate_next</mat-icon>
    </button>
</div>
